import {
    homePageRoute,
    showfloorPageRoute,
    programPageRoute,
    conferencePageRoute,
    networkingPageRoute,
    sponsorsPageRoute,
    couponsPageRoute,
    pressMediaPageRoute,
    businessAreaPageRoute,
    iframeTestPageRoute,
    exhibitorStartupPageRoute,
    helpPageRoute,
    collectionOverviewRoute,
    hallplanOverviewRoute
} from "../RoutePaths"
import {
    IconHome,
    IconOrganization,
    IconProgram,
    IconMeetings,
    IconNetworking,
    IconSponsor,
    IconCoupon,
    IconMyFair,
    IconPressMedia,
    IconBusinessArea,
    IconIframeTest,
    IconExhibitorStartup,
    IconNavigationHelp,
    IconCollections,
    IconHallplan,
    IconMySchedule
} from "../../ui/Icons"
import branding, { Branding } from "../../branding/branding"

//side icon bar enumeration for IDs
export enum SideIconBarItemIds {
    "LOBBY" = "LOBBY",
    "MY_FAVOURITES" = "MY_FAVOURITES",
    "MY_SCHEDULE" = "MY_SCHEDULE",
    "SHOWFLOOR" = "SHOWFLOOR",
    "PROGRAM" = "PROGRAM",
    "VIRTUAL_CAFES" = "VIRTUAL_CAFES",
    "NETWORKING" = "NETWORKING",
    "PARTNERS_SPONSORS" = "PARTNERS_SPONSORS",
    "GOODIE_BAG" = "GOODIE_BAG",
    "PRESS_MEDIA" = "PRESS_MEDIA",
    "BUSINESS_AREA" = "BUSINESS_AREA",
    "IFRAME_TEST" = "IFRAME_TEST",
    "STARTUP" = "STARTUP",
    "HELP" = "HELP",
    "COLLECTIONS" = "COLLECTIONS",
    "HALLPLAN" = "HALLPLAN"
}

//side icon bar enumeration for keys
export enum SideIconBarItemKeys {
    "home" = "home",
    "organization" = "organization",
    "program" = "program",
    "meetings" = "meetings",
    "network" = "network",
    "collections" = "collections",
    "hallplan" = "hallplan",
    "sponsor" = "sponsor",
    "coupon" = "coupon",
    "press_media" = "press_media",
    "business" = "business",
    "iframe_test" = "iframe_test",
    "startup" = "startup",
    "help" = "help"
}

export function checkActiveItem(
    key: string,
    lobbyCheck: boolean,
    checkString: string,
    currentItem: string,
    myToggle: string,
    isMobile?: boolean
): boolean {
    let toggle = myToggle

    if (lobbyCheck) {
        if (toggle === "MY_SCHEDULE" && !branding.sideIconBar.sideIconBarItemsOrder.includes(SideIconBarItemIds.MY_SCHEDULE)) {
            toggle = "MY_FAIR"
        }

        if (isMobile === false && !branding.sideIconBar.sideIconBarItemsOrder.includes(SideIconBarItemIds.MY_FAVOURITES)) {
            return currentItem === key && (toggle === SideIconBarItemIds.LOBBY || toggle === "MY_FAIR")
        }

        return currentItem === key && toggle === checkString
    }
    return currentItem === key
}

interface SideBarConfigType {
    id: SideIconBarItemIds
    key: SideIconBarItemKeys
    path: string
    icon: any
    title: string
    shortName: string
    lobbyCheck: boolean
    checkString?: string
    isExternalLink?: boolean
    customUrl?: string
    hideOnMobile?: boolean
}

//method which provides side icon bar items with configuration
export const SideBarConfig = (
    strings: Branding,
    currentItem: string,
    isMeetingPage: boolean,
    myToggle: string,
    isNavOpen: boolean,
    isMobile?: boolean
): Array<SideBarConfigType> => {
    function isActiveItem(key: string, lobbyCheck?: boolean, checkString?: string): boolean {
        return checkActiveItem(key, lobbyCheck ?? false, checkString ?? "", currentItem, myToggle, isMobile)
    }

    function getIconFill(active: boolean): string {
        let color: string = isMeetingPage
            ? branding.sideIconBar.sideIconColorLight
            : isNavOpen
            ? branding.activeIconColor
            : branding.navBarActiveIconColor
        // (isNavOpen && active ? branding.navBarActiveIconColor : (!isNavOpen && active ? branding.navBarActiveIconColor : branding.navBarActiveIconColor))

        if (!active && !isMobile) {
            color = branding.sideIconBar.inactiveItemColor
        }

        return color
    }

    function getIconStroke(active: boolean): string {
        let color: string =
            isMeetingPage && !active
                ? branding.sideIconBar.sideIconColorLight
                : !isNavOpen && active
                ? branding.sideIconBar.sideIconColorDark
                : isNavOpen && active
                ? branding.sideIconBar.sideIconColorDark
                : branding.sideIconBar.sideIconColorLight
        return color
    }

    return [
        {
            id: SideIconBarItemIds.LOBBY,
            key: SideIconBarItemKeys.home,
            path: homePageRoute,
            icon: IconHome({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.home, true, SideIconBarItemIds.LOBBY)) }),
            title: strings.sideIconBar.lobbyMenuText,
            shortName: strings.receptionPage.navbarHomePageShortName,
            lobbyCheck: true,
            checkString: SideIconBarItemIds.LOBBY
        },
        {
            id: SideIconBarItemIds.MY_FAVOURITES,
            key: SideIconBarItemKeys.home,
            path: homePageRoute,
            icon: IconMyFair({
                fill: getIconFill(isActiveItem(SideIconBarItemKeys.home, true, "MY_FAIR"))
            }),
            title: strings.receptionPage.receptionPageMyHeaderTitle,
            shortName: strings.receptionPage.navbarMyPageShortName,
            lobbyCheck: true,
            checkString: "MY_FAIR"
        },
        {
            id: SideIconBarItemIds.MY_SCHEDULE,
            key: SideIconBarItemKeys.home,
            path: homePageRoute,
            icon: IconMySchedule({
                fill: getIconFill(isActiveItem(SideIconBarItemKeys.home, true, "MY_SCHEDULE")),
                stroke: getIconStroke(isActiveItem(SideIconBarItemKeys.home, true, "MY_SCHEDULE"))
            }),
            title: strings.receptionPage.mySchedule,
            shortName: strings.receptionPage.mySchedule,
            lobbyCheck: true,
            checkString: "MY_SCHEDULE"
        },
        {
            id: SideIconBarItemIds.SHOWFLOOR,
            key: SideIconBarItemKeys.organization,
            path: showfloorPageRoute,
            icon: IconOrganization({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.organization)) }),
            title: strings.sideIconBar.showfloorMenuText,
            shortName: strings.receptionPage.navbarShowfloorPageShortName,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.PROGRAM,
            key: SideIconBarItemKeys.program,
            path: programPageRoute,
            icon: IconProgram({
                fill: getIconFill(isActiveItem(SideIconBarItemKeys.program)),
                stroke: getIconStroke(isActiveItem(SideIconBarItemKeys.program))
            }),
            title: strings.sideIconBar.programMenuText,
            shortName: strings.receptionPage.navbarProgramPageShortName,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.VIRTUAL_CAFES,
            key: SideIconBarItemKeys.meetings,
            path: conferencePageRoute,
            icon: IconMeetings({
                fill: getIconFill(isActiveItem(SideIconBarItemKeys.meetings)),
                stroke: getIconStroke(isActiveItem(SideIconBarItemKeys.meetings))
            }),
            title: strings.sideIconBar.virtualcafeMenuText,
            shortName: strings.receptionPage.navbarVirtualCafePageShortName,
            lobbyCheck: false,
            hideOnMobile: true
        },
        {
            id: SideIconBarItemIds.COLLECTIONS,
            key: SideIconBarItemKeys.collections,
            path: collectionOverviewRoute,
            icon: IconCollections({
                fill: getIconFill(isActiveItem(SideIconBarItemKeys.collections)),
                stroke: getIconStroke(isActiveItem(SideIconBarItemKeys.collections))
            }),
            title: strings.sideIconBar.collectionsMenuText,
            shortName: strings.receptionPage.navbarCollectionsPageShortName,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.HALLPLAN,
            key: SideIconBarItemKeys.hallplan,
            path: hallplanOverviewRoute,
            icon: IconHallplan({
                fill: getIconFill(isActiveItem(SideIconBarItemKeys.hallplan)),
                stroke: getIconStroke(isActiveItem(SideIconBarItemKeys.hallplan))
            }),
            title: strings.sideIconBar.hallplanMenuText,
            shortName: strings.receptionPage.navbarHallplanPageShortName,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.NETWORKING,
            key: SideIconBarItemKeys.network,
            path: networkingPageRoute,
            icon: IconNetworking({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.network)) }),
            title: strings.sideIconBar.networkingMenuText,
            shortName: strings.receptionPage.navbarNetworkingPageShortName,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.PARTNERS_SPONSORS,
            key: SideIconBarItemKeys.sponsor,
            path: sponsorsPageRoute,
            icon: IconSponsor({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.sponsor)) }),
            title: strings.sideIconBar.mediapartnersMenuText,
            shortName: strings.receptionPage.navbarPartnersSponsorsPageShortName,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.GOODIE_BAG,
            key: SideIconBarItemKeys.coupon,
            path: couponsPageRoute,
            icon: IconCoupon({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.coupon)) }),
            title: strings.sideIconBar.goodiebagMenuText,
            shortName: strings.receptionPage.navbarGoodieBagPageShortName,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.PRESS_MEDIA,
            key: SideIconBarItemKeys.press_media,
            path: pressMediaPageRoute,
            icon: IconPressMedia({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.press_media)) }),
            title: strings.sideIconBar.pressMediaMenuText,
            shortName: strings.receptionPage.navbarPressMediaPageShortName,
            lobbyCheck: false,
            hideOnMobile: true
        },
        {
            id: SideIconBarItemIds.BUSINESS_AREA,
            key: SideIconBarItemKeys.business,
            path: businessAreaPageRoute,
            icon: IconBusinessArea({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.business)) }),
            title: strings.sideIconBar.businessAreaMenuText,
            shortName: strings.receptionPage.navbarLeadFinderPageShortName,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.IFRAME_TEST,
            key: SideIconBarItemKeys.iframe_test,
            path: iframeTestPageRoute,
            icon: IconIframeTest({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.iframe_test)) }),
            title: strings.sideIconBar.iFrameTestMenuText,
            shortName: strings.receptionPage.navbarIFramePageShortName,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.STARTUP,
            key: SideIconBarItemKeys.startup,
            path: exhibitorStartupPageRoute,
            icon: IconExhibitorStartup({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.startup)) }),
            title: strings.sideIconBar.exhibitorStartupMenuText,
            shortName: strings.receptionPage.navbarStartupPageShortName,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.HELP,
            key: SideIconBarItemKeys.help,
            path: helpPageRoute,
            icon: IconNavigationHelp({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.help)) }),
            title: strings.sideIconBar.helpItem.title,
            shortName: strings.receptionPage.navbarHelpPageShortName,
            lobbyCheck: false,
            isExternalLink: branding.sideIconBar.helpItem.isExternalLink,
            customUrl: strings.sideIconBar.helpItem.externalLink ?? strings.sideIconBar.helpItem.link
        }
    ]
}
