import { format } from "date-fns"
import _ from "lodash"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import * as React from "react"
import { Col, Row, Tooltip } from "react-bootstrap"
import { useParams } from "react-router"
import styled from "styled-components"
import { syncFavorites } from "../../backendServices/FavoritesServices"
import {
    AccessProvider,
    Attachment,
    Category,
    EntityType,
    Event,
    EventDate,
    Person,
    ShareTargetType
} from "../../backendServices/Types"
import branding from "../../branding/branding"
import { saveEvent, saveEvents } from "../../communicationArea/ICal"
import { useAppState } from "../../globalStates/AppState"
import { useCategoriesState } from "../../globalStates/CategoriesState"
import { useFavoriteState } from "../../globalStates/Favorites"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import Breadcrumb, { BreadcrumbItem } from "../../navigationArea/Breadcrumb"
import { programPageRoute } from "../../navigationArea/RoutePaths"
import TopBar from "../../navigationArea/TopBar"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import { AvatarWithPresenceState } from "../../ui/AvatarWithPresenceState"
import BookmarkWithToggle from "../../ui/BookmarkWithToggle"
import CenteredLoader from "../../ui/CenteredLoader"
import ContextMenu from "../../ui/ContextMenu"
import {
    IconArrowDownLangSwitcher,
    IconBookmarkFilled,
    IconBookmarkToggle,
    IconDownload,
    IconFile,
    IconLiveStream,
    IconLock,
    IconShare,
    IconThreeDotsMenu,
    IconTicket,
    IconVideo
} from "../../ui/Icons"
import { useRecommendModal } from "../../ui/modals/RecommendModal"
import ScrollBarComponent from "../../ui/ScrollBar"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import { Staff, StaffEntry, StaffType } from "../../ui/StaffEntry"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { isEventDateLive } from "../../utils/DateUtils"
import { device, MobileVersionContainer, DesktopVersionContainer } from "../../utils/Device"
import TopBannerSharedState from "../advertisingBanner/TopBannerSharedState"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"
import { SectionTopButton } from "../myprofile/MobileMyProfilePageContent"
import { PointsBadge } from "../program/EventDateEntry"
import Categories from "./components/Categories"
import { filterCategories, ModalRoot, ScrollableDiv } from "./components/DetailPageSections"
import { FormattedDateExtensionDiv, FormattedDatePar, HeaderTitle } from "./components/StyledComponents"
import { buildDetailLink, DetailNavLink } from "./DetailNavLink"
import { EventDateDetailSection } from "./DetailPageBranding"
import { EventDateContentCol, EventDateView } from "./PersonDetailPageContent"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import BadgeArea from "../../ui/BadgeArea"
import { CustomOverlayTrigger } from "../../ui/CustomOverlayTrigger"
import { EventDetailsResponse, loadEventDetails } from "../../backendServices/EventServices"

/* #region  Share and bookmark icons component */
const ShareIcon = styled.div`
    cursor: pointer;
    margin-right: 20px;
    color: ${branding.primaryColor};
`
interface ShareBookmarkIconsProps {
    person?: Person
    event?: Event
    type: EntityType
    strings: any
    showRecommendModal: (shareType: ShareTargetType, entityId: string, link: string) => void
}

const ShareBookmarkIcons: React.FunctionComponent<ShareBookmarkIconsProps> = (props) => {
    const favorites = useFavoriteState()
    if (!props.event && !props.person) return null

    const targetId = props.event ? props.event.id : props.person!.id
    const type = props.event ? ShareTargetType.EVENT : ShareTargetType.PERSON
    const link = props.event
        ? buildDetailLink(targetId, props.event.name, "event")
        : buildDetailLink(props.person!.id, `/person/${props.person!.firstName}_${props.person!.lastName}`, "person")

    const isBookmarked = favorites.is("event", props.event?.id!)

    return (
        <>
            {props.event && (
                <CustomOverlayTrigger
                    trigger={["focus", "click", "hover"]}
                    placement="bottom"
                    delay={{ show: 250, hide: 250 }}
                    overlay={
                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                            {branding.backofficeReporting.download}
                        </Tooltip>
                    }
                >
                    <div>
                        <ShareIcon
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()

                                saveEvents(props.strings, props.event?.eventDates!)
                            }}
                        >
                            {IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}
                        </ShareIcon>
                    </div>
                </CustomOverlayTrigger>
            )}
            <CustomOverlayTrigger
                trigger={["focus", "click", "hover"]}
                placement="bottom"
                delay={{ show: 250, hide: 250 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {branding.communicationArea.chatToolRecommend}
                    </Tooltip>
                }
            >
                <div>
                    <ShareIcon
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            props.showRecommendModal(type, targetId, link)
                        }}
                    >
                        {IconShare({ fill: branding.sideIconBar.sideIconColorDark })}
                    </ShareIcon>
                </div>
            </CustomOverlayTrigger>
            <CustomOverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="button-tooltip-share" style={{ fontFamily: branding.font1 }}>
                        {isBookmarked
                            ? branding.organizationDetailPageContent.unBookmarkPerson
                            : branding.organizationDetailPageContent.bookmarkPerson}
                    </Tooltip>
                }
            >
                <div>
                    <BookmarkWithToggle
                        newBookmarkItem={true}
                        fontSize={"25px"}
                        color={branding.sideIconBar.sideIconColorDark}
                        favIconBasic={true}
                        type={props.type}
                        id={props.type === "person" ? props.person?.id! : props.event?.id!}
                        name={props.type === "person" ? props.person?.name! : props.event?.name!}
                    />
                </div>
            </CustomOverlayTrigger>
        </>
    )
}
/* #endregion */

/* #region  Person and attachment data component */
const PersonAttachmentRow = styled(Row)`
    display: flex;
    margin-bottom: 25px;
`

const PersonAttachmentImageCol = styled(Col)`
    display: auto;
    align-items: center;
`

const PersonColInfo = styled(Col)`
    font-family: ${branding.font1};
    display: flex;
    align-items: left;
`

const PersonInfoDiv = styled.div<{ size: number }>`
    font-family: ${branding.font1};
    width: ${(props) => (props.size > 1600 ? "55%" : "40%")};
    margin-left: -20px;
`

const PersonAttachmentTitle = styled.h5`
    font-family: ${branding.font1};
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px !important;
    margin-top: 10px;
`

const PersonPosition = styled.p`
    font-family: ${branding.font1};
    font-size: 12px;
    white-space: pre-wrap;
    margin-bottom: 0px !important;
`

const PersonAttachmentColIcons = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const AttachmentExstensionDiv = styled.div`
    border: 1px solid ${branding.mainInfoColor};
    border-radius: 5px;
    padding: 3px 5px;
    margin-left: 20px;
`

const ExstensionText = styled.p`
    font-size: 18px;
    font-family: ${branding.font1};
`

const DownloadIconDiv = styled.div`
    cursor: pointer;
`

interface PersonAttachmentDataProps {
    person?: Person
    attachment?: Attachment
    type: "person" | "attachment"
    setSelectedAttachment?(value: Attachment): void
}

const PersonAttachmentData: React.FunctionComponent<PersonAttachmentDataProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const windowSize = useWindowDimensions()

    const [staff, setStaff] = useState<Staff>()

    function handleClick() {
        if (props.setSelectedAttachment) props.setSelectedAttachment(props.attachment!)
    }

    useEffect(() => {
        setStaff({
            firstName: props.person?.firstName!,
            lastName: props.person?.lastName!,
            id: props.person?.id!,
            type: StaffType.PERSON,
            logoUrl: props.person?.logoUrl!,
            position: props.person?.position!,
            company: props.person?.organization!,
            sotUserId: props.person?.userId!,
            visible: true,
            showroomStandby: false,
            title: "",
            categories: []
        })
    }, [props.person])

    if (props.type === "person" && props.person) {
        return (
            <>
                <DesktopVersionContainer>
                    <PersonAttachmentRow>
                        <PersonAttachmentImageCol sm={4} className="pr-0">
                            {props.person.userId && (
                                <DetailNavLink
                                    id={props.person.id}
                                    type={"person"}
                                    name={`/person/${props.person.firstName}_${props.person.lastName}`}
                                >
                                    <AvatarWithPresenceState
                                        badgeSize={20}
                                        showAvatarBadge={true}
                                        badgeRight={windowSize.width < 1850 ? -16 : -45}
                                        badgeTop={windowSize.width < 1850 ? 50 : 70}
                                        userId={props.person.userId}
                                        avatarSize={windowSize.width < 1850 ? 71 : 101}
                                        content={{
                                            pictureUrl: props.person.logoUrl || props.person.userLogoUrl,
                                            alt: [props.person.firstName, props.person.lastName].filter(Boolean).join(" ") ?? "#"
                                        }}
                                    />
                                </DetailNavLink>
                            )}
                            {!props.person.userId && (
                                <AvatarWithDefault
                                    size={windowSize.width < 1850 ? 65 : windowSize.width < 1350 ? 45 : 95}
                                    src={props.person.userLogoUrl ?? props.person.logoUrl ?? undefined}
                                    alt={[props.person.firstName, props.person.lastName].filter(Boolean).join(" ") ?? "#"}
                                    backgroundSize="cover"
                                />
                            )}
                        </PersonAttachmentImageCol>
                        <PersonInfoDiv
                            size={windowSize.width}
                            style={{
                                marginLeft:
                                    windowSize.width > 2450
                                        ? "-20px"
                                        : windowSize.width > 2060
                                        ? "5px"
                                        : windowSize.width > 1850
                                        ? "22px"
                                        : windowSize.width > 1680
                                        ? "5px"
                                        : windowSize.width > 1500
                                        ? "15px"
                                        : windowSize.width > 1300
                                        ? "25px"
                                        : "35px"
                            }}
                        >
                            {props.person.userId && (
                                <DetailNavLink
                                    id={props.person.id}
                                    type={"person"}
                                    name={`/person/${props.person.firstName}_${props.person.lastName}`}
                                >
                                    <PersonAttachmentTitle>
                                        {[props.person.title, props.person.firstName, props.person.lastName]
                                            .filter(Boolean)
                                            .join(" ")}
                                    </PersonAttachmentTitle>
                                    <PersonPosition>
                                        {[props.person.position, props.person.organization]
                                            .filter(Boolean)
                                            .join(" " + strings.communicationArea.personCompanyLink + " ")}
                                    </PersonPosition>
                                </DetailNavLink>
                            )}
                            {!props.person.userId && (
                                <DetailNavLink
                                    id={props.person.id}
                                    type={"person"}
                                    name={`/person/${props.person.firstName}_${props.person.lastName}`}
                                >
                                    <PersonAttachmentTitle>
                                        {[props.person.title, props.person.firstName, props.person.lastName]
                                            .filter(Boolean)
                                            .join(" ")}
                                    </PersonAttachmentTitle>
                                    <PersonPosition>
                                        {[props.person.position, props.person.organization]
                                            .filter(Boolean)
                                            .join(" " + strings.communicationArea.personCompanyLink + " ")}
                                    </PersonPosition>
                                </DetailNavLink>
                            )}
                        </PersonInfoDiv>
                    </PersonAttachmentRow>
                </DesktopVersionContainer>
                <MobileVersionContainer>
                    {staff && <StaffEntry staff={staff} organizationId={""} backOffice={false} speaker={true} />}
                </MobileVersionContainer>
            </>
        )
    } else if (props.attachment) {
        let index = props.attachment.url.lastIndexOf(".")
        let exstension = props.attachment.url.substring(index + 1).toUpperCase()
        return (
            <PersonAttachmentRow>
                <PersonAttachmentImageCol xs={1}>{IconFile({ width: "20px", height: "20px" })}</PersonAttachmentImageCol>
                <PersonColInfo xs={8}>
                    <PersonAttachmentTitle>{props.attachment.title ? props.attachment.title : "NO TITLE"}</PersonAttachmentTitle>
                    <AttachmentExstensionDiv>
                        <ExstensionText>{exstension}</ExstensionText>
                    </AttachmentExstensionDiv>
                </PersonColInfo>
                <PersonAttachmentColIcons xs={3}>
                    <DownloadIconDiv onClick={handleClick}>
                        {IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}
                    </DownloadIconDiv>
                </PersonAttachmentColIcons>
                <Col xs={2}></Col>
            </PersonAttachmentRow>
        )
    } else {
        return <></>
    }
}
/* #endregion */

/* #region  All persons content */
const PersonsAttachmentsContentRow = styled(Row)<{ descriptionExists?: any }>`
    font-family: ${branding.font1};
    margin-left: "30px";
    border-left: ${(props) =>
        props.descriptionExists ? (branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9") : "none"};
    padding-left: 20px;

    @media ${device.mobile} {
        border-left: none;
    }
`

const PersonsAttachmentsContentCol = styled(Col)`
    font-family: ${branding.font1};
    padding: 0px 0;
`
interface PersonsAttachmentsHeaderPositionRowProps {
    type: "person" | "attachment"
}

const PersonsAttachmentsHeaderPositionRow = styled(Row)<PersonsAttachmentsHeaderPositionRowProps>`
    margin: ${(props) => (props.type === "person" ? "30px 0 25px 0" : "30px 0 25px 0")};
`

const PersonsAttachmentsHeaderTitle = styled.h3`
    font-family: ${branding.font1};
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
`

const SessionRow = styled(Row)`
    line-height: 30px;
`
interface SessionDataProps {
    session: EventDate
}

const SessionData: React.FunctionComponent<SessionDataProps> = (props) => {
    const languageState = useLanguageState()
    const favorites = useFavoriteState()
    const strings = languageState.getStrings()
    const lang = languageState.getLanguage()
    const displayTicketIcon = props.session?.accessProvider === AccessProvider.TICKET

    const isBookmarked = favorites.is("eventdate", props.session?.id!)

    const { RecommendModal, showRecommendModal } = useRecommendModal()

    function getFormattedDate(date: string, start: string) {
        return (
            format(moment(date).toDate(), strings.eventTiming.eventDaysFormatPatternEvtDetailPage, {
                locale: lang === "de" ? de : en
            }) +
            (lang === "de" ? " um " : " at ") +
            start +
            (lang === "de" ? " Uhr" : "")
        ) //have to find a better way to do this but this works for now
    }
    const isLive = isEventDateLive(props.session)

    const categoriesState = useCategoriesState()
    const [pointsBadgeData, setPointsBadgeData] = useState<Category>()

    useEffect(() => {
        if (categoriesState.getCategories().length)
            setPointsBadgeData(categoriesState.getCategoryByAlias(branding.programSchedule.pointsBadgeCategoryAlias))
        // eslint-disable-next-line
    }, [])

    return (
        <DetailNavLink
            name={props.session.name}
            id={isLive && props.session.channel ? props.session.channel.id : props.session.id}
            type={isLive && props.session.channel ? "channel" : "eventdate"}
            source="EVENT"
        >
            <SessionRow>
                <Col style={{ paddingRight: "0", flex: "0 0 20px", marginRight: "20px" }} xs={1}>
                    {IconVideo({ fill: branding.sideIconBar.sideIconColorDark })}
                </Col>
                <Col xs={8} className="p-0">
                    <FormattedDatePar>{props.session.name}</FormattedDatePar>
                    <FormattedDateExtensionDiv>
                        {getFormattedDate(props.session.date, props.session.start)}
                    </FormattedDateExtensionDiv>
                    {props.session.location && <FormattedDateExtensionDiv>{props.session.location}</FormattedDateExtensionDiv>}
                    {pointsBadgeData &&
                        props.session.categories
                            ?.map((x) => x.alias)
                            ?.includes(branding.programSchedule.pointsBadgeCategoryAlias) && (
                            <PointsBadge
                                className={"ml-2"}
                                textColor={pointsBadgeData.colorText}
                                fillColor={pointsBadgeData.colorFill}
                                borderColor={pointsBadgeData.colorBorder}
                            >
                                {strings.programSchedule.pointsBadgeText}
                            </PointsBadge>
                        )}
                    {props.session.accessProvider !== AccessProvider.PUBLIC && (
                        <FormattedDateExtensionDiv>
                            {displayTicketIcon
                                ? IconTicket({ width: "18", height: "18" })
                                : IconLock({ width: "18", height: "18" })}
                        </FormattedDateExtensionDiv>
                    )}
                </Col>
                <Col xs={3} style={{ marginLeft: "calc(8.333333% - 55px)" }}>
                    <ShareBookmarkDiv>
                        <CustomOverlayTrigger
                            trigger={["focus", "click", "hover"]}
                            placement="bottom"
                            delay={{ show: 250, hide: 250 }}
                            overlay={
                                <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                    {branding.communicationArea.chatToolRecommend}
                                </Tooltip>
                            }
                        >
                            <div>
                                <ShareIcon
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        showRecommendModal(
                                            ShareTargetType.EVENTDATE,
                                            props.session.id,
                                            buildDetailLink(props.session.id, props.session.name, "eventdate")
                                        )
                                    }}
                                >
                                    {IconShare({ fill: branding.sideIconBar.sideIconColorDark })}
                                </ShareIcon>
                            </div>
                        </CustomOverlayTrigger>
                        <CustomOverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="button-tooltip-share" style={{ fontFamily: branding.font1 }}>
                                    {isBookmarked
                                        ? branding.organizationDetailPageContent.unBookmarkPerson
                                        : branding.organizationDetailPageContent.bookmarkPerson}
                                </Tooltip>
                            }
                        >
                            <div>
                                <BookmarkWithToggle
                                    newBookmarkItem={true}
                                    fontSize={"25px"}
                                    color={branding.sideIconBar.sideIconColorDark}
                                    stroke={branding.sideIconBar.sideIconColorDark}
                                    favIconBasic={true}
                                    type="eventdate"
                                    id={props.session.id}
                                    name={props.session.name}
                                />
                            </div>
                        </CustomOverlayTrigger>
                    </ShareBookmarkDiv>
                </Col>
            </SessionRow>
            <RecommendModal />
        </DetailNavLink>
    )
}

interface PersonsProps {
    persons: Array<Person>
    descriptionExists?: any
}

const Persons: React.FunctionComponent<PersonsProps> = (props) => {
    let strings = useLanguageState().getStrings()
    let lastPosition: string | undefined = ""
    var personsWithFunctionNameSorted = props.persons.sort((a, b) => {
        if (a!.personFunctionTargetName === undefined && b!.personFunctionTargetName === undefined) {
            return 0
        } else if (a!.personFunctionTargetName === undefined) {
            return 1
        } else if (b!.personFunctionTargetName === undefined) {
            return -1
        } else {
            return a!.personFunctionTargetName > b!.personFunctionTargetName
                ? -1
                : a!.personFunctionTargetName < b!.personFunctionTargetName
                ? 1
                : 0
        }
    })

    function getPositionHeader(positionName: string | undefined) {
        let count = personsWithFunctionNameSorted.filter((person) => person!.personFunctionTargetName === positionName).length
        if (positionName === undefined) {
            return strings.eventDateDetailPageContent.noSectionTitle + " (" + count + ")"
        } else {
            return positionName + " (" + count + ")"
        }
    }

    return (
        <PersonsAttachmentsContentRow descriptionExists={props.descriptionExists}>
            {personsWithFunctionNameSorted.map((person) => {
                if (person!.personFunctionTargetName !== lastPosition) {
                    lastPosition = person!.personFunctionTargetName
                    let positionHeader = getPositionHeader(person!.personFunctionTargetName)
                    return (
                        <PersonsAttachmentsContentCol xs={12} key={person!.id}>
                            <PersonsAttachmentsHeaderPositionRow type="person">
                                <PersonsAttachmentsHeaderTitle>{positionHeader}</PersonsAttachmentsHeaderTitle>
                            </PersonsAttachmentsHeaderPositionRow>
                            <PersonAttachmentData person={person} type="person" />
                        </PersonsAttachmentsContentCol>
                    )
                } else {
                    return (
                        <PersonsAttachmentsContentCol xs={12} key={person!.id}>
                            <PersonAttachmentData person={person} type="person" />
                        </PersonsAttachmentsContentCol>
                    )
                }
            })}
        </PersonsAttachmentsContentRow>
    )
}
/* #endregion */

const FormattedDateCol = styled(Col)`
    display: flex;
    align-items: center;
    font-size: 20px;
    padding-left: 0px;
    margin-top: 40px;

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

const ShareBookmarkDiv = styled.div`
    float: right;
    display: flex;
    margin-top: 15px;
    font-size: 20px;
`

const EventNameTeaserCol = styled(Col)`
    font-family: ${branding.font1};
    padding-left: 0px;
    @media (max-width: 769px) {
        margin-top: 100px;
    }
`

const EventName = styled.h2`
    font-family: ${branding.font1};
    font-size: 22px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;

    @media (max-width: 1500px) {
        font-size: 16px;
    }
`

const EventDateTeaserArea = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    width: 95%;
`
const EventDateTeaser = styled.p`
    font-size: 14px;
    margin-top: 25px;
    font-family: ${branding.font1};
    width: 95%;
`

const EventDateTeaserTitle = styled.div`
    font-size: 18px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: bold;
    line-height: 17px;
`

const EventDateDetailBodyRoot = styled.div<{ adjustHeight: number }>`
    font-family: ${branding.font1};
    display: flex;
    flex-direction: row;
    width: 100%;
    height: ${(props) => `calc(100vh - ${props.adjustHeight}px)`};

    .ScrollbarsCustom-Content {
        padding: 0 !important;
    }

    .container-root {
        padding-top: 20px;
        padding-bottom: 40px;

        @media ${device.mobile} {
            padding-top: 10px;
        }
    }

    @media ${device.mobile} {
        background: ${branding.myProfilePageContent.mobilePageBackgroundColor};
    }
`

export enum EventDateTiming {
    BEFORE,
    LIVE,
    AFTER
}

export const EventLogoDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    border-radius: 5px;
    width: 150px;
    height: 150px;
    padding: 20px;
    background: #fff;

    @media (max-width: 1600px) {
        width: 120px;
        height: 120px;
        padding: 25px;
    }

    @media (max-width: 1400px) {
        width: 100px;
        height: 100px;
        padding: 15px;
    }

    @media (max-width: 1200px) {
        width: 80px;
        height: 80px;
        padding: 10px;
    }

    @media ${device.mobile} {
        width: 95px;
        height: 95px;
        padding: 10px;
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
`
export const EventLogo = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
`
const LocationCol = styled(Col)`
    display: flex;
    width: 100%;

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
    }
`
const SessionsContentCol = styled(Col)`
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    padding: 25px 0;
`
export const EventLocation = styled.div`
    width: 85%;
    height: auto;
    line-height: 12px;
    /* margin-left: 15px; */
    font-family: ${branding.font1};
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 1px;

    @media ${device.mobile} {
        text-transform: none;
        font-size: 16px;
        line-height: 24px;
    }
`
const EventFormattedDate = styled.div`
    font-family: ${branding.font1};
    font-size: 12px;
    width: auto;
    line-height: 30px;
    /* padding-left: 15px; */
    /* padding-top: 25px; */
`

const HeaderScrollDiv = styled.div`
    width: 100%;
    position: relative;
`
const ScrollerShadowTop = styled.div`
    position: absolute;
    width: 100%;
    height: 10%;
    background: transparent;
    background: ${branding.primaryScrollWhiteShadowTTB};
    left: 0;
    top: 0;
    z-index: 2;
`

const ScrollerShadowBottom = styled.div`
    position: absolute;
    width: 100%;
    height: 10%;
    background: transparent;
    background: ${branding.primaryScrollWhiteShadowTTB};
    left: 0;
    bottom: 0;
    z-index: 2;
    transform: rotate(180deg);
`

export const SectionRoot = styled.div<{ marginTop?: string }>`
    @media ${device.mobile} {
        background: #fff;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        margin-top: ${(props) => props.marginTop ?? "0px"};
        border-radius: 5px;
        padding-bottom: 10px;
    }
`

export const EventLocationRoot = styled.div`
    position: relative;
    width: 80%;
    height: 20px;
    margin-left: 10px;
    font-family: ${branding.font1};
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
`

export const EventLocationContainer = styled.div`
    margin: 0;
    position: absolute;
    margin-top: 7px;
`

function checkIfDescriptionExists(event: Event, lang: string) {
    if (lang === "de") {
        return event.descriptionLongDe && event.descriptionLongDe.length > 0
    } else {
        return event.descriptionLong && event.descriptionLong.length > 0
    }
}

export const BasicInfoDescription = styled.div`
    border-top: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-top: 15px;
    width: 100%;
    font-family: ${branding.font1};
`

export const BasicInfoDescriptionTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
`

export const BasicInfoDescriptionText = styled.div`
    font-size: 14px;
`

const ShowMoreButton = styled.div`
    font-family: ${branding.font1};
    display: inline-flex;
    font-size: 14px;
    margin-top: 15px;
    border-top: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    justify-content: center;
    text-align: center;
    width: 100%;
    cursor: pointer;
    height: 40px;
`

const BasicInfoSection: React.FunctionComponent<{
    event: Event
    showRecommendModal: (shareType: ShareTargetType, entityId: string, link: string) => void
}> = (props) => {
    const lang = useLanguageState().getLanguage()
    const strings = useLanguageState().getStrings()

    const [showDescription, setShowDescription] = useState<boolean>(false)
    const [showEventMenuModal, setShowEventMenuModal] = useState<boolean>(false)

    //returns date-time in format: Wed., 12 Sept., 2020, 17:00 - 18:30
    const getFormattedDate = (date: string) => {
        return format(moment(date).toDate(), strings.eventTiming.eventDaysFormatPatternEvtDetailPage, {
            locale: lang === "de" ? de : en
        })
    }

    return (
        <SectionRoot>
            <MobileVersionContainer>
                <div style={{ marginLeft: "12px", paddingTop: "10px" }}>
                    <div style={{ color: branding.primaryColor, height: "20px", display: "inline-block" }}>
                        {IconLiveStream({ fill: branding.sideIconBar.sideIconColorDark })}
                    </div>
                    <EventLocationRoot style={{ display: "inline-block" }}>
                        <EventLocationContainer>
                            {lang === "de" ? props.event.locationDe : props.event.location}
                        </EventLocationContainer>
                    </EventLocationRoot>
                    <SectionTopButton style={{ right: "1.5rem", marginTop: "-30px" }} onClick={() => setShowEventMenuModal(true)}>
                        {IconThreeDotsMenu({ fill: branding.sideIconBar.sideIconColorDark })}
                    </SectionTopButton>
                </div>
            </MobileVersionContainer>

            <Row className="m-0">
                <SpacingCol xs={2} />
                <EventLogoDiv>
                    <EventLogo src={props.event.logo || "/branding/rsz_defaultgrey.png"} alt="" />
                </EventLogoDiv>
                <LocationCol xs={8}>
                    <div style={{ display: "block", marginLeft: "15px", width: "100%" }}>
                        <DesktopVersionContainer>
                            <EventLocation>{lang === "de" ? props.event.locationDe : props.event.location}</EventLocation>
                            {props.event.start && props.event.end && (
                                <EventFormattedDate>
                                    {getFormattedDate(props.event.start) + " - " + getFormattedDate(props.event.end)}
                                </EventFormattedDate>
                            )}
                        </DesktopVersionContainer>
                        <MobileVersionContainer>
                            <div style={{ marginTop: "4px" }}>
                                {props.event.start && props.event.end && (
                                    <EventFormattedDate>
                                        {getFormattedDate(props.event.start) + " - " + getFormattedDate(props.event.end)}
                                    </EventFormattedDate>
                                )}
                                <EventLocation>{lang === "de" ? props.event.nameDe : props.event.name}</EventLocation>
                            </div>
                        </MobileVersionContainer>
                    </div>
                </LocationCol>
            </Row>

            <MobileVersionContainer>
                {checkIfDescriptionExists(props.event, lang) && (
                    <Row className="m-0">
                        {showDescription ? (
                            <BasicInfoDescription>
                                <BasicInfoDescriptionTitle>
                                    {strings.eventDateDetailPageContent.descriptionTextForEventDate}
                                </BasicInfoDescriptionTitle>
                                <BasicInfoDescriptionText>
                                    {(lang === "de" ? props.event.descriptionLongDe : props.event.descriptionLong)
                                        ?.split(/\n+/)
                                        .map((item, i) => (
                                            <p>{item}</p>
                                        ))}
                                </BasicInfoDescriptionText>
                            </BasicInfoDescription>
                        ) : (
                            <ShowMoreButton onClick={() => setShowDescription(true)}>
                                <p style={{ marginTop: "15px" }}>{strings.myProfilePageContent.showMoreButtonText}</p>
                                <div style={{ marginTop: "15px", marginLeft: "5px" }}>
                                    {IconArrowDownLangSwitcher({ fill: branding.sideIconBar.sideIconColorDark })}
                                </div>
                            </ShowMoreButton>
                        )}
                    </Row>
                )}
            </MobileVersionContainer>

            <DesktopVersionContainer>
                <Row className="m-0">
                    <Col xs={2} />
                    <FormattedDateCol xs={6}>
                        <EventNameTeaserCol xs={12} className="mb-3">
                            <EventName>{lang === "de" ? props.event.nameDe : props.event.name}</EventName>
                        </EventNameTeaserCol>
                    </FormattedDateCol>
                    <Col xs={2} className="p-0" style={{ marginTop: "20px" }}>
                        <ShareBookmarkDiv>
                            <ShareBookmarkIcons
                                type="event"
                                event={props.event}
                                strings={strings}
                                showRecommendModal={props.showRecommendModal}
                            />
                        </ShareBookmarkDiv>
                    </Col>
                </Row>
            </DesktopVersionContainer>

            {showEventMenuModal && (
                <EventMenuModal
                    event={props.event}
                    handleClose={() => setShowEventMenuModal(false)}
                    showRecommendModal={props.showRecommendModal}
                    type={ShareTargetType.EVENT}
                />
            )}
        </SectionRoot>
    )
}

interface EventMenuModalProps {
    event?: Event | EventDate
    type: ShareTargetType
    handleClose: () => void
    showRecommendModal: (shareType: ShareTargetType, entityId: string, link: string) => void

    marginTop?: string
}

export const EventMenuModal: React.FunctionComponent<EventMenuModalProps> = (props: EventMenuModalProps) => {
    const strings = useLanguageState().getStrings()
    const favoriteState = useFavoriteState()

    const eventId = props.event?.id ?? ""
    const isBookmarked = favoriteState.is(props.type === ShareTargetType.EVENT ? "event" : "eventdate", eventId)
    const userState = useLoggedInState()
    const user = userState.user()

    const type = props.type === ShareTargetType.EVENT ? "event" : "eventdate"

    function onBookmark() {
        if (props.event && user) {
            let date = new Date()
            date.setMinutes(date.getMinutes() - 1)
            favoriteState.toggle(type, eventId)
            const isFavorite = favoriteState.is(type, eventId)
            syncFavorites({
                profileId: user.profileId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //"2020-01-01 12:00:00",
                    lastSyncTime: favoriteState.getLastSyncTime(),
                    changedFavorites: [
                        {
                            id: eventId,
                            kind: type.toUpperCase(),
                            deleted: !isFavorite,
                            lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }
                    ]
                }
            }).then(() => {
                favoriteState.setLastSyncTime(new Date())
            })
        }
    }

    return (
        <>
            <ModalRoot show={true} animation={false} onHide={() => props.handleClose()} marginTop={props.marginTop ?? "110px"}>
                <ContextMenu
                    collapsed={false}
                    items={() => [
                        {
                            title: strings.organizationDetailPageContent.bookmark,
                            icon: isBookmarked
                                ? branding.defaultToggleIcon
                                    ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark })
                                    : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark })
                                : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark }),
                            onClick: () => onBookmark()
                        },
                        {
                            title: strings.organizationDetailPageContent.recommend,
                            icon: IconShare({ fill: branding.sideIconBar.sideIconColorDark }),
                            onClick: () =>
                                props.showRecommendModal(props.type, eventId, buildDetailLink(eventId, props.event?.name!, type))
                        },
                        {
                            title: strings.contactEntry.downloadVCardTitle,
                            icon: IconDownload({ fill: branding.sideIconBar.sideIconColorDark }),
                            onClick: () => {
                                if (props.type === ShareTargetType.EVENT) {
                                    saveEvents(strings, (props.event as Event)?.eventDates!)
                                } else if (props.type === ShareTargetType.EVENTDATE) {
                                    saveEvent(strings, props.event! as EventDate)
                                }
                            }
                        }
                    ]}
                />
            </ModalRoot>
        </>
    )
}

export const SpacingCol = styled(Col)`
    @media ${device.mobile} {
        display: none;
    }
`

const EventDatesSectionCol = styled(Col)`
    @media ${device.tablet} {
        border-top: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
        margin-top: 15px;
    }

    @media ${device.mobile} {
        margin-left: 15px;
    }

    .verticalBarRoot {
        height: 100%;
    }
`

interface EventDatesSectionProps {
    eventDates: EventDate[]
    showRecommendModal: (shareType: ShareTargetType, entityId: string, link: string) => void
}

const EventDatesSection: React.FunctionComponent<EventDatesSectionProps> = (props: EventDatesSectionProps) => {
    const { useMobileDesign } = useWindowDimensions()

    const scrollRef = useRef<HTMLDivElement>(null)
    const [position, setPosition] = useState(0)
    const [value, setValue] = useState(0)
    const maxScrollRange = scrollRef.current?.scrollHeight! - scrollRef.current?.clientHeight!
    const width = maxScrollRange !== 0 ? "95%" : "100%"

    //const [width, setWidth] = useState("")

    // Are shadows active
    const [isShadowTop, setIsShadowTop] = useState<boolean>(false)
    const [isShadowBottom, setIsShadowBottom] = useState<boolean>(false)

    useEffect(() => {
        //setWidth(sessions.length > 3 ? "95%" : "100%")
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        scrollRef.current?.scrollTo(0, position)
    }, [position])

    useEffect(() => {
        if (scrollRef.current!.scrollWidth === scrollRef.current!.clientHeight) return
        value === 0 ? setIsShadowTop(false) : setIsShadowTop(true)
        maxScrollRange - value < 40 ? setIsShadowBottom(false) : setIsShadowBottom(true)
        // eslint-disable-next-line
    }, [value, maxScrollRange])

    return (
        <SectionRoot>
            <Row className="m-0">
                <SpacingCol xs={2} />
                <EventDatesSectionCol xs={useMobileDesign ? 11 : 8} className="p-0">
                    <Row className="m-0">
                        <Col xs={12} className="p-0 mb-3 mt-4">
                            <HeaderTitle>{branding.eventDateDetailPageContent.descriptionForEventDatesBindedToEvent}</HeaderTitle>
                        </Col>

                        <Col xs={12}>
                            <DesktopVersionContainer>
                                <HeaderScrollDiv>
                                    <ScrollableDiv
                                        ref={scrollRef}
                                        onScroll={() => setValue(scrollRef?.current?.scrollTop!)}
                                        width={width}
                                    >
                                        {props.eventDates &&
                                            props.eventDates.map((session) => {
                                                return (
                                                    <SessionsContentCol xs={24} key={session.name}>
                                                        <SessionData session={session} />
                                                    </SessionsContentCol>
                                                )
                                            })}
                                        {isShadowTop && <ScrollerShadowTop />}
                                        {isShadowBottom && <ScrollerShadowBottom />}
                                    </ScrollableDiv>
                                </HeaderScrollDiv>
                                <ScrollBarComponent
                                    visiblity={props.eventDates.length > 3 ? true : false}
                                    value={value}
                                    maxScrollRange={maxScrollRange ? maxScrollRange : 0}
                                    onScroll={(val: number) => {
                                        setPosition(val)
                                        setValue(val)
                                    }}
                                    isVertical={true}
                                ></ScrollBarComponent>
                            </DesktopVersionContainer>
                        </Col>

                        <MobileVersionContainer>
                            {props.eventDates.map((eventDate, idx) => {
                                return (
                                    <EventDateContentCol
                                        xs={12}
                                        key={idx}
                                        style={{
                                            borderBottom:
                                                idx < props.eventDates.length - 1
                                                    ? branding.mainBorder
                                                        ? branding.mainBorder
                                                        : "1px solid #d9d9d9"
                                                    : "none"
                                        }}
                                    >
                                        <EventDateView
                                            eventDate={eventDate}
                                            type="eventdate"
                                            showRecommendModal={props.showRecommendModal}
                                        />
                                    </EventDateContentCol>
                                )
                            })}
                        </MobileVersionContainer>
                    </Row>
                </EventDatesSectionCol>
                <Col xs={2} />
            </Row>
        </SectionRoot>
    )
}

const DescriptionPersonsRow = styled(Row)`
    @media ${device.mobile} {
        margin-left: 10px !important;
        margin-right: 5px !important;
    }
`

const DescriptionPersonsSection: React.FunctionComponent<{ event: Event; persons: Person[]; categories: Category[] }> = (
    props
) => {
    const lang = useLanguageState().getLanguage()

    return (
        <SectionRoot>
            <DesktopVersionContainer>
                <DescriptionPersonsRow className="m-0">
                    <SpacingCol xs={2} />
                    {checkIfDescriptionExists(props.event, lang) && (
                        <Col xs={5} className="p-0">
                            <EventDateTeaserArea>
                                <EventDateTeaserTitle>
                                    {branding.eventDateDetailPageContent.descriptionTextForEventDate}
                                </EventDateTeaserTitle>
                                <EventDateTeaser>
                                    {(lang === "de" ? props.event.descriptionLongDe : props.event.descriptionLong)
                                        ?.split(/\n+/)
                                        .map((item, i) => (
                                            <p>{item}</p>
                                        ))}
                                </EventDateTeaser>
                            </EventDateTeaserArea>
                        </Col>
                    )}
                    <Col xs={checkIfDescriptionExists(props.event, lang) ? 3 : 4} className="p-0">
                        <Persons persons={props.persons} descriptionExists={checkIfDescriptionExists(props.event, lang)} />
                    </Col>
                </DescriptionPersonsRow>
                <Row className="m-0">
                    <Col xs={2} />
                    <Col xs={10} style={{ paddingLeft: "0px" }}>
                        <BadgeArea categories={props.categories || []} />
                    </Col>
                </Row>
            </DesktopVersionContainer>
            <MobileVersionContainer>
                <DescriptionPersonsRow className="m-0">
                    <Col xs={11} className="p-0">
                        <Persons persons={props.persons} />
                    </Col>
                </DescriptionPersonsRow>
            </MobileVersionContainer>
        </SectionRoot>
    )
}

const CategoriesSection: React.FunctionComponent<{ categories: Category[] }> = (props) => {
    const { useMobileDesign } = useWindowDimensions()

    return (
        <SectionRoot>
            <Row className="m-0">
                <SpacingCol xs={2} />
                <Col
                    xs={useMobileDesign ? 12 : 8}
                    style={{
                        borderTop: branding.eventDateDetailPageContent.eventLayoutVisibleOnEventDateDetailPage
                            ? "0px solid #transparent"
                            : "1px solid #d9d9d9",
                        marginTop: "15px"
                    }}
                >
                    <Categories
                        categories={filterCategories(props.categories)}
                        categoryType={"eventdate"}
                        viewAllInitially={true}
                        className="evtdDetail"
                    />
                </Col>
            </Row>
        </SectionRoot>
    )
}

interface EventDetailBodyProps {
    event: Event
    eventDates: Array<EventDate>
    adjustHeight: number
    pageTitle?: string
    hideShadows?: boolean
}

export const EventDetailBody: React.FunctionComponent<EventDetailBodyProps> = (props) => {
    const lang = useLanguageState().getLanguage()
    const strings = useLanguageState().getStrings()
    const appState = useAppState()
    const [categories, setCategories] = useState<Category[]>([])
    const [persons, setPersons] = useState<Person[]>([])
    const { RecommendModal, showRecommendModal } = useRecommendModal()

    const sectionsOrder: EventDateDetailSection[] = branding.eventDateDetailPageContent.eventDetailSectionsOrder

    useEffect(() => {
        appState.setCurrentMobileBreadcrumb(strings.programPageContent.scheduleTabTitle)
        // eslint-disable-next-line
    }, [lang])

    function getCategories() {
        let categoriesTemp: Category[] = []
        props.eventDates.forEach((element) => {
            if (element.categories) {
                categoriesTemp = categoriesTemp.concat(element.categories)

                categoriesTemp = _.uniqBy(categoriesTemp, function (c) {
                    return c.id
                })
            }
        })

        setCategories(categoriesTemp)
    }
    function getPersons() {
        let personsTemp: Person[] = []
        props.eventDates.forEach((element) => {
            if (element.persons) {
                personsTemp = personsTemp.concat(element.persons)

                personsTemp = _.uniqBy(personsTemp, function (p) {
                    return p.id
                })
            }
        })

        setPersons(personsTemp)
    }

    useEffect(() => {
        getCategories()
        getPersons()
        // eslint-disable-next-line
    }, [lang])

    return (
        <EventDateDetailBodyRoot adjustHeight={props.adjustHeight}>
            <ContentScrollContainer containerWidth={"75%"} adjustForHeaderWith={"0"}>
                <div className="container-root" style={{ position: "relative", color: branding.mainInfoColor }}>
                    {sectionsOrder.map((item: EventDateDetailSection, index: number) => {
                        switch (item) {
                            case EventDateDetailSection.BASIC_INFO: {
                                return (
                                    <BasicInfoSection key={index} event={props.event} showRecommendModal={showRecommendModal} />
                                )
                            }

                            case EventDateDetailSection.DESCRIPTION_PERSONS: {
                                return (
                                    <DescriptionPersonsSection
                                        key={index}
                                        event={props.event}
                                        persons={persons}
                                        categories={categories}
                                    />
                                )
                            }

                            case EventDateDetailSection.EVENTDATES: {
                                return (
                                    <>
                                        {props.eventDates && props.eventDates.length > 0 && (
                                            <EventDatesSection
                                                key={index}
                                                eventDates={props.eventDates}
                                                showRecommendModal={showRecommendModal}
                                            />
                                        )}
                                    </>
                                )
                            }

                            case EventDateDetailSection.CATEGORIES: {
                                return (
                                    <>
                                        {categories && categories.length > 0 && (
                                            <CategoriesSection key={index} categories={categories} />
                                        )}
                                    </>
                                )
                            }

                            default:
                                return <div />
                        }
                    })}

                    <MobileVersionContainer>
                        <div style={{ marginBottom: "120px" }} />
                    </MobileVersionContainer>
                </div>
            </ContentScrollContainer>
            <RecommendModal />
        </EventDateDetailBodyRoot>
    )
}
/* #endregion */
const EventDetailPageContent: React.FunctionComponent = (props) => {
    let { eventId }: any = useParams()
    const [event, setEvent] = useState<any>()
    const [error, setError] = useState("")
    const [isLoaded, setIsLoaded] = useState(false)
    const langState = useLanguageState()
    const strings = langState.getStrings()
    const lang = langState.getLanguage()
    const profileId = useLoggedInState().user()?.profileId

    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const { topBarRef, setTopBarRef } = TopBannerSharedState()
    const adjustHeight =
        (guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0) +
        (topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0) +
        65

    useEffect(() => {
        loadEventDetails(eventId || "")
            .then((data) => {
                const resp = data as EventDetailsResponse
                setIsLoaded(true)
                setEvent(resp.content)
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setIsLoaded(true)
                setError(e.message)
            })
        // eslint-disable-next-line
    }, [eventId, error, profileId, lang])

    const breadcrumb: BreadcrumbItem[] = []
    if (event) {
        const locations = calcBreadcrumbLocations(strings)
        breadcrumb.push(
            { to: programPageRoute, name: strings.programPageContent.scheduleTabTitle },
            { to: locations[1], name: event.name }
        )
    }

    let content: JSX.Element | null = null

    if (isLoaded && event) {
        content = (
            <EventDetailBody event={event} eventDates={event.eventDates} pageTitle={event.name} adjustHeight={adjustHeight} />
        )
    } else {
        content = <CenteredLoader />
    }

    return (
        <>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar setRef={setTopBarRef} />
            <div style={{ borderBottom: branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9" }}>
                <Breadcrumb breadcrumb={breadcrumb} />
            </div>
            {content}
        </>
    )
}

export default EventDetailPageContent
