import { API, graphqlOperation } from "aws-amplify"
import {
    BatchGetUserPresenceQuery,
    CalendarEntryParticipationStatus,
    ConversationType,
    CreateCalendarEntryInput,
    CreateCalendarEntryParticipationInput,
    CreateCalendarEntryParticipationMutation,
    CreateConversationInput,
    CreateConversationMutation,
    CreateMeetingMutation,
    CreateMessageInput,
    CreateMessageLightMutation,
    CreateUnreadCounterMutation,
    CreateUserConversationMutation,
    CreateUserMutation,
    CreateUserSessionMutation,
    DeleteCalendarEntryMutation,
    DeleteCalendarEntryParticipationMutation,
    DeleteUserMutation,
    DeleteUserSessionMutation,
    GetCalendarEntryLightQuery,
    GetConversationByIdQuery,
    GetConversationNameAndTypeByIdQuery,
    GetConversationParticipationByIdQuery,
    GetConversationsQuery,
    GetMeetingLightQuery,
    GetMeetingsQuery,
    getOnlineUsersQuery,
    GetUnreadCounterQuery,
    GetUserConversationQuery,
    GetUserNameAndPictureQuery,
    GetUserQuery,
    InviteStatus,
    MessagesByConversationIdLightQuery,
    ModelCalendarEntryConditionInput,
    ModelSortDirection,
    ModelStringKeyConditionInput,
    ModelUserActionConditionInput,
    NotificationDisplayGroup,
    NotificationsByUserIdAndDisplayGroupSortedQuery,
    UpdateCalendarEntryInput,
    UpdateCalendarEntryParticipationInput,
    UpdateCalendarEntryParticipationMutation,
    UpdateConversationMutation,
    UpdateMeetingParticipantMutation,
    UpdateUserConversationLightMutation,
    UpdateUserMutation,
    UpdateUserSessionMutation,
    UserActionType,
    UserConversationListEntryQuery,
    UserConversationsByUserAndConversationLightQuery,
    UserSessionsByLocationQuery,
    usersInCallsInLoungeQuery
} from "../API"
import {
    batchGetUserPresenceLight,
    conversationById,
    conversationNameAndTypeById,
    conversationParticipationIdById,
    conversationsByMembers,
    doesMeetingExist,
    getCalendarEntryLight,
    getCalendarEntryParticipations,
    getOnlineUsers,
    getUserConversationLight,
    getUserConversationListEntry,
    getUserConversations,
    getUserLight,
    listCalendarEntryByOrganization,
    listMeetings,
    messagesByConversationId,
    userConversationByUserAndConversation,
    userNameAndPicture,
    usersInCallsInLounge
} from "../graphql/ownQueries"
import { BackendServiceError, DynamoDBErrors, errorDynamoDBmessage, seriesOfTopicsName, topic } from "./BackendServicesUtils"
import { GraphQLResult } from "@aws-amplify/api-graphql"
import { getUnreadCounter, notificationsByUserIdAndDisplayGroupSorted, userSessionsByLocation } from "../graphql/queries"
import { Md5 } from "ts-md5"
import {
    createUnreadCounter,
    createUserAction,
    createUserSession,
    updateUserAction,
    deleteUserSession
} from "../graphql/mutations"
import {
    createCalendarEntryLight,
    updateCalendarEntryLight,
    createMeeting,
    createMessageLight,
    createMeetingParticipant,
    deleteCalendarEntryParticipation,
    createUserConversation,
    updateUserConversationLight,
    updateMeetingParticipantLight,
    updateUserSessionLight,
    createCalendarEntryParticipationLight,
    deleteCalendarEntryLight,
    updateCalendarEntryParticipationLight,
    createConversationLight,
    createUserLight,
    deleteUserLight,
    deleteUserConversationLight,
    updateConversationLight,
    updateUserLight
} from "../graphql/ownMutations"
import branding from "../branding/branding"
import { defaultLogger as logger } from "../globalStates/AppState"
import moment from "moment"
/*********************************************************************************************
 * ALL CONVERSATIONS LIST
 **********************************************************************************************/
export interface ConversationListEntry {
    id: string
    type: ConversationType
    userId?: string
    userConversationId: string
    isMuted: boolean
    title?: string
    opponentIds: string[]
    pictureUrls: (string | undefined)[]
    opponentNames: string[]
    lastMessage?: string
    lastMessageTime: Date
    lastReadTime?: Date | undefined
}

export async function loadConversationListEntries(
    profileId: string,
    conversationMemberLimit: number,
    nextToken?: string
): Promise<[ConversationListEntry[], string | undefined] | undefined> {
    const params = { userId: profileId, callLimit: 25, memberLimit: conversationMemberLimit, nextToken: nextToken }
    try {
        const result = (await API.graphql(graphqlOperation(getUserConversations, params))) as GraphQLResult<GetConversationsQuery>
        if (result?.data?.userConversationsByUser?.items) {
            const convos: ConversationListEntry[] = []
            const userConvos = result.data.userConversationsByUser.items
            const nextToken = result.data.userConversationsByUser.nextToken
            userConvos.forEach((item) => {
                const convEntry = processConversationListEntry(profileId, item)
                if (convEntry) {
                    convos.push(convEntry)
                }
            })
            return [convos, nextToken ?? undefined]
        }
        return undefined
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getUserConversations failed", params: params, error: error })
        return undefined
    }
}

export async function loadConversationListEntry(
    profileId: string,
    conversationId: string,
    conversationMemberLimit: number
): Promise<ConversationListEntry | undefined> {
    const params = { userId: profileId, conversationId: conversationId, memberLimit: conversationMemberLimit }
    try {
        const result = (await API.graphql(
            graphqlOperation(getUserConversationListEntry, params)
        )) as GraphQLResult<UserConversationListEntryQuery>
        if (result?.data?.userConversationsByUserAndConversation?.items) {
            const userConvos = result.data.userConversationsByUserAndConversation.items as ConversationListEntryProps[]
            return processConversationListEntry(profileId, userConvos[0])
        }
        return undefined
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getUserConversationListEntry failed", params: params, error: error })
        return undefined
    }
}

/*********************************************************************************************
 * NOTIFICATIONS
 **********************************************************************************************/

export interface loadNotificationsProps {
    userId: string
    displayGroup: NotificationDisplayGroup
    nextToken?: string
}

export interface loadNotificationsByUserIdAndTypeResult {
    data: {
        notificationsByUserIdAndDisplayGroupSorted: {
            items: [
                {
                    userId: string
                    content: string
                    createdAt: string
                }
            ]
            nextToken: String | undefined
        }
    }
}

export async function loadNotificationsByUserIdAndDisplayGroup(
    parameters: loadNotificationsProps
): Promise<NotificationsByUserIdAndDisplayGroupSortedQuery | null> {
    const userIdDisplayGroup = parameters.userId + parameters.displayGroup
    const params = {
        userIdDisplayGroup: userIdDisplayGroup,
        sortDirection: ModelSortDirection.DESC,
        nextToken: parameters.nextToken,
        limit: 25
    }
    try {
        const findNotificationResult = (await API.graphql(
            graphqlOperation(notificationsByUserIdAndDisplayGroupSorted, params)
        )) as any
        return findNotificationResult.data as NotificationsByUserIdAndDisplayGroupSortedQuery
    } catch (error: any) {
        errorDynamoDBmessage({
            message: "BackendServices notificationsByUserIdAndDisplayGroupSorted failed",
            params: params,
            error: error
        })
        return null
    }
}

/**********************************************************************************************
 * CHAT
 **********************************************************************************************/

export interface ChatMessage {
    id: string
    content: string
    authorId: string
    isSent: boolean
    timestamp: Date
    conversationId: string
}

export interface ConversationEntry {
    id: string
    name?: string
    description?: string
    userConversationId: string
    isMuted: boolean
    opponents: User[]
}
export async function findChatConversation(
    profileId: string,
    opponentId: string
): Promise<{ conversationId: string; userConversationId: string; isMuted: boolean } | undefined> {
    const params = { memberId1: profileId, memberId2: opponentId }
    try {
        const findConversationResult = (await API.graphql(graphqlOperation(conversationsByMembers, params))) as GraphQLResult<any>
        const conversations = findConversationResult.data?.getConversationsByMembers?.items
        if (!conversations?.length) {
            return undefined
        }
        const conversation = conversations.find((c: any) => {
            const members = c?.members?.items
            if (members?.length === 2) {
                const userId1 = members[0]?.userId
                const userId2 = members[1]?.userId
                return (userId1 === profileId && userId2 === opponentId) || (userId1 === opponentId && userId2 === profileId)
            }
            return false
        })
        const userConversation = conversation?.members?.items?.find((item: any) => item.userId === profileId)

        if (!conversation || !userConversation) {
            return undefined
        }

        return {
            conversationId: conversation.id,
            userConversationId: userConversation.id,
            isMuted: userConversation.isMuted ?? false
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices conversationByMemberIdsHash failed", params: params, error: error })
        return undefined
    }
}

export async function getConversationParticipantCount(conversationId: string): Promise<number | undefined> {
    const params = { id: conversationId }
    try {
        const result = (await API.graphql(
            graphqlOperation(conversationParticipationIdById, params)
        )) as GraphQLResult<GetConversationParticipationByIdQuery>

        return result.data?.getConversation?.members?.items?.length
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices conversationParticipationIdById failed", params: params, error: error })
        return undefined
    }
}

export async function getConversationParticipants(conversationId: string, membersLimit?: number): Promise<User[] | undefined> {
    const params = { id: conversationId, limit: membersLimit }
    try {
        const result = (await API.graphql(graphqlOperation(conversationById, params))) as GraphQLResult<GetConversationByIdQuery>
        const conversation = result?.data?.getConversation
        if (!conversation) {
            return undefined
        }
        return conversation.members?.items?.map((item) => {
            return {
                id: item!.user.id,
                name: item!.user.name ?? "",
                pictureUrl: item!.user.pictureUrl ?? undefined
            }
        })
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices conversationById failed", params: params, error: error })
        return undefined
    }
}

export async function checkConversationExists(
    conversationId: string,
    participantIds: string[]
): Promise<{ conversationExists: boolean; userConversationExists: boolean[] }> {
    const params = { id: conversationId }
    try {
        const result = (await API.graphql(
            graphqlOperation(conversationParticipationIdById, params)
        )) as GraphQLResult<GetConversationParticipationByIdQuery>
        const conversation = result.data?.getConversation
        const conversationParticipantIds = conversation?.members?.items?.map((uc) => uc?.userId) ?? []
        const userConversationExists = participantIds.map((id) => conversationParticipantIds.includes(id))

        return { conversationExists: !!conversation, userConversationExists: userConversationExists }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices conversationParticipationIdById failed", params: params, error: error })
        return { conversationExists: false, userConversationExists: [] }
    }
}

export async function findChatConversationById(
    conversationId: string,
    profileId: string,
    membersLimit?: number
): Promise<ConversationEntry | undefined> {
    const params = { id: conversationId, limit: membersLimit }
    try {
        const result = (await API.graphql(graphqlOperation(conversationById, params))) as GraphQLResult<GetConversationByIdQuery>
        const conversation = result?.data?.getConversation
        if (!conversation) {
            return undefined
        }
        const opponents: User[] = []
        let userConversationId: string
        let isMuted: boolean
        conversation.members?.items?.forEach(function (item: any, index: number) {
            if (item.user.id === profileId) {
                userConversationId = item.id
                isMuted = item.isMuted
            } else {
                opponents.push(item.user)
            }
        })
        return {
            id: conversationId,
            name: conversation.name ?? undefined,
            description: conversation.description ?? undefined,
            userConversationId: userConversationId!,
            isMuted: isMuted!,
            opponents: opponents
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices conversationById failed", params: params, error: error })
        return undefined
    }
}

export async function getLastReadUserConversation(userCoversationId: string) {
    const params = { id: userCoversationId }
    try {
        const result = (await API.graphql(
            graphqlOperation(getUserConversationLight, params)
        )) as GraphQLResult<GetUserConversationQuery>
        const userConversation = result.data?.getUserConversation

        return userConversation
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getUserConversation failed", params: params, error: error })
        return undefined
    }
}

export async function createPrivateChatConversation(
    profileId: string,
    opponentId: string,
    isMuted?: boolean
): Promise<{ conversationId: string; userConversationId: string; isMuted: boolean } | undefined> {
    const participantIdsSorted = [profileId, opponentId].sort()
    // const participantIdsSorted = Object.assign([], participantIds).sort();
    const idsHash = Md5.hashStr(participantIdsSorted.join(""))

    const params: { input: CreateConversationInput } = {
        input: { userId: profileId, memberIdsHash: idsHash as string, type: ConversationType.PRIVATE }
    }
    try {
        const createConvResult = (await API.graphql(
            graphqlOperation(createConversationLight, params)
        )) as GraphQLResult<CreateConversationMutation>
        if (!createConvResult?.data?.createConversation) {
            return undefined
        }
        const conversationId = createConvResult.data.createConversation.id

        const params2 = { input: { userId: opponentId, conversationId: conversationId } }
        try {
            const results = await Promise.all([
                API.graphql(
                    graphqlOperation(createUserConversation, {
                        input: { userId: profileId, conversationId: conversationId, isMuted: isMuted }
                    })
                ) as GraphQLResult<CreateUserConversationMutation>,
                API.graphql(
                    graphqlOperation(createUserConversation, { input: { userId: opponentId, conversationId: conversationId } })
                ) as GraphQLResult<CreateUserConversationMutation>
            ])

            const userConversation = results[0]?.data?.createUserConversation

            if (!userConversation) {
                return undefined
            }

            return {
                conversationId: conversationId,
                userConversationId: userConversation.id,
                isMuted: userConversation.isMuted ?? false
            }
        } catch (error: any) {
            errorDynamoDBmessage({ message: "BackendServices createUserConversation failed", params: params2, error: error })
            return undefined
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createConversation failed", params: params, error: error })
        return undefined
    }
}

/* TODO Tasks for custom resolvers
    - On UserConversation creation: 
        - set field 'mostRecentMessageCreatedAt' to same value as 'createdAt'
        - check if there already exists a UserConversation with the same userId & conversationId
        - check if conversation participant limit has already been reached
    - On send message & change conversation name/description: Validate max length
    - On send message: check if user is part of conversation
*/
async function createChatConversation(
    conversationType: ConversationType,
    profileId: string,
    opponentIds?: string[],
    name?: string,
    description?: string,
    showInListIfEmpty?: boolean,
    isMuted?: boolean,
    conversationId?: string
): Promise<{ createdConversationId: string; createdUserConversationId: string } | undefined> {
    const params: { input: CreateConversationInput } = {
        input: {
            id: conversationId,
            userId: profileId,
            name: name ?? null,
            description: description ?? null,
            type: conversationType
        }
    }
    try {
        const createConvResult = (await API.graphql(
            graphqlOperation(createConversationLight, params)
        )) as GraphQLResult<CreateConversationMutation>
        const createdConversationId = createConvResult.data?.createConversation?.id

        if (createdConversationId) {
            const results = await Promise.all([
                addParticipantsToGroupChatConversation(createdConversationId, [profileId], showInListIfEmpty, isMuted), // <= TODO adding oneself to the conersation should be done in the resolver during the Conversation creation
                addParticipantsToGroupChatConversation(createdConversationId, opponentIds ?? [], showInListIfEmpty)
            ])

            const myUserConversationId = results.flat()[0]?.createdUserConversationId

            if (!myUserConversationId) {
                return undefined
            }

            return {
                createdConversationId: createdConversationId,
                createdUserConversationId: myUserConversationId
            }
        } else return undefined
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createConversation failed", params: params, error: error })
        return undefined
    }
}

export async function createGroupChatConversation(
    profileId: string,
    opponentIds: string[],
    name?: string,
    description?: string
): Promise<{ createdConversationId: string; createdUserConversationId: string } | undefined> {
    return await createChatConversation(ConversationType.GROUP, profileId, opponentIds, name, description, true, false)
}

export async function createCalendarEntryChatConversation(
    conversationId: string,
    profileId: string,
    name?: string,
    description?: string
): Promise<{ createdConversationId: string; createdUserConversationId: string } | undefined> {
    return await createChatConversation(
        ConversationType.CALENDARENTRY,
        profileId,
        undefined,
        name,
        description,
        false,
        false,
        conversationId
    )
}

export async function createCallChatConversation(
    conversationId: string,
    profileId: string,
    opponentIds: string[],
    name?: string,
    description?: string
): Promise<{ createdConversationId: string; createdUserConversationId: string } | undefined> {
    return await createChatConversation(
        ConversationType.CALL,
        profileId,
        opponentIds,
        name,
        description,
        false,
        false,
        conversationId
    )
}

function createSingleUserConversation(participantId: string, conversationId: string, now: string | undefined, isMuted?: boolean) {
    const params = {
        input: {
            userId: participantId,
            conversationId: conversationId,
            mostRecentMessageCreatedAt: now,
            lastReadMessageCreatedAt: now,
            isMuted: isMuted
        }
    }
    try {
        const result = API.graphql(
            graphqlOperation(createUserConversation, params)
        ) as GraphQLResult<CreateUserConversationMutation>

        return result
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createUserConversation failed", params: params, error: error })
        return undefined
    }
}

export async function addParticipantsToGroupChatConversation(
    conversationId: string,
    participantIds: string[],
    showInListIfEmpty?: boolean,
    isMuted?: boolean
): Promise<{ participantId: string; createdUserConversationId: string }[] | undefined> {
    const now = showInListIfEmpty ? new Date().toISOString() : undefined
    const result = await Promise.all(
        participantIds.map((participantId) => createSingleUserConversation(participantId, conversationId, now, isMuted))
    )
    if (!result || result.length === 0) {
        return undefined
    }
    return result
        .filter((result) => !!result?.data?.createUserConversation)
        .map((result) => {
            const userConversation = result!.data!.createUserConversation!
            return { participantId: userConversation.userId, createdUserConversationId: userConversation.id }
        })
}

export async function updateGroupChatConversationName(conversationId: string, name: string | null) {
    const params = { input: { id: conversationId, name: name } }
    try {
        return (
            (await API.graphql(graphqlOperation(updateConversationLight, params))) as GraphQLResult<UpdateConversationMutation>
        )?.data?.updateConversation
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateConversation failed", params: params, error: error })
        return undefined
    }
}

export async function updateGroupChatConversationDescription(conversationId: string, description?: string) {
    const params = { input: { id: conversationId, description: description } }
    try {
        return (
            (await API.graphql(graphqlOperation(updateConversationLight, params))) as GraphQLResult<UpdateConversationMutation>
        )?.data?.updateConversation
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateConversation failed", params: params, error: error })
        return undefined
    }
}

/**
 * If the UserConversation ID is already known better use deleteUserConversation(userConversationId) instead.
 * @param conversationId
 * @param participantId
 */
export async function removeParticipantsFromGroupChatConversation(
    conversationId: string,
    participantId: string
): Promise<boolean> {
    const params = { userId: participantId, conversationId: conversationId }
    try {
        const findUserConversationResult = (await API.graphql(
            graphqlOperation(userConversationByUserAndConversation, params)
        )) as GraphQLResult<UserConversationsByUserAndConversationLightQuery>
        if (!findUserConversationResult?.data?.userConversationsByUserAndConversation?.items?.length) {
            return false
        }
        const userConversationId = findUserConversationResult?.data?.userConversationsByUserAndConversation?.items[0]?.id
        if (!userConversationId) return false
        return await deleteUserConversation(userConversationId)
    } catch (error: any) {
        errorDynamoDBmessage({
            message: "BackendServices userConversationByUserAndConversation failed",
            params: params,
            error: error
        })
        return false
    }
}

export async function deleteUserConversation(userConversationId: string): Promise<boolean> {
    const params = { input: { id: userConversationId } }
    try {
        return !!(await API.graphql(graphqlOperation(deleteUserConversationLight, params)))
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices deleteUserConversation failed", params: params, error: error })
        return false
    }
}

export async function setLastReadConversation(userConversationId: string, lastReadMessageCreatedAt: string) {
    const params = {
        input: { id: userConversationId, lastReadMessageCreatedAt: lastReadMessageCreatedAt },
        condition: { lastReadMessageCreatedAt: { ne: lastReadMessageCreatedAt } }
    }
    try {
        //eslint-disable-next-line
        ;(await API.graphql(
            graphqlOperation(updateUserConversationLight, params)
        )) as GraphQLResult<UpdateUserConversationLightMutation>
    } catch (error: any) {
        if (error.errors[0]?.errorType === "DynamoDB:ConditionalCheckFailedException")
            logger.warn({
                message: "BackendServices updateUserConversationLight failed",
                request: "graphql",
                params: params,
                errorMessage: error.errors[0]?.errorType as DynamoDBErrors,
                errorStack: error as DynamoDBErrors
            })
        else errorDynamoDBmessage({ message: "BackendServices updateUserConversationLight failed", params: params, error: error })
    }
}

export async function setMuteStatus(userConversationId: string, muted: boolean): Promise<boolean | undefined> {
    const params = { input: { id: userConversationId, isMuted: muted } }
    try {
        const result = (await API.graphql(
            graphqlOperation(updateUserConversationLight, params)
        )) as GraphQLResult<UpdateUserConversationLightMutation>
        return result?.data?.updateUserConversation?.isMuted ?? undefined
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateUserConversationLight failed", params: params, error: error })
        return undefined
    }
}

export async function sendChatMessage(conversationId: string, authorId: string, text: string): Promise<ChatMessage | undefined> {
    const params: { input: CreateMessageInput } = {
        input: { conversationId: conversationId, authorId: authorId, content: text, sotName: topic }
    }
    try {
        const result = (await API.graphql(
            graphqlOperation(createMessageLight, params)
        )) as GraphQLResult<CreateMessageLightMutation>
        if (result?.data?.createMessage) {
            const msg = result.data.createMessage
            return {
                id: msg.id,
                content: msg.content,
                authorId: msg.authorId,
                isSent: msg.isSent ?? false,
                timestamp: new Date(msg.createdAt),
                conversationId: conversationId
            }
        }
        return undefined
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createMessageLight failed", params: params, error: error })
        return undefined
    }
}

export async function sendChatMessageAndCreateConversationIfNecessary(
    profileId: string,
    opponentId: string,
    text: string
): Promise<ChatMessage | undefined> {
    const findConversationResult = await findChatConversation(profileId, opponentId)
    if (findConversationResult) {
        const { conversationId } = findConversationResult
        return await sendChatMessage(conversationId, profileId, text)
    } else {
        const createConversationResult = await createPrivateChatConversation(profileId, opponentId)
        if (createConversationResult) {
            const { conversationId } = createConversationResult
            return await sendChatMessage(conversationId, profileId, text)
        } else {
            return undefined
        }
    }
}

export async function loadChatMessages(
    conversationId: string,
    nextToken?: string
): Promise<[ChatMessage[], string | undefined] | undefined> {
    const params = { conversationId: conversationId, limit: 25, nextToken: nextToken }
    try {
        const result = (await API.graphql(
            graphqlOperation(messagesByConversationId, params)
        )) as GraphQLResult<MessagesByConversationIdLightQuery>
        if (result?.data?.messagesByConversationId?.items) {
            const chatMessages: ChatMessage[] = []
            const messages = result.data.messagesByConversationId.items
            const nextToken = result.data.messagesByConversationId.nextToken
            messages.forEach((message: any) => {
                chatMessages.push({
                    id: message.id,
                    content: message.content,
                    authorId: message.authorId,
                    isSent: message.isSent,
                    timestamp: new Date(message.createdAt),
                    conversationId: message.conversationId
                })
            })
            return [chatMessages, nextToken ?? undefined]
        }
        return undefined
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices messagesByConversationId failed", params: params, error: error })
        return undefined
    }
}

/*********************************************************************************************
 * CHAT NOTIFICATIONS
 **********************************************************************************************/
export async function fetchConversationNameAndType(
    conversationId: string
): Promise<{ convType: ConversationType; convName?: string }> {
    const params = { id: conversationId }
    try {
        const result = (await API.graphql(
            graphqlOperation(conversationNameAndTypeById, params)
        )) as GraphQLResult<GetConversationNameAndTypeByIdQuery>
        const conversation = result.data?.getConversation
        const type = conversation?.type ?? ConversationType.PRIVATE
        const name = conversation?.name ?? undefined
        return { convType: type, convName: name }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices conversationNameAndTypeById failed", params: params, error: error })
        return { convType: ConversationType.PRIVATE, convName: undefined }
    }
}

export async function fetchUserName(userId: string): Promise<{ userName?: string; userPictureUrl?: string }> {
    const params = { id: userId }
    try {
        const result = (await API.graphql(
            graphqlOperation(userNameAndPicture, params)
        )) as GraphQLResult<GetUserNameAndPictureQuery>
        const user = result.data?.getUser
        const name = user?.name ?? undefined
        const pictureUrl = user?.pictureUrl ?? undefined
        return { userName: name, userPictureUrl: pictureUrl }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices userNameAndPicture failed", params: params, error: error })
        return { userName: undefined, userPictureUrl: undefined }
    }
}

/*********************************************************************************************
 * Presence status from dynamoDB
 **********************************************************************************************/

export enum PresenceType {
    DEFAULT = "unlisted",
    AVAILABLE = "Available",
    BUSY = "Busy",
    DONOTDISTURB = "Do not disturb",
    OFFWORK = "Off Work"
}

export enum AvatarType {
    VISITOR = "visitor",
    EXHIBITOR = "exhibitor",
    SPEAKER = "speaker"
}

export interface UserProps {
    id: string
    name?: string
    pictureUrl?: string
    presenceStatus?: PresenceType
    lastConnected: string
}

export interface UserResponse {
    getUser: {
        id: string
        name: string
        pictureUrl?: string
        presenceStatus: PresenceType
        lastConnected: string
    } | null
}

export async function createNewUser(input: UserProps): Promise<UserResponse | BackendServiceError | DynamoDBErrors> {
    const params = { input }
    try {
        const result = (await API.graphql(graphqlOperation(createUserLight, params))) as GraphQLResult<CreateUserMutation>
        return result as UserResponse
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createUser failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function updateUserValues(input: UserProps): Promise<UserResponse | BackendServiceError | DynamoDBErrors> {
    const params = { input }
    try {
        const result = (await API.graphql(graphqlOperation(updateUserLight, params))) as GraphQLResult<UpdateUserMutation>
        const data = result.data
        // TODO better return type and null checks
        return {
            getUser: {
                id: data!.updateUser!.id,
                name: data!.updateUser!.name!,
                pictureUrl: data!.updateUser!.pictureUrl ?? undefined,
                presenceStatus: PresenceType[data!.updateUser!.presenceStatus! as keyof typeof PresenceType],
                lastConnected: data!.updateUser!.lastConnected
            }
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateUser failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function deleteUserById(id: string): Promise<UserResponse | BackendServiceError | DynamoDBErrors> {
    const params = { input: { id: id } }
    try {
        const result = (await API.graphql(graphqlOperation(deleteUserLight, params))) as GraphQLResult<DeleteUserMutation>
        const data = result.data
        // TODO better return type and null checks
        return {
            getUser: {
                id: data!.deleteUser!.id,
                name: data!.deleteUser!.name!,
                pictureUrl: data!.deleteUser!.pictureUrl ?? undefined,
                presenceStatus: PresenceType[data!.deleteUser!.presenceStatus! as keyof typeof PresenceType],
                lastConnected: data!.deleteUser!.lastConnected
            }
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices deleteUser failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function getUserById(id: string): Promise<UserResponse | BackendServiceError> {
    const params = { id }
    try {
        const result = (await API.graphql(graphqlOperation(getUserLight, params))) as GraphQLResult<GetUserQuery>
        return result.data as UserResponse
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getUser failed", params: params, error: error })
        return error
    }
}

export interface UserBatchPresenceResponse {
    id: string
    presenceStatus: PresenceType
    lastConnected: string
}
export async function getBatchPresenceByUserId(listIds: string[]): Promise<UserBatchPresenceResponse[] | BackendServiceError> {
    const params = { listIds: listIds }
    try {
        const result = (await API.graphql(
            graphqlOperation(batchGetUserPresenceLight, params)
        )) as GraphQLResult<BatchGetUserPresenceQuery>

        return result.data?.batchGetUserPresence as UserBatchPresenceResponse[]
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getBatchPresenceByUser failed", params: params, error: error })
        return {
            httpStatus: 500,
            httpStatusText: error.data,
            responseJson: error
        }
    }
}

/*********************************************************************************************
 * CALLING
 **********************************************************************************************/
export interface Meeting {
    id: string
    participants: MeetingParticipant[]
    start: Date
    end: Date
}

export interface User {
    id: string
    name: string
    pictureUrl?: string
}

export interface MeetingParticipant {
    id: string
    meeting: Meeting
    inviter: User
    invitee: User
    status: InviteStatus
    created: Date
}

export async function inviteToMeeting(
    callerId: string,
    hailingId: string,
    meetingId: string
): Promise<MeetingParticipant | null> {
    const params = {
        input: {
            meetingId: meetingId,
            inviterId: callerId,
            inviteeId: hailingId,
            status: InviteStatus.INVITING,
            created: new Date().toISOString()
        }
    }
    try {
        const result = (await API.graphql(graphqlOperation(createMeetingParticipant, params))) as any // TODO use correct type
        if (result?.data?.createMeetingParticipant) {
            return result.data.createMeetingParticipant
        }
        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createMeetingParticipant failed", params: params, error: error })
        return null
    }
}

export async function ensureMeeting(meetingId: string): Promise<string | undefined> {
    const params = { id: meetingId }
    try {
        const getMeetingResultAwait = (await API.graphql(
            graphqlOperation(doesMeetingExist, params)
        )) as GraphQLResult<GetMeetingLightQuery>
        if (getMeetingResultAwait?.data?.getMeeting?.id) return getMeetingResultAwait?.data?.getMeeting?.id
        const params2 = {
            input: {
                id: meetingId,
                start: new Date().toISOString()
            }
        }
        try {
            const createMeetingResultAwait = (await API.graphql(
                graphqlOperation(createMeeting, params2)
            )) as GraphQLResult<CreateMeetingMutation>
            return createMeetingResultAwait?.data?.createMeeting?.id
        } catch (error: any) {
            errorDynamoDBmessage({ message: "BackendServices createMeeting failed", params: params2, error: error })
            return undefined
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices doesMeetingExist failed", params: params, error: error })
        return undefined
    }
}

export async function ensureChatConversation(
    externalMeetingId: string,
    inviterId: string,
    hailedId: string,
    participantLimit: number
): Promise<boolean> {
    const conversationId = externalMeetingId.substr(3)
    const participants = await getConversationParticipants(conversationId, participantLimit)

    if (!participants) {
        const result = await createCallChatConversation(conversationId, inviterId, [hailedId])
        return !!result
    }

    if (participants?.length >= participantLimit) {
        return false
    }

    const inviterExists = participants.find((p) => p.id === inviterId)
    if (inviterExists) {
        const hailedExists = participants.find((p) => p.id === hailedId)
        if (hailedExists) {
            return true
        } else {
            try {
                const result = await addParticipantsToGroupChatConversation(conversationId, [hailedId])
                return !!result
            } catch {
                return false
            }
        }
    }

    // inviter not part of conversation => not authorized to add invitee
    return false
}

export async function updateMeetingInvite(id: string, status: InviteStatus) {
    const params = { input: { id: id, status: status } }
    try {
        const result = (await API.graphql(
            graphqlOperation(updateMeetingParticipantLight, params)
        )) as GraphQLResult<UpdateMeetingParticipantMutation>
        if (result?.data?.updateMeetingParticipant) {
            return result.data.updateMeetingParticipant
        }
        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateMeetingParticipant failed", params: params, error: error })
        return null
    }
}

export async function getMeetingHistoryEntries(id: string) {
    const params = { id: id, limit: 20 }
    try {
        const result = (await API.graphql(graphqlOperation(listMeetings, params))) as GraphQLResult<GetMeetingsQuery>
        if (result?.data?.getUser) {
            const inviteHistory: MeetingParticipant[] = []
            const incomingMeetings = result.data.getUser.incomingMeetings?.items
            incomingMeetings?.forEach((item: any) => {
                item.meeting.participants = item.meeting.participants.items
                inviteHistory.push(item)
            })
            const outgoingMeetings = result.data.getUser.outgoingMeetings?.items
            outgoingMeetings?.forEach((item: any) => {
                item.meeting.participants = item.meeting.participants.items
                inviteHistory.push(item)
            })
            inviteHistory.sort((a, b) => (a.created > b.created ? 1 : b.created > a.created ? -1 : 0))
            return inviteHistory
        }
        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices listMeetings failed", params: params, error: error })
        return null
    }
}

/*********************************************************************************************
 * CALENDAR
 **********************************************************************************************/

export async function createNewCalendarEntry(
    userId: string,
    participantsId: string[],
    title: string,
    start: Date,
    end: Date,
    isVirtual: boolean,
    description?: string,
    organization?: string,
    locationName?: string,
    locationActionType?: string,
    locationActionParam?: string,
): Promise<CalendarEntry | null> {
    const input: CreateCalendarEntryInput = {
        userId: userId,
        title: title,
        description: description,
        start: start.toISOString(),
        end: end.toISOString(),
        organizationId: organization,
        status: organization ? CalendarEntryParticipationStatus.REQUESTED : undefined,
        topicName: branding.configuration.topicName,
        userPoolName: branding.configuration.userPoolName,
        locationName: locationName,
        locationActionType: locationActionType,
        locationActionParam: locationActionParam,
        isVirtual: isVirtual
    }

    const params = { input: input }
    try {
        const result = (await API.graphql(graphqlOperation(createCalendarEntryLight, params))) as any
        const calendarEntry: CalendarEntry = result?.data?.createCalendarEntry

        if (calendarEntry) {
            const calendarEntryId = result.data.createCalendarEntry.id
            const items: CalendarEntryParticipation[] = []

            await Promise.all(
                participantsId.map(async (participantId) => {
                    if (userId !== participantId) {
                        const participant = await createNewCalendarEntryParticipation(
                            calendarEntryId,
                            participantId,
                            CalendarEntryParticipationStatus.REQUESTED,
                            start
                        )

                        if (participant) {
                            items.push(participant)
                        }
                    }
                })
            )

            const owner = await createNewCalendarEntryParticipation(
                calendarEntryId,
                userId,
                CalendarEntryParticipationStatus.ACCEPTED,
                start
            )
            if (owner) {
                items.push(owner)
            }
            const participants = {
                items: items
            }
            calendarEntry.participants = participants
            return calendarEntry
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createCalendarEntryLight failed", params: params, error: error })
        return null
    }
}

export async function createNewCalendarEntryParticipation(
    calendarEntryId: string,
    participantId: string,
    status: CalendarEntryParticipationStatus,
    start: Date
): Promise<CalendarEntryParticipation | null> {
    const participantInput: CreateCalendarEntryParticipationInput = {
        userId: participantId,
        calendarEntryId: calendarEntryId,
        status: status,
        start: start.toISOString()
    }

    const params = { input: participantInput }
    try {
        const result = (await API.graphql(
            graphqlOperation(createCalendarEntryParticipationLight, params)
        )) as GraphQLResult<CreateCalendarEntryParticipationMutation>
        const participation = result?.data?.createCalendarEntryParticipation
        if (participation) {
            return {
                id: participation.id,
                status: participation.status,
                userId: participation.userId,
                user: {
                    id: participation.userId,
                    name: participation.user.name ? participation.user.name : "",
                    pictureUrl: participation.user.pictureUrl ? participation.user.pictureUrl : ""
                }
            }
        }
        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createCalendarEntryParticipation failed", params: params, error: error })
        return null
    }
}

export async function deleteCalendarEntryParticipationById(participationId: string) {
    const params = { input: { id: participationId } }
    try {
        const result = (await API.graphql(
            graphqlOperation(deleteCalendarEntryParticipation, params)
        )) as GraphQLResult<DeleteCalendarEntryParticipationMutation>
        return result?.data?.deleteCalendarEntryParticipation?.id
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices deleteCalendarEntryParticipation failed", params: params, error: error })
        return null
    }
}

export async function deleteCalendarEntryForUser(userId: string, calendarEntryId: string) {
    const condition: ModelCalendarEntryConditionInput = {
        userId: { eq: userId }
    }
    const params = { input: { id: calendarEntryId }, condition: condition }
    try {
        // Only the calendarEntry is deleted here
        // Lambda function "DeleteCalendarEntryMailSender" deletes participations and sends an email to each participant
        const result = (await API.graphql(
            graphqlOperation(deleteCalendarEntryLight, params)
        )) as GraphQLResult<DeleteCalendarEntryMutation>
        if (result?.data?.deleteCalendarEntry) {
            return result?.data?.deleteCalendarEntry
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices deleteCalendarEntry failed", params: params, error: error })
        return null
    }
}

export async function acceptOrganizationMeeting(calendarEntry: CalendarEntry, userId: string) {
    const input: UpdateCalendarEntryInput = {
        id: calendarEntry.id,
        userId: userId,
        status: CalendarEntryParticipationStatus.ACCEPTED
    }
    const params = { input: input }
    try {
        const result = (await API.graphql(graphqlOperation(updateCalendarEntryLight, params))) as any
        const calendarEntry = result?.data?.updateCalendarEntry
        if (calendarEntry) {
            return createNewCalendarEntryParticipation(
                calendarEntry.id,
                userId,
                CalendarEntryParticipationStatus.ACCEPTED,
                new Date(calendarEntry.start)
            )
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateCalendarEntryLight failed", params: params, error: error })
        return null
    }
}

export async function declineOrganizationMeeting(calendarEntry: CalendarEntry, userId: string) {
    const input: UpdateCalendarEntryInput = {
        id: calendarEntry.id,
        status: CalendarEntryParticipationStatus.DECLINED
    }
    const params = { input: input }
    try {
        const result = (await API.graphql(graphqlOperation(updateCalendarEntryLight, params))) as any
        const calendarEntry = result?.data?.updateCalendarEntry
        if (calendarEntry) {
            return createNewCalendarEntryParticipation(
                calendarEntry.id,
                userId,
                CalendarEntryParticipationStatus.DECLINED,
                new Date(calendarEntry.start)
            )
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateCalendarEntryLight failed", params: params, error: error })
        return null
    }
}

export async function updateCalendarEntryById(
    calendarEntry: CalendarEntry,
    title: string,
    start: Date,
    end: Date,
    isVirtual: boolean,
    description?: string,
    locationName?: string,
    locationActionType?: string,
    locationActionParam?: string
): Promise<CalendarEntry | null> {
    const input: UpdateCalendarEntryInput = {
        id: calendarEntry.id,
        start: start.toISOString(),
        end: end.toISOString(),
        title: title,
        description: description,
        locationName: locationName,
        locationActionType: locationActionType,
        locationActionParam: locationActionParam,
        isVirtual: isVirtual
    }

    const params = { input: input }
    try {
        const result = (await API.graphql(graphqlOperation(updateCalendarEntryLight, params))) as any
        const calendarEntry = result?.data?.updateCalendarEntry

        if (calendarEntry) {
            return calendarEntry
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateCalendarEntryLight failed", params: params, error: error })
        return null
    }
}

export async function updateCalendarEntryParticipationStatus(
    calendarEntryParticipationId: string,
    userId: string,
    start?: Date,
    status?: CalendarEntryParticipationStatus
): Promise<CalendarEntryParticipationStatus | null> {
    const input: UpdateCalendarEntryParticipationInput = {
        id: calendarEntryParticipationId,
        start: start?.toISOString(),
        status: status
    }

    const params = { input: input }
    try {
        const result = (await API.graphql(
            graphqlOperation(updateCalendarEntryParticipationLight, params)
        )) as GraphQLResult<UpdateCalendarEntryParticipationMutation>

        if (result?.data?.updateCalendarEntryParticipation) {
            return result?.data?.updateCalendarEntryParticipation.status
        } else {
            return null
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateCalendarEntryParticipation failed", params: params, error: error })
        return null
    }
}

export interface CalendarUser {
    id: string
    name: string
    pictureUrl: string
}

export interface CalendarEntryParticipation {
    id: string
    status: CalendarEntryParticipationStatus
    user: CalendarUser
    userId: string
}

export interface CalendarEntry {
    id: string
    title: string
    description?: string
    start: string
    end: string
    userId: string
    user: CalendarUser
    organizationId?: string
    participants: {
        items: CalendarEntryParticipation[]
    }
    locationName: string
    locationActionType: string
    locationActionParam: string
    isVirtual: boolean
}

export interface CalendarEntries {
    items: {
        calendarEntryId: string
        calendarEntry: CalendarEntry
    }[]
    nextToken: string
}

export enum CalendarEntrySortType {
    ALL,
    PAST,
    FUTURE
}

export async function getCalendarEntriesAcceptedBetweenDates(
    userId: string,
    startTime: Date,
    endTime: Date,
    nextToken?: string
): Promise<CalendarEntries | null> {
    const dayBeforeStartTime = new Date(startTime.getTime() - 24 * 60 * 60 * 1000) // used to extend between range for dates that start before startTime but are finished in range of startTime and endTime
    const startInput: ModelStringKeyConditionInput = { between: [dayBeforeStartTime.toISOString(), endTime.toISOString()] }

    const params = {
        userIdStatus: userId + CalendarEntryParticipationStatus.ACCEPTED,
        start: startInput,
        limit: 50,
        nextToken: nextToken,
        sortDirection: ModelSortDirection.ASC
    }
    try {
        const result = (await API.graphql(graphqlOperation(getCalendarEntryParticipations, params))) as any

        const calendarEntries = result?.data?.calendarEntryParticipationByUserAndStatusSorted
        if (calendarEntries) {
            var participationIdsToDelete: string[] = []
            // Filter out calendarEntries that are null
            calendarEntries.items = calendarEntries.items.filter(
                (item: { calendarEntryId: string; calendarEntry: CalendarEntry | null; id: string }) => {
                    if (item.calendarEntry === null) {
                        participationIdsToDelete.push(item.id)
                    }
                    return item.calendarEntry !== null && !(moment(item.calendarEntry.end).toDate() <= startTime) //exclude dates that are start and finished in day before startTime
                }
            )

            // Delete participations with a calendarEntry that is null
            await Promise.all(
                participationIdsToDelete.map(async (participantId) => {
                    await deleteCalendarEntryParticipationById(participantId)
                })
            )

            return calendarEntries
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getCalendarEntryParticipations failed", params: params, error: error })
        return null
    }
}

export async function getCalendarEntries(
    userId: string,
    sortType: CalendarEntrySortType,
    status?: CalendarEntryParticipationStatus,
    nextToken?: string
): Promise<CalendarEntries | null> {
    const currentDate = new Date()
    const startInput: ModelStringKeyConditionInput =
        sortType === CalendarEntrySortType.FUTURE
            ? {
                  ge: currentDate.toISOString()
              }
            : {
                  lt: currentDate.toISOString()
              }
    const sortDirection: ModelSortDirection =
        sortType === CalendarEntrySortType.PAST ? ModelSortDirection.DESC : ModelSortDirection.ASC

    const params = {
        userIdStatus: userId + status,
        start: sortType === CalendarEntrySortType.ALL ? undefined : startInput,
        limit: 25,
        nextToken: nextToken,
        sortDirection: sortDirection
    }
    try {
        const result = (await API.graphql(graphqlOperation(getCalendarEntryParticipations, params))) as any // TODO use correct type

        const calendarEntries = result?.data?.calendarEntryParticipationByUserAndStatusSorted
        if (calendarEntries) {
            var participationIdsToDelete: string[] = []
            // Filter out calendarEntries that are null
            calendarEntries.items = calendarEntries.items.filter(
                (item: { calendarEntryId: string; calendarEntry: CalendarEntry | null; id: string }) => {
                    if (item.calendarEntry === null) {
                        participationIdsToDelete.push(item.id)
                    }
                    return item.calendarEntry !== null
                }
            )

            // Delete participations with a calendarEntry that is null
            await Promise.all(
                participationIdsToDelete.map(async (participantId) => {
                    await deleteCalendarEntryParticipationById(participantId)
                })
            )

            return calendarEntries
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getCalendarEntryParticipations failed", params: params, error: error })
        return null
    }
}

export interface OrganizationCalendarEntry {
    id: string
    title: string
    description?: string
    start: string
    end: string
    organizationId: string
    user: {
        id: string
        presenceStatus: string
        name: string
        pictureUrl?: string
    }
    participants: {
        items: {
            id: string
            status: CalendarEntryParticipationStatus
            user: {
                id: string
                presenceStatus: string
                name: string
                pictureUrl?: string
            }
        }[]
    }
}

export interface OrganizationCalendarEntryResponse {
    nextToken?: string
    items: CalendarEntry[]
}

export async function getOrganizationMeetingRequests(
    organizationId: string,
    listType: "upcoming" | "now" | "past",
    nextToken?: string
): Promise<OrganizationCalendarEntryResponse | null> {
    const date = new Date().toISOString()
    const params = {
        organizationIdStatus: organizationId + CalendarEntryParticipationStatus.REQUESTED,
        sortDirection: listType === "past" ? ModelSortDirection.DESC : ModelSortDirection.ASC,
        limit: 25,
        nextToken: nextToken,
        start: listType === "upcoming" ? { ge: date } : { lt: date },
        filter: {
            topicName: { eq: topic },
            end: listType === "now" ? { ge: date } : listType === "past" ? { lt: date } : undefined
        }
    }
    try {
        const result = (await API.graphql(graphqlOperation(listCalendarEntryByOrganization, params))) as any
        if (result?.data?.calendarEntryByOrganizationAndStatusSorted) {
            return result?.data?.calendarEntryByOrganizationAndStatusSorted
        }
        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices listCalendarEntryByOrganization failed", params: params, error: error })
        return null
    }
}

export interface CalendarAvailability {
    id: string
    userId: string
    user: CalendarUser
    start: string
    end: string
    available: boolean
}

export interface CalendarAvailabilities {
    items: [CalendarAvailability]
}

export async function findCalendarEntryById(calendarEntryId: string): Promise<CalendarEntry | undefined> {
    const params = { id: calendarEntryId, limit: branding.configuration.calendarEntryParticipantLimit }
    try {
        const result = (await API.graphql(
            graphqlOperation(getCalendarEntryLight, params)
        )) as GraphQLResult<GetCalendarEntryLightQuery>
        if (!result?.data?.getCalendarEntry) {
            return undefined
        }
        const calendarEntry = result.data.getCalendarEntry
        return calendarEntry as any
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getCalendarEntryLight failed", params: params, error: error })
        return undefined
    }
}

/*********************************************************************************************
 * UserSessions
 **********************************************************************************************/

export interface UserSessionProps {
    id: string
    userId: string
    location?: string
    locationLevel1?: string
    locationLevel2?: string
    locationLevel3?: string
    sotName?: string
    topic?: string
    time?: string
    ttl?: number
    source?: string
    queryHelper: string
    userType?: string
    countryCode?: string
}

export async function createNewUserSession(input: UserSessionProps, tryUpdate: boolean): Promise<string | undefined> {
    const params = createUserSessionParam(input)
    try {
        const resp = (await API.graphql(graphqlOperation(createUserSession, params))) as GraphQLResult<CreateUserSessionMutation>
        return resp.data?.createUserSession?.id
    } catch (error: any) {
        errorDynamoDBmessage({
            message: "BackendServices createUserSession failed" + (tryUpdate ? " - trying update" : " - no update"),
            params: params,
            error: error
        })
        if (tryUpdate) {
            return updateUserSessionData(input, false)
        }
        return undefined
    }
}

export async function closeUserSession(sessionId: string): Promise<string | undefined> {
    const params = { input: { id: sessionId } }
    try {
        const resp = (await API.graphql(graphqlOperation(deleteUserSession, params))) as GraphQLResult<DeleteUserSessionMutation>
        return resp.data?.deleteUserSession?.id
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices closeUserSession failed", params: params, error: error })
        return undefined
    }
}

export async function updateUserSessionData(input: UserSessionProps, tryCreate: boolean): Promise<string | undefined> {
    const params = createUserSessionParam(input)
    try {
        const resp = (await API.graphql(
            graphqlOperation(updateUserSessionLight, params)
        )) as GraphQLResult<UpdateUserSessionMutation>
        return resp.data?.updateUserSession?.id
    } catch (error: any) {
        // Try self heal
        errorDynamoDBmessage({
            message: "BackendServices updateUserSessionData failed" + (tryCreate ? " - trying create" : " - no create"),
            params: params,
            error: error
        })
        if (tryCreate) {
            return createNewUserSession(input, false)
        }
        return undefined
    }
}

function createUserSessionParam(input: UserSessionProps) {
    const param = { input }
    param.input.sotName = seriesOfTopicsName
    param.input.time = new Date().toISOString()
    param.input.ttl = Math.round(new Date().getTime() / 1000) + 6 * 60
    param.input.queryHelper = "X"
    param.input.topic = topic
    return param
}

export interface UsersByLocationResponse {
    getCurrentLocationCounts: {
        id: string
        cnt: number
        lastConnected: string
    } | null
}

export async function getUsersByLocation(location: string): Promise<number> {
    const id = location === "" ? seriesOfTopicsName : seriesOfTopicsName + "#" + location
    const params = { id: id }
    try {
        const result = (await API.graphql(graphqlOperation(getOnlineUsers, params))) as GraphQLResult<getOnlineUsersQuery>
        return result.data?.getCurrentLocationCounts?.cnt ? result.data.getCurrentLocationCounts.cnt : 0
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getUsersByLocation failed", params: params, error: error })
        return 0
    }
}

export async function trackStartOfCall(
    sessionId: string,
    userId: string,
    callId: string,
    countryCode?: string,
    userType?: string
): Promise<string | DynamoDBErrors> {
    const startTime = new Date().toISOString()
    const id = sessionId + "#" + startTime
    const param = {
        id: id,
        userId: userId,
        sotName: seriesOfTopicsName,
        topic: topic,
        actionType: UserActionType.CALL,
        param: callId,
        startTime: startTime,
        duration: 0,
        countryCode,
        userType
    }
    const params = { input: param }
    try {
        await API.graphql(graphqlOperation(createUserAction, params))
        return id
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createUserAction failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function trackStartOfStream(
    sessionId: string,
    userId: string,
    event: string,
    countryCode?: string,
    userType?: string
): Promise<string | DynamoDBErrors> {
    const startTime = new Date().toISOString()
    const id = sessionId + "#" + startTime
    const param = {
        id: id,
        userId: userId,
        sotName: seriesOfTopicsName,
        actionType: UserActionType.STREAM,
        topic: topic,
        param: event,
        startTime: startTime,
        duration: 0,
        countryCode,
        userType
    }
    const params = { input: param }
    try {
        await API.graphql(graphqlOperation(createUserAction, params))
        return id
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createUserAction failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function trackVODWatchTime(
    profileId: string,
    organizationId: string,
    eventDateId: string,
    duration?: string | null
): Promise<string | DynamoDBErrors> {
    const param = {
        profileId: profileId,
        organizationId: organizationId,
        eventDateId: eventDateId,
        duration: duration,
        actionType: UserActionType.VODWATCHTIME
    }
    const params = { input: param }
    try {
        await API.graphql(graphqlOperation(createUserAction, params))
        return eventDateId
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createUserAction failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function trackEndOfCall(trackingId: string, type: UserActionType): Promise<string | DynamoDBErrors> {
    const startTime = new Date(trackingId.toString().split("#")[1])
    const endTime = new Date()
    const duration = endTime.getTime() - startTime.getTime()
    const param = {
        id: trackingId,
        endTime: endTime.toISOString(),
        duration: duration
    }
    const condition: ModelUserActionConditionInput = { actionType: { eq: type } }
    const params = { input: param, condition }
    try {
        await API.graphql(graphqlOperation(updateUserAction, params))
        return trackingId
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateUserAction failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function listUsersInAllLoungesOrCalls(specificCallParam?: string): Promise<SessionUserMap> {
    const [inLounge, inCall] = await Promise.all([
        listUsersInAllConferenceRooms(),
        listUsersInAllLounges(),
        listUsersInAllCalls(specificCallParam)
    ])
    return mergeSessionUserMaps(inCall, inLounge)
}

function mergeSessionUserMaps(map1: SessionUserMap, map2: SessionUserMap) {
    const result: SessionUserMap = Object.assign({}, map1)
    for (const [key, val] of Object.entries(map1)) {
        if (!result[key]) {
            result[key] = []
        }
        result[key] = unique((x) => x.user.id, val)
    }

    for (const [key, val] of Object.entries(map2)) {
        if (!result[key]) {
            result[key] = []
        }
        result[key] = unique((x) => x.user.id, result[key].concat(val))
    }
    return result
}

function unique(idFun: (x: any) => string, arr: any[]): SessionUser[] {
    const ids: string[] = []
    const result: any[] = []
    arr.forEach((x: any) => {
        const id = idFun(x)
        if (ids.indexOf(id) < 0) {
            ids.push(id)
            result.push(x)
        }
    })
    return result
}

export type SessionUser = {
    id: string
    user?: {
        id: string
        name: string
        pictureUrl: string
    }
}

export interface SessionUserMap {
    [loungeOrRoom: string]: SessionUser[]
}

async function listUsersInAllConferenceRooms(): Promise<SessionUserMap> {
    const params = {
        sotName: seriesOfTopicsName,
        locationLevel1: { beginsWith: "/meeting/cr" }
    }
    try {
        const lounges: SessionUserMap = {}
        const result = (await API.graphql(
            graphqlOperation(userSessionsByLocation, params)
        )) as GraphQLResult<UserSessionsByLocationQuery>

        if (result.data?.userSessionsByLocation?.items) {
            result.data.userSessionsByLocation.items
                .filter((x: any) => x.user)
                .forEach((item: any) => {
                    const loungeId = (item.locationLevel1 as string).substring(12)
                    if (!lounges[loungeId]) {
                        lounges[loungeId] = []
                    }
                    lounges[loungeId].push(item)
                })
        }
        return lounges
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices userSessionsByLocation failed", params: params, error: error })
        return {}
    }
}

async function listUsersInAllLounges(): Promise<SessionUserMap> {
    const params = {
        sotName: seriesOfTopicsName,
        locationLevel2: { beginsWith: "/meetings/" }
    }
    try {
        const lounges: SessionUserMap = {}
        const result = (await API.graphql(
            graphqlOperation(userSessionsByLocation, params)
        )) as GraphQLResult<UserSessionsByLocationQuery>
        if (result.data?.userSessionsByLocation?.items) {
            result.data.userSessionsByLocation.items
                .filter((x: any) => x.user)
                .forEach((item: any) => {
                    const loungeId = (item.locationLevel2 as string).substring(10)
                    if (!lounges[loungeId]) {
                        lounges[loungeId] = []
                    }
                    lounges[loungeId].push(item)
                })
        }
        return lounges
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices userSessionsByLocation failed", params: params, error: error })
        return {}
    }
}

interface ChannelParticipants {
    nextToken?: string
    items: SessionUser[]
}
export async function listChannelParticipants(channelId: string, nextToken?: string): Promise<ChannelParticipants | null> {
    const params = {
        sotName: seriesOfTopicsName,
        locationLevel2: { beginsWith: `/channel/${channelId}` },
        limit: 25,
        nextToken: nextToken
    }
    try {
        const result = (await API.graphql(
            graphqlOperation(userSessionsByLocation, params)
        )) as GraphQLResult<UserSessionsByLocationQuery>
        if (result.data?.userSessionsByLocation?.items) {
            const items = result.data.userSessionsByLocation.items.map((x: any) => x.user)
            return { items: items, nextToken: result.data.userSessionsByLocation.nextToken ?? undefined }
        }
        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices userSessionsByLocation failed", params: params, error: error })
        return null
    }
}

async function listUsersInAllCalls(specificCallParam?: string): Promise<SessionUserMap> {
    const beginsWithParam = specificCallParam ?? "virtualCafe#"

    const params = {
        actionType: UserActionType.CALL,
        duration: { eq: 0 },
        filter: { sotName: { eq: seriesOfTopicsName }, param: { beginsWith: beginsWithParam } }
    }
    try {
        const lounges: SessionUserMap = {}
        const result = (await API.graphql(
            graphqlOperation(usersInCallsInLounge, params)
        )) as GraphQLResult<usersInCallsInLoungeQuery>
        if (result.data?.byActionType?.items) {
            result.data.byActionType.items
                .filter((x: any) => x.user)
                .forEach((item: any) => {
                    const roomId = (item.param as string).substring(beginsWithParam.length)
                    const [loungeId] = roomId.split("/")
                    if (!lounges[loungeId]) {
                        lounges[loungeId] = []
                    }
                    lounges[loungeId].push(item)
                    if (!lounges[roomId]) {
                        lounges[roomId] = []
                    }
                    lounges[roomId].push(item)
                })
        }
        return lounges
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices usersInCallsInLounge failed", params: params, error: error })
        return {}
    }
}

/*********************************************************************************************
 * UnreadCount within CommunicationArea
 **********************************************************************************************/

export type UnreadObject = {
    id: string
    requests?: number
    contacts?: number
    conversations?: number
    schedules?: number
}

export async function getUnreadCounterUser(userId: string): Promise<UnreadObject | DynamoDBErrors> {
    const params = { id: userId }
    try {
        const result = (await API.graphql(graphqlOperation(getUnreadCounter, params))) as GraphQLResult<GetUnreadCounterQuery>
        const data = result.data?.getUnreadCounter
        return data as UnreadObject
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getUnreadCounter failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function createUnreadCounterUser(param: UnreadObject): Promise<UnreadObject | DynamoDBErrors> {
    const params = { input: param }
    try {
        const result = (await API.graphql(
            graphqlOperation(createUnreadCounter, params)
        )) as GraphQLResult<CreateUnreadCounterMutation>
        const data = result.data?.createUnreadCounter
        return data as UnreadObject
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createUnreadCounter failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

/* #region  HELPERS */
interface ConversationListEntryProps {
    __typename: "UserConversation"
    id: string
    isMuted?: boolean | null
    conversation: {
        __typename: "Conversation"
        id: string
        type?: ConversationType | null
        name?: string | null
        userId?: string | null
        mostRecentMessage?: string | null
        members?: {
            __typename: "ModelUserConversationConnection"
            items?:
                | ({
                      __typename: "UserConversation"
                      id: string
                      userId: string
                      user: {
                          __typename: "User"
                          id: string
                          name?: string | null
                          pictureUrl?: string | null
                      }
                  } | null)[]
                | null
        } | null
    }
    mostRecentMessageCreatedAt?: string | null
    lastReadMessageCreatedAt?: string | null
}

function processConversationListEntry(
    profileId: string,
    item: ConversationListEntryProps | null
): ConversationListEntry | undefined {
    if (!item) {
        return
    }
    const convo = item.conversation
    const opponents = convo.members?.items
        ?.filter((item) => item && item.user && item?.user.id !== profileId)
        ?.map((item) => item!.user!)
    if (!opponents || (convo.type === ConversationType.PRIVATE && opponents.length === 0)) {
        return
    }
    let convoTitle: string | undefined
    if (!convo.type || convo.type === ConversationType.PRIVATE) {
        convoTitle = opponents[0].name ?? undefined
    } else {
        convoTitle = convo.name ?? undefined
    }
    const myUserConvo = item

    return {
        id: convo.id,
        type: convo.type ?? ConversationType.PRIVATE,
        userId: convo.userId ?? undefined,
        userConversationId: myUserConvo.id,
        isMuted: myUserConvo.isMuted ?? false,
        title: convoTitle,
        opponentIds: opponents.map((o) => o.id),
        pictureUrls: opponents.map((o) => o.pictureUrl ?? undefined),
        opponentNames: opponents.map((o) => o.name!),
        lastMessage: convo.mostRecentMessage!,
        lastMessageTime: new Date(item.mostRecentMessageCreatedAt!),
        lastReadTime: item.lastReadMessageCreatedAt ? new Date(item.lastReadMessageCreatedAt!) : undefined
    }
}

/* #endregion */
