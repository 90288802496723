import {
    News,
    Trademark,
    Product,
    JobOffer,
    EntityTrademark,
    EntityProduct,
    EntityNews,
    EntityJobOffer,
    ShareTargetType
} from "../../../backendServices/Types"
import styled from "styled-components"
import branding, { Branding } from "../../../branding/branding"
import BookmarkWithToggle from "../../BookmarkWithToggle"
import { useNavigationSource } from "../../../tracking/NavigationSource"
import { buildDetailLink } from "../../../contentArea/detailPages/DetailNavLink"
import { IconLinkRightArrow, IconShare } from "../../Icons"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { device } from "../../../utils/Device"
import { NavLink } from "react-router-dom"
import { TileLogo } from "./TileLogo"
import useWindowDimensions from "../../WindowDimensionsHook"
import { useFavoriteState } from "../../../globalStates/Favorites"
import { Tooltip } from "react-bootstrap"
import { useRecommendModal } from "../../modals/RecommendModal"
import { CustomOverlayTrigger } from "../../CustomOverlayTrigger"

type ComponentType = Product | Trademark | News | JobOffer
type ComponentTypeString = EntityProduct | EntityTrademark | EntityNews | EntityJobOffer

const getVisitEntityLinkText = (type: ComponentTypeString, strings: Branding) => {
    switch (type) {
        case "product":
            return strings.receptionPage.visitProductText
        case "trademark":
            return strings.receptionPage.visitTrademarkText
        case "news":
            return strings.receptionPage.visitNewsText
        case "joboffer":
            return strings.receptionPage.visitJobofferText
        default:
            break
    }
}

const EntityActionsWrapper = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

interface EntityActionsProps {
    type: ComponentTypeString
    entity: ComponentType
    isBookmarked: boolean
    shareTargetType?: ShareTargetType
    showRecommendModal: (shareType: ShareTargetType, entityId: string, link: string) => void
}
const EntityActions = (props: EntityActionsProps) => {
    const { isMobile } = useWindowDimensions()

    if (isMobile) return null

    return (
        <EntityActionsWrapper>
            {props.shareTargetType && (
                <CustomOverlayTrigger
                    trigger={["focus", "click", "hover"]}
                    placement="bottom"
                    delay={{ show: 250, hide: 250 }}
                    overlay={
                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                            {branding.communicationArea.chatToolRecommend}
                        </Tooltip>
                    }
                >
                    <div
                        className="show-on-hover mr-3"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (props.shareTargetType)
                                props.showRecommendModal(
                                    props.shareTargetType,
                                    props.entity.id,
                                    buildDetailLink(props.entity.id, props.entity.name, props.type)
                                )
                        }}
                    >
                        {IconShare({ fill: branding.receptionPage.myFairBookmarkTileIconColor })}
                    </div>
                </CustomOverlayTrigger>
            )}

            <CustomOverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {props.isBookmarked
                            ? branding.organizationDetailPageContent.unBookmarkPerson
                            : branding.organizationDetailPageContent.bookmarkPerson}
                    </Tooltip>
                }
            >
                <div className="exhibitor-bookmark">
                    <BookmarkWithToggle
                        className={"show-on-hover"}
                        newBookmarkItem={true}
                        type={props.type}
                        id={props.entity.id}
                        name={props.entity.name}
                        color={branding.receptionPage.myFairBookmarkTileIconColor}
                        stroke={branding.receptionPage.myFairBookmarkTileIconColor}
                    />
                </div>
            </CustomOverlayTrigger>
        </EntityActionsWrapper>
    )
}

interface EntityComponentProps {
    type: ComponentTypeString
    entity: ComponentType
    shareTargetType?: ShareTargetType
}
export const EntityComponent: React.FC<EntityComponentProps> = (props) => {
    const nav = useNavigationSource()
    const strings = useLanguageState().getStrings()
    const favoriteState = useFavoriteState()
    const { RecommendModal, showRecommendModal } = useRecommendModal()
    const { isMobile } = useWindowDimensions()
    const { entity } = props
    const isBookmarked = favoriteState.is(props.type, entity.id || "")
    const visitEntityText = getVisitEntityLinkText(props.type, strings)

    return (
        <EntityComponentRoot
            type={props.type}
            onClick={() => nav.set("LOBBY")}
            to={buildDetailLink(entity.id, entity.name, props.type)}
            className={isMobile ? "isMobile" : ""}
        >
            <EntityActions
                type={props.type}
                entity={props.entity}
                shareTargetType={props.shareTargetType}
                isBookmarked={isBookmarked}
                showRecommendModal={showRecommendModal}
            />

            <TileLogo
                url={entity.pictureURL ?? (props.type === "joboffer" && (entity as Product | Trademark | News).logoUrl)}
                alt={isMobile ? entity.name.replace(/(.{25})..+/, "$1…") : entity.name.replace(/(.{80})..+/, "$1…")}
                entityType={props.type}
            />

            {!isMobile && (
                <VisitEntityLink className={"show-on-hover"}>
                    <div className="mr-3">{visitEntityText}</div>
                    <div>
                        {IconLinkRightArrow({
                            stroke: branding.receptionPage.myFairBookmarkTileTextColor
                        })}
                    </div>
                </VisitEntityLink>
            )}

            <RecommendModal />
        </EntityComponentRoot>
    )
}

const VisitEntityLink = styled.span`
    font-family: ${branding.font1};
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 1;
    width: 80%;
    transform: translateX(-50%);
    color: ${branding.receptionPage.myFairBookmarkTileTextColor};
    font-size: 16px;
`

const EntityComponentRoot = styled(NavLink)<{ type: ComponentTypeString }>`
    position: relative;
    display: flex;
    flex-basis: 95px;
    justify-content: center;
    align-items: center;
    width: 95px;
    height: 95px;
    border-style: solid;
    border-width: 1px;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    padding: 5px;
    margin: 0 0 1.25rem 0;
    color: #000;
    margin-right: ${branding.receptionPage.myFairPageExhibitorTilesMargin ?? "10px"};
    border-radius: 5px;

    &::before {
        background-color: white;
    }

    &:hover .show-on-hover {
        display: none;

        @media ${device.tablet} {
            display: flex;

            &.isMobile {
                display: none;
            }
        }
    }

    & .show-on-hover {
        display: none;
        color: ${branding.crsTabs.defaultActionItemColor};
    }

    ${VisitEntityLink} + ${VisitEntityLink} {
        left: 80%;

        @media ${device.tablet} {
            ${(props) => (props.type === "product" ? "left: 63%;" : "")}
        }
    }

    @media ${device.tablet} {
        flex-basis: ${(props) => (props.type === "product" ? "500px" : "250px")};
        width: ${(props) => (props.type === "product" ? "500px" : "250px")};
        height: ${(props) => (props.type === "product" ? "280px" : "250px")};
        padding: 20px;

        &.isMobile {
            flex-basis: 95px;
            width: 95px;
            height: 95px;

            padding: 5px;
        }
    }

    p  {
        font-family: ${branding.font1};

        &.isMobile {
            font-size: 12px;
        }

        @media ${device.mobile} {
            font-size: 12px;
        }
    }
`
