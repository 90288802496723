import { useEffect, useState, useRef, useCallback } from "react"
import * as React from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import { uniqBy, debounce } from "lodash"
import loadZoomchart from "./loadZoomchart"
import branding from "../../branding/branding"
import CenteredLoader from "../../ui/CenteredLoader"
import TopBar from "../../navigationArea/TopBar"
import Breadcrumb from "../../navigationArea/Breadcrumb"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"
import NodeDetailPopup from "./components/NodeDetailPopup"
import NodeActionsPopup from "./components/NodeActionsPopup"
import LoadMoreConnectionsBtn from "./components/LoadMoreConnectionsBtn"
import TopFilterArea from "./TopFilterArea"
import BottomFilterArea from "./BottomFilterArea"
import { NetworkingUser, Contact, ModalType, SotUser } from "../../backendServices/Types"
import { useLanguageState } from "../../globalStates/LanguageState"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { CommunicationModals } from "../../communicationArea/CommunicationOptions"
import { useFavoriteState } from "../../globalStates/Favorites"
import { useContactState } from "../../communicationArea/ContactState"
import {
    createFindNewPeopleChartData,
    filterLinks,
    getUserAttributes,
    setNodeBorder,
    setNodeImage,
    setNodeLabel,
    setNodeRadius,
    setFindNewPeopleViewNodesLoadNumber,
    removeSeparateNodes
} from "./HelperFunctions"
import { ZOOM_MAX_VALUE, ZOOM_MIN_VALUE } from "./components/ZoomRangeSlider"
import { setLoadSuggestionsValueMethod } from "./components/SuggestionsSlider"
import { viewType } from "./NetworkingPageContentBranding"
import { networkingPageRoute } from "../../navigationArea/RoutePaths"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { useAppState } from "../../globalStates/AppState"
import queryString from "query-string"
import EmptyTile from "../reception/EmptyTile"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import {
    ContactItem,
    loadRelevantProfilesListData,
    ContactListResponse,
    loadUserData,
    UserResponse,
    getConnectionsService,
    getInterest,
    removeFromRelevantList
} from "../../backendServices/SeriesOfTopicsUserServices"
import RelevantTilesAndListView from "./RelevantTilesAndListView"
import { ViewMode } from "../../ui/CrsTabs"

// Setting zoomchart licences from branding.
window.ZoomChartsLicense =
    branding.networkingArea.zoomChartLicences.filter((x) => x.domain === window.location.hostname)[0]?.value || ""
window.ZoomChartsLicenseKey =
    branding.networkingArea.zoomChartLicences.filter((x) => x.domain === window.location.hostname)[0]?.key || ""

const NetworkingPageRoot = styled.div`
    height: 100%;
`

const NetworkingPageContentRoot = styled.div<{ reservedSpaceHeight: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - ${(props) => props.reservedSpaceHeight}px);
    position: relative;

    & #networking-chart {
        width: 100%;
        height: 100%;
        background-color: #fff;
    }
    & .DVSL-menu-container {
        cursor: grab !important;
    }

    & .DVSL-interaction {
        cursor: grab !important;

        &:active {
            cursor: grabbing !important;
        }
    }

    /* Move zoom slider up */
    & .DVSL-bar-left {
        bottom: 150px !important;
    }

    /* Hide zoomchart icons */
    & .DVSL-bar-left > li {
        display: none;
    }

    & .opacity-0 {
        opacity: 0;
    }
    & .absolute-center {
        left: 0;
        top: 0;
        position: absolute;
        z-index: 10;
    }
`

const OpenNodeDetailTransparentBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9998;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
`

/*********************************************************************************************
 * INTERFACES
 **********************************************************************************************/

export interface Interest {
    id: string
    name: string
}

/*********************************************************************************************
 * ENUMS
 **********************************************************************************************/
export enum personFilterType {
    EXHIBITORS = "staff",
    BOTH = "both",
    ATENDEES = "attendee"
}

interface IState {
    isSuggestion?: boolean
}

interface viewTypeProps {
    value: viewType
    label: string
    hide?: boolean
}

/*********************************************************************************************
 * ROOT COMPONENT
 **********************************************************************************************/
const NetworkingPageContent: React.FunctionComponent = (props) => {
    // User state
    let userState = useLoggedInState()
    let loggedInUserId = userState.user()?.profileId

    // Setting initial view type via branding.js (if provided)
    // Temporary solution for global search
    const location = useLocation()

    // Setting initial view type via branding.js
    let initialViewType = (location.state as IState)?.isSuggestion
        ? viewType.RELEVANT_TILES
        : branding.networkingArea.initialViewType

    // Setting available views via branding.js
    let availableViews = branding.networkingArea.availableViews

    const initialViewTypeFromQuery = queryString.parse(location.search)
    const typeFromQuery = initialViewTypeFromQuery?.initialViewType
    if (typeFromQuery) {
        switch (typeFromQuery) {
            case viewType.EXPLORE_MY_CON:
                initialViewType = viewType.EXPLORE_MY_CON
                break
            case viewType.RELEVANT_TILES:
                initialViewType = viewType.RELEVANT_TILES
                break
            case viewType.RELEVANT_PPL:
                initialViewType = viewType.RELEVANT_PPL
                break
            case viewType.USERS_FILTERED_BY_INTEREST:
                initialViewType = viewType.USERS_FILTERED_BY_INTEREST
                break
        }
    }
    // Making sure that the initial view type is available
    if (!availableViews.includes(initialViewType) && availableViews.length) initialViewType = availableViews[0]

    // Script load
    const [scriptLoaded, setScriptLoaded] = useState(false)

    // Localization
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const lang = languageState.getLanguage()

    // Refs
    const pageRootRef = useRef<HTMLDivElement>(null)
    const topFilterRef = useRef<HTMLDivElement>(null)
    const bottomFilterRef = useRef<HTMLDivElement>(null)
    const [topBarRef, setTopBarRef] = useState<React.RefObject<HTMLDivElement>>()
    const [breadcrumbRef, setBreadcrumbRef] = useState<React.RefObject<HTMLDivElement>>()

    // State
    const [netChart, setNetChart] = useState<ZoomCharts.NetChart>()
    const [netChartData, setNetChartData] = useState<ZoomCharts.Configuration.NetChartDataObject>({ nodes: [], links: [] })
    const [selectedUser, setSelectedUser] = useState(loggedInUserId)
    const currentUser = useLoggedInState().user()
    const [hoveredUser, setHoveredUser] = useState("")
    const [userHovered, setUserHovered] = useState(false)
    const [loadMoreConnections, setLoadMoreConnections] = useState(true)
    const [shouldCollapseNode, setShouldCollapseNode] = useState<number>(0)
    const [isLoader, setIsLoader] = useState(false)
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const [currentDataPage, setCurrentDataPage] = useState(0)
    const [showLoadNewSuggestionsBtn, setShowLoadNewSuggestionsBtn] = useState(false)
    const [lastLoadView, setLastLoadView] = useState(initialViewType)
    const [usersWithLoadedConnections, setUsersWithLoadedConnections] = useState<Array<string>>([])
    const [allInterests, setAllInterest] = useState<Array<Interest>>()
    const [hasNextPage, setHasNextPage] = useState(false)
    // Height that is taken by the page subcomponents. Top banner, top bar, breadcrumb,...
    const [subContentHeight, setSubContentHeight] = useState(0)
    // Topic filter
    const [selectedTopic, setSelectedTopic] = useState<number | null>(null)

    // Node detail popup (node left click)
    const [showNodeDetail, setShowNodeDetail] = useState(false)
    const [showLoadMoreConnectionsBtn, setShowLoadMoreConnectionsBtn] = useState(false)
    const nodeDetailRootRef = useRef<HTMLDivElement>(null)
    const loadMoreConnectionsBtnRef = useRef<HTMLDivElement>(null)

    // Node actions popup (node right click)
    const [showNodeActionsPopup, setShowNodeActionsPopup] = useState(false)
    const nodeActionsRootRef = useRef<HTMLDivElement>(null)
    const [rightClickSelectedUser, setRightClickSelectedUser] = useState(loggedInUserId)

    // Filters
    const [userAttributes, setUserAttributes] = useState<Array<string>>([])
    const [userAttributeFilter, setUserAttributeFilter] = useState<string>("")
    const [selectedPersonType, setSelectedPersonType] = useState(personFilterType.BOTH)
    const [zoomStrengthValue, setZoomStrengthValue] = useState([1])
    const [loadSuggestionsValue, setLoadSuggestionsValue] = useState([10])

    const { isMobile } = useWindowDimensions()

    // Filter display data
    const personTypeData = [
        { value: personFilterType.EXHIBITORS, label: strings.networkingArea.exhibitorsTitle },
        { value: personFilterType.ATENDEES, label: strings.networkingArea.attendeesTitle }
    ]
    const [viewTypeData, setViewTypeData] = useState<viewTypeProps[]>([
        { value: viewType.USERS_FILTERED_BY_INTEREST, label: strings.networkingArea.filteredByInterestText },
        { value: viewType.RELEVANT_TILES, label: strings.networkingArea.relevantTilesText },
        { value: viewType.RELEVANT_PPL, label: strings.networkingArea.findNewPeopleText },
        { value: viewType.EXPLORE_MY_CON, label: strings.networkingArea.myConnectionsText }
    ])

    // const [currentViewType, setCurrentViewType] = useState(window.location.href.includes("findnewpeople") ? viewType.RELEVANT_PPL : isMobile ? viewType.RELEVANT_TILES : initialViewType)
    const [currentViewType, setCurrentViewType] = useState(isMobile ? viewType.RELEVANT_TILES : initialViewType)
    // Say hello section
    const flexDivRef = useRef<HTMLDivElement>(null)
    const [contact, setContact] = useState<Contact>()
    const favoriteState = useFavoriteState()
    const [modalType, setModalType] = useState<ModalType>("none")

    // Add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()

    const contactState = useContactState()
    var connectionStatus = selectedUser ? contactState.getConnectionStatus(selectedUser) : "UNRELATED"
    const isBookmarked = contact ? favoriteState.is("person", contact.id) || favoriteState.is("sotuser", contact.id) : false

    // Say hello modal toggle
    const flexDiv = flexDivRef.current
    const blurFilter = modalType !== "connect" ? "none" : "blur(5px)"
    if (flexDiv) flexDiv.style.filter = blurFilter

    // Relevant people - tiles view
    const [page, setPage] = useState(0)
    const [relevantUsers, setRelevantUsers] = useState<ContactItem[]>([])
    const [searchString, setSearchString] = useState<string>("")
    const [searchValue, setSearchValue] = useState<string>("")

    // Users by interest - tiles view
    const [sotUsers, setSotUsers] = useState<SotUser[]>([])

    const locations = calcBreadcrumbLocations(strings)

    const viewModeFromState = localStorage.getItem("networkingRelevantViewMode")
    const [viewMode, setViewMode] = useState<ViewMode>(
        viewModeFromState !== undefined ? parseInt(viewModeFromState || "") : ViewMode.TILES
    )
    //const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.TILES)
    const [showOnlyBookmarks, setShowOnlyBookmarks] = useState<boolean>(false)

    const breadcrumb = [{ to: locations[0], name: strings.sideIconBar.networkingMenuText }, getCurrentBreadcrumb()]

    function getCurrentBreadcrumb(): any {
        if (currentViewType === viewType.USERS_FILTERED_BY_INTEREST) {
            return { to: networkingPageRoute, name: strings.networkingArea.filteredByInterestText }
        } else if (currentViewType === viewType.RELEVANT_TILES) {
            return { to: networkingPageRoute, name: strings.networkingArea.relevantTilesText }
        } else if (currentViewType === viewType.RELEVANT_PPL) {
            return { to: networkingPageRoute, name: strings.networkingArea.findNewPeopleText }
        } else if (currentViewType === viewType.EXPLORE_MY_CON) {
            return { to: networkingPageRoute, name: strings.networkingArea.myConnectionsText }
        }
    }

    useEffect(() => {
        if (isMobile) {
            setCurrentViewType(viewType.RELEVANT_TILES)
        }
    }, [isMobile])

    /*********************************************************************************************
     * HELPER FUNCTIONS
     **********************************************************************************************/

    // #region  Helper functions

    //Relevant people - tiles view
    const debouncedSearch = React.useMemo(
        () =>
            debounce((searchStr: string) => {
                setRelevantUsers([])
                setSotUsers([])
                setSearchString(searchStr)
                setPage(0)
            }, 800),
        []
    )

    const handleSearchDebounced = useCallback(
        (value: string) => {
            debouncedSearch(value)
        },
        [debouncedSearch]
    )

    function handleSearch(searchValue: string) {
        setSearchValue(searchValue)
        handleSearchDebounced(searchValue)
    }

    function shouldCompleteProfile(): boolean {
        if (
            !userState.user()?.firstName ||
            !userState.user()?.lastName ||
            !userState.user()?.position ||
            !userState.user()?.company
        ) {
            return true
        }

        return false
    }

    function incrementCollapseCounter() {
        setShouldCollapseNode(shouldCollapseNode + 1)
    }

    // #endregion

    /*********************************************************************************************
     * NETCHART CONFIGURATIONS
     **********************************************************************************************/

    //#region Netchart configurations
    let baseNetChartConfiguration: ZoomCharts.Configuration.NetChartSettings = {
        data: [
            {
                preloaded: {
                    links: [],
                    nodes: []
                }
            }
        ],
        events: {
            onClick: (e, node) => {
                if (node.hoverNode) {
                    setSelectedUser("")
                    setSelectedUser(node.hoverNode.data.id)
                }
            },
            onRightClick: (e, node) => {
                e.preventDefault()
                if (node.hoverNode) {
                    setRightClickSelectedUser(node.hoverNode.data.id)
                    setShowNodeActionsPopup(true)
                }
            },
            onHoverChange: (e, node) => {
                if (nodeDetailRootRef.current) return

                if (node.hoverNode) {
                    setHoveredUser(node.hoverNode.id)
                    setUserHovered(true)
                } else {
                    setUserHovered(false)
                }
            },
            onPositionChange: () => {
                setShowLoadMoreConnectionsBtn(false)
            }
        },
        layout: {
            mode: "radial",
            nodeSpacing: 100
        },
        toolbar: {
            fullscreen: true,
            enabled: true
        }
    }

    let exploreMyConnectionsViewConf: ZoomCharts.Configuration.NetChartSettings = {
        navigation: {
            initialNodes: [loggedInUserId ?? ""],
            mode: "showall",
            expandOnClick: false
        },
        interaction: {
            zooming: {
                zoomExtent: [ZOOM_MIN_VALUE, ZOOM_MAX_VALUE],
                wheel: false,
                zoomInOnDoubleClick: false,
                initialAutoZoom: "overview"
            },
            resizing: {
                enabled: true
            },
            nodesMovable: true
        },
        style: {
            node: { imageCropping: "crop" },
            nodeHovered: {
                labelStyle: {
                    textStyle: {
                        fillColor: "#000"
                    },
                    backgroundStyle: {
                        lineColor: "#000"
                    }
                }
            },
            link: { fillColor: "#D9D9D9" },
            linkHovered: {
                fillColor: "#000",
                shadowColor: "#fff"
            },
            nodeLabel: {
                padding: 2,
                margin: 5,
                borderRadius: 4,
                maxWidth: 500,
                textStyle: {
                    fillColor: "#808080"
                },
                backgroundStyle: {
                    fillColor: "#fff",
                    lineWidth: 1,
                    lineColor: "#808080"
                }
            },
            nodeStyleFunction: (node) => {
                if (currentViewType !== viewType.RELEVANT_PPL) {
                    setNodeLabel(node)
                    setNodeImage(node)
                    setNodeBorder(node)
                }
            },
            linkStyleFunction: (link) => {
                link.invisible = false
            }
        }
    }

    let findNewPeopleViewConf: ZoomCharts.Configuration.NetChartSettings = {
        navigation: {
            initialNodes: [loggedInUserId ?? ""],
            mode: "focusnodes",
            initialFocusNodeExpansionRadius: 4,
            expandOnClick: false
        },
        layout: { mode: "radial", nodeSpacing: 100 },
        interaction: { nodesMovable: false },
        style: {
            node: { imageCropping: "crop" },
            nodeHovered: {
                labelStyle: {
                    textStyle: {
                        fillColor: "#000"
                    },
                    backgroundStyle: {
                        lineColor: "#000"
                    }
                }
            },
            link: { fillColor: "#D9D9D9" },
            linkHovered: {
                fillColor: "#000",
                shadowColor: "#fff"
            },
            nodeLabel: {
                padding: 2,
                margin: 5,
                borderRadius: 4,
                maxWidth: 500,
                textStyle: {
                    fillColor: "#808080"
                },
                backgroundStyle: {
                    fillColor: "#fff",
                    lineWidth: 1,
                    lineColor: "#808080"
                }
            },
            linkStyleFunction: (link) => {
                link.invisible = true
            },
            nodeStyleFunction: (node) => {
                if (node.id === loggedInUserId) setNodeRadius(node)

                setNodeLabel(node)
                setNodeImage(node)
                setNodeBorder(node)
            }
        }
    }

    const updateNetChartSettings = (netChart: ZoomCharts.NetChart) => {
        if (!netChart) return
        if (currentViewType === viewType.EXPLORE_MY_CON) {
            netChart.updateSettings({ ...baseNetChartConfiguration, ...exploreMyConnectionsViewConf })
        } else if (currentViewType === viewType.RELEVANT_PPL) {
            netChart.updateSettings({ ...baseNetChartConfiguration, ...findNewPeopleViewConf })
        }
    }
    //#endregion

    /*********************************************************************************************
     * NETCHART INITIALIZATION
     **********************************************************************************************/

    //#region Netchart initialization

    useEffect(() => {
        appState.setCurrentMobileBreadcrumb(strings.sideIconBar.networkingMenuText)

        let viewTypesOrdered: viewTypeProps[] = []
        availableViews.forEach((view) => {
            let currentViewType: viewTypeProps
            switch (view) {
                case viewType.EXPLORE_MY_CON:
                    currentViewType = {
                        value: viewType.EXPLORE_MY_CON,
                        label: strings.networkingArea.myConnectionsText,
                        hide: !availableViews.includes(viewType.EXPLORE_MY_CON)
                    }
                    break
                case viewType.RELEVANT_PPL:
                    currentViewType = {
                        value: viewType.RELEVANT_PPL,
                        label: strings.networkingArea.findNewPeopleText,
                        hide: !availableViews.includes(viewType.RELEVANT_PPL)
                    }
                    break
                case viewType.USERS_FILTERED_BY_INTEREST:
                    currentViewType = {
                        value: viewType.USERS_FILTERED_BY_INTEREST,
                        label: strings.networkingArea.filteredByInterestText,
                        hide: !availableViews.includes(viewType.USERS_FILTERED_BY_INTEREST)
                    }
                    break
                case viewType.RELEVANT_TILES:
                    currentViewType = {
                        value: viewType.RELEVANT_TILES,
                        label: strings.networkingArea.relevantTilesText,
                        hide: !availableViews.includes(viewType.RELEVANT_TILES)
                    }
                    break
            }
            viewTypesOrdered.push(currentViewType)
        })

        if (isMobile) {
            viewTypesOrdered = viewTypesOrdered.filter((x) => x.value === viewType.RELEVANT_TILES)
        }

        if (viewTypesOrdered.length) {
            setViewTypeData(viewTypesOrdered)
        }
        // eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        loadZoomchart(() => {
            setScriptLoaded(true)
        })
    }, [])

    const createNetChartInstance = () => {
        if (document.getElementById("networking-chart")) {
            let NetChart = new ZoomCharts.NetChart({ container: document.getElementById("networking-chart") || undefined })
            updateNetChartSettings(NetChart)
            setNetChart(NetChart)
        }
    }

    useEffect(() => {
        if (!userState.isMatchActive() && netChart) {
            setNetChart(undefined)
        }
        // eslint-disable-next-line
    }, [userState.isMatchActive()])

    useEffect(() => {
        if (scriptLoaded && currentViewType !== viewType.RELEVANT_TILES && userState.isMatchActive()) {
            if (shouldCompleteProfile() && currentViewType === viewType.RELEVANT_PPL) {
                return
            }
            createNetChartInstance()
        }
        // eslint-disable-next-line
    }, [scriptLoaded, currentViewType])

    useEffect(() => {
        if (
            lastLoadView === viewType.RELEVANT_TILES ||
            lastLoadView === viewType.USERS_FILTERED_BY_INTEREST ||
            (currentViewType === viewType.EXPLORE_MY_CON && !userState.isMatchActive()) ||
            (currentViewType === viewType.RELEVANT_PPL && userState.isMatchActive())
        ) {
            createNetChartInstance()
        }
        // eslint-disable-next-line
    }, [currentViewType, userState.isMatchActive()])

    //#endregion

    /*********************************************************************************************
     * NETCHART - FIND NEW PEOPLE VIEW
     **********************************************************************************************/

    useEffect(() => {
        if (!netChart || !loadSuggestionsValue[0]) return

        if (currentViewType === viewType.RELEVANT_PPL && loggedInUserId && userState.isMatchActive()) {
            setIsLoader(true)
            let params = {
                itemsPerPage: setFindNewPeopleViewNodesLoadNumber(loadSuggestionsValue[0]),
                page: currentDataPage,
                searchString: userAttributeFilter as any,
                userType: selectedPersonType as any,
                topicFilter: selectedTopic as any,
                hideConnections: true
            }
            if (selectedPersonType === personFilterType.BOTH) delete params.userType
            if (!selectedTopic) delete params.topicFilter

            setTimeout(() => {
                loadRelevantProfilesListData(loggedInUserId || "", params)
                    .then((res) => {
                        setUserAttributes(getUserAttributes((res as ContactListResponse).contacts?.map((x) => x.sotUser)) || [])
                        if (netChart) netChart?.resetLayout()
                        updateNetChartSettings(netChart)
                        let chartData = createFindNewPeopleChartData(res as ContactListResponse, loggedInUserId!, userState)
                        netChart.replaceData(chartData)
                        setNetChartData(chartData)
                        setShowLoadNewSuggestionsBtn((res as ContactListResponse).hasNextPage)
                        contactState.setAll(
                            (res as ContactListResponse).contacts.map((contact) => {
                                return {
                                    id: contact.sotUser.id,
                                    connectionStatus: contact.sotUser.myConnectionStatus,
                                    userType: contact.sotUser.type
                                }
                            })
                        )
                        setLastLoadView(viewType.RELEVANT_PPL)
                        setHasNextPage((res as ContactListResponse).hasNextPage)
                    })
                    .finally(() => {
                        setIsLoader(false)
                    })
            }, 600)
        } else if (currentViewType === viewType.EXPLORE_MY_CON) {
            if (
                selectedPersonType === personFilterType.BOTH &&
                !userAttributeFilter &&
                (lastLoadView === viewType.RELEVANT_PPL || lastLoadView === viewType.RELEVANT_TILES)
            ) {
                setIsLoader(true)
                setIsInitialLoad(true)
                setLoadMoreConnections(true)
                updateNetChartSettings(netChart)
            } else {
                netChart?.replaceData(netChartData!)
            }
        }
        // eslint-disable-next-line
    }, [
        currentViewType,
        selectedPersonType,
        userAttributeFilter,
        loadSuggestionsValue,
        currentDataPage,
        searchString,
        page,
        netChart,
        selectedTopic,
        // eslint-disable-next-line
        languageState.getLanguage()
    ])

    /*********************************************************************************************
     * HANDLING ZOOM EVENT
     **********************************************************************************************/

    useEffect(() => {
        if (!netChart) return

        netChart?.zoom(zoomStrengthValue[0])
        // eslint-disable-next-line
    }, [zoomStrengthValue])

    /*********************************************************************************************
     * RESETING FILTERS ON VIEW TYPE CHANGE
     **********************************************************************************************/

    useEffect(() => {
        setSelectedPersonType(personFilterType.BOTH)
        setUserAttributeFilter("")
        setSearchString("")
        setSearchValue("")
        // eslint-disable-next-line
    }, [currentViewType])

    /*********************************************************************************************
     * UNFOCUSING NODE ON NODE DETAIL POPUP CLOSE
     **********************************************************************************************/

    useEffect(() => {
        if (!netChart || !selectedUser) return
        if (!showNodeDetail) {
            netChart.removeFocusNode(selectedUser)
        }
        // eslint-disable-next-line
    }, [showNodeDetail])

    /*********************************************************************************************
     * FILTERS
     **********************************************************************************************/

    useEffect(() => {
        if (!netChart) return

        let netChartDataCopy: ZoomCharts.Configuration.NetChartDataObject = {
            links: netChartData!.links,
            nodes: netChartData!.nodes
        }

        if (selectedPersonType && selectedPersonType !== personFilterType.BOTH && currentViewType !== viewType.RELEVANT_PPL) {
            if (selectedPersonType === personFilterType.EXHIBITORS) {
                netChartDataCopy.nodes = netChartDataCopy.nodes.filter((x) => {
                    if ((x as NetworkingUser).person) return true
                    else return false
                })
            } else if (selectedPersonType === personFilterType.ATENDEES) {
                netChartDataCopy.nodes = netChartDataCopy.nodes.filter((x) => {
                    if ((x as NetworkingUser).person) return false
                    else return true
                })
            }
            filterLinks(netChartDataCopy, netChartDataCopy.nodes, netChartData)
        }

        if (userAttributeFilter && currentViewType !== viewType.RELEVANT_PPL) {
            netChartDataCopy.nodes = netChartDataCopy.nodes.filter((x) => {
                if (
                    (x as NetworkingUser).firstName ||
                    (x as NetworkingUser).lastName ||
                    (x as NetworkingUser).position ||
                    (x as NetworkingUser).company
                ) {
                    return (
                        userAttributeFilter.toLocaleLowerCase().includes((x as NetworkingUser).firstName?.toLocaleLowerCase()) ||
                        (x as NetworkingUser).firstName
                            ?.toLocaleLowerCase()
                            .startsWith(userAttributeFilter.toLocaleLowerCase()) ||
                        userAttributeFilter
                            .toLocaleLowerCase()
                            .includes((x as NetworkingUser).lastName?.toLocaleLowerCase() || "") ||
                        (x as NetworkingUser).lastName?.toLocaleLowerCase().startsWith(userAttributeFilter.toLocaleLowerCase()) ||
                        (x as NetworkingUser).position?.toLocaleLowerCase().startsWith(userAttributeFilter.toLocaleLowerCase()) ||
                        (x as NetworkingUser).company?.toLocaleLowerCase().startsWith(userAttributeFilter.toLocaleLowerCase())
                    )
                } else return false
            })

            filterLinks(netChartDataCopy, netChartDataCopy.nodes, netChartData)

            netChartDataCopy.links = uniqBy(netChartDataCopy.links, "id")
        }

        // Adding/removing link connection after say hello action
        if (connectionStatus) {
            if (connectionStatus === "CONNECTED" && loggedInUserId && selectedUser) {
                const connectionAlreadyExist = () => {
                    return (
                        netChartDataCopy.links.findIndex(
                            (x) =>
                                (x.from === selectedUser && x.to === loggedInUserId) ||
                                (x.from === loggedInUserId && x.to === selectedUser)
                        ) !== -1
                    )
                }
                if (!connectionAlreadyExist())
                    netChartDataCopy.links.push({ id: selectedUser + loggedInUserId, from: selectedUser, to: loggedInUserId! })
            } else if (connectionStatus === "UNRELATED") {
                let indexOfConnection = netChartDataCopy.links.findIndex(
                    (x) =>
                        (x.to === loggedInUserId && x.from === selectedUser) ||
                        (x.to === selectedUser && x.from === loggedInUserId)
                )
                if (indexOfConnection > 0) netChartDataCopy.links.splice(indexOfConnection, 1)
            }
        }

        // Updating netchart data
        if (!userAttributeFilter && !selectedPersonType && !connectionStatus) netChart.replaceData(netChartData!!)
        else netChart.replaceData(netChartDataCopy)

        // eslint-disable-next-line
    }, [selectedPersonType, userAttributeFilter, connectionStatus])

    /*********************************************************************************************
     * NETCHART - EXPLORE MY NETWORK VIEW
     **********************************************************************************************/

    useEffect(() => {
        if (!netChart || !loadMoreConnections || !loggedInUserId || !selectedUser || currentViewType !== viewType.EXPLORE_MY_CON)
            return

        // We don't want to add more data while filter is active
        if (selectedPersonType !== personFilterType.BOTH || userAttributeFilter) return
        ;(function getConnections(profileId: string, depth: number) {
            let params = {
                profileId,
                beConnectionToken: "",
                depth,
                firstResult: 0,
                topicFilter: selectedTopic as any
            }
            if (selectedTopic === null) {
                delete params.topicFilter
            }

            getConnectionsService(params)
                .then((res) => {
                    let data = res.content
                    if (netChartData && !isInitialLoad) {
                        var nodes = netChartData.nodes.concat(res.content.nodes)
                        var links = netChartData.links.concat(res.content.links)

                        nodes = uniqBy(nodes, "id")
                        links = uniqBy(links, "id")

                        setUsersWithLoadedConnections([...usersWithLoadedConnections, ...[hoveredUser]])

                        data = { nodes, links }
                    }
                    setUserAttributes(getUserAttributes(data.nodes) || [])
                    setNetChartData(data)
                    netChart.replaceData(data)
                    contactState.setAll(
                        data.nodes.map((contact: any) => {
                            return { id: contact.id, connectionStatus: contact.myConnectionStatus, userType: contact.type }
                        })
                    )
                    setLoadMoreConnections(false)
                    setIsInitialLoad(false)
                    setLastLoadView(viewType.EXPLORE_MY_CON)
                })
                .finally(() => {
                    setIsLoader(false)
                })

            // if initialLoad depth = 3 else depth = 2
        })(isInitialLoad ? loggedInUserId : hoveredUser ? hoveredUser : selectedUser, 1)

        // eslint-disable-next-line
    }, [loadMoreConnections, netChart, currentViewType, netChart])

    // collapse node behavior
    useEffect(() => {
        if (
            !netChart ||
            !loggedInUserId ||
            !selectedUser ||
            currentViewType !== viewType.EXPLORE_MY_CON ||
            shouldCollapseNode < 1
        )
            return
        let newNetChartData: ZoomCharts.Configuration.NetChartDataObject = {
            links: netChartData!.links,
            nodes: netChartData!.nodes
        }
        let newUserWithLoadedConnections = usersWithLoadedConnections.filter((user) => {
            if (user === hoveredUser) return false
            else return true
        })
        let saveLinksFromThoseNodes = [...newUserWithLoadedConnections, ...[currentUser?.profileId]]
        newNetChartData.links = newNetChartData.links.filter((link) => {
            if (saveLinksFromThoseNodes.includes(link.from) || saveLinksFromThoseNodes.includes(link.to)) return true
            else return false
        })
        removeSeparateNodes(newNetChartData, netChartData)
        setUsersWithLoadedConnections(newUserWithLoadedConnections)
        setUserAttributes(getUserAttributes(newNetChartData.nodes) || [])
        setNetChartData(newNetChartData)
        netChart.replaceData(newNetChartData)
        contactState.setAll(
            newNetChartData.nodes.map((contact: any) => {
                return { id: contact.id, connectionStatus: contact.myConnectionStatus, userType: contact.type }
            })
        )
        setIsLoader(false)
        // eslint-disable-next-line
    }, [shouldCollapseNode])

    /*********************************************************************************************
     * ON SELECTED USER CHANGE
     **********************************************************************************************/

    useEffect(() => {
        if (selectedUser && selectedUser !== loggedInUserId && loggedInUserId) {
            loadUserData({
                loggedInUserId: loggedInUserId,
                targetProfileId: selectedUser
            }).then((response) => {
                if ((response as BackendServiceError).httpStatus) {
                    // TODO ERROR
                } else {
                    const userResponse: UserResponse = response as UserResponse
                    setContact(userResponse.content)
                    contactState.setAll([
                        {
                            id: selectedUser,
                            connectionStatus: userResponse.content.myConnectionStatus,
                            userType: userResponse.content.type
                        }
                    ])
                    setShowNodeDetail(true)
                }
            })
        }
        // eslint-disable-next-line
    }, [selectedUser])

    /*********************************************************************************************
     * NODE DETAIL POPUP
     **********************************************************************************************/

    //#region Node detail page

    // Observing node detail outside click
    const onOutsideNodeDetailClick = (e: any) => {
        if (!nodeDetailRootRef.current) return
        if (!nodeDetailRootRef.current!.contains(e.target)) {
            setShowNodeDetail(false)
        }
    }

    const menuCallback: (param: { modalType?: ModalType }) => void = ({ modalType }) => {
        if (modalType) {
            setModalType(modalType)
        }
    }

    const onHideFromSuggestionClick = () => {
        if (!selectedUser || !loggedInUserId) return
        removeFromRelevantList({ requestedUser: loggedInUserId, userToRemove: selectedUser })
            .then((res) => {
                setShowNodeDetail(false)
                let loadSuggestionValue = loadSuggestionsValue[0]
                setLoadSuggestionsValue([0])
                setLoadSuggestionsValue([loadSuggestionValue])
            })
            .catch((err) => {})
    }

    // Fetching all interest
    useEffect(() => {
        getInterest().then((res) => {
            setAllInterest(res.content.interests)
        })
    }, [])

    //#endregion

    /*********************************************************************************************
     * NODE ACTIONS POPUP
     **********************************************************************************************/

    // Observing node actions popup outside click
    const onOutsideNodeActionsPopupClick = (e: any) => {
        if (!nodeActionsRootRef.current) return
        if (!nodeActionsRootRef.current!.contains(e.target)) {
            setShowNodeActionsPopup(false)
        }
    }

    /*********************************************************************************************
     * ON HOVERED USER CHANGE
     **********************************************************************************************/

    const [timeout, setTimeoutState] = useState<number | undefined>()

    useEffect(() => {
        if (userHovered) {
            clearInterval(timeout)
            setShowLoadMoreConnectionsBtn(true)
        } else {
            setTimeoutState(
                window.setTimeout(() => {
                    setShowLoadMoreConnectionsBtn(false)
                }, 200)
            )
        }
        // eslint-disable-next-line
    }, [userHovered])

    /*********************************************************************************************
     * RESET GRAPH
     **********************************************************************************************/

    const onGraphReset = () => {
        if (currentViewType === viewType.EXPLORE_MY_CON) {
            setIsInitialLoad(true)
            setLoadMoreConnections(true)
            setUsersWithLoadedConnections([])
            setSelectedPersonType(personFilterType.BOTH)
            if (netChart) netChart?.resetLayout()
        } else if (currentViewType === viewType.RELEVANT_PPL) {
            setLoadSuggestionsValueMethod({
                value: 10,
                hasNextPage: hasNextPage,
                loadSuggestionsValue: loadSuggestionsValue,
                setCurrentDataPage: setCurrentDataPage,
                setLoadSuggestionsValue: setLoadSuggestionsValue
            })
            setSelectedPersonType(personFilterType.BOTH)
        } else if (currentViewType === viewType.RELEVANT_TILES || currentViewType === viewType.USERS_FILTERED_BY_INTEREST) {
            setPage(0)
            setRelevantUsers([])
            setSotUsers([])
        }
        setUserAttributeFilter("")
        setSelectedTopic(null)
        setSelectedPersonType(personFilterType.BOTH)
    }

    /*********************************************************************************************
     * CALCULATING SPACE TAKEN BY THE PAGE SUBCOMPONENTS
     **********************************************************************************************/

    useEffect(() => {
        let spaceHeight = 0
        if (guestUserBannerRef && guestUserBannerRef.current) {
            spaceHeight += guestUserBannerRef.current.clientHeight
        }
        if (topBarRef && topBarRef.current) {
            spaceHeight += topBarRef.current.clientHeight
        }
        if (breadcrumbRef && breadcrumbRef.current) {
            spaceHeight += breadcrumbRef.current.clientHeight
        }
        if (currentViewType === viewType.RELEVANT_TILES) {
            spaceHeight += 90 // Search input area
        }

        setSubContentHeight(spaceHeight)
    }, [guestUserBannerRef, topBarRef, breadcrumbRef, currentViewType])

    /*********************************************************************************************
     * REACTING ON TOPIC FILTER CHANGE
     **********************************************************************************************/

    useEffect(() => {
        if (currentViewType === viewType.EXPLORE_MY_CON) {
            setIsInitialLoad(true)
            setLoadMoreConnections(true)
        } else if (currentViewType === viewType.RELEVANT_TILES) {
            setPage(0)
            setRelevantUsers([])
        }
        // eslint-disable-next-line
    }, [selectedTopic])

    const appState = useAppState()

    /*********************************************************************************************
     * MAIN CONTENT
     **********************************************************************************************/

    const mainContent = () => {
        if (currentViewType === viewType.RELEVANT_TILES || currentViewType === viewType.USERS_FILTERED_BY_INTEREST) {
            return (
                <>
                    <RelevantTilesAndListView
                        relevantUsers={relevantUsers}
                        setRelevantUsers={setRelevantUsers}
                        sotUsers={sotUsers}
                        setSotUsers={setSotUsers}
                        loggedInUserId={loggedInUserId}
                        isLoader={isLoader}
                        page={page}
                        searchString={searchString}
                        topicFilter={selectedTopic}
                        setIsLoader={setIsLoader}
                        setLastLoadView={setLastLoadView}
                        setPage={setPage}
                        subContentHeight={subContentHeight}
                        isMatchActive={userState.isMatchActive()}
                        currentViewType={currentViewType}
                        filterAreaProps={{
                            currentViewType: currentViewType,
                            searchValue: searchValue,
                            topFilterRef: topFilterRef,
                            userAttributeFilter: userAttributeFilter,
                            userAttributes: userAttributes,
                            viewTypeData: viewTypeData.filter((view) => !view.hide),
                            selectedTopic: selectedTopic,
                            personTypeData: personTypeData,
                            onSetSearchString: setSearchString,
                            onHandleSearch: handleSearch,
                            onSetUserAttributeFilter: setUserAttributeFilter,
                            setUsersWithLoadedConnections: setUsersWithLoadedConnections,
                            setCurrentViewType: setCurrentViewType,
                            onGraphReset: onGraphReset,
                            onTopicChanged: setSelectedTopic,
                            setSelectedPersonType: setSelectedPersonType,
                            currentDataPage: currentDataPage,
                            setCurrentDataPage: setCurrentDataPage,
                            showLoadNewSuggestionsBtn: showLoadNewSuggestionsBtn,
                            selectedPersonType: selectedPersonType,
                            viewMode: viewMode,
                            setViewMode: setViewMode,
                            showOnlyBookmarks: showOnlyBookmarks,
                            setShowOnlyBookmarks: setShowOnlyBookmarks
                        }}
                        setSelectedUser={setSelectedUser}
                        callback={menuCallback}
                        viewMode={viewMode}
                        showOnlyBookmarks={showOnlyBookmarks}
                    />
                    <CommunicationModals show={modalType} contact={selectedUser} onHide={() => setModalType("none")} />
                </>
            )
        } else {
            return (
                <NetworkingPageContentRoot
                    onClick={(e) => {
                        onOutsideNodeDetailClick(e)
                        onOutsideNodeActionsPopupClick(e)
                    }}
                    reservedSpaceHeight={subContentHeight}
                >
                    <LoadMoreConnectionsBtn
                        active={showLoadMoreConnectionsBtn && currentViewType === viewType.EXPLORE_MY_CON}
                        currentViewType={currentViewType}
                        hoveredUser={hoveredUser}
                        loadMoreConnectionsBtnRef={loadMoreConnectionsBtnRef}
                        loggedInUserId={loggedInUserId}
                        netChart={netChart}
                        selectedPersonType={selectedPersonType}
                        showNodeDetail={showNodeDetail}
                        userAttributeFilter={userAttributeFilter}
                        usersWithLoadedConnections={usersWithLoadedConnections}
                        setLoadMoreConnections={setLoadMoreConnections}
                        setShouldCollapseNode={incrementCollapseCounter}
                        setUserHovered={setUserHovered}
                    />

                    {showNodeActionsPopup && (
                        <NodeActionsPopup
                            onNodeActionsHide={() => setShowNodeActionsPopup(false)}
                            currentViewType={currentViewType}
                            nodeActionsRootRef={nodeActionsRootRef}
                            netChart={netChart}
                            selectedUser={rightClickSelectedUser}
                            parentPageInfo={{
                                parentPageWidth: pageRootRef.current?.clientWidth || 0,
                                parentPageHeight: pageRootRef.current?.clientHeight || 0,
                                bottomReservedSpace: bottomFilterRef.current?.clientHeight || 0,
                                topReservedSpace: subContentHeight
                            }}
                        />
                    )}

                    {showNodeDetail && <OpenNodeDetailTransparentBackground />}

                    {showNodeDetail && (
                        <NodeDetailPopup
                            contact={contact!}
                            currentViewType={currentViewType}
                            netChart={netChart}
                            nodeDetailRootRef={nodeDetailRootRef}
                            selectedUser={selectedUser}
                            allInterests={allInterests || []}
                            selectedUserInterest={
                                (netChartData?.nodes.find((x) => x.id === selectedUser) as NetworkingUser).interests || []
                            }
                            onMenuCallback={menuCallback}
                            onHideFromSuggestionClick={onHideFromSuggestionClick}
                            onNodeDetailHide={() => setShowNodeDetail(false)}
                            bookmarked={isBookmarked}
                        />
                    )}

                    <CommunicationModals show={modalType} contact={contact} onHide={() => setModalType("none")} />
                    <div ref={flexDivRef} style={{ width: "100%", height: "100%" }}>
                        <TopFilterArea
                            currentViewType={(appState.lastVisitedTab as viewType) || currentViewType}
                            onHandleSearch={handleSearch}
                            onGraphReset={onGraphReset}
                            onSetSearchString={setSearchString}
                            onSetUserAttributeFilter={setUserAttributeFilter}
                            searchValue={searchValue}
                            setCurrentViewType={setCurrentViewType}
                            setUsersWithLoadedConnections={setUsersWithLoadedConnections}
                            topFilterRef={topFilterRef}
                            userAttributeFilter={userAttributeFilter}
                            userAttributes={userAttributes}
                            personTypeData={personTypeData}
                            viewTypeData={viewTypeData.filter((view) => !view.hide)}
                            selectedTopic={selectedTopic}
                            onTopicChanged={setSelectedTopic}
                            setSelectedPersonType={setSelectedPersonType}
                            showLoadNewSuggestionsBtn={showLoadNewSuggestionsBtn}
                            currentDataPage={currentDataPage}
                            setCurrentDataPage={setCurrentDataPage}
                            selectedPersonType={selectedPersonType}
                            showShadow={false}
                            isMatchActive={userState.isMatchActive()}
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                            showOnlyBookmarks={showOnlyBookmarks}
                            setShowOnlyBookmarks={setShowOnlyBookmarks}
                        />

                        {isLoader && <CenteredLoader className={"absolute-center"} />}

                        {(userState.isMatchActive() && !shouldCompleteProfile()) ||
                        currentViewType === viewType.EXPLORE_MY_CON ? (
                            <div className={isLoader ? "opacity-0" : ""} id="networking-chart"></div>
                        ) : (
                            <>
                                {!shouldCompleteProfile() && (
                                    <div style={{ marginTop: "15.4%", paddingTop: "100px" }}>
                                        <EmptyTile
                                            header={strings.communicationArea.activateNetworkingText}
                                            bgColor="transparent"
                                            hideButton={true}
                                        />
                                    </div>
                                )}

                                {shouldCompleteProfile() && (
                                    <div style={{ marginTop: "15.4%", paddingTop: "100px" }}>
                                        <EmptyTile
                                            header={branding.communicationArea.completeProfileNetworkingText}
                                            headerClickable={true}
                                            headerClickableWord={
                                                branding.communicationArea.completeProfileNetworkingClickableWord
                                            }
                                            headerLink={"/myprofile?mode=EDIT"}
                                            hideButton={true}
                                            bgColor="transparent"
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        {(currentViewType === viewType.EXPLORE_MY_CON ||
                            (userState.isMatchActive() && !shouldCompleteProfile())) && (
                            <BottomFilterArea
                                currentViewType={currentViewType}
                                selectedPersonType={selectedPersonType}
                                zoomStrengthValue={zoomStrengthValue}
                                showLoadNewSuggestionsBtn={showLoadNewSuggestionsBtn}
                                bottomFilterRef={bottomFilterRef}
                                currentDataPage={currentDataPage}
                                personTypeData={personTypeData}
                                setCurrentDataPage={setCurrentDataPage}
                                setSelectedPersonType={setSelectedPersonType}
                                setZoomStrengthValue={setZoomStrengthValue}
                                suggestionSliderProps={{
                                    hasNextPage: hasNextPage,
                                    loadSuggestionsValue: loadSuggestionsValue,
                                    setCurrentDataPage: setCurrentDataPage,
                                    setLoadSuggestionsValue: setLoadSuggestionsValue
                                }}
                            />
                        )}
                    </div>
                </NetworkingPageContentRoot>
            )
        }
    }

    return (
        <NetworkingPageRoot ref={pageRootRef}>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar setRef={setTopBarRef} />
            <Breadcrumb setRef={setBreadcrumbRef} breadcrumb={breadcrumb} fillBackground={true} />
            {mainContent()}
        </NetworkingPageRoot>
    )
}

export default NetworkingPageContent
