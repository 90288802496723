import styled from "styled-components"
import { SelectThemeCustom } from "../myprofile/EditMyProfileLayout"
import Select, { OptionTypeBase } from "react-select"
import { device } from "../../utils/Device"
import branding from "../../branding/branding"
import { lang } from "moment"
import { useEffect, useState } from "react"
import { loadExhibitorsData } from "../../backendServices/ExhibitorServices"
import { Category, Exhibitor } from "../../backendServices/Types"
import { CustomSelectPlaceholder, CustomSelectOption } from "../GlobalSearchResultPageContent"
import { getCategoryStandlist } from "./HallplanServices"

export interface BadgeSelectProps {
    selectedHall?: string
    badges?: Category[]
    setBadges?: (value: Category[]) => void
}

export default function BadgeSelect(props: BadgeSelectProps) {
    const abortController = new AbortController()
    const abortSignal = abortController.signal
    // eslint-disable-next-line
    const [exhibitors, setExhibitors] = useState<Exhibitor[]>()
    const [badgeCategories, setBadgeCategories] = useState<Category[]>([])

    useEffect(() => {
        let params: any = {
            filterlist: "entity_orga, hall_" + props.selectedHall + ", ",
            startresultrow: 0,
            numresultrows: 100,
            order: "lexic",
            lang: lang
        }

        loadExhibitorsData(params, abortSignal, true).then((resp) => {
            const ex = resp.entities as Exhibitor[]
            if (ex) {
                setExhibitors(ex)
            }
        })

        if (props.selectedHall) {
            getCategoryStandlist(props.selectedHall, abortSignal).then((resp) => {
                const data = resp
                if (data && data.categoryStandList) {
                    let badgeCategoriesTemp: Category[] = []

                    data.categoryStandList?.forEach((item: any) => {
                        badgeCategoriesTemp.push(item.cat)
                    })
                    setBadgeCategories(badgeCategoriesTemp)
                } else {
                    setBadgeCategories([])
                }
            })
        }
        // eslint-disable-next-line
    }, [props.selectedHall])

    if (!badgeCategories || badgeCategories?.length! === 0) {
        return null
    }

    return (
        <BadgeSelectRoot
            styles={styledSelectStyles}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                Option: CustomSelectOption,
                Placeholder: CustomSelectPlaceholder
            }}
            allowSelectAll={true}
            placeholder={branding.hallPlanPageBranding.hallPlanPageSelectBadgePlaceholder}
            options={badgeCategories?.map((category) => {
                return {
                    alias: category.alias,
                    value: category.alias,
                    id: category.id,
                    key: category.key,
                    name: category.name,
                    label: category.badgeName,
                    badgeName: category.badgeName,
                    badgeType: "A",
                    colorBooth: category.colorBooth,
                    iconSmallUrl: category.iconSmallUrl
                }
            })}
            value={props.badges || []}
            controlShouldRenderValue={false}
            onChange={(value: OptionTypeBase) => {
                if (value && value !== null) {
                    if (props.setBadges) props.setBadges(value as any)
                } else {
                    if (props.setBadges) props.setBadges([])
                }
            }}
            theme={SelectThemeCustom}
        />
    )
}

const styledSelectStyles = {
    // eslint-disable-next-line
    control: (provided: any, state: any) => ({
        borderRadius: "5px",
        display: "flex",
        height: "35px",
        fontFamily: branding.font1,
        width: "100%",
        border: state.isFocused ? branding.networkingArea.filterBorderBottomColor : "1px solid #d9d9d9",
        color: branding.networkingArea.filterInputColor,
        outline: "none"
    }),
    option: (styles: { [x: string]: any }, { isFocused, isSelected }: any) => {
        return {
            backgroundColor: isSelected ? "#C9C9C9" : isFocused ? "#C9C9C9" : undefined,
            height: "35px",
            fontSize: "12px",
            width: "auto",
            display: "flex",
            fontFamily: branding.font1,
            paddingLeft: "10px",
            paddingRight: "10px",
            marginTop: "0px",
            marginBottom: "0px",
            textAlign: "left",
            alignItems: "center",
            cursor: "pointer"
        }
    },
    input: (provided: any) => ({
        ...provided,
        width: "100% !important",
        div: {
            width: "100% !important"
        },
        input: {
            opacity: "1 !important",
            width: "100% !important"
        }
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        border: branding.networkingArea.filterBorderBottomColor,
        boxShadow: "none",
        fontFamily: branding.font1,
        borderRadius: "3px",
        marginTop: "0px",
        marginBottom: "0px",
        padding: "0px"
    }),
    placeholder: (provided: any) => ({
        ...provided,
        fontFamily: branding.font1,
        fontSize: "12px",
        color: branding.networkingArea.filterPlaceholderColor
    })
}

export const colourOptions = [
    { value: "ocean1", label: "Ocean" },
    { value: "blue", label: "Blue" },
    { value: "purple", label: "Purple" },
    { value: "red", label: "Red" },
    { value: "orange", label: "Orange" },
    { value: "yellow", label: "Yellow" },
    { value: "green", label: "Green" },
    { value: "forest", label: "Forest" },
    { value: "slate", label: "Slate" },
    { value: "silver", label: "Silver" }
]

const BadgeSelectRoot = styled(Select)`
    width: 230px;
    font-family: ${branding.font1};
    margin-left: 15px;
    z-index: 50;

    font-size: 12px;
    font-family: ${branding.font1};

    @media ${device.tablet} {
        flex: 0 200px;
        width: 100%;
    }

    @media ${device.laptop} {
        flex: 0 250px;
    }

    @media ${device.mobile} {
        font-size: 12px;
        width: 100%;
    }

    & > div[class*="menu"] {
        margin-top: -2px;
    }
`
