import moment, { Moment } from "moment"
import "moment-timezone"
import { EventDate } from "../backendServices/Types"
import * as _ from "lodash"
import { format } from "date-fns"
import en from "date-fns/locale/en-GB"
import de from "date-fns/locale/de"
import branding from "../branding/branding"
import { isSoftOpeningPhase, isLivePhase } from "./EventPhaseChecker"
import { orderBy } from "lodash"

export const eventTimezoneName = "Europe/Berlin"
const selectedTimezoneName = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()

export interface Timezone {
    timezones: string[]
    utcDifference: number
    name: string
}

export function isToday(dateMoment: Moment): boolean {
    const today = moment.tz(selectedTimezoneName).startOf("day")
    return dateMoment.isSame(today, "d")
}

export function isYesterday(dateMoment: Moment): boolean {
    const yesterday = moment.tz(selectedTimezoneName).subtract(1, "days").startOf("day")
    return dateMoment.isSame(yesterday, "d")
}

export function isTomorrow(dateMoment: Moment): boolean {
    const tomorrow = moment.tz(selectedTimezoneName).add(1, "days").startOf("day")
    return dateMoment.isSame(tomorrow, "d")
}

export function isAfterToday(dateMoment: Moment): boolean {
    const today = moment.tz(selectedTimezoneName).startOf("day")
    return dateMoment.isAfter(today, "d")
}

export function isBeforeToday(dateMoment: Moment): boolean {
    const today = moment.tz(selectedTimezoneName).startOf("day")
    return dateMoment.isBefore(today, "d")
}

export function isNotIncludedInRange(dateMoment: Moment) {
    const timezone = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    const endDateMoment = moment(branding.personDetailPageContent.meetingSlotsSection.endDate).tz(timezone)
    return dateMoment.isAfter(endDateMoment, "d")
}

export function getIndexOfInitialDayToShowIgnoreDefault(days: Moment[]): number {
    const indexToday = days.findIndex((day) => isToday(day))
    const indexAfterToday = days.findIndex((day) => isAfterToday(day))
    const daysCopy = [...days]
    const dayBeforeToday = daysCopy.reverse().find((day) => isBeforeToday(day))
    const idxBefore = dayBeforeToday ? days.findIndex((day) => day.isSame(dayBeforeToday)) : 0
    const indexBeforeToday = idxBefore === -1 ? 0 : idxBefore

    //first check if current day can be displayed and display it,
    //second, try to display first next day (after today),
    //then display the first previous day (before today)
    return indexToday === -1 ? (indexAfterToday === -1 ? indexBeforeToday : indexAfterToday) : indexToday
}

export function getIndexOfInitialDayToShow(days: Moment[], mySchedule?: boolean): number {
    const defaultStartDate: Moment = moment(
        mySchedule ? branding.mySchedule.defaultStartDate : branding.programSchedule.defaultStartDate
    )
    const indexToday = days.findIndex((day) => isToday(day))
    const indexAfterToday = days.findIndex((day) => isAfterToday(day))
    const daysCopy = [...days]
    const dayBeforeToday = daysCopy.reverse().find((day) => isBeforeToday(day))
    const idxBefore = dayBeforeToday ? days.findIndex((day) => day.isSame(dayBeforeToday)) : 0
    const idxDefault =
        branding.programSchedule.defaultStartDate === ""
            ? 0
            : defaultStartDate
            ? days.findIndex((day) => day.isSame(defaultStartDate))
            : 0
    const indexBeforeToday = idxBefore === -1 ? 0 : idxBefore
    const indexDefault =
        idxDefault === -1
            ? isAfterToday(defaultStartDate)
                ? indexAfterToday === -1
                    ? indexBeforeToday
                    : indexAfterToday
                : indexToday
            : idxDefault

    //first check if current day can be displayed and display it,
    //second, try to display first next day (after today),
    //then display the first previous day (before today)
    if (branding.programSchedule.defaultStartDate === "") {
        return indexToday === -1 ? (indexAfterToday === -1 ? indexBeforeToday : indexAfterToday) : indexToday
    }

    return isAfterToday(defaultStartDate)
        ? indexDefault
        : indexToday === -1
        ? indexAfterToday === -1
            ? indexBeforeToday
            : indexAfterToday
        : indexToday
}

export const getTimezoneOffest = () => {
    const localTimezoneName = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    const eventTimeOffset = (moment.tz(eventTimezoneName) as unknown as { _offset: number })._offset
    const localTimeOffset = localTimezoneName
        ? (moment.tz(localTimezoneName) as unknown as { _offset: number })._offset
        : moment(moment.tz(moment.tz.guess()).format()).utcOffset()

    let timezoneMinutesDifference = Math.abs(eventTimeOffset - localTimeOffset)

    if (eventTimeOffset > localTimeOffset) timezoneMinutesDifference = -timezoneMinutesDifference

    return timezoneMinutesDifference
}

export function momentWithoutTimezoneFromTimezonedMoment(momentWithTimezone: Moment, timezone: string): Moment {
    return moment(momentWithTimezone.tz(timezone).format("YYYY-MM-DDTHH:mm:ss"))
}

let timeZones: Timezone[] = []
export function getTimezones(): Timezone[] {
    if (timeZones.length > 0) return timeZones
    var momentTimezones = moment.tz.names().filter((tz) => tz.indexOf("/") > -1 && tz.indexOf("GMT") < 0 && tz.indexOf("Etc") < 0)
    var timezones = _.groupBy(
        momentTimezones.map((mTz) => {
            const hourDifference = moment().tz(mTz).utcOffset() / 60
            return { utcDifference: hourDifference, timezone: mTz, area: mTz.slice(0, mTz.indexOf("/")) }
        }),
        (item) => {
            return [item.area, item.utcDifference]
        }
    )
    var timezonesGrouped: Timezone[] = []
    _.forEach(timezones, (tzGroupedList) => {
        var groupedTimezones: string[] = []
        tzGroupedList.forEach((it) => groupedTimezones.push(it.timezone))
        var names = groupedTimezones.map((it) => it.slice(it.indexOf("/") + 1))
        timezonesGrouped.push({
            timezones: groupedTimezones,
            utcDifference: tzGroupedList[0].utcDifference,
            name:
                tzGroupedList[0].area +
                ` UTC(${tzGroupedList[0].utcDifference > 0 ? "+" : ""}${tzGroupedList[0].utcDifference}) - ` +
                names
        })
    })
    timeZones = timezonesGrouped.sort((a, b) => (a.utcDifference > b.utcDifference ? 1 : -1))
    return timeZones
}

export function findTimezoneName(timezoneName: string): string {
    return getTimezones().find((it) => it.timezones.indexOf(timezoneName) > -1)?.name ?? ""
}

export function isEventDateLive(eventdate: EventDate) {
    const localTimezoneName = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    const now = momentWithoutTimezoneFromTimezonedMoment(moment(), localTimezoneName)
    const start = moment(eventdate.date + " " + eventdate.start)
    const end = moment(eventdate.date + " " + eventdate.end)

    if (now.isBetween(start, end)) return true
    return false
}

export function activateChannelBefore(eventdate: EventDate) {
    const localTimezoneName = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    const now = momentWithoutTimezoneFromTimezonedMoment(moment(), localTimezoneName)
    const start = moment(eventdate.date + " " + eventdate.start)
    const end = moment(eventdate.date + " " + eventdate.end)
    const activateBefore = branding.programSchedule.activateChannelBeforeMinutes ?? 5

    if (!isSoftOpeningPhase && !isLivePhase) return false

    if (now.clone().add(activateBefore, "minutes").isSameOrAfter(start) && now.isSameOrBefore(end)) return true
    return false
}

export function getTimeToLiveString(eventDate: EventDate, strings: any, language: string) {
    const lang = language === "de" ? de : en
    if (isEventDateLive(eventDate)) {
        return strings.receptionPage.nowLive
    } else if (isTomorrow(moment(eventDate.date))) {
        return strings.receptionPage.liveTomorrow
    } else if (isToday(moment(eventDate.date))) {
        const now = moment.tz(eventTimezoneName)
        const start = moment(eventDate.date + " " + eventDate.start)
        const duration = moment.duration(start.diff(now))
        return "Live " + duration.locale(language).humanize(true)
    } else {
        return (
            strings.receptionPage.liveOn +
            " " +
            format(moment(eventDate.date).toDate(), strings.eventTiming.eventDaysFormatPatternShort, { locale: lang })
        )
    }
}

const getOnlyTimestampForTime = (date: Moment) => {
    const h: number = date.hours()
    const m = date.minutes()
    const s = date.seconds()
    return h * 3600 + m * 60 + s
}

export const showCurrentTimeMarkerHelper = (selectedDate: string, timezone: string) => {
    const currentDateTime = momentWithoutTimezoneFromTimezonedMoment(moment(), timezone)
    if (isToday(moment(selectedDate))) {
        const dayStart = momentWithoutTimezoneFromTimezonedMoment(moment(branding.eventTiming.eventStartDateTime), timezone)
        return getOnlyTimestampForTime(currentDateTime) >= getOnlyTimestampForTime(dayStart)
    }

    return false
}

export const checkLatestTime = (eventdates: EventDate[]) => {
    if (eventdates && eventdates.length > 0) {
        const sortedEventdates = orderBy(eventdates, ["dateTimeEnd"], ["desc"])
        const latestTime = moment(sortedEventdates[0]?.dateTimeEnd).format("HH:mm")
        return latestTime
    }
    return null
}
