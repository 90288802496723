import { useEffect, useState } from "react"
import * as React from "react"
import styled from 'styled-components'
import { NetworkingUser } from '../../../backendServices/Types'
import branding from '../../../branding/branding'
import { useLanguageState } from '../../../globalStates/LanguageState'
import { IconClose, IconCollapse, IconExpand, IconFocus, IconHide, IconPin, IconUnfocus, IconUnpin } from '../../../ui/Icons'
import { viewType } from '../NetworkingPageContentBranding'
import { UserInfo } from './NodeDetailPopup'

const NodeActionsPopupRoot = styled.div<{ top: number, left: number }>`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    z-index: 10000;
    border: 1px solid black;
    background-color: rgb(255, 255, 255);
    padding: 30px;
    width: 400px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px ${branding.networkingArea.nodeDetailRootBoxShadowColor ?? "rgba(0, 0, 0, 0.2)"};
`
const NodeActionsCloseBtn = styled.div`
    position: absolute;
    right: 20px; 
    top: 20px; 
    cursor: pointer;
`

const ActionsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid ${branding.networkingArea.nodeDetailRootBorderColor ?? "#000"};
`

const ActionRoot = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 5px 15px;
    cursor: pointer;
    &:hover { background-color: #F2F2F2 }
`

const ActionTitle = styled.p`
    font-size: 10px;
    line-height: 14px;
    font-family: ${branding.font1};
    color: ${branding.primaryColor};
    text-align: center;
    margin: 7px 0 0 0;

  @media only screen and (max-width: 1400px){
    font-size: 8.5px;
    word-break: keep-all;
  }
  @media only screen and (max-width: 1100px){
    font-size: 7px;
    word-break: keep-all;
  }
`

export enum ActionType {
    HIDE = "HIDE",
    FOCUS = "FOCUS",
    UNFOCUS = "UNFOCUS",
    PIN = "PIN",
    UNPIN = "UNPIN",
    COLLAPSE = "COLLAPSE",
    EXPAND = "EXPAND"
}

interface NodeAction {
    title: string,
    icon: any,
    actionType: ActionType,
    visible: boolean
}

interface ParentPageInfo {
    parentPageWidth: number
    parentPageHeight: number
    bottomReservedSpace: number
    topReservedSpace: number
}

interface NodeActionPopupProps {
    nodeActionsRootRef: React.RefObject<HTMLDivElement>
    netChart: ZoomCharts.NetChart | undefined
    selectedUser: string | undefined
    parentPageInfo: ParentPageInfo
    onNodeActionsHide: () => void
    currentViewType: viewType
}

const NodeActionsPopup = (props: NodeActionPopupProps) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()

    const [rootPositionTop, setRootPositionTop] = useState(0)
    const [rootPositionLeft, setRootPositionLeft] = useState(0)

    let selectedNode = props.netChart?.getNode(props.selectedUser || '')
    let userData = (selectedNode?.data as NetworkingUser)

    const nodeActions: NodeAction[] = [
        {
            title: strings.networkingArea.hideNodeText,
            icon: IconHide({ fill: branding.sideIconBar.sideIconColorDark }),
            actionType: ActionType.HIDE,
            visible: true
        },
        {
            title: selectedNode?.focused ? strings.networkingArea.unfocusNodeText : strings.networkingArea.focusNodeText,
            icon: selectedNode?.focused ? IconUnfocus({ fill: branding.sideIconBar.sideIconColorDark }) : IconFocus({ fill: branding.sideIconBar.sideIconColorDark }),
            actionType: selectedNode?.focused ? ActionType.UNFOCUS : ActionType.FOCUS,
            visible: true
        },
        {
            title: selectedNode?.userLock ? strings.networkingArea.unpinNodeText : strings.networkingArea.pinNodeText,
            icon: selectedNode?.userLock ? IconUnpin({ fill: branding.sideIconBar.sideIconColorDark }) : IconPin({ fill: branding.sideIconBar.sideIconColorDark }),
            actionType: selectedNode?.userLock ? ActionType.UNPIN : ActionType.PIN,
            visible: true
        },
        {
            title: strings.networkingArea.collapseNodeText,
            icon: IconCollapse({ fill: branding.sideIconBar.sideIconColorDark }),
            actionType: ActionType.COLLAPSE,
            visible: props.currentViewType === viewType.EXPLORE_MY_CON
        },
        {
            title: strings.networkingArea.expandNodeText,
            icon: IconExpand({ fill: branding.sideIconBar.sideIconColorDark }),
            actionType: ActionType.EXPAND,
            visible: !selectedNode?.expanded && props.currentViewType === viewType.EXPLORE_MY_CON
        }
    ]

    const onActionClicked = (actionType: ActionType) => {
        if (!props.netChart || !selectedNode)
            return;

        switch (actionType) {
            case ActionType.HIDE:
                props.netChart.hideNode(selectedNode)
                break;
            case ActionType.FOCUS:
                props.netChart.addFocusNode(selectedNode)
                break;
            case ActionType.UNFOCUS:
                props.netChart.removeFocusNode(selectedNode)
                break;
            case ActionType.PIN:
                props.netChart.lockNode(selectedNode, selectedNode.anchorX!, selectedNode.anchorY!)
                break;
            case ActionType.UNPIN:
                props.netChart.unlockNode(selectedNode)
                break;
            case ActionType.COLLAPSE:
                props.netChart.collapseNode(selectedNode)
                break;
            case ActionType.EXPAND:
                props.netChart.expandNode(selectedNode)
                break;

        }

        props.onNodeActionsHide()
    }

    useEffect(() => {
        if (!selectedNode || !props.netChart)
            return
        let selectedNodeDimensions = props.netChart.getNodeDimensions(selectedNode)

        if (selectedNodeDimensions.x > props.parentPageInfo.parentPageWidth / 2) {
            // Node is in the right part of the parent available width space
            setRootPositionTop(selectedNodeDimensions.y + selectedNodeDimensions.hwidth)
            setRootPositionLeft(selectedNodeDimensions.x - selectedNodeDimensions.hwidth - props.nodeActionsRootRef.current!.clientWidth)
        }
        else {
            // Node is in the left part of the parent available width space
            setRootPositionTop(selectedNodeDimensions.y + selectedNodeDimensions.hwidth)
            setRootPositionLeft(selectedNodeDimensions.x + selectedNodeDimensions.hwidth)
        }
        // eslint-disable-next-line
    }, [props.selectedUser])

    // Making sure that popup is in the boundaries of the page content
    useEffect(() => {
        if (!selectedNode || !props.netChart)
            return

        let selectedNodeDimensions = props.netChart.getNodeDimensions(selectedNode)

        let availablePageHeight =
            props.parentPageInfo.parentPageHeight -
            props.parentPageInfo.topReservedSpace -
            props.parentPageInfo.bottomReservedSpace

        if ((rootPositionTop + props.nodeActionsRootRef.current!.clientHeight) > availablePageHeight) {
            setRootPositionTop(selectedNodeDimensions.y - selectedNodeDimensions.hwidth - props.nodeActionsRootRef.current!.clientHeight)
        }
        // eslint-disable-next-line
    }, [rootPositionTop])

    return (
        <NodeActionsPopupRoot
            ref={props.nodeActionsRootRef}
            top={rootPositionTop}
            left={rootPositionLeft}>
            <NodeActionsCloseBtn onClick={props.onNodeActionsHide}>
                {IconClose({ fill: branding.sideIconBar.sideIconColorDark })}
            </NodeActionsCloseBtn>
            <UserInfo userData={userData} />
            <ActionsContainer>
                {nodeActions.map(action => {
                    return (
                        action.visible && <ActionRoot onClick={() => onActionClicked(action.actionType)}>
                            {action.icon}
                            <ActionTitle>{action.title}</ActionTitle>
                        </ActionRoot>)
                })}
            </ActionsContainer>
        </NodeActionsPopupRoot>
    )
}


export default NodeActionsPopup