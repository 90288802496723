import { useState, useEffect } from "react"
import styled from "styled-components"
import HallSelect, { HallSelectProps } from "./HallSelect"
import hallplan from "./HallplanWrapper"
import { device } from "../../utils/Device"
import BadgeSelect from "./BadgeSelect"
import branding from "../../branding/branding"
import { Category } from "../../backendServices/Types"

interface ActionBarProps extends HallSelectProps {
    selectedHall?: string
    setIsStandSelected?: (value: boolean) => void | undefined
    badges?: Category[]
    setBadges?: (value: Category[]) => void
}
// Action bar is presenting filters above the loaded hallplan
export default function ActionBar(props: ActionBarProps) {
    const [zoomStrengthValue, setZoomStrengthValue] = useState([1])

    useEffect(() => {
        const changeTargetHandler = () => {
            setZoomStrengthValue([1])
        }
        hallplan.addContentDidChangeTargetHandler(changeTargetHandler)
        return () => {
            hallplan.removeContentDidChangeTargetHandler(changeTargetHandler)
        }
    }, [])

    useEffect(() => {
        hallplan.setZoomScale(zoomStrengthValue[0])
    }, [zoomStrengthValue])

    return (
        <ActionBarRoot>
            {/* HallSelect is presenting dropdown with list of halls */}
            <HallSelect {...props} setIsStandSelected={props.setIsStandSelected} />
            {/* BadgeSelect is presenting dropdown with list of badges loaded and visible after hall is selected and option in branding.js enabled */}
            {props.selectedHall && props.selectedHall !== "overview" && branding.hallPlanPageBranding.badgeDropDownVisible && (
                <BadgeSelect selectedHall={props.selectedHall} badges={props.badges} setBadges={props.setBadges} />
            )}
        </ActionBarRoot>
    )
}

const ActionBarRoot = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    padding: 10px 20px;

    @media ${device.mobile} {
        padding: 10px 15px 10px 15px;
    }
`
