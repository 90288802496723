import { createState, useState, State } from "@hookstate/core"
import { Persistence } from "@hookstate/persistence"
import { Auth } from "aws-amplify"
import { useEffect, useMemo } from "react"
import { PresenceType } from "../backendServices/GraphQLServices"
import { JobOffer, PrivacyUserAnswer } from "../backendServices/Types"
import { updateOrInvalidateSession } from "../contentArea/loginregistration/LoginRegistrationSite"
import branding from "../branding/branding"
import { LoginWithSSOKoelnmesseThirdPartyData } from "../contentArea/loginregistration/ssoKoelnmesse/LoginWithSSOKoelnmesse"
import { LoginWithPasswordThirdPartyData } from "../contentArea/loginregistration/password/LoginWithPassword"
import { LoginWithTokenThirdPartyData } from "../contentArea/loginregistration/token/LoginWithToken"

export const localStorageKey = "virtualGuide-loggedInUser"
var jwt = require("jsonwebtoken")
export interface User {
    profileId: string
    remoteId?: string
    email: string
    firstName?: string
    lastName?: string
    infotext?: string
    infotextDe?: string
    interests?: Array<String>
    lookingfor?: Array<String>
    offering?: Array<String>
    logoUrl?: string
    matchActive?: boolean
    industry?: string
    industryDe?: string
    countrycode?: string
    emailLanguage?: string
    postalCode?: string
    adress1?: string
    adress2?: string
    adress3?: string
    city?: string
    languages?: Array<any>
    middleName?: string
    mobile?: string
    phone?: string
    position?: string
    positionDe?: string
    salutation?: string
    salutationDe?: string
    company?: string
    areaOfResponsibility?: string
    presence?: PresenceType
    facebook?: string
    linkedIn?: string
    googleplus?: string
    youTube?: string
    twitter?: string
    xing?: string
    pinterest?: string
    instagram?: string
    type?: string
    organizations?: UserOrganization[]
    eventDates?: Array<{ id: string; name: string }>
    events?: Array<{ id: string; name: string }>
    invitingOrganization?: { id: string; name: string }
    joboffers?: JobOffer[]
    person?: string
}
export enum PersonType {
    CONTENTMANAGER = "CONTENTMANAGER",
    SHOPMANAGER = "SHOPMANAGER"
}
export interface UserOrganization {
    id: string
    name: string
    logo?: string
    isMainContact: boolean
    personTypes: PersonType[]
}

export type ThirdPartyData = LoginWithSSOKoelnmesseThirdPartyData | LoginWithPasswordThirdPartyData | LoginWithTokenThirdPartyData

export interface LoggendInUserContextMethods {
    isLoggedIn: boolean
    sessionVerificationDone: boolean
    sessionAndTicketValid: boolean
    user: () => User | undefined
    jwtToken: () => string | undefined
    updateToken: (jwtToken: string) => void
    setUser: (user?: User) => void
    setSessionAndTicketValid: (isValid: boolean) => void
    setUserAndTicketValid: (user: User, ticketValid: boolean) => void
    isMatchActive: () => boolean
    setMatchActive: (matchActive: boolean) => void
    setFirstName: (firstName: string) => void
    setLastName: (lastName: string) => void
    setLanguages: (languages: any[]) => void
    setCompany: (company: string) => void
    setPosition: (position: string) => void
    setPositionDe: (positionDe: string) => void
    setSalutation: (salutation: string) => void
    setSalutationDe: (salutationDe: string) => void
    setAreaOfResponsibility: (areaOfResponsibility: string) => void
    setIndustry: (industry: string) => void
    setIndustryDe: (industryDe: string) => void
    setCountryCode: (countrycode: string) => void
    setEmailLanguage: (emailLanguage: string) => void
    setMobile: (mobile: string) => void
    setPhone: (phone: string) => void
    setAdress1: (adress1: string) => void
    setAdress2: (adress2: string) => void
    setAdress3: (adress3: string) => void
    setCity: (city: string) => void
    setPostalCode: (postalCode: string) => void
    setProfileImage: (imageUrl: string) => void
    setInterests: (interests: string[]) => void
    setOrganizations: (organizations: any) => void
    setEventDates: (eventDates: any) => void
    setEvents: (events: any) => void
    setInfotext: (infotext: string) => void
    setInfotextDe: (infotextDe: string) => void
    setFacebook: (facebook: string) => void
    setLinkedin: (linkedIn: string) => void
    setGooglePlus: (googleplus: string) => void
    setYouTube: (youTube: string) => void
    setTwitter: (twitter: string) => void
    setXing: (xing: string) => void
    setPinterest: (pinterest: string) => void
    setInstagram: (instagram: string) => void
    setType: (type: string) => void
    setPrivacyUserAnswer: (privacyUserAnswer: PrivacyUserAnswer) => void
    setInvitingOrganization: (invitingOrganization: any) => void
    getPrivacyUserAnswer: (questionId: String) => PrivacyUserAnswer | undefined
    setUserState: (newState: {
        jwtToken?: string
        user?: User
        privacyUserAnswer2?: PrivacyUserAnswer[]
        sessionAndTicketValid: boolean
        thirdPartyData?: ThirdPartyData
    }) => void
    logout: () => void
    wasLeadFinderNoticeShown: () => boolean
    setLeadFinterNoticeShown: () => void
}

export interface UserState {
    user?: User
    jwtToken?: string
    privacyUserAnswer2?: PrivacyUserAnswer[]
    leadFinderNoticeShown?: boolean
    sessionVerificationDone: boolean
    sessionAndTicketValid: boolean
    thirdPartyData?: ThirdPartyData
}

let sessionBeeingVerified = false
resetSessionVerificationDone()
const useStateWrapper = (state: State<UserState>): LoggendInUserContextMethods => {
    const jwtToken = state.jwtToken.get()
    const isLoggedIn = useMemo(() => {
        return jwt.decode(jwtToken)?.sotUserId !== undefined
    }, [jwtToken])

    state.attach(Persistence(localStorageKey))

    useEffect(() => {
        if (!sessionBeeingVerified && jwtToken) {
            sessionBeeingVerified = true
            const validation = async () => {
                await updateOrInvalidateSession(
                    isLoggedIn,
                    invalidateSession,
                    {
                        profileId: getMyIdFromLocalStorage() || "",
                        beConnectionToken: jwtToken,
                        email: getMyEmailFromLocalStorage() || ""
                    },
                    setUser,
                    setMatchActive,
                    setSessionAndTicketValid
                )
                state.sessionVerificationDone.set(true)
            }
            validation()
        }
        // eslint-disable-next-line
    }, [jwtToken])

    function invalidateSession() {
        setUserState({
            jwtToken: undefined,
            user: undefined,
            privacyUserAnswer2: undefined,
            sessionAndTicketValid: !branding.configuration.ticketBarrierPageEnabled || false
        })
        Auth.signOut()
    }

    function setUserState(newState: {
        jwtToken?: string
        user?: User
        privacyUserAnswer2?: PrivacyUserAnswer[]
        sessionAndTicketValid: boolean
        thirdPartyData?: ThirdPartyData
    }) {
        state.set((prev) => {
            prev.jwtToken = newState.jwtToken
            prev.user = newState.user
            prev.sessionAndTicketValid = newState.sessionAndTicketValid
            prev.privacyUserAnswer2 = newState.privacyUserAnswer2
            prev.thirdPartyData = newState.thirdPartyData
            return prev
        })
    }

    function setUser(user?: User) {
        state.set((prevState) => {
            prevState.user = user
            return prevState
        })
    }

    function setMatchActive(matchActive: boolean) {
        state.set((prevState) => {
            prevState.user!!.matchActive = matchActive
            return prevState
        })
    }

    function setSessionAndTicketValid(isValid: boolean) {
        state.set((prevState) => {
            prevState.sessionAndTicketValid = isValid
            return prevState
        })
    }

    return {
        isLoggedIn: isLoggedIn,
        sessionVerificationDone: state.get().sessionVerificationDone,
        sessionAndTicketValid: state.get().sessionAndTicketValid,
        user: () => {
            return state.get().user
        },
        jwtToken: () => {
            return state.jwtToken.get()
        },
        updateToken: (jwtToken: string) => {
            state.set((prevState) => {
                prevState.jwtToken = jwtToken
                return prevState
            })
        },
        setUser: setUser,
        setSessionAndTicketValid: (isValid: boolean) => {
            state.set((prevState) => {
                prevState.sessionAndTicketValid = isValid
                return prevState
            })
        },
        setUserAndTicketValid: (user: User, ticketValid: boolean) => {
            state.set((prevState) => {
                prevState.sessionAndTicketValid = ticketValid
                prevState.user = user
                return prevState
            })
        },
        isMatchActive: () => {
            return state.user.value?.matchActive === true
        },
        setMatchActive: setMatchActive,
        setFirstName: (firstName: string) => {
            state.set((prevState) => {
                prevState.user!!.firstName = firstName
                return prevState
            })
        },
        setLastName: (lastName: string) => {
            state.set((prevState) => {
                prevState.user!!.lastName = lastName
                return prevState
            })
        },
        setLanguages: (languages: any[]) => {
            state.set((prevState) => {
                prevState.user!!.languages = languages
                return prevState
            })
        },
        setCompany: (company: string) => {
            state.set((prevState) => {
                prevState.user!!.company = company
                return prevState
            })
        },
        setPosition: (position: string) => {
            state.set((prevState) => {
                prevState.user!!.position = position
                return prevState
            })
        },
        setPositionDe: (positionDe: string) => {
            state.set((prevState) => {
                prevState.user!!.positionDe = positionDe
                return prevState
            })
        },
        setSalutation: (salutation: string) => {
            state.set((prevState) => {
                prevState.user!!.salutation = salutation
                return prevState
            })
        },
        setSalutationDe: (salutationDe: string) => {
            state.set((prevState) => {
                prevState.user!!.salutationDe = salutationDe
                return prevState
            })
        },
        setAreaOfResponsibility: (areaOfResponsibility: string) => {
            state.set((prevState) => {
                prevState.user!!.areaOfResponsibility = areaOfResponsibility
                return prevState
            })
        },
        setIndustry: (industry: string) => {
            state.set((prevState) => {
                prevState.user!!.industry = industry
                return prevState
            })
        },
        setIndustryDe: (industryDe: string) => {
            state.set((prevState) => {
                prevState.user!!.industryDe = industryDe
                return prevState
            })
        },
        setCountryCode: (countrycode: string) => {
            state.set((prevState) => {
                prevState.user!!.countrycode = countrycode
                return prevState
            })
        },
        setEmailLanguage: (emailLanguage: string) => {
            state.set((prevState) => {
                prevState.user!!.emailLanguage = emailLanguage
                return prevState
            })
        },
        setMobile: (mobile: string) => {
            state.set((prevState) => {
                prevState.user!!.mobile = mobile
                return prevState
            })
        },
        setPhone: (phone: string) => {
            state.set((prevState) => {
                prevState.user!!.phone = phone
                return prevState
            })
        },
        setAdress1: (adress1: string) => {
            state.set((prevState) => {
                prevState.user!!.adress1 = adress1
                return prevState
            })
        },
        setAdress2: (adress2: string) => {
            state.set((prevState) => {
                prevState.user!!.adress2 = adress2
                return prevState
            })
        },
        setAdress3: (adress3: string) => {
            state.set((prevState) => {
                prevState.user!!.adress3 = adress3
                return prevState
            })
        },
        setCity: (city: string) => {
            state.set((prevState) => {
                prevState.user!!.city = city
                return prevState
            })
        },
        setPostalCode: (postalCode: string) => {
            state.set((prevState) => {
                prevState.user!!.postalCode = postalCode
                return prevState
            })
        },
        setProfileImage: (imageUrl: string) => {
            state.set((prevState) => {
                prevState.user!!.logoUrl = imageUrl
                return prevState
            })
        },
        setInterests: (interests: string[]) => {
            state.set((prevState) => {
                prevState.user!!.interests = interests
                return prevState
            })
        },
        setOrganizations: (organizations: any) => {
            state.set((prevState) => {
                prevState.user!!.organizations = organizations
                return prevState
            })
        },
        setEventDates: (eventDates: any) => {
            state.set((prevState) => {
                prevState.user!!.eventDates = eventDates
                return prevState
            })
        },
        setEvents: (events: any) => {
            state.set((prevState) => {
                prevState.user!!.events = events
                return prevState
            })
        },
        setInfotext: (infotext: string) => {
            state.set((prevState) => {
                prevState.user!!.infotext = infotext
                return prevState
            })
        },
        setInfotextDe: (infotextDe: string) => {
            state.set((prevState) => {
                prevState.user!!.infotextDe = infotextDe
                return prevState
            })
        },
        setFacebook: (facebook: string) => {
            state.set((prevState) => {
                prevState.user!!.facebook = facebook
                return prevState
            })
        },
        setLinkedin: (linkedIn: string) => {
            state.set((prevState) => {
                prevState.user!!.linkedIn = linkedIn
                return prevState
            })
        },
        setGooglePlus: (googleplus: string) => {
            state.set((prevState) => {
                prevState.user!!.googleplus = googleplus
                return prevState
            })
        },
        setYouTube: (youTube: string) => {
            state.set((prevState) => {
                prevState.user!!.youTube = youTube
                return prevState
            })
        },
        setTwitter: (twitter: string) => {
            state.set((prevState) => {
                prevState.user!!.twitter = twitter
                return prevState
            })
        },
        setXing: (xing: string) => {
            state.set((prevState) => {
                prevState.user!!.xing = xing
                return prevState
            })
        },
        setPinterest: (pinterest: string) => {
            state.set((prevState) => {
                prevState.user!!.pinterest = pinterest
                return prevState
            })
        },
        setInstagram: (instagram: string) => {
            state.set((prevState) => {
                prevState.user!!.instagram = instagram
                return prevState
            })
        },
        setType: (type: string) => {
            state.set((prevState) => {
                prevState.user!!.type = type
                return prevState
            })
        },
        setPrivacyUserAnswer: (privacyUserAnswer: PrivacyUserAnswer) => {
            state.set((prevState) => {
                const arr =
                    prevState.privacyUserAnswer2?.filter((answer) => answer.questionId !== privacyUserAnswer.questionId) || []
                arr.push(privacyUserAnswer)
                prevState.privacyUserAnswer2 = arr
                return prevState
            })
        },
        setInvitingOrganization: (invitingOrganization: any) => {
            state.set((prevState) => {
                prevState.user!!.invitingOrganization = invitingOrganization
                return prevState
            })
        },
        getPrivacyUserAnswer: (questionId: String) => {
            return state.get().privacyUserAnswer2?.find((answer) => answer.questionId === questionId)
        },
        setUserState: setUserState,
        logout: () => {
            invalidateSession()
        },
        wasLeadFinderNoticeShown: () => {
            return state.value?.leadFinderNoticeShown === true
        },
        setLeadFinterNoticeShown: () => {
            state.set((prevState) => {
                prevState.leadFinderNoticeShown = true
                return prevState
            })
        }
    }
}

const state = createState({
    sessionVerificationDone: false,
    sessionAndTicketValid: !branding.configuration.ticketBarrierPageEnabled || false
} as UserState)
export const useLoggedInState = (): LoggendInUserContextMethods => useStateWrapper(useState(state))

export function getMyIdFromLocalStorage(): string | undefined {
    const profilJson = JSON.parse(localStorage.getItem(localStorageKey) ?? "null") as UserState | null
    return profilJson?.user?.profileId ?? undefined
}

export function getMyEmailFromLocalStorage(): string | undefined {
    const profilJson = JSON.parse(localStorage.getItem(localStorageKey) ?? "null") as UserState | null
    return profilJson?.user?.email ?? undefined
}

export function getMyTypeFromLocalStorage(): string | undefined {
    const profilJson = JSON.parse(localStorage.getItem(localStorageKey) ?? "null") as UserState | null
    return profilJson?.user?.type ?? undefined
}

export function getThirdPartyDataFromLocalStorage(): ThirdPartyData | undefined {
    const profilJson = JSON.parse(localStorage.getItem(localStorageKey) ?? "null") as UserState | null
    return profilJson?.thirdPartyData
}

/**
 * Is there a better fix? SessionVerificationDone should be false on every page refresh. But it's part of the state and therefore persisted in the localstorage.
 * Didn't find a solution for this property to be transient. Therefor it's reset with this function.
 */
function resetSessionVerificationDone() {
    const userState = JSON.parse(localStorage.getItem(localStorageKey) ?? "null") as UserState | null
    if (!userState) return
    userState.sessionVerificationDone = false
    localStorage.setItem(localStorageKey, JSON.stringify(userState))
}
