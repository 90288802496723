import { useEffect, useState } from "react"
import * as React from "react"
import styled from "styled-components"
import { TabContentArea, TabEmpty, TabRoot, MultiSwitchRoot, NextPageLoader } from "./CommunicationArea"
import CalendarEntryListView from "./CalendarEntryListView"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import CenteredLoader from "../ui/CenteredLoader"
import InfiniteScroll from "react-infinite-scroll-component"
import { useLanguageState } from "../globalStates/LanguageState"
import CrsMultiSwitch from "../ui/CrsMultiSwitch"
import branding from "../branding/branding"
import { IconDownload, IconChevronRight, IconMeetingSchedule } from "../ui/Icons"
import { Button } from "../conference/components/Button"
import { saveMeetings } from "./ICal"
import { useAppState } from "../globalStates/AppState"
import CalendarEntryModal, { CalendarEntryModalViewMode } from "../ui/modals/CalendarEntryModal"
import { API, graphqlOperation } from "aws-amplify"
import Observable from "zen-observable-ts"
import { onCalendarEntryParticipationCreatedLight, onCalendarEntryParticipationDeletedLight } from "../graphql/ownSubscriptions"
import { ContentScrollContainer } from "../ui/ScrollContainer"
import GuestModal from "../ui/modals/GuestModal"
import { detect } from "detect-browser"
import { isOnboardingPhase, isPostEventPhase } from "../utils/EventPhaseChecker"
import { getIamPartOf } from "../globalStates/IAmPartOf"
import queryString from "query-string"
import { getCalendarEntries, CalendarEntrySortType, CalendarEntry } from "../backendServices/GraphQLServices"
import { CalendarEntryParticipationStatus } from "../API"

const TabListSectionHeader = styled.div`
    margin-bottom: 5px;
    margin-left: 15px;
    margin-top: 35px;
    margin-right: 20px;
    font-size: 14px;
    font-family: ${branding.font1};
    color: ${branding.communicationArea.titleTextColor ?? "#000"};
    display: flex;
    justify-content: space-between;
`

export interface badgeWrapperProps {
    xPos: number
}
const BadgeContentArea = styled.div<badgeWrapperProps>`
    position: relative;
    top: -15px;
    left: ${(props) => props.xPos}px !important;
    z-index: 99;
`
const BadgeRow = styled.div`
    display: flex;
    position: absolute;
`

const CreatedNewMeetingBtn = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0.75rem;
    margin-top: 0.5rem;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    color: ${branding.mainInfoColor ?? "black"};
    cursor: pointer;
    &:hover {
        background-color: rgb(240, 240, 240);
    }
    span {
        color: ${branding.mainInfoColor ?? "black"};
    }
    svg {
        color: ${branding.primaryColor ?? "black"};
    }
`

const SubTabRoot = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    height: calc(100vh - 177px - 74px - 10px); /* - height of tab switchers and create meeting button and footer*/
    overflow-x: hidden;
`

const BadgeUnreadCounter = styled.span`
    position: relative;
    background-color: ${branding.communicationArea.badgeUnreadCounterBgColor ?? "#000"};
    font-size: 10px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1px;
    border: 2px solid ${branding.communicationArea.badgeUnreadCounterBorderColor ?? "#fff"};
    font-family: ${branding.font1};
`
const ScheduleMeetingTitle = styled.span`
    font-family: ${branding.font1};
`
interface BadgeTabsProps {
    xPos: { x: number; count: number }[]
}
const BadgeTabs: React.FunctionComponent<BadgeTabsProps> = (props) => {
    return (
        <BadgeRow>
            {" "}
            {props.xPos.map((pos, index) => {
                return (
                    <BadgeContentArea key={index} xPos={pos.x}>
                        {pos.count <= 0 ? null : (
                            <h6 style={{ fontSize: "14px" }}>
                                <BadgeUnreadCounter className="badge badge-pill badge-dark">{pos.count}</BadgeUnreadCounter>
                            </h6>
                        )}
                    </BadgeContentArea>
                )
            })}
        </BadgeRow>
    )
}

export enum ScheduleListType {
    CONFIRMED,
    PENDING,
    DECLINED
}
interface ScheduleTabProps {
    schedules: number
    reloadScheduleList: boolean
    setReloadScheduleList: (value: boolean) => void
}
const ScheduleTab: React.FunctionComponent<ScheduleTabProps> = (props) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const itemList = [
        { label: strings.communicationArea.allScheduleTabTitle, value: ScheduleListType.CONFIRMED },
        { label: strings.communicationArea.pendingScheduleTabTitle, value: ScheduleListType.PENDING },
        { label: strings.communicationArea.declinedScheduleTabTitle, value: ScheduleListType.DECLINED }
    ]
    const appState = useAppState()
    const userState = useLoggedInState()
    const listType = appState.communicationCenterDisplayParam
    const selectedItem = listType === undefined ? ScheduleListType.CONFIRMED : appState.communicationCenterDisplayParam

    const [showRequestMeetingModal, setShowRequestMeetingModal] = useState<boolean>(false)

    const [showGuestModal, setShowGuestModal] = useState<boolean>(false)
    const queryParams: any = queryString.parse(window.location.search)
    return (
        <>
            <TabRoot>
                <MultiSwitchRoot>
                    {showRequestMeetingModal && (
                        <CalendarEntryModal
                            viewMode={CalendarEntryModalViewMode.CREATE}
                            close={() => {
                                setShowRequestMeetingModal(false)
                                props.setReloadScheduleList(false)
                                props.setReloadScheduleList(true)
                            }}
                        />
                    )}
                    <BadgeTabs xPos={[{ x: 130, count: props.schedules }]} />
                    <CrsMultiSwitch
                        items={itemList}
                        activeItem={selectedItem}
                        onItemClick={appState.setShowScheduleTab}
                        style={{
                            mainWidth: "280px",
                            msRootItem: { padding: "1px" },
                            border: branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"
                        }}
                    />
                </MultiSwitchRoot>
                {(!isPostEventPhase || (isOnboardingPhase && getIamPartOf(queryParams) === "onboarding")) && (
                    <CreatedNewMeetingBtn
                        onClick={() => {
                            if (
                                userState.user() &&
                                userState.user()?.type === "guest" &&
                                userState.user()?.invitingOrganization
                            ) {
                                setShowGuestModal(true)
                                return
                            }

                            setShowRequestMeetingModal(true)
                        }}
                    >
                        <div className={"d-flex align-items-center"}>
                            <span className={"mr-2"}>
                                {IconMeetingSchedule({ fill: branding.sideIconBar.sideIconColorDark })}
                            </span>
                            <ScheduleMeetingTitle>{strings.communicationArea.scheduleMeetingText}</ScheduleMeetingTitle>
                        </div>
                        <div>
                            {IconChevronRight({ fill: branding.sideIconBar.sideIconColorDark, width: "20px", height: "20px" })}
                        </div>
                    </CreatedNewMeetingBtn>
                )}
                <TabContentArea className="rs-nav-content">
                    <ScheduleListTab
                        reloadScheduleList={props.reloadScheduleList}
                        setReloadScheduleList={props.setReloadScheduleList}
                    />
                </TabContentArea>
            </TabRoot>
            {showGuestModal && <GuestModal close={() => setShowGuestModal(false)} />}
        </>
    )
}

export default ScheduleTab

export enum ScheduleListTabHeaderType {
    PAST,
    UPCOMING,
    REQUESTED,
    DECLINED,
    NOW
}

export enum ScheduleListSectionType {
    NOW,
    UPCOMING,
    PAST
}

interface ScheduleListTabProps {
    reloadScheduleList?: boolean
    setReloadScheduleList: (value: boolean) => void
}

const ScheduleListTab: React.FunctionComponent<ScheduleListTabProps> = (props) => {
    const [calendarEntries, setCalendarEntries] = useState<Array<CalendarEntry | ScheduleListTabHeaderType>>([])
    const [calendarEntriesNow, setCalendarEntriesNow] = useState<Array<CalendarEntry | ScheduleListTabHeaderType>>([])
    const [calendarEntriesPast, setCalendarEntriesPast] = useState<Array<CalendarEntry | ScheduleListTabHeaderType>>([])
    const [nextTokenNow, setNextTokenNow] = useState<string>()
    const [nextToken, setNextToken] = useState<string>()
    const [nextTokenPast, setNextTokenPast] = useState<string>()
    const [hasMorePages, setHasMorePages] = useState<boolean>(true)
    const [currentLoadingList, setCurrentLoadingList] = useState<ScheduleListSectionType>(ScheduleListSectionType.NOW)
    const userLink = useLoggedInState()
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const [listNeedsReload, setListNeedsReload] = useState<boolean>(true)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const appState = useAppState()
    const profileId = userLink.user()?.profileId
    const [customScrollRenderDelay, setCustomScrollRenderDelay] = useState(false)
    const browser = detect()

    const currentFilterStatus =
        appState.communicationCenterDisplayParam === ScheduleListType.PENDING
            ? CalendarEntryParticipationStatus.REQUESTED
            : appState.communicationCenterDisplayParam === ScheduleListType.DECLINED
            ? CalendarEntryParticipationStatus.DECLINED
            : CalendarEntryParticipationStatus.ACCEPTED

    function resetList() {
        setNextToken(undefined)
        setNextTokenPast(undefined)
        setNextTokenNow(undefined)
        setHasMorePages(true)
        setCurrentLoadingList(ScheduleListSectionType.NOW)
        setCalendarEntries([])
        setCalendarEntriesPast([])
        setCalendarEntriesNow([])
        setListNeedsReload(true)
        setIsLoaded(false)
    }

    useEffect(() => {
        const onCalendarEntryParticipationCreatedHandler = (
            API.graphql(graphqlOperation(onCalendarEntryParticipationCreatedLight, { userId: profileId })) as Observable<any>
        ).subscribe({
            next: (resp: any) => {
                resetList()
            }
        })
        return () => onCalendarEntryParticipationCreatedHandler.unsubscribe()
    }, [profileId])

    useEffect(() => {
        const onCalendarEntryParticipationDeletedHandler = (
            API.graphql(graphqlOperation(onCalendarEntryParticipationDeletedLight, { userId: profileId })) as Observable<any>
        ).subscribe({
            next: (resp: any) => {
                resetList()
            }
        })
        return () => onCalendarEntryParticipationDeletedHandler.unsubscribe()
    }, [profileId])

    useEffect(() => {
        // displayed tab changed, reset data
        appState.setCommunicationCenterForceLoadListFunction(resetList)
        resetList()

        // eslint-disable-next-line
    }, [appState.communicationCenterDisplayParam])

    useEffect(() => {
        if (listNeedsReload || props.reloadScheduleList) {
            loadScheduleData()
        }
        // eslint-disable-next-line
    }, [listNeedsReload, props.reloadScheduleList])

    function exportAll(listType: ScheduleListSectionType) {
        saveMeetings(
            strings,
            (listType === ScheduleListSectionType.PAST
                ? calendarEntriesPast
                : listType === ScheduleListSectionType.UPCOMING
                ? calendarEntries
                : calendarEntriesNow
            ).filter((x) => (x as CalendarEntry).id) as CalendarEntry[]
        )
    }

    function calendarEntriesComparator(
        objectA: CalendarEntry | ScheduleListTabHeaderType,
        objectB: CalendarEntry | ScheduleListTabHeaderType,
        isPast: boolean
    ): number {
        if ((objectA as CalendarEntry).id && (objectB as CalendarEntry).id) {
            const firstCalendarEntry = objectA as CalendarEntry
            const secondCalendarEntry = objectB as CalendarEntry

            if (isPast) {
                return firstCalendarEntry.start > secondCalendarEntry.start ? -1 : 1
            } else {
                return firstCalendarEntry.start > secondCalendarEntry.start ? 1 : -1
            }
        } else {
            return 0
        }
    }

    function calendarEntriesComparatorPast(
        objectA: CalendarEntry | ScheduleListTabHeaderType,
        objectB: CalendarEntry | ScheduleListTabHeaderType
    ) {
        return calendarEntriesComparator(objectA, objectB, true)
    }

    function calendarEntriesComparatorFuture(
        objectA: CalendarEntry | ScheduleListTabHeaderType,
        objectB: CalendarEntry | ScheduleListTabHeaderType
    ) {
        return calendarEntriesComparator(objectA, objectB, false)
    }

    function createCalendarEntryListView(calendarEntry: CalendarEntry, index: number, listType: ScheduleListSectionType) {
        let numberOfAcceptedParticipants = calendarEntry.participants.items.filter(
            (x) => x.status === CalendarEntryParticipationStatus.ACCEPTED
        ).length
        return (
            <CalendarEntryListView
                numberOfAcceptedParticipants={numberOfAcceptedParticipants}
                isPast={listType === ScheduleListSectionType.PAST}
                calendarEntry={calendarEntry}
                key={index}
                onUpdated={(calendarEntry) => {
                    if (listType === ScheduleListSectionType.PAST) {
                        const updatedCalendarEntries = calendarEntriesPast
                        updatedCalendarEntries[index] = calendarEntry
                        updatedCalendarEntries.sort(calendarEntriesComparatorPast)
                        setCalendarEntriesPast([...updatedCalendarEntries])
                    } else if (listType === ScheduleListSectionType.UPCOMING) {
                        const updatedCalendarEntries = calendarEntries
                        updatedCalendarEntries[index] = calendarEntry
                        updatedCalendarEntries.sort(calendarEntriesComparatorFuture)
                        setCalendarEntries([...updatedCalendarEntries])
                    } else {
                        const updatedCalendarEntries = calendarEntriesNow
                        updatedCalendarEntries[index] = calendarEntry
                        updatedCalendarEntries.sort(calendarEntriesComparatorPast)
                        setCalendarEntriesNow([...updatedCalendarEntries])
                    }
                }}
                onRemovedFromList={() => {
                    if (listType === ScheduleListSectionType.PAST) {
                        const updatedCalendarEntries = calendarEntriesPast
                        updatedCalendarEntries.splice(index, 1)
                        setCalendarEntriesPast(updatedCalendarEntries.length === 1 ? [] : [...updatedCalendarEntries])
                    } else if (listType === ScheduleListSectionType.UPCOMING) {
                        const updatedCalendarEntries = calendarEntries
                        updatedCalendarEntries.splice(index, 1)
                        setCalendarEntries(updatedCalendarEntries.length === 1 ? [] : [...updatedCalendarEntries])
                    } else {
                        const updatedCalendarEntries = calendarEntriesNow
                        updatedCalendarEntries.splice(index, 1)
                        setCalendarEntriesNow(updatedCalendarEntries.length === 1 ? [] : [...updatedCalendarEntries])
                    }
                }}
                reloadScheduleList={props.reloadScheduleList}
                setReloadScheduleList={props.setReloadScheduleList}
            />
        )
    }

    function createTabListSectionHeader(type: ScheduleListTabHeaderType, index: number, listType: ScheduleListSectionType) {
        let titleString
        switch (type) {
            case ScheduleListTabHeaderType.PAST: {
                titleString = strings.communicationArea.scheduleListSectionHeaderPast
                break
            }
            case ScheduleListTabHeaderType.UPCOMING: {
                titleString = strings.communicationArea.scheduleListSectionHeaderAccepted
                break
            }
            case ScheduleListTabHeaderType.REQUESTED: {
                titleString = strings.communicationArea.scheduleListSectionHeaderRequests
                break
            }
            case ScheduleListTabHeaderType.DECLINED: {
                titleString = strings.communicationArea.scheduleListSectionHeaderDeclined
                break
            }
            case ScheduleListTabHeaderType.NOW: {
                titleString = strings.communicationArea.scheduleListSectionHeaderNow
                break
            }
        }
        return (
            <TabListSectionHeader key={index}>
                <div>{titleString}</div>
                <Button
                    margin={3}
                    size={browser && browser.name === "safari" ? 30 : 15}
                    icon={IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}
                    tooltip={strings.communicationArea.exportICals}
                    onClick={(x) => exportAll(listType)}
                    backgroundColor="#ffffff"
                />
            </TabListSectionHeader>
        )
    }

    let content: JSX.Element = <div />
    if (!isLoaded) {
        content = <CenteredLoader />
    } else {
        const scheduleTabEmptyContent = (
            <TabEmpty>
                <p>{strings.communicationArea.scheduleTabEmpty}</p>
            </TabEmpty>
        )

        const contactsLoadedContent = (
            <ContentScrollContainer scrollBarAlwaysVisible={true} adjustForHeaderWith="280px">
                {customScrollRenderDelay && (
                    <InfiniteScroll
                        dataLength={calendarEntriesNow.length + calendarEntries.length + calendarEntriesPast.length}
                        next={loadScheduleData}
                        hasMore={hasMorePages}
                        loader={<NextPageLoader />}
                        scrollableTarget={document.querySelector("#customScrollbarSchedule .ScrollbarsCustom-Scroller")}
                    >
                        {calendarEntriesNow.map((object, index) => {
                            if ((object as CalendarEntry).id) {
                                return createCalendarEntryListView(object as CalendarEntry, index, ScheduleListSectionType.NOW)
                            } else {
                                return createTabListSectionHeader(
                                    object as ScheduleListTabHeaderType,
                                    index,
                                    ScheduleListSectionType.NOW
                                )
                            }
                        })}
                        {calendarEntries.map((object, index) => {
                            if ((object as CalendarEntry).id) {
                                return createCalendarEntryListView(
                                    object as CalendarEntry,
                                    index,
                                    ScheduleListSectionType.UPCOMING
                                )
                            } else {
                                return createTabListSectionHeader(
                                    object as ScheduleListTabHeaderType,
                                    index,
                                    ScheduleListSectionType.UPCOMING
                                )
                            }
                        })}
                        {calendarEntriesPast.map((object, index) => {
                            if ((object as CalendarEntry).id) {
                                return createCalendarEntryListView(object as CalendarEntry, index, ScheduleListSectionType.PAST)
                            } else {
                                return createTabListSectionHeader(
                                    object as ScheduleListTabHeaderType,
                                    index,
                                    ScheduleListSectionType.PAST
                                )
                            }
                        })}
                    </InfiniteScroll>
                )}
            </ContentScrollContainer>
        )

        content =
            calendarEntriesNow.length > 0 || calendarEntries.length > 0 || calendarEntriesPast.length > 0
                ? contactsLoadedContent
                : scheduleTabEmptyContent
    }

    return <SubTabRoot id="customScrollbarSchedule">{content}</SubTabRoot>

    async function loadScheduleData() {
        const profileId = userLink.user()?.profileId
        if (profileId === undefined) {
            return
        }
        setCustomScrollRenderDelay(false)

        let loadingListType = currentLoadingList
        var calendarEntriesNowCached: Array<CalendarEntry | ScheduleListTabHeaderType> = calendarEntriesNow
        if (loadingListType === ScheduleListSectionType.NOW) {
            const data = await getCalendarEntries(profileId, CalendarEntrySortType.PAST, currentFilterStatus, nextTokenNow)

            if (data == null) {
                // TODO ERROR
            } else {
                const calendarEntriesResp: CalendarEntry[] = data.items.map((item) => item.calendarEntry)

                // Filter out entries from past days
                const calendarEntriesToday = calendarEntriesResp.filter((calendarEntry) => {
                    const startDate = new Date(calendarEntry.start)
                    const currentDate = new Date()
                    return currentDate.getDate() === startDate.getDate()
                })

                const loadedAll = calendarEntriesToday.length < calendarEntriesResp.length || data.nextToken == null
                // Filter out entries that are not currently ongoing
                const calendarEntriesTodayOngoing = calendarEntriesToday.filter((calendarEntry) => {
                    const endDate = new Date(calendarEntry.end)
                    const currentDate = new Date()
                    return endDate.getTime() >= currentDate.getTime()
                })
                if (listNeedsReload) {
                    setCalendarEntries([])
                    setCalendarEntriesPast([])
                    setListNeedsReload(false)

                    const sectionHeader: Array<CalendarEntry | ScheduleListTabHeaderType> = [ScheduleListTabHeaderType.NOW]
                    calendarEntriesNowCached =
                        calendarEntriesTodayOngoing.length === 0
                            ? calendarEntriesTodayOngoing
                            : sectionHeader.concat(calendarEntriesTodayOngoing)
                    setCalendarEntriesNow(calendarEntriesNowCached)
                } else {
                    const newCalendarEntries = calendarEntriesNow.concat(calendarEntriesTodayOngoing)
                    if (calendarEntriesTodayOngoing.length > 0) newCalendarEntries[0] = ScheduleListTabHeaderType.NOW
                    calendarEntriesNowCached = newCalendarEntries
                    setCalendarEntriesNow(calendarEntriesNowCached)
                }
                setNextTokenNow(data.nextToken)
                loadingListType = loadedAll ? ScheduleListSectionType.UPCOMING : ScheduleListSectionType.NOW
                setCurrentLoadingList(loadingListType)
                setHasMorePages(true)
                setIsLoaded(!loadedAll)
            }
        }

        if (loadingListType === ScheduleListSectionType.UPCOMING) {
            const data = await getCalendarEntries(profileId, CalendarEntrySortType.FUTURE, currentFilterStatus, nextToken)
            if (data == null) {
                // TODO ERROR
            } else {
                const calendarEntriesResp: CalendarEntry[] = data.items.map((item) => item.calendarEntry)
                let titleType =
                    currentFilterStatus === CalendarEntryParticipationStatus.ACCEPTED
                        ? ScheduleListTabHeaderType.UPCOMING
                        : currentFilterStatus === CalendarEntryParticipationStatus.DECLINED
                        ? ScheduleListTabHeaderType.DECLINED
                        : ScheduleListTabHeaderType.REQUESTED

                if (nextToken == null) {
                    const sectionHeader: Array<CalendarEntry | ScheduleListTabHeaderType> = [titleType]
                    setCalendarEntries(
                        calendarEntriesResp.length === 0 ? calendarEntriesResp : sectionHeader.concat(calendarEntriesResp)
                    )
                } else {
                    const newCalendarEntries = calendarEntries.concat(calendarEntriesResp)
                    if (calendarEntriesResp.length > 0) newCalendarEntries[0] = titleType
                    setCalendarEntries(newCalendarEntries)
                }
                setNextToken(data.nextToken)
                loadingListType = data.nextToken == null ? ScheduleListSectionType.PAST : ScheduleListSectionType.UPCOMING
                setCurrentLoadingList(loadingListType)
                setHasMorePages(true)
                setIsLoaded(data.nextToken != null)
            }
        }

        if (loadingListType === ScheduleListSectionType.PAST) {
            const pastData = await getCalendarEntries(profileId, CalendarEntrySortType.PAST, currentFilterStatus, nextTokenPast)

            if (pastData == null) {
                // TODO ERROR
            } else {
                // Filter out entries that are shown in the now section
                const calendarEntriesResp = pastData.items
                    .filter((item) => {
                        return (
                            calendarEntriesNowCached.find((calendarEntry) => {
                                if ((calendarEntry as CalendarEntry).id) {
                                    return (calendarEntry as CalendarEntry).id === item.calendarEntry.id
                                }
                                return undefined
                            }) === undefined
                        )
                    })
                    .map((item) => item.calendarEntry)

                if (nextTokenPast == null) {
                    const sectionHeader: Array<CalendarEntry | ScheduleListTabHeaderType> = [ScheduleListTabHeaderType.PAST]
                    setCalendarEntriesPast(
                        calendarEntriesResp.length === 0 ? calendarEntriesResp : sectionHeader.concat(calendarEntriesResp)
                    )
                } else {
                    const newCalendarEntries = calendarEntriesPast.concat(calendarEntriesResp)
                    if (calendarEntriesResp.length > 0) newCalendarEntries[0] = ScheduleListTabHeaderType.PAST
                    setCalendarEntriesPast(newCalendarEntries)
                }

                setNextTokenPast(pastData.nextToken)
                setHasMorePages(pastData.nextToken != null)
                setIsLoaded(true)
            }
        }
        const tmpTimeout = setTimeout(() => {
            setCustomScrollRenderDelay(true)
            clearTimeout(tmpTimeout)
        }, 10)
    }
}
