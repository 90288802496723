import { useState, useEffect } from "react"
import * as React from "react"
import styled from "styled-components"
import { useLanguageState } from "../../globalStates/LanguageState"
import Schedule from "../program/Schedule"
import moment from "moment"
import { getIndexOfInitialDayToShow } from "../../utils/DateUtils"
import branding from "../../branding/branding"
import { useAppState } from "../../globalStates/AppState"
import { loadEventDateDates } from "../../backendServices/EventdateServices"
import { ViewMode } from "../../ui/CrsTabs"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { device } from "../../utils/Device"

const MyScheduleContentRoot = styled.div`
    display: flex;
    height: calc(100vh - 309px);
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    font-family: ${branding.font1};
`

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #fff;
    padding-top: 0;

    .schedule-root {
        height: 100vh;

        @media ${device.mobile} {
            margin-top: 40px;
        }
    }
    .my-schedule-root {
        margin-left: 0 !important;
        box-shadow: 0 2px 2px -2px ${branding.lightThemeHoverColor};
    }

    #round-scrollable-div  {
        width: 100% !important;
        scrollbar-width: auto;
    }

    .verticalBarRoot {
        display: none !important;
    }
`

interface MyScheduleContentProps {
    changeRoute: (route: string) => void
    viewMode: ViewMode
    addBannerHeight?: number
    guestBannerHeight?: number
    reloadScheduleList: boolean
    setReloadScheduleList: (value: boolean) => void
    setItemsCount: (value: number) => void
}

const MyScheduleContent: React.FunctionComponent<MyScheduleContentProps> = (props) => {
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const eventDays = branding.eventTiming.eventDays
    const [selectedDay, setSelectedDay] = useState(0)
    const timezone = useAppState().timezone
    const [days, setDays] = useState<moment.Moment[]>([])
    const windowSize = useWindowDimensions()

    useEffect(() => {
        localStorage.setItem("lobbyMyScheduleDefault", "true")
    }, [])

    useEffect(() => {
        let params = { lang: lang }
        loadEventDateDates(params)
            .then((resp) => {
                if (resp.dates?.length > 0) {
                    let datesTemp: any = []

                    resp.dates.forEach((day) => {
                        datesTemp.push(day)
                    })

                    eventDays.forEach((day) => {
                        datesTemp.push(day)
                    })

                    setDays(datesTemp.map((date: any) => moment(date)))
                }
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setDays(eventDays.map((x) => moment(x)))
            })
    }, [eventDays, lang])

    useEffect(() => {
        setSelectedDay(getIndexOfInitialDayToShow(days, true))
    }, [days, timezone])

    return (
        <MyScheduleContentRoot>
            <ContentContainer>
                <Schedule
                    onlyFavorites={true}
                    listViewMode={props.viewMode === ViewMode.LIST}
                    dateColumns={true}
                    dateColumnsDays={[]}
                    mySchedule={true}
                    roundScroller={true}
                    day={selectedDay}
                    addBannerHeight={windowSize.width < 1700 ? (windowSize.width < 1520 ? 435 : 390) : 370}
                    guestBannerHeight={props.guestBannerHeight || 0}
                    reloadScheduleList={props.reloadScheduleList}
                    setReloadScheduleList={props.setReloadScheduleList}
                    setItemsCount={props.setItemsCount}
                />
            </ContentContainer>
        </MyScheduleContentRoot>
    )
}

export default MyScheduleContent
