import { LocalizedString, ColorString, Branding, Configuration, MeetingDurationType, PresenceConfiguration, EventTiming, PluralString, TopSponsor, TopNews, ConferenceListTiles, BackendErrorBranding, TopicBadge, TopBarBranding, JiraSupportDeskData, TrackingBranding, CSVExportBranding, DropdownStyle, VideoPlayerBranding, VideoPageContentBranding, SocialMediaIconsBranding, TileViewConfig } from "./branding/branding";
import { Lang } from "./branding/BrandingGenerator";
import { GlobalStatePopupTextsBranding } from "./globalStates/GlobalStatePopupTextsBranding";
import { LoginRegistrationSiteBranding, LoginType, InvalidEmailPanelWithLink, PrivacyDocsCheckbox, TicketShopUrlType } from "./contentArea/loginregistration/LoginRegistrationSiteBranding";
import { LoginWithSSOKoelnmesseBranding } from "./contentArea/loginregistration/ssoKoelnmesse/LoginWithSSOKoelnmesseBranding";
import { ErrorSiteBranding } from "./contentArea/errorPages/ErrorSiteBranding";
import { BasisPremiumBranding, BasisPremiumConfig, TileSize, BasisPremiumType } from "./branding/BasisPremiumBranding";
import { CommunicationAreaBranding } from "./communicationArea/CommunicationAreaBranding";
import { ChatBranding } from "./communicationArea/ChatBranding";
import { ContactEntryBranding } from "./communicationArea/ContactEntryBranding";
import { DeviceSwitcherBranding, MeetingRoomGroupType, InfoBox, MeetingRoomType, ConferenceTexts, RosterBranding } from "./conference/AudioVideoBranding";
import { SideIconBarBranding, ExternalLinksBranding } from "./navigationArea/SideIconBar/SideIconBarBranding";
import { NavigationAreaBranding, PresenceTypeNames } from "./navigationArea/NavigationAreaBranding";
import { SponsorsPageContentBranding, MediaPartnerPageContentBranding, ProductsPageContentBranding, NewsPageContentBranding, TrademarksPageContentBranding, ExhibitorsPageContentBranding, DropdownFilterConfigType, FillerTypeRoot, FillerType, SuggestBoxContentBranding, SuggestBoxButtonType, CategoriesPageContentBranding, CategoryConfigType, CategoryTileConfig, BusinessAreaPageContentBranding, HelpPageContentBranding, HelpTileType, HelpLinkType, CouponsBranding, RulesPageContentBranding, RuleType, IFrameTestPageContentBranding, GlobalSearchResultPageBranding, SearchEntityConfig, HallPlanPageBranding } from "./contentArea/ContentAreaPageBranding";
import { CalendarEntryModalBranding } from "./ui/modals/modalBrandings/CalendarEntryModalBranding";
import { GroupConfig, GroupConfigType } from "./backendServices/SuggestionServices";
import { CalendarEntryListViewBranding } from "./communicationArea/CalendarEntryListViewBranding";
import { CalendarEntryExportBranding } from "./communicationArea/CalendarEntryExportBranding";
import { EventDateIcsExportBranding } from "./communicationArea/EventDateIcsExportBranding";
import { MyProfilePageContentBranding, EditTab, Field, SocialMediaType, CategoryType, OffersNeedsCategoryType, BasicInfoLanguage } from "./contentArea/myprofile/MyProfilePageContentBranding";
import { PersonDetailPageContentBranding, MeetingSlotsSectionBranding, OrganizationDetailPageContentBranding, DetailSection, InfoContentOrderType, ProductDetailPageContentBranding, TrademarkDetailPageContentBranding, JobOfferDetailPageContentBranding, NewsDetailPageContentBranding, EventDateDetailPageContentBranding, EventDateDetailSection } from "./contentArea/detailPages/DetailPageBranding";
import { SayHelloModalBranding } from "./ui/modals/modalBrandings/SayHelloModalBranding";
import { FilterBarBranding } from "./ui/FilterBarBranding";
import { BackofficeContentBranding, BackofficeStaffBranding, BackofficeStaffReqBranding, BackofficeMeetingsBranding, BackofficeLVisitorsBranding, VisitorTabType, BackofficeLoungeBranding, BackofficeReportingBranding, StatisticsColumnType } from "./contentArea/backoffice/BackofficeBranding";
import { ProgramPageContentBranding, ProgramScheduleBranding, Stage, Filter, CustomColorByOrganization, ProgramSpeakersBranding } from "./contentArea/program/ProgramBranding";
import { PressMediaPageContentBranding, SwitchTabProps, PressItemBranding, LinkInfo, MagazineItemBranding, PressEventStage } from "./contentArea/pressMedia/PressMediaPageContentBranding";
import { SystemCheckBranding } from "./systemCheck/SystemCheckBranding";
import { ReceptionPageSiteBranding, LobbyExhibitorsBasisPremiumType, TopBarLinkRoot, MySchedulePageBranding, ColorBranding } from "./contentArea/reception/ReceptionPageContentBranding";
import { CollectionPageBranding, CollectionBranding, EntityListWidgetBranding, TextGalleryWidgetBranding, GalleryImageTwoColumnWidgetBranding, GalleryItem, GalleryItemMediaType, GalleryItemLinkType } from "./contentArea/collection/CollectionBranding";
import { SettingsBranding } from "./conference/components/settings/SettingsBranding";
import { ThirdPartyResources, SlidoConfigBranding, SlidoRooms } from "./branding/ThirdPartyResources";
import { CrsMultiSwitcherBranding } from "./ui/CrsMultiSwitchBranding";
import { CrsTabsBranding } from "./ui/CrsTabsBranding";
import { NetworkingAreaBranding, viewType, ZoomChartLicenceObject, Topic } from "./contentArea/networkingGraph/NetworkingPageContentBranding";
import { autosuggestFilterOptions } from "./contentArea/networkingGraph/HelperFunctions";
import { GlobalTextsBranding } from "./globalStates/GlobalTextsBranding";
import { TicketSaleBranding } from "./PreLoginAppBranding";
import { NotificationBranding } from "./conference/context/NotificationContextBranding";
import { RecommendModalBranding } from "./ui/modals/modalBrandings/RecommendModalBranding";
import { TopBannerBranding, BannerPool, BannerBrandingItem } from "./contentArea/advertisingBanner/TopBannerBranding";
import { GuestUserBannerBranding, TopBannerConfig, TopBannerUserTypeConfig } from "./contentArea/guestUserBanner/GuestUserBannerBranding";
import { SettingsTabBranding } from "./communicationArea/settings/SettingsTabBranding";
import { ThirdPartySettingsKoelnmesseBranding } from "./communicationArea/settings/koelnmesse/ThirdPartySettingsKoelnmesseBranding";
import { ConversationEntryBranding } from "./communicationArea/ConversationEntryBranding";
import { CrsTimePickerBranding } from "./ui/CrsTimePicker/CrsTimePickerBranding";
import { ContextMenuBranding, ContextMenuItem } from "./ui/ContextMenuBranding";
import { AvatarBranding } from "./ui/AvatarBranding";
import { PagesUsingTileView } from "./globalStates/TileViewConfig";

export class BrandingData {
    protected lang: Lang;
    protected jsonData: any;
    protected subBrandings: BrandingData[] = [];

    constructor(jsonData: any, lang: Lang) {
        this.jsonData = jsonData
        this.lang = lang
    }

    setLang(lang: Lang) {
        this.lang = lang
        this.subBrandings.forEach(subBranding => subBranding.setLang(lang))
    }
}

export class BrandingImpl extends BrandingData implements Branding {
    _configuration!: Configuration;
    _presenceConfiguration!: PresenceConfiguration;
    _eventTiming!: EventTiming;
    _greenroomGoLiveFollowupDelaySec!: number;
    _pageTitle!: string;
    _ticketSalePhasePageTitle!: LocalizedString;
    _ticketSalePhaseHeaderTitleFontSize!: string;
    _availableLanguages!: string[];
    _highlightSelectedLanguage!: boolean;
    _globalStatePopupTexts!: GlobalStatePopupTextsBranding;
    _loginRegistrationSite!: LoginRegistrationSiteBranding;
    _errorSite!: ErrorSiteBranding;
    _basisPremium!: BasisPremiumBranding;
    _communicationArea!: CommunicationAreaBranding;
    _chatBranding!: ChatBranding;
    _contactEntry!: ContactEntryBranding;
    _deviceSwitcher!: DeviceSwitcherBranding;
    _sideIconBar!: SideIconBarBranding;
    _navigationArea!: NavigationAreaBranding;
    _sponsorsPageContent!: SponsorsPageContentBranding;
    _mediaPartnerPageContent!: MediaPartnerPageContentBranding;
    _calendarEntryModalPageContent!: CalendarEntryModalBranding;
    _calendarEntryListView!: CalendarEntryListViewBranding;
    _calendarEntryExport!: CalendarEntryExportBranding;
    _eventDateIcsExport!: EventDateIcsExportBranding;
    _myProfilePageContent!: MyProfilePageContentBranding;
    _personDetailPageContent!: PersonDetailPageContentBranding;
    _sayHelloModal!: SayHelloModalBranding;
    _filterBar!: FilterBarBranding;
    _organizationDetailPageContent!: OrganizationDetailPageContentBranding;
    _backofficeContent!: BackofficeContentBranding;
    _backofficeStaff!: BackofficeStaffBranding;
    _backofficeStaffReq!: BackofficeStaffReqBranding;
    _backofficeMeetings!: BackofficeMeetingsBranding;
    _backofficeVisitors!: BackofficeLVisitorsBranding;
    _backofficeLounge!: BackofficeLoungeBranding;
    _backofficeReporting!: BackofficeReportingBranding;
    _productDetailPageContent!: ProductDetailPageContentBranding;
    _trademarkDetailPageContent!: TrademarkDetailPageContentBranding;
    _jobofferDetailPageContent!: JobOfferDetailPageContentBranding;
    _newsDetailPageContent!: NewsDetailPageContentBranding;
    _eventDateDetailPageContent!: EventDateDetailPageContentBranding;
    _productsPageContent!: ProductsPageContentBranding;
    _newsPageContent!: NewsPageContentBranding;
    _trademarksPageContent!: TrademarksPageContentBranding;
    _exhibitorsPageContent!: ExhibitorsPageContentBranding;
    _suggestBoxContent!: SuggestBoxContentBranding;
    _categoriesPageContent!: CategoriesPageContentBranding;
    _programPageContent!: ProgramPageContentBranding;
    _programSchedule!: ProgramScheduleBranding;
    _programSpeakers!: ProgramSpeakersBranding;
    _pressMediaPageContent!: PressMediaPageContentBranding;
    _businessAreaPageContent!: BusinessAreaPageContentBranding;
    _systemCheck!: SystemCheckBranding;
    _receptionPage!: ReceptionPageSiteBranding;
    _collectionBranding!: CollectionPageBranding;
    _meetingRoomMaxTiles!: number;
    _meetingRoomMarginRight!: string;
    _liveTileMargins!: string;
    _meetingRoomGroupsDisablePictureOverlay!: boolean;
    _meetingRoomGroupsOverlayValue!: string;
    _meetingRoomGroupsTitleFontStyle!: string;
    _meetingRoomGroupsTitleFontSize!: string;
    _meetingRoomGroupsHosterFontStyle!: string;
    _meetingRoomGroupsHosterFontSize!: string;
    _meetingRoomGroupsDescriptionFontStyle!: string;
    _meetingRoomGroupsDescriptionFontSize!: string;
    _meetingRoomGroupsRequestAccessButtonBgColorOnHover!: ColorString;
    _meetingRoomGroups!: MeetingRoomGroupType[];
    _audioVideoSettings!: SettingsBranding;
    _conferenceTexts!: ConferenceTexts;
    _conferenceListTiles!: ConferenceListTiles;
    _thirdPartyResources!: ThirdPartyResources[];
    _theme1Color!: string;
    _theme2Color!: string;
    _hasConnectWithAllParticipants!: boolean;
    _primaryColor!: ColorString;
    _placeholderFocusedColor!: ColorString;
    _placeholderUnFocusedColor!: ColorString;
    _inputFocusedColor!: ColorString;
    _inputUnFocusedColor!: ColorString;
    _inputFocusedBorderColor!: ColorString;
    _inputUnFocusedBorderColor!: ColorString;
    _secondaryColor!: ColorString;
    _activeIconColor!: ColorString;
    _navBarActiveIconColor!: ColorString;
    _mainInfoColor!: ColorString;
    _mainInfoDarkColor!: ColorString;
    _chatListColor!: ColorString;
    _chatListBackgroundCommunicationAreaColor!: ColorString;
    _chatInputColor!: ColorString;
    _chatInputColorTime!: ColorString;
    _chatDarkenInputColor!: ColorString;
    _darkenOverlayColor!: string;
    _communicationAreaSearchPlaceholderColor!: ColorString;
    _communicationAreaDefaultAvatarColor!: ColorString;
    _communicationAreaAudioVideoSettingsActiveColor!: ColorString;
    _communicationAreaAudioVideoSettingsLabel!: LocalizedString;
    _communicationAreaAudioVideoSettingsNotAvailableLogoMessage!: LocalizedString;
    _communicationAreaAudioVideoSettingsNotAvailableBackgroundMessage!: LocalizedString;
    _communicationAreaAudioVideoSettingsNotAvailablePreviewMessage!: LocalizedString;
    _communicationAreaAudioVideoSettingsVideoPermissionMessage!: LocalizedString;
    _communicationAreaAudioVideoSettingsAudioPermissionMessage!: LocalizedString;
    _secondaryTextColor!: string;
    _darkenThemeBackgroundColor!: ColorString;
    _darkenThemeHoverColor!: string;
    _lightThemeHoverColor!: string;
    _theme3ColorPrimary!: string;
    _theme3ColorSecondary!: string;
    _crsMultiSwitcher!: CrsMultiSwitcherBranding;
    _crsTabs!: CrsTabsBranding;
    _helpPage!: HelpPageContentBranding;
    _video1!: string;
    _video2!: string;
    _video3!: string;
    _font1!: string;
    _font2!: string;
    _loaderBgColor!: string;
    _loaderSpinColor!: ColorString;
    _customScrollbarBgColor!: ColorString;
    _customScrollbarTrackBgColor!: ColorString;
    _customScrollbarBorderColor!: string;
    _customScrollbarWidth!: string;
    _customScrollbarHeight!: string;
    _dangerButtonColor!: ColorString;
    _networkingArea!: NetworkingAreaBranding;
    _filterButtonColor!: ColorString;
    _globalTexts!: GlobalTextsBranding;
    _ticketSale!: TicketSaleBranding;
    _notification!: NotificationBranding;
    _backendError!: BackendErrorBranding;
    _recommendModal!: RecommendModalBranding;
    _topSponsors!: TopSponsor[];
    _ideaSponsors!: TopSponsor[];
    _topicBadges!: TopicBadge[];
    _categoryBadges!: string[];
    _categoryBadgesVisible!: boolean;
    _categoryBadgesShowfloorTextSize!: string;
    _press!: LocalizedString;
    _coupons!: CouponsBranding;
    _topBanner!: TopBannerBranding;
    _guestUserBanner!: GuestUserBannerBranding;
    _topBannerConfig!: TopBannerConfig;
    _rulesPage!: RulesPageContentBranding;
    _topBar!: TopBarBranding;
    _roster!: RosterBranding;
    _settingsTab!: SettingsTabBranding;
    _mySchedule!: MySchedulePageBranding;
    _hubspotScriptTag!: string;
    _gtmNoScript!: string;
    _gtmScriptDataLayer!: string;
    _gtmTagScript!: string;
    _gtmFairNumber!: string;
    _gtmFairHeartbeatEventMinutes!: number;
    _matomoScript!: string;
    _cookieOneTrustScript!: string;
    _usercentrixScript!: string;
    _jiraSupportDeskData!: JiraSupportDeskData;
    _showPersonNameInUrl!: boolean;
    _videoBackground!: ColorString;
    _showroomMeetingDuration!: number;
    _breakoutTimeoutDuration!: number;
    _defaultToggleIcon!: boolean;
    _conversationEntry!: ConversationEntryBranding;
    _globalOptIn!: boolean;
    _trackingBrandingNone!: TrackingBranding;
    _trackingBrandingBasic!: TrackingBranding;
    _trackingBrandingStandard!: TrackingBranding;
    _trackingBrandingPremium!: TrackingBranding;
    _trackingBrandingUltimate!: TrackingBranding;
    _csvExportBranding!: CSVExportBranding;
    _timePickerBranding!: CrsTimePickerBranding;
    _contextMenuBranding!: ContextMenuBranding;
    _dropdownStyle!: DropdownStyle;
    _iframeTest!: IFrameTestPageContentBranding;
    _globalSearchResultPage!: GlobalSearchResultPageBranding;
    _videoPlayerBranding!: VideoPlayerBranding;
    _videoPageContentBranding!: VideoPageContentBranding;
    _searchBarFontFamily!: string;
    _searchBarFontSize!: string;
    _searchBarLetterSpacing!: string;
    _searchBarFontWeight!: string;
    _searchBarTextTransform!: string;
    _mainRootCategories!: string[];
    _mainBorder!: string;
    _listDividerColor!: string;
    _mainIconsColor!: string;
    _primaryScrollWhiteShadowLTR!: string;
    _primaryScrollWhiteShadowTTB!: string;
    _horizontalScrollShadowBlur!: string;
    _primaryScrollDarkShadowTTB!: string;
    _primaryScrollDarkShadowBTT!: string;
    _primaryScrollDarkShadowTTT!: string;
    _primaryScrollDarkShadowBTB!: string;
    _socialMediaIcons!: SocialMediaIconsBranding;
    _avatarBranding!: AvatarBranding;
    _tileViewConfig!: TileViewConfig[];
    _hallPlanPageBranding!: HallPlanPageBranding;

    get configuration() {
        if (!this._configuration) {
            this._configuration = new ConfigurationImpl(this.jsonData["configuration"], this.lang)
            this.subBrandings.push(this._configuration as any as BrandingData)
        }
        return this._configuration
    }

    get presenceConfiguration() {
        if (!this._presenceConfiguration) {
            this._presenceConfiguration = new PresenceConfigurationImpl(this.jsonData["presenceConfiguration"], this.lang)
            this.subBrandings.push(this._presenceConfiguration as any as BrandingData)
        }
        return this._presenceConfiguration
    }

    get eventTiming() {
        if (!this._eventTiming) {
            this._eventTiming = new EventTimingImpl(this.jsonData["eventTiming"], this.lang)
            this.subBrandings.push(this._eventTiming as any as BrandingData)
        }
        return this._eventTiming
    }

    get greenroomGoLiveFollowupDelaySec() {
        return this.jsonData["greenroomGoLiveFollowupDelaySec"]
    }

    get pageTitle() {
        return this.jsonData["pageTitle"]
    }

    get ticketSalePhasePageTitle() {
        return this.jsonData["ticketSalePhasePageTitle"][this.lang]
    }

    get ticketSalePhaseHeaderTitleFontSize() {
        return this.jsonData["ticketSalePhaseHeaderTitleFontSize"]
    }

    get availableLanguages() {
        return this.jsonData["availableLanguages"]
    }

    get highlightSelectedLanguage() {
        return this.jsonData["highlightSelectedLanguage"]
    }

    get globalStatePopupTexts() {
        if (!this._globalStatePopupTexts) {
            this._globalStatePopupTexts = new GlobalStatePopupTextsBrandingImpl(this.jsonData["globalStatePopupTexts"], this.lang)
            this.subBrandings.push(this._globalStatePopupTexts as any as BrandingData)
        }
        return this._globalStatePopupTexts
    }

    get loginRegistrationSite() {
        if (!this._loginRegistrationSite) {
            this._loginRegistrationSite = new LoginRegistrationSiteBrandingImpl(this.jsonData["loginRegistrationSite"], this.lang)
            this.subBrandings.push(this._loginRegistrationSite as any as BrandingData)
        }
        return this._loginRegistrationSite
    }

    get errorSite() {
        if (!this._errorSite) {
            this._errorSite = new ErrorSiteBrandingImpl(this.jsonData["errorSite"], this.lang)
            this.subBrandings.push(this._errorSite as any as BrandingData)
        }
        return this._errorSite
    }

    get basisPremium() {
        if (!this._basisPremium) {
            this._basisPremium = new BasisPremiumBrandingImpl(this.jsonData["basisPremium"], this.lang)
            this.subBrandings.push(this._basisPremium as any as BrandingData)
        }
        return this._basisPremium
    }

    get communicationArea() {
        if (!this._communicationArea) {
            this._communicationArea = new CommunicationAreaBrandingImpl(this.jsonData["communicationArea"], this.lang)
            this.subBrandings.push(this._communicationArea as any as BrandingData)
        }
        return this._communicationArea
    }

    get chatBranding() {
        if (!this._chatBranding) {
            this._chatBranding = new ChatBrandingImpl(this.jsonData["chatBranding"], this.lang)
            this.subBrandings.push(this._chatBranding as any as BrandingData)
        }
        return this._chatBranding
    }

    get contactEntry() {
        if (!this._contactEntry) {
            this._contactEntry = new ContactEntryBrandingImpl(this.jsonData["contactEntry"], this.lang)
            this.subBrandings.push(this._contactEntry as any as BrandingData)
        }
        return this._contactEntry
    }

    get deviceSwitcher() {
        if (!this._deviceSwitcher) {
            this._deviceSwitcher = new DeviceSwitcherBrandingImpl(this.jsonData["deviceSwitcher"], this.lang)
            this.subBrandings.push(this._deviceSwitcher as any as BrandingData)
        }
        return this._deviceSwitcher
    }

    get sideIconBar() {
        if (!this._sideIconBar) {
            this._sideIconBar = new SideIconBarBrandingImpl(this.jsonData["sideIconBar"], this.lang)
            this.subBrandings.push(this._sideIconBar as any as BrandingData)
        }
        return this._sideIconBar
    }

    get navigationArea() {
        if (!this._navigationArea) {
            this._navigationArea = new NavigationAreaBrandingImpl(this.jsonData["navigationArea"], this.lang)
            this.subBrandings.push(this._navigationArea as any as BrandingData)
        }
        return this._navigationArea
    }

    get sponsorsPageContent() {
        if (!this._sponsorsPageContent) {
            this._sponsorsPageContent = new SponsorsPageContentBrandingImpl(this.jsonData["sponsorsPageContent"], this.lang)
            this.subBrandings.push(this._sponsorsPageContent as any as BrandingData)
        }
        return this._sponsorsPageContent
    }

    get mediaPartnerPageContent() {
        if (!this._mediaPartnerPageContent) {
            this._mediaPartnerPageContent = new MediaPartnerPageContentBrandingImpl(this.jsonData["mediaPartnerPageContent"], this.lang)
            this.subBrandings.push(this._mediaPartnerPageContent as any as BrandingData)
        }
        return this._mediaPartnerPageContent
    }

    get calendarEntryModalPageContent() {
        if (!this._calendarEntryModalPageContent) {
            this._calendarEntryModalPageContent = new CalendarEntryModalBrandingImpl(this.jsonData["calendarEntryModalPageContent"], this.lang)
            this.subBrandings.push(this._calendarEntryModalPageContent as any as BrandingData)
        }
        return this._calendarEntryModalPageContent
    }

    get calendarEntryListView() {
        if (!this._calendarEntryListView) {
            this._calendarEntryListView = new CalendarEntryListViewBrandingImpl(this.jsonData["calendarEntryListView"], this.lang)
            this.subBrandings.push(this._calendarEntryListView as any as BrandingData)
        }
        return this._calendarEntryListView
    }

    get calendarEntryExport() {
        if (!this._calendarEntryExport) {
            this._calendarEntryExport = new CalendarEntryExportBrandingImpl(this.jsonData["calendarEntryExport"], this.lang)
            this.subBrandings.push(this._calendarEntryExport as any as BrandingData)
        }
        return this._calendarEntryExport
    }

    get eventDateIcsExport() {
        if (!this._eventDateIcsExport) {
            this._eventDateIcsExport = new EventDateIcsExportBrandingImpl(this.jsonData["eventDateIcsExport"], this.lang)
            this.subBrandings.push(this._eventDateIcsExport as any as BrandingData)
        }
        return this._eventDateIcsExport
    }

    get myProfilePageContent() {
        if (!this._myProfilePageContent) {
            this._myProfilePageContent = new MyProfilePageContentBrandingImpl(this.jsonData["myProfilePageContent"], this.lang)
            this.subBrandings.push(this._myProfilePageContent as any as BrandingData)
        }
        return this._myProfilePageContent
    }

    get personDetailPageContent() {
        if (!this._personDetailPageContent) {
            this._personDetailPageContent = new PersonDetailPageContentBrandingImpl(this.jsonData["personDetailPageContent"], this.lang)
            this.subBrandings.push(this._personDetailPageContent as any as BrandingData)
        }
        return this._personDetailPageContent
    }

    get sayHelloModal() {
        if (!this._sayHelloModal) {
            this._sayHelloModal = new SayHelloModalBrandingImpl(this.jsonData["sayHelloModal"], this.lang)
            this.subBrandings.push(this._sayHelloModal as any as BrandingData)
        }
        return this._sayHelloModal
    }

    get filterBar() {
        if (!this._filterBar) {
            this._filterBar = new FilterBarBrandingImpl(this.jsonData["filterBar"], this.lang)
            this.subBrandings.push(this._filterBar as any as BrandingData)
        }
        return this._filterBar
    }

    get organizationDetailPageContent() {
        if (!this._organizationDetailPageContent) {
            this._organizationDetailPageContent = new OrganizationDetailPageContentBrandingImpl(this.jsonData["organizationDetailPageContent"], this.lang)
            this.subBrandings.push(this._organizationDetailPageContent as any as BrandingData)
        }
        return this._organizationDetailPageContent
    }

    get backofficeContent() {
        if (!this._backofficeContent) {
            this._backofficeContent = new BackofficeContentBrandingImpl(this.jsonData["backofficeContent"], this.lang)
            this.subBrandings.push(this._backofficeContent as any as BrandingData)
        }
        return this._backofficeContent
    }

    get backofficeStaff() {
        if (!this._backofficeStaff) {
            this._backofficeStaff = new BackofficeStaffBrandingImpl(this.jsonData["backofficeStaff"], this.lang)
            this.subBrandings.push(this._backofficeStaff as any as BrandingData)
        }
        return this._backofficeStaff
    }

    get backofficeStaffReq() {
        if (!this._backofficeStaffReq) {
            this._backofficeStaffReq = new BackofficeStaffReqBrandingImpl(this.jsonData["backofficeStaffReq"], this.lang)
            this.subBrandings.push(this._backofficeStaffReq as any as BrandingData)
        }
        return this._backofficeStaffReq
    }

    get backofficeMeetings() {
        if (!this._backofficeMeetings) {
            this._backofficeMeetings = new BackofficeMeetingsBrandingImpl(this.jsonData["backofficeMeetings"], this.lang)
            this.subBrandings.push(this._backofficeMeetings as any as BrandingData)
        }
        return this._backofficeMeetings
    }

    get backofficeVisitors() {
        if (!this._backofficeVisitors) {
            this._backofficeVisitors = new BackofficeLVisitorsBrandingImpl(this.jsonData["backofficeVisitors"], this.lang)
            this.subBrandings.push(this._backofficeVisitors as any as BrandingData)
        }
        return this._backofficeVisitors
    }

    get backofficeLounge() {
        if (!this._backofficeLounge) {
            this._backofficeLounge = new BackofficeLoungeBrandingImpl(this.jsonData["backofficeLounge"], this.lang)
            this.subBrandings.push(this._backofficeLounge as any as BrandingData)
        }
        return this._backofficeLounge
    }

    get backofficeReporting() {
        if (!this._backofficeReporting) {
            this._backofficeReporting = new BackofficeReportingBrandingImpl(this.jsonData["backofficeReporting"], this.lang)
            this.subBrandings.push(this._backofficeReporting as any as BrandingData)
        }
        return this._backofficeReporting
    }

    get productDetailPageContent() {
        if (!this._productDetailPageContent) {
            this._productDetailPageContent = new ProductDetailPageContentBrandingImpl(this.jsonData["productDetailPageContent"], this.lang)
            this.subBrandings.push(this._productDetailPageContent as any as BrandingData)
        }
        return this._productDetailPageContent
    }

    get trademarkDetailPageContent() {
        if (!this._trademarkDetailPageContent) {
            this._trademarkDetailPageContent = new TrademarkDetailPageContentBrandingImpl(this.jsonData["trademarkDetailPageContent"], this.lang)
            this.subBrandings.push(this._trademarkDetailPageContent as any as BrandingData)
        }
        return this._trademarkDetailPageContent
    }

    get jobofferDetailPageContent() {
        if (!this._jobofferDetailPageContent) {
            this._jobofferDetailPageContent = new JobOfferDetailPageContentBrandingImpl(this.jsonData["jobofferDetailPageContent"], this.lang)
            this.subBrandings.push(this._jobofferDetailPageContent as any as BrandingData)
        }
        return this._jobofferDetailPageContent
    }

    get newsDetailPageContent() {
        if (!this._newsDetailPageContent) {
            this._newsDetailPageContent = new NewsDetailPageContentBrandingImpl(this.jsonData["newsDetailPageContent"], this.lang)
            this.subBrandings.push(this._newsDetailPageContent as any as BrandingData)
        }
        return this._newsDetailPageContent
    }

    get eventDateDetailPageContent() {
        if (!this._eventDateDetailPageContent) {
            this._eventDateDetailPageContent = new EventDateDetailPageContentBrandingImpl(this.jsonData["eventDateDetailPageContent"], this.lang)
            this.subBrandings.push(this._eventDateDetailPageContent as any as BrandingData)
        }
        return this._eventDateDetailPageContent
    }

    get productsPageContent() {
        if (!this._productsPageContent) {
            this._productsPageContent = new ProductsPageContentBrandingImpl(this.jsonData["productsPageContent"], this.lang)
            this.subBrandings.push(this._productsPageContent as any as BrandingData)
        }
        return this._productsPageContent
    }

    get newsPageContent() {
        if (!this._newsPageContent) {
            this._newsPageContent = new NewsPageContentBrandingImpl(this.jsonData["newsPageContent"], this.lang)
            this.subBrandings.push(this._newsPageContent as any as BrandingData)
        }
        return this._newsPageContent
    }

    get trademarksPageContent() {
        if (!this._trademarksPageContent) {
            this._trademarksPageContent = new TrademarksPageContentBrandingImpl(this.jsonData["trademarksPageContent"], this.lang)
            this.subBrandings.push(this._trademarksPageContent as any as BrandingData)
        }
        return this._trademarksPageContent
    }

    get exhibitorsPageContent() {
        if (!this._exhibitorsPageContent) {
            this._exhibitorsPageContent = new ExhibitorsPageContentBrandingImpl(this.jsonData["exhibitorsPageContent"], this.lang)
            this.subBrandings.push(this._exhibitorsPageContent as any as BrandingData)
        }
        return this._exhibitorsPageContent
    }

    get suggestBoxContent() {
        if (!this._suggestBoxContent) {
            this._suggestBoxContent = new SuggestBoxContentBrandingImpl(this.jsonData["suggestBoxContent"], this.lang)
            this.subBrandings.push(this._suggestBoxContent as any as BrandingData)
        }
        return this._suggestBoxContent
    }

    get categoriesPageContent() {
        if (!this._categoriesPageContent) {
            this._categoriesPageContent = new CategoriesPageContentBrandingImpl(this.jsonData["categoriesPageContent"], this.lang)
            this.subBrandings.push(this._categoriesPageContent as any as BrandingData)
        }
        return this._categoriesPageContent
    }

    get programPageContent() {
        if (!this._programPageContent) {
            this._programPageContent = new ProgramPageContentBrandingImpl(this.jsonData["programPageContent"], this.lang)
            this.subBrandings.push(this._programPageContent as any as BrandingData)
        }
        return this._programPageContent
    }

    get programSchedule() {
        if (!this._programSchedule) {
            this._programSchedule = new ProgramScheduleBrandingImpl(this.jsonData["programSchedule"], this.lang)
            this.subBrandings.push(this._programSchedule as any as BrandingData)
        }
        return this._programSchedule
    }

    get programSpeakers() {
        if (!this._programSpeakers) {
            this._programSpeakers = new ProgramSpeakersBrandingImpl(this.jsonData["programSpeakers"], this.lang)
            this.subBrandings.push(this._programSpeakers as any as BrandingData)
        }
        return this._programSpeakers
    }

    get pressMediaPageContent() {
        if (!this._pressMediaPageContent) {
            this._pressMediaPageContent = new PressMediaPageContentBrandingImpl(this.jsonData["pressMediaPageContent"], this.lang)
            this.subBrandings.push(this._pressMediaPageContent as any as BrandingData)
        }
        return this._pressMediaPageContent
    }

    get businessAreaPageContent() {
        if (!this._businessAreaPageContent) {
            this._businessAreaPageContent = new BusinessAreaPageContentBrandingImpl(this.jsonData["businessAreaPageContent"], this.lang)
            this.subBrandings.push(this._businessAreaPageContent as any as BrandingData)
        }
        return this._businessAreaPageContent
    }

    get systemCheck() {
        if (!this._systemCheck) {
            this._systemCheck = new SystemCheckBrandingImpl(this.jsonData["systemCheck"], this.lang)
            this.subBrandings.push(this._systemCheck as any as BrandingData)
        }
        return this._systemCheck
    }

    get receptionPage() {
        if (!this._receptionPage) {
            this._receptionPage = new ReceptionPageSiteBrandingImpl(this.jsonData["receptionPage"], this.lang)
            this.subBrandings.push(this._receptionPage as any as BrandingData)
        }
        return this._receptionPage
    }

    get collectionBranding() {
        if (!this._collectionBranding) {
            this._collectionBranding = new CollectionPageBrandingImpl(this.jsonData["collectionBranding"], this.lang)
            this.subBrandings.push(this._collectionBranding as any as BrandingData)
        }
        return this._collectionBranding
    }

    get meetingRoomMaxTiles() {
        return this.jsonData["meetingRoomMaxTiles"]
    }

    get meetingRoomMarginRight() {
        return this.jsonData["meetingRoomMarginRight"]
    }

    get liveTileMargins() {
        return this.jsonData["liveTileMargins"]
    }

    get meetingRoomGroupsDisablePictureOverlay() {
        return this.jsonData["meetingRoomGroupsDisablePictureOverlay"]
    }

    get meetingRoomGroupsOverlayValue() {
        return this.jsonData["meetingRoomGroupsOverlayValue"]
    }

    get meetingRoomGroupsTitleFontStyle() {
        return this.jsonData["meetingRoomGroupsTitleFontStyle"]
    }

    get meetingRoomGroupsTitleFontSize() {
        return this.jsonData["meetingRoomGroupsTitleFontSize"]
    }

    get meetingRoomGroupsHosterFontStyle() {
        return this.jsonData["meetingRoomGroupsHosterFontStyle"]
    }

    get meetingRoomGroupsHosterFontSize() {
        return this.jsonData["meetingRoomGroupsHosterFontSize"]
    }

    get meetingRoomGroupsDescriptionFontStyle() {
        return this.jsonData["meetingRoomGroupsDescriptionFontStyle"]
    }

    get meetingRoomGroupsDescriptionFontSize() {
        return this.jsonData["meetingRoomGroupsDescriptionFontSize"]
    }

    get meetingRoomGroupsRequestAccessButtonBgColorOnHover() {
        return this.jsonData["meetingRoomGroupsRequestAccessButtonBgColorOnHover"]
    }

    get meetingRoomGroups() {
        if (!this._meetingRoomGroups) {
            this._meetingRoomGroups = []
            for (const arrayElement of this.jsonData["meetingRoomGroups"]) {
                const arrayObject = new MeetingRoomGroupTypeImpl(arrayElement, this.lang)
                this.meetingRoomGroups.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._meetingRoomGroups
    }

    get audioVideoSettings() {
        if (!this._audioVideoSettings) {
            this._audioVideoSettings = new SettingsBrandingImpl(this.jsonData["audioVideoSettings"], this.lang)
            this.subBrandings.push(this._audioVideoSettings as any as BrandingData)
        }
        return this._audioVideoSettings
    }

    get conferenceTexts() {
        if (!this._conferenceTexts) {
            this._conferenceTexts = new ConferenceTextsImpl(this.jsonData["conferenceTexts"], this.lang)
            this.subBrandings.push(this._conferenceTexts as any as BrandingData)
        }
        return this._conferenceTexts
    }

    get conferenceListTiles() {
        if (!this._conferenceListTiles) {
            this._conferenceListTiles = new ConferenceListTilesImpl(this.jsonData["conferenceListTiles"], this.lang)
            this.subBrandings.push(this._conferenceListTiles as any as BrandingData)
        }
        return this._conferenceListTiles
    }

    get thirdPartyResources() {
        if (!this._thirdPartyResources) {
            this._thirdPartyResources = []
            for (const arrayElement of this.jsonData["thirdPartyResources"]) {
                const arrayObject = new ThirdPartyResourcesImpl(arrayElement, this.lang)
                this.thirdPartyResources.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._thirdPartyResources
    }

    get theme1Color() {
        return this.jsonData["theme1Color"]
    }

    get theme2Color() {
        return this.jsonData["theme2Color"]
    }

    get hasConnectWithAllParticipants() {
        return this.jsonData["hasConnectWithAllParticipants"]
    }

    get primaryColor() {
        return this.jsonData["primaryColor"]
    }

    get placeholderFocusedColor() {
        return this.jsonData["placeholderFocusedColor"]
    }

    get placeholderUnFocusedColor() {
        return this.jsonData["placeholderUnFocusedColor"]
    }

    get inputFocusedColor() {
        return this.jsonData["inputFocusedColor"]
    }

    get inputUnFocusedColor() {
        return this.jsonData["inputUnFocusedColor"]
    }

    get inputFocusedBorderColor() {
        return this.jsonData["inputFocusedBorderColor"]
    }

    get inputUnFocusedBorderColor() {
        return this.jsonData["inputUnFocusedBorderColor"]
    }

    get secondaryColor() {
        return this.jsonData["secondaryColor"]
    }

    get activeIconColor() {
        return this.jsonData["activeIconColor"]
    }

    get navBarActiveIconColor() {
        return this.jsonData["navBarActiveIconColor"]
    }

    get mainInfoColor() {
        return this.jsonData["mainInfoColor"]
    }

    get mainInfoDarkColor() {
        return this.jsonData["mainInfoDarkColor"]
    }

    get chatListColor() {
        return this.jsonData["chatListColor"]
    }

    get chatListBackgroundCommunicationAreaColor() {
        return this.jsonData["chatListBackgroundCommunicationAreaColor"]
    }

    get chatInputColor() {
        return this.jsonData["chatInputColor"]
    }

    get chatInputColorTime() {
        return this.jsonData["chatInputColorTime"]
    }

    get chatDarkenInputColor() {
        return this.jsonData["chatDarkenInputColor"]
    }

    get darkenOverlayColor() {
        return this.jsonData["darkenOverlayColor"]
    }

    get communicationAreaSearchPlaceholderColor() {
        return this.jsonData["communicationAreaSearchPlaceholderColor"]
    }

    get communicationAreaDefaultAvatarColor() {
        return this.jsonData["communicationAreaDefaultAvatarColor"]
    }

    get communicationAreaAudioVideoSettingsActiveColor() {
        return this.jsonData["communicationAreaAudioVideoSettingsActiveColor"]
    }

    get communicationAreaAudioVideoSettingsLabel() {
        return this.jsonData["communicationAreaAudioVideoSettingsLabel"][this.lang]
    }

    get communicationAreaAudioVideoSettingsNotAvailableLogoMessage() {
        return this.jsonData["communicationAreaAudioVideoSettingsNotAvailableLogoMessage"][this.lang]
    }

    get communicationAreaAudioVideoSettingsNotAvailableBackgroundMessage() {
        return this.jsonData["communicationAreaAudioVideoSettingsNotAvailableBackgroundMessage"][this.lang]
    }

    get communicationAreaAudioVideoSettingsNotAvailablePreviewMessage() {
        return this.jsonData["communicationAreaAudioVideoSettingsNotAvailablePreviewMessage"][this.lang]
    }

    get communicationAreaAudioVideoSettingsVideoPermissionMessage() {
        return this.jsonData["communicationAreaAudioVideoSettingsVideoPermissionMessage"][this.lang]
    }

    get communicationAreaAudioVideoSettingsAudioPermissionMessage() {
        return this.jsonData["communicationAreaAudioVideoSettingsAudioPermissionMessage"][this.lang]
    }

    get secondaryTextColor() {
        return this.jsonData["secondaryTextColor"]
    }

    get darkenThemeBackgroundColor() {
        return this.jsonData["darkenThemeBackgroundColor"]
    }

    get darkenThemeHoverColor() {
        return this.jsonData["darkenThemeHoverColor"]
    }

    get lightThemeHoverColor() {
        return this.jsonData["lightThemeHoverColor"]
    }

    get theme3ColorPrimary() {
        return this.jsonData["theme3ColorPrimary"]
    }

    get theme3ColorSecondary() {
        return this.jsonData["theme3ColorSecondary"]
    }

    get crsMultiSwitcher() {
        if (!this._crsMultiSwitcher) {
            this._crsMultiSwitcher = new CrsMultiSwitcherBrandingImpl(this.jsonData["crsMultiSwitcher"], this.lang)
            this.subBrandings.push(this._crsMultiSwitcher as any as BrandingData)
        }
        return this._crsMultiSwitcher
    }

    get crsTabs() {
        if (!this._crsTabs) {
            this._crsTabs = new CrsTabsBrandingImpl(this.jsonData["crsTabs"], this.lang)
            this.subBrandings.push(this._crsTabs as any as BrandingData)
        }
        return this._crsTabs
    }

    get helpPage() {
        if (!this._helpPage) {
            this._helpPage = new HelpPageContentBrandingImpl(this.jsonData["helpPage"], this.lang)
            this.subBrandings.push(this._helpPage as any as BrandingData)
        }
        return this._helpPage
    }

    get video1() {
        return this.jsonData["video1"]
    }

    get video2() {
        return this.jsonData["video2"]
    }

    get video3() {
        return this.jsonData["video3"]
    }

    get font1() {
        return this.jsonData["font1"]
    }

    get font2() {
        return this.jsonData["font2"]
    }

    get loaderBgColor() {
        return this.jsonData["loaderBgColor"]
    }

    get loaderSpinColor() {
        return this.jsonData["loaderSpinColor"]
    }

    get customScrollbarBgColor() {
        return this.jsonData["customScrollbarBgColor"]
    }

    get customScrollbarTrackBgColor() {
        return this.jsonData["customScrollbarTrackBgColor"]
    }

    get customScrollbarBorderColor() {
        return this.jsonData["customScrollbarBorderColor"]
    }

    get customScrollbarWidth() {
        return this.jsonData["customScrollbarWidth"]
    }

    get customScrollbarHeight() {
        return this.jsonData["customScrollbarHeight"]
    }

    get dangerButtonColor() {
        return this.jsonData["dangerButtonColor"]
    }

    get networkingArea() {
        if (!this._networkingArea) {
            this._networkingArea = new NetworkingAreaBrandingImpl(this.jsonData["networkingArea"], this.lang)
            this.subBrandings.push(this._networkingArea as any as BrandingData)
        }
        return this._networkingArea
    }

    get filterButtonColor() {
        return this.jsonData["filterButtonColor"]
    }

    get globalTexts() {
        if (!this._globalTexts) {
            this._globalTexts = new GlobalTextsBrandingImpl(this.jsonData["globalTexts"], this.lang)
            this.subBrandings.push(this._globalTexts as any as BrandingData)
        }
        return this._globalTexts
    }

    get ticketSale() {
        if (!this._ticketSale) {
            this._ticketSale = new TicketSaleBrandingImpl(this.jsonData["ticketSale"], this.lang)
            this.subBrandings.push(this._ticketSale as any as BrandingData)
        }
        return this._ticketSale
    }

    get notification() {
        if (!this._notification) {
            this._notification = new NotificationBrandingImpl(this.jsonData["notification"], this.lang)
            this.subBrandings.push(this._notification as any as BrandingData)
        }
        return this._notification
    }

    get backendError() {
        if (!this._backendError) {
            this._backendError = new BackendErrorBrandingImpl(this.jsonData["backendError"], this.lang)
            this.subBrandings.push(this._backendError as any as BrandingData)
        }
        return this._backendError
    }

    get recommendModal() {
        if (!this._recommendModal) {
            this._recommendModal = new RecommendModalBrandingImpl(this.jsonData["recommendModal"], this.lang)
            this.subBrandings.push(this._recommendModal as any as BrandingData)
        }
        return this._recommendModal
    }

    get topSponsors() {
        if (!this._topSponsors) {
            this._topSponsors = []
            for (const arrayElement of this.jsonData["topSponsors"]) {
                const arrayObject = new TopSponsorImpl(arrayElement, this.lang)
                this.topSponsors.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._topSponsors
    }

    get ideaSponsors() {
        if (!this._ideaSponsors) {
            this._ideaSponsors = []
            for (const arrayElement of this.jsonData["ideaSponsors"]) {
                const arrayObject = new TopSponsorImpl(arrayElement, this.lang)
                this.ideaSponsors.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._ideaSponsors
    }

    get topicBadges() {
        if (!this._topicBadges) {
            this._topicBadges = []
            for (const arrayElement of this.jsonData["topicBadges"]) {
                const arrayObject = new TopicBadgeImpl(arrayElement, this.lang)
                this.topicBadges.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._topicBadges
    }

    get categoryBadges() {
        return this.jsonData["categoryBadges"]
    }

    get categoryBadgesVisible() {
        return this.jsonData["categoryBadgesVisible"]
    }

    get categoryBadgesShowfloorTextSize() {
        return this.jsonData["categoryBadgesShowfloorTextSize"]
    }

    get press() {
        return this.jsonData["press"][this.lang]
    }

    get coupons() {
        if (!this._coupons) {
            this._coupons = new CouponsBrandingImpl(this.jsonData["coupons"], this.lang)
            this.subBrandings.push(this._coupons as any as BrandingData)
        }
        return this._coupons
    }

    get topBanner() {
        if (!this._topBanner) {
            this._topBanner = new TopBannerBrandingImpl(this.jsonData["topBanner"], this.lang)
            this.subBrandings.push(this._topBanner as any as BrandingData)
        }
        return this._topBanner
    }

    get guestUserBanner() {
        if (!this._guestUserBanner) {
            this._guestUserBanner = new GuestUserBannerBrandingImpl(this.jsonData["guestUserBanner"], this.lang)
            this.subBrandings.push(this._guestUserBanner as any as BrandingData)
        }
        return this._guestUserBanner
    }

    get topBannerConfig() {
        if (!this._topBannerConfig) {
            this._topBannerConfig = new TopBannerConfigImpl(this.jsonData["topBannerConfig"], this.lang)
            this.subBrandings.push(this._topBannerConfig as any as BrandingData)
        }
        return this._topBannerConfig
    }

    get rulesPage() {
        if (!this._rulesPage) {
            this._rulesPage = new RulesPageContentBrandingImpl(this.jsonData["rulesPage"], this.lang)
            this.subBrandings.push(this._rulesPage as any as BrandingData)
        }
        return this._rulesPage
    }

    get topBar() {
        if (!this._topBar) {
            this._topBar = new TopBarBrandingImpl(this.jsonData["topBar"], this.lang)
            this.subBrandings.push(this._topBar as any as BrandingData)
        }
        return this._topBar
    }

    get roster() {
        if (!this._roster) {
            this._roster = new RosterBrandingImpl(this.jsonData["roster"], this.lang)
            this.subBrandings.push(this._roster as any as BrandingData)
        }
        return this._roster
    }

    get settingsTab() {
        if (!this._settingsTab) {
            this._settingsTab = new SettingsTabBrandingImpl(this.jsonData["settingsTab"], this.lang)
            this.subBrandings.push(this._settingsTab as any as BrandingData)
        }
        return this._settingsTab
    }

    get mySchedule() {
        if (!this._mySchedule) {
            this._mySchedule = new MySchedulePageBrandingImpl(this.jsonData["mySchedule"], this.lang)
            this.subBrandings.push(this._mySchedule as any as BrandingData)
        }
        return this._mySchedule
    }

    get hubspotScriptTag() {
        return this.jsonData["hubspotScriptTag"]
    }

    get gtmNoScript() {
        return this.jsonData["gtmNoScript"]
    }

    get gtmScriptDataLayer() {
        return this.jsonData["gtmScriptDataLayer"]
    }

    get gtmTagScript() {
        return this.jsonData["gtmTagScript"]
    }

    get gtmFairNumber() {
        return this.jsonData["gtmFairNumber"]
    }

    get gtmFairHeartbeatEventMinutes() {
        return this.jsonData["gtmFairHeartbeatEventMinutes"]
    }

    get matomoScript() {
        return this.jsonData["matomoScript"]
    }

    get cookieOneTrustScript() {
        return this.jsonData["cookieOneTrustScript"]
    }

    get usercentrixScript() {
        return this.jsonData["usercentrixScript"]
    }

    get jiraSupportDeskData() {
        if (!this._jiraSupportDeskData) {
            this._jiraSupportDeskData = new JiraSupportDeskDataImpl(this.jsonData["jiraSupportDeskData"], this.lang)
            this.subBrandings.push(this._jiraSupportDeskData as any as BrandingData)
        }
        return this._jiraSupportDeskData
    }

    get showPersonNameInUrl() {
        return this.jsonData["showPersonNameInUrl"]
    }

    get videoBackground() {
        return this.jsonData["videoBackground"]
    }

    get showroomMeetingDuration() {
        return this.jsonData["showroomMeetingDuration"]
    }

    get breakoutTimeoutDuration() {
        return this.jsonData["breakoutTimeoutDuration"]
    }

    get defaultToggleIcon() {
        return this.jsonData["defaultToggleIcon"]
    }

    get conversationEntry() {
        if (!this._conversationEntry) {
            this._conversationEntry = new ConversationEntryBrandingImpl(this.jsonData["conversationEntry"], this.lang)
            this.subBrandings.push(this._conversationEntry as any as BrandingData)
        }
        return this._conversationEntry
    }

    get globalOptIn() {
        return this.jsonData["globalOptIn"]
    }

    get trackingBrandingNone() {
        if (!this._trackingBrandingNone) {
            this._trackingBrandingNone = new TrackingBrandingImpl(this.jsonData["trackingBrandingNone"], this.lang)
            this.subBrandings.push(this._trackingBrandingNone as any as BrandingData)
        }
        return this._trackingBrandingNone
    }

    get trackingBrandingBasic() {
        if (!this._trackingBrandingBasic) {
            this._trackingBrandingBasic = new TrackingBrandingImpl(this.jsonData["trackingBrandingBasic"], this.lang)
            this.subBrandings.push(this._trackingBrandingBasic as any as BrandingData)
        }
        return this._trackingBrandingBasic
    }

    get trackingBrandingStandard() {
        if (!this._trackingBrandingStandard) {
            this._trackingBrandingStandard = new TrackingBrandingImpl(this.jsonData["trackingBrandingStandard"], this.lang)
            this.subBrandings.push(this._trackingBrandingStandard as any as BrandingData)
        }
        return this._trackingBrandingStandard
    }

    get trackingBrandingPremium() {
        if (!this._trackingBrandingPremium) {
            this._trackingBrandingPremium = new TrackingBrandingImpl(this.jsonData["trackingBrandingPremium"], this.lang)
            this.subBrandings.push(this._trackingBrandingPremium as any as BrandingData)
        }
        return this._trackingBrandingPremium
    }

    get trackingBrandingUltimate() {
        if (!this._trackingBrandingUltimate) {
            this._trackingBrandingUltimate = new TrackingBrandingImpl(this.jsonData["trackingBrandingUltimate"], this.lang)
            this.subBrandings.push(this._trackingBrandingUltimate as any as BrandingData)
        }
        return this._trackingBrandingUltimate
    }

    get csvExportBranding() {
        if (!this._csvExportBranding) {
            this._csvExportBranding = new CSVExportBrandingImpl(this.jsonData["csvExportBranding"], this.lang)
            this.subBrandings.push(this._csvExportBranding as any as BrandingData)
        }
        return this._csvExportBranding
    }

    get timePickerBranding() {
        if (!this._timePickerBranding) {
            this._timePickerBranding = new CrsTimePickerBrandingImpl(this.jsonData["timePickerBranding"], this.lang)
            this.subBrandings.push(this._timePickerBranding as any as BrandingData)
        }
        return this._timePickerBranding
    }

    get contextMenuBranding() {
        if (!this._contextMenuBranding) {
            this._contextMenuBranding = new ContextMenuBrandingImpl(this.jsonData["contextMenuBranding"], this.lang)
            this.subBrandings.push(this._contextMenuBranding as any as BrandingData)
        }
        return this._contextMenuBranding
    }

    get dropdownStyle() {
        if (!this._dropdownStyle) {
            this._dropdownStyle = new DropdownStyleImpl(this.jsonData["dropdownStyle"], this.lang)
            this.subBrandings.push(this._dropdownStyle as any as BrandingData)
        }
        return this._dropdownStyle
    }

    get iframeTest() {
        if (!this._iframeTest) {
            this._iframeTest = new IFrameTestPageContentBrandingImpl(this.jsonData["iframeTest"], this.lang)
            this.subBrandings.push(this._iframeTest as any as BrandingData)
        }
        return this._iframeTest
    }

    get globalSearchResultPage() {
        if (!this._globalSearchResultPage) {
            this._globalSearchResultPage = new GlobalSearchResultPageBrandingImpl(this.jsonData["globalSearchResultPage"], this.lang)
            this.subBrandings.push(this._globalSearchResultPage as any as BrandingData)
        }
        return this._globalSearchResultPage
    }

    get videoPlayerBranding() {
        if (!this._videoPlayerBranding) {
            this._videoPlayerBranding = new VideoPlayerBrandingImpl(this.jsonData["videoPlayerBranding"], this.lang)
            this.subBrandings.push(this._videoPlayerBranding as any as BrandingData)
        }
        return this._videoPlayerBranding
    }

    get videoPageContentBranding() {
        if (!this._videoPageContentBranding) {
            this._videoPageContentBranding = new VideoPageContentBrandingImpl(this.jsonData["videoPageContentBranding"], this.lang)
            this.subBrandings.push(this._videoPageContentBranding as any as BrandingData)
        }
        return this._videoPageContentBranding
    }

    get searchBarFontFamily() {
        return this.jsonData["searchBarFontFamily"]
    }

    get searchBarFontSize() {
        return this.jsonData["searchBarFontSize"]
    }

    get searchBarLetterSpacing() {
        return this.jsonData["searchBarLetterSpacing"]
    }

    get searchBarFontWeight() {
        return this.jsonData["searchBarFontWeight"]
    }

    get searchBarTextTransform() {
        return this.jsonData["searchBarTextTransform"]
    }

    get mainRootCategories() {
        return this.jsonData["mainRootCategories"]
    }

    get mainBorder() {
        return this.jsonData["mainBorder"]
    }

    get listDividerColor() {
        return this.jsonData["listDividerColor"]
    }

    get mainIconsColor() {
        return this.jsonData["mainIconsColor"]
    }

    get primaryScrollWhiteShadowLTR() {
        return this.jsonData["primaryScrollWhiteShadowLTR"]
    }

    get primaryScrollWhiteShadowTTB() {
        return this.jsonData["primaryScrollWhiteShadowTTB"]
    }

    get horizontalScrollShadowBlur() {
        return this.jsonData["horizontalScrollShadowBlur"]
    }

    get primaryScrollDarkShadowTTB() {
        return this.jsonData["primaryScrollDarkShadowTTB"]
    }

    get primaryScrollDarkShadowBTT() {
        return this.jsonData["primaryScrollDarkShadowBTT"]
    }

    get primaryScrollDarkShadowTTT() {
        return this.jsonData["primaryScrollDarkShadowTTT"]
    }

    get primaryScrollDarkShadowBTB() {
        return this.jsonData["primaryScrollDarkShadowBTB"]
    }

    get socialMediaIcons() {
        if (!this._socialMediaIcons) {
            this._socialMediaIcons = new SocialMediaIconsBrandingImpl(this.jsonData["socialMediaIcons"], this.lang)
            this.subBrandings.push(this._socialMediaIcons as any as BrandingData)
        }
        return this._socialMediaIcons
    }

    get avatarBranding() {
        if (!this._avatarBranding) {
            this._avatarBranding = new AvatarBrandingImpl(this.jsonData["avatarBranding"], this.lang)
            this.subBrandings.push(this._avatarBranding as any as BrandingData)
        }
        return this._avatarBranding
    }

    get tileViewConfig() {
        if (!this._tileViewConfig) {
            this._tileViewConfig = []
            for (const arrayElement of this.jsonData["tileViewConfig"]) {
                const arrayObject = new TileViewConfigImpl(arrayElement, this.lang)
                this.tileViewConfig.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._tileViewConfig
    }

    get hallPlanPageBranding() {
        if (!this._hallPlanPageBranding) {
            this._hallPlanPageBranding = new HallPlanPageBrandingImpl(this.jsonData["hallPlanPageBranding"], this.lang)
            this.subBrandings.push(this._hallPlanPageBranding as any as BrandingData)
        }
        return this._hallPlanPageBranding
    }
}

class ConfigurationImpl extends BrandingData implements Configuration {
    _topicName!: string;
    _userPoolName!: string;
    _sotName!: string;
    _sotAccessToken!: string;
    _networkingToggle!: boolean;
    _ogTitle!: LocalizedString;
    _ogDescription!: LocalizedString;
    _browserTranslation!: string;
    _speakerPersonFunctions!: string[];
    _defaultStaffPersonFunction!: string;
    _calendarEntryParticipantLimit!: number;
    _showPrivacyModelForEventDates!: boolean;
    _chatParticipantLimit!: number;
    _virtualCafeParticipantLimit!: number;
    _greenRoomParticipantLimit!: number;
    _breakoutParticipantLimit!: number;
    _conferenceRoomParticipantLimit!: number;
    _showRoomParticipantLimit!: number;
    _roundTableParticipantLimit!: number;
    _roundTableOpenBeforeMinutes!: number;
    _roundTableOpenAfterMinutes!: number;
    _roundTableAvailableAfterEventTimerLabel!: LocalizedString;
    _conferenceRoomRecomendedBrowserModalTitle!: LocalizedString;
    _conferenceRoomRecomendedBrowserModalMessage!: LocalizedString;
    _recomendBrowserClickableWord!: LocalizedString;
    _recomendBrowserClickableWordURL!: LocalizedString;
    _conferenceRoomRecomendedBrowserModalCheckboxLabel!: LocalizedString;
    _conferenceRoomRecomendedBrowserModalSubmit!: LocalizedString;
    _meetingDurations!: MeetingDurationType;
    _eventDateParticipation!: boolean;
    _detailSectionToggle!: boolean;
    _internEmailAdresses!: string[];
    _breakoutEnabled!: boolean;
    _conferenceRoomNoModRemainingDuration!: number;
    _ticketBarrierPageEnabled!: boolean;

    get topicName() {
        return this.jsonData["topicName"]
    }

    get userPoolName() {
        return this.jsonData["userPoolName"]
    }

    get sotName() {
        return this.jsonData["sotName"]
    }

    get sotAccessToken() {
        return this.jsonData["sotAccessToken"]
    }

    get networkingToggle() {
        return this.jsonData["networkingToggle"]
    }

    get ogTitle() {
        return this.jsonData["ogTitle"][this.lang]
    }

    get ogDescription() {
        return this.jsonData["ogDescription"][this.lang]
    }

    get browserTranslation() {
        return this.jsonData["browserTranslation"]
    }

    get speakerPersonFunctions() {
        return this.jsonData["speakerPersonFunctions"]
    }

    get defaultStaffPersonFunction() {
        return this.jsonData["defaultStaffPersonFunction"]
    }

    get calendarEntryParticipantLimit() {
        return this.jsonData["calendarEntryParticipantLimit"]
    }

    get showPrivacyModelForEventDates() {
        return this.jsonData["showPrivacyModelForEventDates"]
    }

    get chatParticipantLimit() {
        return this.jsonData["chatParticipantLimit"]
    }

    get virtualCafeParticipantLimit() {
        return this.jsonData["virtualCafeParticipantLimit"]
    }

    get greenRoomParticipantLimit() {
        return this.jsonData["greenRoomParticipantLimit"]
    }

    get breakoutParticipantLimit() {
        return this.jsonData["breakoutParticipantLimit"]
    }

    get conferenceRoomParticipantLimit() {
        return this.jsonData["conferenceRoomParticipantLimit"]
    }

    get showRoomParticipantLimit() {
        return this.jsonData["showRoomParticipantLimit"]
    }

    get roundTableParticipantLimit() {
        return this.jsonData["roundTableParticipantLimit"]
    }

    get roundTableOpenBeforeMinutes() {
        return this.jsonData["roundTableOpenBeforeMinutes"]
    }

    get roundTableOpenAfterMinutes() {
        return this.jsonData["roundTableOpenAfterMinutes"]
    }

    get roundTableAvailableAfterEventTimerLabel() {
        return this.jsonData["roundTableAvailableAfterEventTimerLabel"][this.lang]
    }

    get conferenceRoomRecomendedBrowserModalTitle() {
        return this.jsonData["conferenceRoomRecomendedBrowserModalTitle"][this.lang]
    }

    get conferenceRoomRecomendedBrowserModalMessage() {
        return this.jsonData["conferenceRoomRecomendedBrowserModalMessage"][this.lang]
    }

    get recomendBrowserClickableWord() {
        return this.jsonData["recomendBrowserClickableWord"][this.lang]
    }

    get recomendBrowserClickableWordURL() {
        return this.jsonData["recomendBrowserClickableWordURL"][this.lang]
    }

    get conferenceRoomRecomendedBrowserModalCheckboxLabel() {
        return this.jsonData["conferenceRoomRecomendedBrowserModalCheckboxLabel"][this.lang]
    }

    get conferenceRoomRecomendedBrowserModalSubmit() {
        return this.jsonData["conferenceRoomRecomendedBrowserModalSubmit"][this.lang]
    }

    get meetingDurations() {
        if (!this._meetingDurations) {
            this._meetingDurations = new MeetingDurationTypeImpl(this.jsonData["meetingDurations"], this.lang)
            this.subBrandings.push(this._meetingDurations as any as BrandingData)
        }
        return this._meetingDurations
    }

    get eventDateParticipation() {
        return this.jsonData["eventDateParticipation"]
    }

    get detailSectionToggle() {
        return this.jsonData["detailSectionToggle"]
    }

    get internEmailAdresses() {
        return this.jsonData["internEmailAdresses"]
    }

    get breakoutEnabled() {
        return this.jsonData["breakoutEnabled"]
    }

    get conferenceRoomNoModRemainingDuration() {
        return this.jsonData["conferenceRoomNoModRemainingDuration"]
    }

    get ticketBarrierPageEnabled() {
        return this.jsonData["ticketBarrierPageEnabled"]
    }
}

class MeetingDurationTypeImpl extends BrandingData implements MeetingDurationType {
    _call!: number;
    _calenderEntry!: number;

    get call() {
        return this.jsonData["call"]
    }

    get calenderEntry() {
        return this.jsonData["calenderEntry"]
    }
}

class PresenceConfigurationImpl extends BrandingData implements PresenceConfiguration {
    _usePresence!: boolean;
    _offlineAfterXMillis!: number;
    _minRequestDebounceMillis!: number;
    _requestDebounceIncFactor!: number;
    _requestDebounceDecFactor!: number;

    get usePresence() {
        return this.jsonData["usePresence"]
    }

    get offlineAfterXMillis() {
        return this.jsonData["offlineAfterXMillis"]
    }

    get minRequestDebounceMillis() {
        return this.jsonData["minRequestDebounceMillis"]
    }

    get requestDebounceIncFactor() {
        return this.jsonData["requestDebounceIncFactor"]
    }

    get requestDebounceDecFactor() {
        return this.jsonData["requestDebounceDecFactor"]
    }
}

class EventTimingImpl extends BrandingData implements EventTiming {
    _ticketShopOpenDateTime!: string;
    _onboardingOpenDateTime!: string;
    _reportingOpenDateTime!: string;
    _reportingEndDateTime!: string;
    _loginOpenDateTime!: string;
    _softOpeningDateTime!: string;
    _eventStartDateTime!: string;
    _eventEndDateTime!: string;
    _eventDateDefaultStartTime!: string;
    _eventDateDefaultEndTime!: string;
    _eventDays!: string[];
    _meetingDays!: string[];
    _eventDaysFormatPattern!: LocalizedString;
    _eventDaysFormatPatternShort!: LocalizedString;
    _timingFormatPatternShort!: LocalizedString;
    _eventDaysFormatPatternWithTime!: LocalizedString;
    _eventDaysFormatPatternEvtDetailPage!: LocalizedString;
    _eventDaysFormatPatternNewsPage!: LocalizedString;

    get ticketShopOpenDateTime() {
        return this.jsonData["ticketShopOpenDateTime"]
    }

    get onboardingOpenDateTime() {
        return this.jsonData["onboardingOpenDateTime"]
    }

    get reportingOpenDateTime() {
        return this.jsonData["reportingOpenDateTime"]
    }

    get reportingEndDateTime() {
        return this.jsonData["reportingEndDateTime"]
    }

    get loginOpenDateTime() {
        return this.jsonData["loginOpenDateTime"]
    }

    get softOpeningDateTime() {
        return this.jsonData["softOpeningDateTime"]
    }

    get eventStartDateTime() {
        return this.jsonData["eventStartDateTime"]
    }

    get eventEndDateTime() {
        return this.jsonData["eventEndDateTime"]
    }

    get eventDateDefaultStartTime() {
        return this.jsonData["eventDateDefaultStartTime"]
    }

    get eventDateDefaultEndTime() {
        return this.jsonData["eventDateDefaultEndTime"]
    }

    get eventDays() {
        return this.jsonData["eventDays"]
    }

    get meetingDays() {
        return this.jsonData["meetingDays"]
    }

    get eventDaysFormatPattern() {
        return this.jsonData["eventDaysFormatPattern"][this.lang]
    }

    get eventDaysFormatPatternShort() {
        return this.jsonData["eventDaysFormatPatternShort"][this.lang]
    }

    get timingFormatPatternShort() {
        return this.jsonData["timingFormatPatternShort"][this.lang]
    }

    get eventDaysFormatPatternWithTime() {
        return this.jsonData["eventDaysFormatPatternWithTime"][this.lang]
    }

    get eventDaysFormatPatternEvtDetailPage() {
        return this.jsonData["eventDaysFormatPatternEvtDetailPage"][this.lang]
    }

    get eventDaysFormatPatternNewsPage() {
        return this.jsonData["eventDaysFormatPatternNewsPage"][this.lang]
    }
}

class GlobalStatePopupTextsBrandingImpl extends BrandingData implements GlobalStatePopupTextsBranding {
    _errorNoCameraPermission!: LocalizedString;
    _errorNoScreenCapturePermission!: LocalizedString;

    get errorNoCameraPermission() {
        return this.jsonData["errorNoCameraPermission"][this.lang]
    }

    get errorNoScreenCapturePermission() {
        return this.jsonData["errorNoScreenCapturePermission"][this.lang]
    }
}

class LoginRegistrationSiteBrandingImpl extends BrandingData implements LoginRegistrationSiteBranding {
    _loginType!: LoginType;
    _ssoLoginLibScript!: string;
    _submitEmailButton!: LocalizedString;
    _emailPlaceHolder!: LocalizedString;
    _passwordPlaceHolder!: LocalizedString;
    _submitTokenButton!: LocalizedString;
    _tokenPlaceholder!: LocalizedString;
    _invalidToken!: LocalizedString;
    _wrongToken!: LocalizedString;
    _createNewToken!: LocalizedString;
    _noToken!: LocalizedString;
    _resendToken!: LocalizedString;
    _ticketPanel!: LocalizedString;
    _buyTicketButton!: LocalizedString;
    _changeEmailButton!: LocalizedString;
    _createTokenFailed!: LocalizedString;
    _createTokenSuccess!: LocalizedString;
    _successMessageColor!: ColorString;
    _dataPrivacyPanel!: LocalizedString;
    _dataPolicy!: LocalizedString;
    _invalidEmailPanel!: LocalizedString;
    _invalidEmailPanelWithLink!: InvalidEmailPanelWithLink;
    _outdatedPrivacyDocsPanel!: LocalizedString;
    _wrongPasswordPanel!: LocalizedString;
    _registerEmailFailed!: LocalizedString;
    _codeResendLimit!: LocalizedString;
    _contactSupportText!: LocalizedString;
    _contactSupportUrl!: LocalizedString;
    _maximumLoginAttempts!: LocalizedString;
    _thirdPartyUserNotFound!: LocalizedString;
    _userCreationNotAllowed!: LocalizedString;
    _forgotPassword!: LocalizedString;
    _passwordForgottenUrl!: LocalizedString;
    _enterEmail!: LocalizedString;
    _enterPassword!: LocalizedString;
    _sendCode!: LocalizedString;
    _enterToken!: LocalizedString;
    _centerLoginStyleTitle!: LocalizedString;
    _centerLoginStyleTicketInputTitle!: LocalizedString;
    _ticketCodeInputPlaceholder!: LocalizedString;
    _centerLoginStyleTitleCode!: LocalizedString;
    _centerLoginStyleSubtitle!: LocalizedString;
    _centerLoginStyleSubtitleShort!: LocalizedString;
    _centerLoginStyleTicketLink!: LocalizedString;
    _getTicketsLink!: LocalizedString;
    _loginRegistrationTitle!: LocalizedString;
    _loginRegistrationTitleFontSize!: string;
    _loginRegistrationSubtitleFontSize!: string;
    _loginRegistrationSubtitle!: LocalizedString;
    _logoutReasonSessionTimeout!: LocalizedString;
    _contactUsLabel!: LocalizedString;
    _contactUsLabelVisible!: boolean;
    _contactUsLabelColor!: ColorString;
    _logoutReasonForbidden!: LocalizedString;
    _loginBackgroundColor1!: ColorString;
    _loginBackgroundColor2!: ColorString;
    _loginInputColor!: ColorString;
    _loginButtonBackgroundColor!: ColorString;
    _loginButtonTextColor!: ColorString;
    _loginButtonDisabledBackgroundColor!: ColorString;
    _loginButtonTextColorDisabled!: ColorString;
    _termsOfUseText!: LocalizedString;
    _termsOfUseUrl!: LocalizedString;
    _notSupportedScreenSize!: LocalizedString;
    _centerLoginStyleTicketBorderBtn!: string;
    _centerLoginStyleTicketTextColorBtn!: ColorString;
    _centerLoginStyleTicketBtnBgColor!: ColorString;
    _centerLoginStyleTicketBtnOnHoverBgColor!: ColorString;
    _centerLoginStyleTicketBtnOnHoverTextColor!: ColorString;
    _ticketBarrierPageLogoutBtnBgColor!: ColorString;
    _ticketBarrierPageLogoutBtnBorder!: string;
    _ticketBarrierPageLogoutBtnTextColor!: ColorString;
    _ticketBarrierPageLogoutBtnOnHoverBgColor!: ColorString;
    _ticketBarrierPageLogoutBtnOnHoverTextColor!: ColorString;
    _ticketBarrierPageLogoutBtnLabel!: LocalizedString;
    _centerLoginSystemCheckBackgroundColor!: ColorString;
    _centerLoginStyleOverlay!: ColorString;
    _centerLoginLogoMaxWidth!: string;
    _centerLoginLogoMaxHeight!: string;
    _centerLoginLogoMaxHeightMobile!: string;
    _loginFormTopMargin!: string;
    _loginInputTopMargin!: string;
    _emailQueryParam!: string;
    _passwordQueryParam!: string;
    _showImprintAndPrivacyPolicyInFooter!: boolean;
    _showImprintInFooter!: boolean;
    _showPrivacyPolicyInFooter!: boolean;
    _showTermsOfUseInFooter!: boolean;
    _imprintLink!: LocalizedString;
    _imprintLinkUrl!: LocalizedString;
    _privacyPolicyLink!: LocalizedString;
    _privacyPolicyLinkUrl!: LocalizedString;
    _termsOfUseLink!: LocalizedString;
    _termsOfUseLinkUrl!: LocalizedString;
    _loginTitleTextColor!: ColorString;
    _loginSubTitleTextColor!: ColorString;
    _loginSubTitleFontWeight!: string;
    _loginModalTextColor!: ColorString;
    _loginModalSubtitleTextColor!: ColorString;
    _loginInputMailTextColor!: string;
    _loginInputMailBackgroundColor!: string;
    _loginInputMailPlaceholderColor!: ColorString;
    _loginBoxOverlayColor!: ColorString;
    _changeEmailAddressLinkColor!: ColorString;
    _enableMinimalResolutionCheck!: boolean;
    _footerTextColor!: string;
    _privacyDocsCheckbox!: PrivacyDocsCheckbox;
    _privacyDocsSecondCheckbox!: PrivacyDocsCheckbox;
    _mobileLoginTextColor!: string;
    _mobileLoginTextFieldBackgroundColor!: string;
    _mobileLoginButtonColor!: string;
    _mobileLoginTicketButtonVisible!: boolean;
    _getYourTicketSectionTopBorderColor!: string;
    _moreOptionsForMobileTitle!: LocalizedString;
    _moreOptionsForMobileDescription!: LocalizedString;
    _androidAppUrl!: string;
    _iOSAppUrl!: string;
    _downloadMobileAppButtonText!: LocalizedString;
    _continueWithMobileButtonText!: LocalizedString;
    _useCustomLogoForMobile!: boolean;
    _appDownloadModeEnabled!: boolean;
    _appDownloadModeFooterShown!: boolean;
    _ssoLoginKoelnmesse!: LoginWithSSOKoelnmesseBranding;
    _showExternalLinkButton!: boolean;
    _externalLinkButtonText!: LocalizedString;
    _externalLinkButtonUrl!: string;
    _ticketConfigurationIssueMessage!: LocalizedString;
    _ticketNotValidMessage!: LocalizedString;
    _ticketAlreadyRedeemedMessage!: LocalizedString;
    _ticketCodeFailureMessage!: LocalizedString;
    _ticketShopUrls!: TicketShopUrlType[];
    _ticketShopUrlColor!: ColorString;

    get loginType() {
        return this.jsonData["loginType"]
    }

    get ssoLoginLibScript() {
        return this.jsonData["ssoLoginLibScript"]
    }

    get submitEmailButton() {
        return this.jsonData["submitEmailButton"][this.lang]
    }

    get emailPlaceHolder() {
        return this.jsonData["emailPlaceHolder"][this.lang]
    }

    get passwordPlaceHolder() {
        return this.jsonData["passwordPlaceHolder"][this.lang]
    }

    get submitTokenButton() {
        return this.jsonData["submitTokenButton"][this.lang]
    }

    get tokenPlaceholder() {
        return this.jsonData["tokenPlaceholder"][this.lang]
    }

    get invalidToken() {
        return this.jsonData["invalidToken"][this.lang]
    }

    get wrongToken() {
        return this.jsonData["wrongToken"][this.lang]
    }

    get createNewToken() {
        return this.jsonData["createNewToken"][this.lang]
    }

    get noToken() {
        return this.jsonData["noToken"][this.lang]
    }

    get resendToken() {
        return this.jsonData["resendToken"][this.lang]
    }

    get ticketPanel() {
        return this.jsonData["ticketPanel"][this.lang]
    }

    get buyTicketButton() {
        return this.jsonData["buyTicketButton"][this.lang]
    }

    get changeEmailButton() {
        return this.jsonData["changeEmailButton"][this.lang]
    }

    get createTokenFailed() {
        return this.jsonData["createTokenFailed"][this.lang]
    }

    get createTokenSuccess() {
        return this.jsonData["createTokenSuccess"][this.lang]
    }

    get successMessageColor() {
        return this.jsonData["successMessageColor"]
    }

    get dataPrivacyPanel() {
        return this.jsonData["dataPrivacyPanel"][this.lang]
    }

    get dataPolicy() {
        return this.jsonData["dataPolicy"][this.lang]
    }

    get invalidEmailPanel() {
        return this.jsonData["invalidEmailPanel"][this.lang]
    }

    get invalidEmailPanelWithLink() {
        if (!this._invalidEmailPanelWithLink) {
            this._invalidEmailPanelWithLink = new InvalidEmailPanelWithLinkImpl(this.jsonData["invalidEmailPanelWithLink"], this.lang)
            this.subBrandings.push(this._invalidEmailPanelWithLink as any as BrandingData)
        }
        return this._invalidEmailPanelWithLink
    }

    get outdatedPrivacyDocsPanel() {
        return this.jsonData["outdatedPrivacyDocsPanel"][this.lang]
    }

    get wrongPasswordPanel() {
        return this.jsonData["wrongPasswordPanel"][this.lang]
    }

    get registerEmailFailed() {
        return this.jsonData["registerEmailFailed"][this.lang]
    }

    get codeResendLimit() {
        return this.jsonData["codeResendLimit"][this.lang]
    }

    get contactSupportText() {
        return this.jsonData["contactSupportText"][this.lang]
    }

    get contactSupportUrl() {
        return this.jsonData["contactSupportUrl"][this.lang]
    }

    get maximumLoginAttempts() {
        return this.jsonData["maximumLoginAttempts"][this.lang]
    }

    get thirdPartyUserNotFound() {
        return this.jsonData["thirdPartyUserNotFound"][this.lang]
    }

    get userCreationNotAllowed() {
        return this.jsonData["userCreationNotAllowed"][this.lang]
    }

    get forgotPassword() {
        return this.jsonData["forgotPassword"][this.lang]
    }

    get passwordForgottenUrl() {
        return this.jsonData["passwordForgottenUrl"][this.lang]
    }

    get enterEmail() {
        return this.jsonData["enterEmail"][this.lang]
    }

    get enterPassword() {
        return this.jsonData["enterPassword"][this.lang]
    }

    get sendCode() {
        return this.jsonData["sendCode"][this.lang]
    }

    get enterToken() {
        return this.jsonData["enterToken"][this.lang]
    }

    get centerLoginStyleTitle() {
        return this.jsonData["centerLoginStyleTitle"][this.lang]
    }

    get centerLoginStyleTicketInputTitle() {
        return this.jsonData["centerLoginStyleTicketInputTitle"][this.lang]
    }

    get ticketCodeInputPlaceholder() {
        return this.jsonData["ticketCodeInputPlaceholder"][this.lang]
    }

    get centerLoginStyleTitleCode() {
        return this.jsonData["centerLoginStyleTitleCode"][this.lang]
    }

    get centerLoginStyleSubtitle() {
        return this.jsonData["centerLoginStyleSubtitle"][this.lang]
    }

    get centerLoginStyleSubtitleShort() {
        return this.jsonData["centerLoginStyleSubtitleShort"][this.lang]
    }

    get centerLoginStyleTicketLink() {
        return this.jsonData["centerLoginStyleTicketLink"][this.lang]
    }

    get getTicketsLink() {
        return this.jsonData["getTicketsLink"][this.lang]
    }

    get loginRegistrationTitle() {
        return this.jsonData["loginRegistrationTitle"][this.lang]
    }

    get loginRegistrationTitleFontSize() {
        return this.jsonData["loginRegistrationTitleFontSize"]
    }

    get loginRegistrationSubtitleFontSize() {
        return this.jsonData["loginRegistrationSubtitleFontSize"]
    }

    get loginRegistrationSubtitle() {
        return this.jsonData["loginRegistrationSubtitle"][this.lang]
    }

    get logoutReasonSessionTimeout() {
        return this.jsonData["logoutReasonSessionTimeout"][this.lang]
    }

    get contactUsLabel() {
        return this.jsonData["contactUsLabel"][this.lang]
    }

    get contactUsLabelVisible() {
        return this.jsonData["contactUsLabelVisible"]
    }

    get contactUsLabelColor() {
        return this.jsonData["contactUsLabelColor"]
    }

    get logoutReasonForbidden() {
        return this.jsonData["logoutReasonForbidden"][this.lang]
    }

    get loginBackgroundColor1() {
        return this.jsonData["loginBackgroundColor1"]
    }

    get loginBackgroundColor2() {
        return this.jsonData["loginBackgroundColor2"]
    }

    get loginInputColor() {
        return this.jsonData["loginInputColor"]
    }

    get loginButtonBackgroundColor() {
        return this.jsonData["loginButtonBackgroundColor"]
    }

    get loginButtonTextColor() {
        return this.jsonData["loginButtonTextColor"]
    }

    get loginButtonDisabledBackgroundColor() {
        return this.jsonData["loginButtonDisabledBackgroundColor"]
    }

    get loginButtonTextColorDisabled() {
        return this.jsonData["loginButtonTextColorDisabled"]
    }

    get termsOfUseText() {
        return this.jsonData["termsOfUseText"][this.lang]
    }

    get termsOfUseUrl() {
        return this.jsonData["termsOfUseUrl"][this.lang]
    }

    get notSupportedScreenSize() {
        return this.jsonData["notSupportedScreenSize"][this.lang]
    }

    get centerLoginStyleTicketBorderBtn() {
        return this.jsonData["centerLoginStyleTicketBorderBtn"]
    }

    get centerLoginStyleTicketTextColorBtn() {
        return this.jsonData["centerLoginStyleTicketTextColorBtn"]
    }

    get centerLoginStyleTicketBtnBgColor() {
        return this.jsonData["centerLoginStyleTicketBtnBgColor"]
    }

    get centerLoginStyleTicketBtnOnHoverBgColor() {
        return this.jsonData["centerLoginStyleTicketBtnOnHoverBgColor"]
    }

    get centerLoginStyleTicketBtnOnHoverTextColor() {
        return this.jsonData["centerLoginStyleTicketBtnOnHoverTextColor"]
    }

    get ticketBarrierPageLogoutBtnBgColor() {
        return this.jsonData["ticketBarrierPageLogoutBtnBgColor"]
    }

    get ticketBarrierPageLogoutBtnBorder() {
        return this.jsonData["ticketBarrierPageLogoutBtnBorder"]
    }

    get ticketBarrierPageLogoutBtnTextColor() {
        return this.jsonData["ticketBarrierPageLogoutBtnTextColor"]
    }

    get ticketBarrierPageLogoutBtnOnHoverBgColor() {
        return this.jsonData["ticketBarrierPageLogoutBtnOnHoverBgColor"]
    }

    get ticketBarrierPageLogoutBtnOnHoverTextColor() {
        return this.jsonData["ticketBarrierPageLogoutBtnOnHoverTextColor"]
    }

    get ticketBarrierPageLogoutBtnLabel() {
        return this.jsonData["ticketBarrierPageLogoutBtnLabel"][this.lang]
    }

    get centerLoginSystemCheckBackgroundColor() {
        return this.jsonData["centerLoginSystemCheckBackgroundColor"]
    }

    get centerLoginStyleOverlay() {
        return this.jsonData["centerLoginStyleOverlay"]
    }

    get centerLoginLogoMaxWidth() {
        return this.jsonData["centerLoginLogoMaxWidth"]
    }

    get centerLoginLogoMaxHeight() {
        return this.jsonData["centerLoginLogoMaxHeight"]
    }

    get centerLoginLogoMaxHeightMobile() {
        return this.jsonData["centerLoginLogoMaxHeightMobile"]
    }

    get loginFormTopMargin() {
        return this.jsonData["loginFormTopMargin"]
    }

    get loginInputTopMargin() {
        return this.jsonData["loginInputTopMargin"]
    }

    get emailQueryParam() {
        return this.jsonData["emailQueryParam"]
    }

    get passwordQueryParam() {
        return this.jsonData["passwordQueryParam"]
    }

    get showImprintAndPrivacyPolicyInFooter() {
        return this.jsonData["showImprintAndPrivacyPolicyInFooter"]
    }

    get showImprintInFooter() {
        return this.jsonData["showImprintInFooter"]
    }

    get showPrivacyPolicyInFooter() {
        return this.jsonData["showPrivacyPolicyInFooter"]
    }

    get showTermsOfUseInFooter() {
        return this.jsonData["showTermsOfUseInFooter"]
    }

    get imprintLink() {
        return this.jsonData["imprintLink"][this.lang]
    }

    get imprintLinkUrl() {
        return this.jsonData["imprintLinkUrl"][this.lang]
    }

    get privacyPolicyLink() {
        return this.jsonData["privacyPolicyLink"][this.lang]
    }

    get privacyPolicyLinkUrl() {
        return this.jsonData["privacyPolicyLinkUrl"][this.lang]
    }

    get termsOfUseLink() {
        return this.jsonData["termsOfUseLink"][this.lang]
    }

    get termsOfUseLinkUrl() {
        return this.jsonData["termsOfUseLinkUrl"][this.lang]
    }

    get loginTitleTextColor() {
        return this.jsonData["loginTitleTextColor"]
    }

    get loginSubTitleTextColor() {
        return this.jsonData["loginSubTitleTextColor"]
    }

    get loginSubTitleFontWeight() {
        return this.jsonData["loginSubTitleFontWeight"]
    }

    get loginModalTextColor() {
        return this.jsonData["loginModalTextColor"]
    }

    get loginModalSubtitleTextColor() {
        return this.jsonData["loginModalSubtitleTextColor"]
    }

    get loginInputMailTextColor() {
        return this.jsonData["loginInputMailTextColor"]
    }

    get loginInputMailBackgroundColor() {
        return this.jsonData["loginInputMailBackgroundColor"]
    }

    get loginInputMailPlaceholderColor() {
        return this.jsonData["loginInputMailPlaceholderColor"]
    }

    get loginBoxOverlayColor() {
        return this.jsonData["loginBoxOverlayColor"]
    }

    get changeEmailAddressLinkColor() {
        return this.jsonData["changeEmailAddressLinkColor"]
    }

    get enableMinimalResolutionCheck() {
        return this.jsonData["enableMinimalResolutionCheck"]
    }

    get footerTextColor() {
        return this.jsonData["footerTextColor"]
    }

    get privacyDocsCheckbox() {
        if (!this._privacyDocsCheckbox) {
            this._privacyDocsCheckbox = new PrivacyDocsCheckboxImpl(this.jsonData["privacyDocsCheckbox"], this.lang)
            this.subBrandings.push(this._privacyDocsCheckbox as any as BrandingData)
        }
        return this._privacyDocsCheckbox
    }

    get privacyDocsSecondCheckbox() {
        if (!this._privacyDocsSecondCheckbox) {
            this._privacyDocsSecondCheckbox = new PrivacyDocsCheckboxImpl(this.jsonData["privacyDocsSecondCheckbox"], this.lang)
            this.subBrandings.push(this._privacyDocsSecondCheckbox as any as BrandingData)
        }
        return this._privacyDocsSecondCheckbox
    }

    get mobileLoginTextColor() {
        return this.jsonData["mobileLoginTextColor"]
    }

    get mobileLoginTextFieldBackgroundColor() {
        return this.jsonData["mobileLoginTextFieldBackgroundColor"]
    }

    get mobileLoginButtonColor() {
        return this.jsonData["mobileLoginButtonColor"]
    }

    get mobileLoginTicketButtonVisible() {
        return this.jsonData["mobileLoginTicketButtonVisible"]
    }

    get getYourTicketSectionTopBorderColor() {
        return this.jsonData["getYourTicketSectionTopBorderColor"]
    }

    get moreOptionsForMobileTitle() {
        return this.jsonData["moreOptionsForMobileTitle"][this.lang]
    }

    get moreOptionsForMobileDescription() {
        return this.jsonData["moreOptionsForMobileDescription"][this.lang]
    }

    get androidAppUrl() {
        return this.jsonData["androidAppUrl"]
    }

    get iOSAppUrl() {
        return this.jsonData["iOSAppUrl"]
    }

    get downloadMobileAppButtonText() {
        return this.jsonData["downloadMobileAppButtonText"][this.lang]
    }

    get continueWithMobileButtonText() {
        return this.jsonData["continueWithMobileButtonText"][this.lang]
    }

    get useCustomLogoForMobile() {
        return this.jsonData["useCustomLogoForMobile"]
    }

    get appDownloadModeEnabled() {
        return this.jsonData["appDownloadModeEnabled"]
    }

    get appDownloadModeFooterShown() {
        return this.jsonData["appDownloadModeFooterShown"]
    }

    get ssoLoginKoelnmesse() {
        if (!this._ssoLoginKoelnmesse) {
            this._ssoLoginKoelnmesse = new LoginWithSSOKoelnmesseBrandingImpl(this.jsonData["ssoLoginKoelnmesse"], this.lang)
            this.subBrandings.push(this._ssoLoginKoelnmesse as any as BrandingData)
        }
        return this._ssoLoginKoelnmesse
    }

    get showExternalLinkButton() {
        return this.jsonData["showExternalLinkButton"]
    }

    get externalLinkButtonText() {
        return this.jsonData["externalLinkButtonText"][this.lang]
    }

    get externalLinkButtonUrl() {
        return this.jsonData["externalLinkButtonUrl"]
    }

    get ticketConfigurationIssueMessage() {
        return this.jsonData["ticketConfigurationIssueMessage"][this.lang]
    }

    get ticketNotValidMessage() {
        return this.jsonData["ticketNotValidMessage"][this.lang]
    }

    get ticketAlreadyRedeemedMessage() {
        return this.jsonData["ticketAlreadyRedeemedMessage"][this.lang]
    }

    get ticketCodeFailureMessage() {
        return this.jsonData["ticketCodeFailureMessage"][this.lang]
    }

    get ticketShopUrls() {
        if (!this._ticketShopUrls) {
            this._ticketShopUrls = []
            for (const arrayElement of this.jsonData["ticketShopUrls"]) {
                const arrayObject = new TicketShopUrlTypeImpl(arrayElement, this.lang)
                this.ticketShopUrls.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._ticketShopUrls
    }

    get ticketShopUrlColor() {
        return this.jsonData["ticketShopUrlColor"]
    }
}

class InvalidEmailPanelWithLinkImpl extends BrandingData implements InvalidEmailPanelWithLink {
    _visible!: boolean;
    _befLinkPart!: LocalizedString;
    _link!: LocalizedString;
    _text!: LocalizedString;
    _textColor!: string;
    _aftLinkPart!: LocalizedString;

    get visible() {
        return this.jsonData["visible"]
    }

    get befLinkPart() {
        return this.jsonData["befLinkPart"][this.lang]
    }

    get link() {
        return this.jsonData["link"][this.lang]
    }

    get text() {
        return this.jsonData["text"][this.lang]
    }

    get textColor() {
        return this.jsonData["textColor"]
    }

    get aftLinkPart() {
        return this.jsonData["aftLinkPart"][this.lang]
    }
}

class PrivacyDocsCheckboxImpl extends BrandingData implements PrivacyDocsCheckbox {
    _visible!: boolean;
    _labelBeforeLink!: LocalizedString;
    _labelAfterLink!: LocalizedString;
    _linkTitle!: LocalizedString;
    _link!: LocalizedString;

    get visible() {
        return this.jsonData["visible"]
    }

    get labelBeforeLink() {
        return this.jsonData["labelBeforeLink"][this.lang]
    }

    get labelAfterLink() {
        return this.jsonData["labelAfterLink"][this.lang]
    }

    get linkTitle() {
        return this.jsonData["linkTitle"][this.lang]
    }

    get link() {
        return this.jsonData["link"][this.lang]
    }
}

class LoginWithSSOKoelnmesseBrandingImpl extends BrandingData implements LoginWithSSOKoelnmesseBranding {
    _brandCode!: string;
    _titleLogin!: LocalizedString;
    _buttonLogin!: LocalizedString;
    _titleTicket!: LocalizedString;
    _buttonTicket!: LocalizedString;
    _step1!: LocalizedString;
    _step2!: LocalizedString;
    _step3!: LocalizedString;

    get brandCode() {
        return this.jsonData["brandCode"]
    }

    get titleLogin() {
        return this.jsonData["titleLogin"][this.lang]
    }

    get buttonLogin() {
        return this.jsonData["buttonLogin"][this.lang]
    }

    get titleTicket() {
        return this.jsonData["titleTicket"][this.lang]
    }

    get buttonTicket() {
        return this.jsonData["buttonTicket"][this.lang]
    }

    get step1() {
        return this.jsonData["step1"][this.lang]
    }

    get step2() {
        return this.jsonData["step2"][this.lang]
    }

    get step3() {
        return this.jsonData["step3"][this.lang]
    }
}

class TicketShopUrlTypeImpl extends BrandingData implements TicketShopUrlType {
    _label!: LocalizedString;
    _link!: string;

    get label() {
        return this.jsonData["label"][this.lang]
    }

    get link() {
        return this.jsonData["link"]
    }
}

class ErrorSiteBrandingImpl extends BrandingData implements ErrorSiteBranding {
    _errorTitle!: LocalizedString;
    _errorDescription!: LocalizedString;
    _iconColor!: ColorString;
    _reloadButtonText!: LocalizedString;
    _reloadButtonColor!: ColorString;

    get errorTitle() {
        return this.jsonData["errorTitle"][this.lang]
    }

    get errorDescription() {
        return this.jsonData["errorDescription"][this.lang]
    }

    get iconColor() {
        return this.jsonData["iconColor"]
    }

    get reloadButtonText() {
        return this.jsonData["reloadButtonText"][this.lang]
    }

    get reloadButtonColor() {
        return this.jsonData["reloadButtonColor"]
    }
}

class BasisPremiumBrandingImpl extends BrandingData implements BasisPremiumBranding {
    _NONE!: BasisPremiumConfig;
    _BASIC!: BasisPremiumConfig;
    _STANDARD!: BasisPremiumConfig;
    _PREMIUM!: BasisPremiumConfig;
    _ULTIMATE!: BasisPremiumConfig;
    _SPONSOR!: BasisPremiumConfig;

    get NONE() {
        if (!this._NONE) {
            this._NONE = new BasisPremiumConfigImpl(this.jsonData["NONE"], this.lang)
            this.subBrandings.push(this._NONE as any as BrandingData)
        }
        return this._NONE
    }

    get BASIC() {
        if (!this._BASIC) {
            this._BASIC = new BasisPremiumConfigImpl(this.jsonData["BASIC"], this.lang)
            this.subBrandings.push(this._BASIC as any as BrandingData)
        }
        return this._BASIC
    }

    get STANDARD() {
        if (!this._STANDARD) {
            this._STANDARD = new BasisPremiumConfigImpl(this.jsonData["STANDARD"], this.lang)
            this.subBrandings.push(this._STANDARD as any as BrandingData)
        }
        return this._STANDARD
    }

    get PREMIUM() {
        if (!this._PREMIUM) {
            this._PREMIUM = new BasisPremiumConfigImpl(this.jsonData["PREMIUM"], this.lang)
            this.subBrandings.push(this._PREMIUM as any as BrandingData)
        }
        return this._PREMIUM
    }

    get ULTIMATE() {
        if (!this._ULTIMATE) {
            this._ULTIMATE = new BasisPremiumConfigImpl(this.jsonData["ULTIMATE"], this.lang)
            this.subBrandings.push(this._ULTIMATE as any as BrandingData)
        }
        return this._ULTIMATE
    }

    get SPONSOR() {
        if (!this._SPONSOR) {
            this._SPONSOR = new BasisPremiumConfigImpl(this.jsonData["SPONSOR"], this.lang)
            this.subBrandings.push(this._SPONSOR as any as BrandingData)
        }
        return this._SPONSOR
    }
}

class BasisPremiumConfigImpl extends BrandingData implements BasisPremiumConfig {
    _tileSize!: TileSize;
    _tileBackgroundImageVisible!: boolean;
    _expoShowroomEnabled!: boolean;
    _teamMembersVisible!: boolean;

    get tileSize() {
        return this.jsonData["tileSize"]
    }

    get tileBackgroundImageVisible() {
        return this.jsonData["tileBackgroundImageVisible"]
    }

    get expoShowroomEnabled() {
        return this.jsonData["expoShowroomEnabled"]
    }

    get teamMembersVisible() {
        return this.jsonData["teamMembersVisible"]
    }
}

class CommunicationAreaBrandingImpl extends BrandingData implements CommunicationAreaBranding {
    _corussoftLogoVisibleInCC!: boolean;
    _disableMeetingIcon!: boolean;
    _disableVideoIcon!: boolean;
    _disableMuteIcon!: boolean;
    _disableSettingsIcon!: boolean;
    _disableCCNetworkingIcon!: boolean;
    _disableCCChatIcon!: boolean;
    _disableCCMeetingIcon!: boolean;
    _disableCCNotificationIcon!: boolean;
    _communicationAreaTitle!: LocalizedString;
    _reloadButtonText!: LocalizedString;
    _relevantListErrorMessage!: LocalizedString;
    _requestListErrorMessage!: LocalizedString;
    _contactListErrorMessage!: LocalizedString;
    _chatListErrorMessage!: LocalizedString;
    _chatErrorMessage!: LocalizedString;
    _chatMessageInputHint!: LocalizedString;
    _chatStartNewChat!: LocalizedString;
    _chatToolFavorite!: LocalizedString;
    _chatToolCalendarEntry!: LocalizedString;
    _chatToolVideoChat!: LocalizedString;
    _chatToolRecommend!: LocalizedString;
    _chatToolMute!: LocalizedString;
    _chatToolUnmute!: LocalizedString;
    _chatToolBlock!: LocalizedString;
    _activateNetworkingText!: LocalizedString;
    _completeProfileNetworkingText!: LocalizedString;
    _completeProfileNetworkingClickableWord!: LocalizedString;
    _relevantTabEmpty!: LocalizedString;
    _requestsTabEmpty!: LocalizedString;
    _contactsTabEmpty!: LocalizedString;
    _requestsContactsTabNetworkingDisabled!: LocalizedString;
    _blockedTabEmpty!: LocalizedString;
    _allChatsTabEmpty!: LocalizedString;
    _contactChatsTabEmpty!: LocalizedString;
    _scheduleTabEmpty!: LocalizedString;
    _scheduleTabNoResult!: LocalizedString;
    _relevantTabTitle!: LocalizedString;
    _requestsTabTitle!: LocalizedString;
    _contactsTabTitle!: LocalizedString;
    _chatTabTitle!: LocalizedString;
    _peopleTabTitle!: LocalizedString;
    _scheduleTabTitle!: LocalizedString;
    _callsTabTitle!: LocalizedString;
    _notificationsTabTitle!: LocalizedString;
    _allCallsTabTitle!: LocalizedString;
    _missedCallsTabTitle!: LocalizedString;
    _allPeopleTabTitle!: LocalizedString;
    _allChatsTabTitle!: LocalizedString;
    _contactChatsTabTitle!: LocalizedString;
    _blockedChatsTabTitle!: LocalizedString;
    _allScheduleTabTitle!: LocalizedString;
    _pendingScheduleTabTitle!: LocalizedString;
    _declinedScheduleTabTitle!: LocalizedString;
    _silentChat!: LocalizedString;
    _settingsTitle!: LocalizedString;
    _closeSettingsTitle!: LocalizedString;
    _onSign!: LocalizedString;
    _offSign!: LocalizedString;
    _chatOnOffTitle!: LocalizedString;
    _audioVideoActivateTitle!: LocalizedString;
    _noSearchResultsTitle!: LocalizedString;
    _scheduleListSectionHeaderAccepted!: LocalizedString;
    _scheduleListSectionHeaderPast!: LocalizedString;
    _scheduleListSectionHeaderRequests!: LocalizedString;
    _scheduleListSectionHeaderDeclined!: LocalizedString;
    _scheduleListSectionHeaderNow!: LocalizedString;
    _personCompanyLink!: LocalizedString;
    _blockedContactsTitle!: LocalizedString;
    _generalSettings!: LocalizedString;
    _audioVideoSettings!: LocalizedString;
    _myOrganizationTitle!: LocalizedString;
    _editCompanyProfileLinkLabel!: LocalizedString;
    _editCompanyProfileLinkOption!: boolean;
    _backofficeLinkLabel!: LocalizedString;
    _showShowAsContactAndStandBy!: boolean;
    _showAsContactTitle!: LocalizedString;
    _standByForShowroom!: LocalizedString;
    _editProfileButtonColor!: LocalizedString;
    _logOutTitle!: LocalizedString;
    _livechat!: LocalizedString;
    _poll!: LocalizedString;
    _qanda!: LocalizedString;
    _relevantTabRelevantSectionTitle!: LocalizedString;
    _relevantTabAllSectionTitle!: LocalizedString;
    _requestsTabAwaitingReplySectionTitle!: LocalizedString;
    _requestsTabMyPendingRequestsSectionTitle!: LocalizedString;
    _requestsTabMyOrganizationRequestsSectionTitle!: LocalizedString;
    _requestsTabMyExhibitorRequestsSectionTitle!: LocalizedString;
    _contactsTabFavoritesSectionTitle!: LocalizedString;
    _contactsTabMyPersonalContactsSectionTitle!: LocalizedString;
    _contactsTabMyOrganizationsContactsSectionTitle!: LocalizedString;
    _requestAcceptTitle!: LocalizedString;
    _requestIgnoreTitle!: LocalizedString;
    _requestDeclineTitle!: LocalizedString;
    _exportContacts!: LocalizedString;
    _exportContactsModalTitle!: LocalizedString;
    _exportContactsModalVCFLabel!: LocalizedString;
    _exportContactsModalCSVLabel!: LocalizedString;
    _exportContactsModalDownloadButtonText!: LocalizedString;
    _exportVCards!: LocalizedString;
    _exportCsv!: LocalizedString;
    _exportICals!: LocalizedString;
    _blockingSectionTitle!: LocalizedString;
    _blockedSectionTitle!: LocalizedString;
    _scheduleMeetingText!: LocalizedString;
    _userReportText!: LocalizedString;
    _userReportTextTitle!: LocalizedString;
    _claimAccountText!: LocalizedString;
    _sendReportText!: LocalizedString;
    _reportDescriptionText!: LocalizedString;
    _reportText!: LocalizedString;
    _reportHint!: LocalizedString;
    _incomingText!: LocalizedString;
    _outcomingText!: LocalizedString;
    _notificationRequestTabTitle!: LocalizedString;
    _notificationChatTabTitle!: LocalizedString;
    _notificationCallTabTitle!: LocalizedString;
    _notificationMeetingTabTitle!: LocalizedString;
    _notificationEventTabTitle!: LocalizedString;
    _notificationEmptyMessage!: LocalizedString;
    _messageReceived!: LocalizedString;
    _searchBarTextPerson!: LocalizedString;
    _badgeUnreadCounterBgColor!: ColorString;
    _badgeUnreadCounterBorderColor!: ColorString;
    _organizerLogoHeight!: string;
    _organizerLogoMarginLeft!: string;
    _organizerLogoMarginTop!: string;
    _inactiveItemColor!: ColorString;
    _titleTextColor!: ColorString;
    _timezone!: LocalizedString;
    _timezonePickerText!: LocalizedString;
    _enableExportContactsAsVCard!: boolean;
    _enableExportContactsAsCsv!: boolean;
    _newNotificationColor!: ColorString;
    _showFairLogo!: boolean;
    _userBadgesLimit!: number;

    get corussoftLogoVisibleInCC() {
        return this.jsonData["corussoftLogoVisibleInCC"]
    }

    get disableMeetingIcon() {
        return this.jsonData["disableMeetingIcon"]
    }

    get disableVideoIcon() {
        return this.jsonData["disableVideoIcon"]
    }

    get disableMuteIcon() {
        return this.jsonData["disableMuteIcon"]
    }

    get disableSettingsIcon() {
        return this.jsonData["disableSettingsIcon"]
    }

    get disableCCNetworkingIcon() {
        return this.jsonData["disableCCNetworkingIcon"]
    }

    get disableCCChatIcon() {
        return this.jsonData["disableCCChatIcon"]
    }

    get disableCCMeetingIcon() {
        return this.jsonData["disableCCMeetingIcon"]
    }

    get disableCCNotificationIcon() {
        return this.jsonData["disableCCNotificationIcon"]
    }

    get communicationAreaTitle() {
        return this.jsonData["communicationAreaTitle"][this.lang]
    }

    get reloadButtonText() {
        return this.jsonData["reloadButtonText"][this.lang]
    }

    get relevantListErrorMessage() {
        return this.jsonData["relevantListErrorMessage"][this.lang]
    }

    get requestListErrorMessage() {
        return this.jsonData["requestListErrorMessage"][this.lang]
    }

    get contactListErrorMessage() {
        return this.jsonData["contactListErrorMessage"][this.lang]
    }

    get chatListErrorMessage() {
        return this.jsonData["chatListErrorMessage"][this.lang]
    }

    get chatErrorMessage() {
        return this.jsonData["chatErrorMessage"][this.lang]
    }

    get chatMessageInputHint() {
        return this.jsonData["chatMessageInputHint"][this.lang]
    }

    get chatStartNewChat() {
        return this.jsonData["chatStartNewChat"][this.lang]
    }

    get chatToolFavorite() {
        return this.jsonData["chatToolFavorite"][this.lang]
    }

    get chatToolCalendarEntry() {
        return this.jsonData["chatToolCalendarEntry"][this.lang]
    }

    get chatToolVideoChat() {
        return this.jsonData["chatToolVideoChat"][this.lang]
    }

    get chatToolRecommend() {
        return this.jsonData["chatToolRecommend"][this.lang]
    }

    get chatToolMute() {
        return this.jsonData["chatToolMute"][this.lang]
    }

    get chatToolUnmute() {
        return this.jsonData["chatToolUnmute"][this.lang]
    }

    get chatToolBlock() {
        return this.jsonData["chatToolBlock"][this.lang]
    }

    get activateNetworkingText() {
        return this.jsonData["activateNetworkingText"][this.lang]
    }

    get completeProfileNetworkingText() {
        return this.jsonData["completeProfileNetworkingText"][this.lang]
    }

    get completeProfileNetworkingClickableWord() {
        return this.jsonData["completeProfileNetworkingClickableWord"][this.lang]
    }

    get relevantTabEmpty() {
        return this.jsonData["relevantTabEmpty"][this.lang]
    }

    get requestsTabEmpty() {
        return this.jsonData["requestsTabEmpty"][this.lang]
    }

    get contactsTabEmpty() {
        return this.jsonData["contactsTabEmpty"][this.lang]
    }

    get requestsContactsTabNetworkingDisabled() {
        return this.jsonData["requestsContactsTabNetworkingDisabled"][this.lang]
    }

    get blockedTabEmpty() {
        return this.jsonData["blockedTabEmpty"][this.lang]
    }

    get allChatsTabEmpty() {
        return this.jsonData["allChatsTabEmpty"][this.lang]
    }

    get contactChatsTabEmpty() {
        return this.jsonData["contactChatsTabEmpty"][this.lang]
    }

    get scheduleTabEmpty() {
        return this.jsonData["scheduleTabEmpty"][this.lang]
    }

    get scheduleTabNoResult() {
        return this.jsonData["scheduleTabNoResult"][this.lang]
    }

    get relevantTabTitle() {
        return this.jsonData["relevantTabTitle"][this.lang]
    }

    get requestsTabTitle() {
        return this.jsonData["requestsTabTitle"][this.lang]
    }

    get contactsTabTitle() {
        return this.jsonData["contactsTabTitle"][this.lang]
    }

    get chatTabTitle() {
        return this.jsonData["chatTabTitle"][this.lang]
    }

    get peopleTabTitle() {
        return this.jsonData["peopleTabTitle"][this.lang]
    }

    get scheduleTabTitle() {
        return this.jsonData["scheduleTabTitle"][this.lang]
    }

    get callsTabTitle() {
        return this.jsonData["callsTabTitle"][this.lang]
    }

    get notificationsTabTitle() {
        return this.jsonData["notificationsTabTitle"][this.lang]
    }

    get allCallsTabTitle() {
        return this.jsonData["allCallsTabTitle"][this.lang]
    }

    get missedCallsTabTitle() {
        return this.jsonData["missedCallsTabTitle"][this.lang]
    }

    get allPeopleTabTitle() {
        return this.jsonData["allPeopleTabTitle"][this.lang]
    }

    get allChatsTabTitle() {
        return this.jsonData["allChatsTabTitle"][this.lang]
    }

    get contactChatsTabTitle() {
        return this.jsonData["contactChatsTabTitle"][this.lang]
    }

    get blockedChatsTabTitle() {
        return this.jsonData["blockedChatsTabTitle"][this.lang]
    }

    get allScheduleTabTitle() {
        return this.jsonData["allScheduleTabTitle"][this.lang]
    }

    get pendingScheduleTabTitle() {
        return this.jsonData["pendingScheduleTabTitle"][this.lang]
    }

    get declinedScheduleTabTitle() {
        return this.jsonData["declinedScheduleTabTitle"][this.lang]
    }

    get silentChat() {
        return this.jsonData["silentChat"][this.lang]
    }

    get settingsTitle() {
        return this.jsonData["settingsTitle"][this.lang]
    }

    get closeSettingsTitle() {
        return this.jsonData["closeSettingsTitle"][this.lang]
    }

    get onSign() {
        return this.jsonData["onSign"][this.lang]
    }

    get offSign() {
        return this.jsonData["offSign"][this.lang]
    }

    get chatOnOffTitle() {
        return this.jsonData["chatOnOffTitle"][this.lang]
    }

    get audioVideoActivateTitle() {
        return this.jsonData["audioVideoActivateTitle"][this.lang]
    }

    get noSearchResultsTitle() {
        return this.jsonData["noSearchResultsTitle"][this.lang]
    }

    get scheduleListSectionHeaderAccepted() {
        return this.jsonData["scheduleListSectionHeaderAccepted"][this.lang]
    }

    get scheduleListSectionHeaderPast() {
        return this.jsonData["scheduleListSectionHeaderPast"][this.lang]
    }

    get scheduleListSectionHeaderRequests() {
        return this.jsonData["scheduleListSectionHeaderRequests"][this.lang]
    }

    get scheduleListSectionHeaderDeclined() {
        return this.jsonData["scheduleListSectionHeaderDeclined"][this.lang]
    }

    get scheduleListSectionHeaderNow() {
        return this.jsonData["scheduleListSectionHeaderNow"][this.lang]
    }

    get personCompanyLink() {
        return this.jsonData["personCompanyLink"][this.lang]
    }

    get blockedContactsTitle() {
        return this.jsonData["blockedContactsTitle"][this.lang]
    }

    get generalSettings() {
        return this.jsonData["generalSettings"][this.lang]
    }

    get audioVideoSettings() {
        return this.jsonData["audioVideoSettings"][this.lang]
    }

    get myOrganizationTitle() {
        return this.jsonData["myOrganizationTitle"][this.lang]
    }

    get editCompanyProfileLinkLabel() {
        return this.jsonData["editCompanyProfileLinkLabel"][this.lang]
    }

    get editCompanyProfileLinkOption() {
        return this.jsonData["editCompanyProfileLinkOption"]
    }

    get backofficeLinkLabel() {
        return this.jsonData["backofficeLinkLabel"][this.lang]
    }

    get showShowAsContactAndStandBy() {
        return this.jsonData["showShowAsContactAndStandBy"]
    }

    get showAsContactTitle() {
        return this.jsonData["showAsContactTitle"][this.lang]
    }

    get standByForShowroom() {
        return this.jsonData["standByForShowroom"][this.lang]
    }

    get editProfileButtonColor() {
        return this.jsonData["editProfileButtonColor"][this.lang]
    }

    get logOutTitle() {
        return this.jsonData["logOutTitle"][this.lang]
    }

    get livechat() {
        return this.jsonData["livechat"][this.lang]
    }

    get poll() {
        return this.jsonData["poll"][this.lang]
    }

    get qanda() {
        return this.jsonData["qanda"][this.lang]
    }

    get relevantTabRelevantSectionTitle() {
        return this.jsonData["relevantTabRelevantSectionTitle"][this.lang]
    }

    get relevantTabAllSectionTitle() {
        return this.jsonData["relevantTabAllSectionTitle"][this.lang]
    }

    get requestsTabAwaitingReplySectionTitle() {
        return this.jsonData["requestsTabAwaitingReplySectionTitle"][this.lang]
    }

    get requestsTabMyPendingRequestsSectionTitle() {
        return this.jsonData["requestsTabMyPendingRequestsSectionTitle"][this.lang]
    }

    get requestsTabMyOrganizationRequestsSectionTitle() {
        return this.jsonData["requestsTabMyOrganizationRequestsSectionTitle"][this.lang]
    }

    get requestsTabMyExhibitorRequestsSectionTitle() {
        return this.jsonData["requestsTabMyExhibitorRequestsSectionTitle"][this.lang]
    }

    get contactsTabFavoritesSectionTitle() {
        return this.jsonData["contactsTabFavoritesSectionTitle"][this.lang]
    }

    get contactsTabMyPersonalContactsSectionTitle() {
        return this.jsonData["contactsTabMyPersonalContactsSectionTitle"][this.lang]
    }

    get contactsTabMyOrganizationsContactsSectionTitle() {
        return this.jsonData["contactsTabMyOrganizationsContactsSectionTitle"][this.lang]
    }

    get requestAcceptTitle() {
        return this.jsonData["requestAcceptTitle"][this.lang]
    }

    get requestIgnoreTitle() {
        return this.jsonData["requestIgnoreTitle"][this.lang]
    }

    get requestDeclineTitle() {
        return this.jsonData["requestDeclineTitle"][this.lang]
    }

    get exportContacts() {
        return this.jsonData["exportContacts"][this.lang]
    }

    get exportContactsModalTitle() {
        return this.jsonData["exportContactsModalTitle"][this.lang]
    }

    get exportContactsModalVCFLabel() {
        return this.jsonData["exportContactsModalVCFLabel"][this.lang]
    }

    get exportContactsModalCSVLabel() {
        return this.jsonData["exportContactsModalCSVLabel"][this.lang]
    }

    get exportContactsModalDownloadButtonText() {
        return this.jsonData["exportContactsModalDownloadButtonText"][this.lang]
    }

    get exportVCards() {
        return this.jsonData["exportVCards"][this.lang]
    }

    get exportCsv() {
        return this.jsonData["exportCsv"][this.lang]
    }

    get exportICals() {
        return this.jsonData["exportICals"][this.lang]
    }

    get blockingSectionTitle() {
        return this.jsonData["blockingSectionTitle"][this.lang]
    }

    get blockedSectionTitle() {
        return this.jsonData["blockedSectionTitle"][this.lang]
    }

    get scheduleMeetingText() {
        return this.jsonData["scheduleMeetingText"][this.lang]
    }

    get userReportText() {
        return this.jsonData["userReportText"][this.lang]
    }

    get userReportTextTitle() {
        return this.jsonData["userReportTextTitle"][this.lang]
    }

    get claimAccountText() {
        return this.jsonData["claimAccountText"][this.lang]
    }

    get sendReportText() {
        return this.jsonData["sendReportText"][this.lang]
    }

    get reportDescriptionText() {
        return this.jsonData["reportDescriptionText"][this.lang]
    }

    get reportText() {
        return this.jsonData["reportText"][this.lang]
    }

    get reportHint() {
        return this.jsonData["reportHint"][this.lang]
    }

    get incomingText() {
        return this.jsonData["incomingText"][this.lang]
    }

    get outcomingText() {
        return this.jsonData["outcomingText"][this.lang]
    }

    get notificationRequestTabTitle() {
        return this.jsonData["notificationRequestTabTitle"][this.lang]
    }

    get notificationChatTabTitle() {
        return this.jsonData["notificationChatTabTitle"][this.lang]
    }

    get notificationCallTabTitle() {
        return this.jsonData["notificationCallTabTitle"][this.lang]
    }

    get notificationMeetingTabTitle() {
        return this.jsonData["notificationMeetingTabTitle"][this.lang]
    }

    get notificationEventTabTitle() {
        return this.jsonData["notificationEventTabTitle"][this.lang]
    }

    get notificationEmptyMessage() {
        return this.jsonData["notificationEmptyMessage"][this.lang]
    }

    get messageReceived() {
        return this.jsonData["messageReceived"][this.lang]
    }

    get searchBarTextPerson() {
        return this.jsonData["searchBarTextPerson"][this.lang]
    }

    get badgeUnreadCounterBgColor() {
        return this.jsonData["badgeUnreadCounterBgColor"]
    }

    get badgeUnreadCounterBorderColor() {
        return this.jsonData["badgeUnreadCounterBorderColor"]
    }

    get organizerLogoHeight() {
        return this.jsonData["organizerLogoHeight"]
    }

    get organizerLogoMarginLeft() {
        return this.jsonData["organizerLogoMarginLeft"]
    }

    get organizerLogoMarginTop() {
        return this.jsonData["organizerLogoMarginTop"]
    }

    get inactiveItemColor() {
        return this.jsonData["inactiveItemColor"]
    }

    get titleTextColor() {
        return this.jsonData["titleTextColor"]
    }

    get timezone() {
        return this.jsonData["timezone"][this.lang]
    }

    get timezonePickerText() {
        return this.jsonData["timezonePickerText"][this.lang]
    }

    get enableExportContactsAsVCard() {
        return this.jsonData["enableExportContactsAsVCard"]
    }

    get enableExportContactsAsCsv() {
        return this.jsonData["enableExportContactsAsCsv"]
    }

    get newNotificationColor() {
        return this.jsonData["newNotificationColor"]
    }

    get showFairLogo() {
        return this.jsonData["showFairLogo"]
    }

    get userBadgesLimit() {
        return this.jsonData["userBadgesLimit"]
    }
}

class ChatBrandingImpl extends BrandingData implements ChatBranding {
    _groupChat!: LocalizedString;
    _callChatDefaultName!: LocalizedString;
    _youText!: LocalizedString;
    _detailsText!: LocalizedString;
    _newChatText!: LocalizedString;
    _newGroupText!: LocalizedString;
    _searchForPeopleText!: LocalizedString;
    _participantsText!: LocalizedString;
    _newGroupChatText!: LocalizedString;
    _setGroupNameText!: LocalizedString;
    _addGroupDescriptionText!: LocalizedString;
    _findParticipantsText!: LocalizedString;
    _startChatText!: LocalizedString;
    _addParticipantsText!: LocalizedString;
    _removeParticipantButtonText!: LocalizedString;
    _removeParticipantTitle!: LocalizedString;
    _removeParticipantModalText!: LocalizedString;
    _removeParticipantModalConfirmText!: LocalizedString;
    _removeParticipantModalDeclineText!: LocalizedString;
    _disableAddedText!: LocalizedString;
    _exitGroupText!: LocalizedString;
    _exitGroupChatText!: LocalizedString;
    _cancelText!: LocalizedString;
    _exitText!: LocalizedString;
    _muteInsteadText!: LocalizedString;
    _closeText!: LocalizedString;
    _noResultFoundText!: LocalizedString;
    _titleMeeting!: LocalizedString;
    _meetingCancelled!: LocalizedString;
    _roundedButtonStartChatBgColor!: ColorString;
    _roundedButtonStartChatTextColor!: ColorString;
    _roundedButtonStartChatBgDisabledColor!: ColorString;
    _roundedButtonStartChatBgOnHoverColor!: ColorString;
    _chatBlockedForPrivateEventText!: LocalizedString;
    _headerInfoBubbleBackgroundColor!: string;
    _headerInfoBubbleColor!: string;
    _participantJoinedMessageText!: LocalizedString;
    _participantRemovedMessageText!: LocalizedString;
    _participantLeftMessageText!: LocalizedString;

    get groupChat() {
        return this.jsonData["groupChat"][this.lang]
    }

    get callChatDefaultName() {
        return this.jsonData["callChatDefaultName"][this.lang]
    }

    get youText() {
        return this.jsonData["youText"][this.lang]
    }

    get detailsText() {
        return this.jsonData["detailsText"][this.lang]
    }

    get newChatText() {
        return this.jsonData["newChatText"][this.lang]
    }

    get newGroupText() {
        return this.jsonData["newGroupText"][this.lang]
    }

    get searchForPeopleText() {
        return this.jsonData["searchForPeopleText"][this.lang]
    }

    get participantsText() {
        return this.jsonData["participantsText"][this.lang]
    }

    get newGroupChatText() {
        return this.jsonData["newGroupChatText"][this.lang]
    }

    get setGroupNameText() {
        return this.jsonData["setGroupNameText"][this.lang]
    }

    get addGroupDescriptionText() {
        return this.jsonData["addGroupDescriptionText"][this.lang]
    }

    get findParticipantsText() {
        return this.jsonData["findParticipantsText"][this.lang]
    }

    get startChatText() {
        return this.jsonData["startChatText"][this.lang]
    }

    get addParticipantsText() {
        return this.jsonData["addParticipantsText"][this.lang]
    }

    get removeParticipantButtonText() {
        return this.jsonData["removeParticipantButtonText"][this.lang]
    }

    get removeParticipantTitle() {
        return this.jsonData["removeParticipantTitle"][this.lang]
    }

    get removeParticipantModalText() {
        return this.jsonData["removeParticipantModalText"][this.lang]
    }

    get removeParticipantModalConfirmText() {
        return this.jsonData["removeParticipantModalConfirmText"][this.lang]
    }

    get removeParticipantModalDeclineText() {
        return this.jsonData["removeParticipantModalDeclineText"][this.lang]
    }

    get disableAddedText() {
        return this.jsonData["disableAddedText"][this.lang]
    }

    get exitGroupText() {
        return this.jsonData["exitGroupText"][this.lang]
    }

    get exitGroupChatText() {
        return this.jsonData["exitGroupChatText"][this.lang]
    }

    get cancelText() {
        return this.jsonData["cancelText"][this.lang]
    }

    get exitText() {
        return this.jsonData["exitText"][this.lang]
    }

    get muteInsteadText() {
        return this.jsonData["muteInsteadText"][this.lang]
    }

    get closeText() {
        return this.jsonData["closeText"][this.lang]
    }

    get noResultFoundText() {
        return this.jsonData["noResultFoundText"][this.lang]
    }

    get titleMeeting() {
        return this.jsonData["titleMeeting"][this.lang]
    }

    get meetingCancelled() {
        return this.jsonData["meetingCancelled"][this.lang]
    }

    get roundedButtonStartChatBgColor() {
        return this.jsonData["roundedButtonStartChatBgColor"]
    }

    get roundedButtonStartChatTextColor() {
        return this.jsonData["roundedButtonStartChatTextColor"]
    }

    get roundedButtonStartChatBgDisabledColor() {
        return this.jsonData["roundedButtonStartChatBgDisabledColor"]
    }

    get roundedButtonStartChatBgOnHoverColor() {
        return this.jsonData["roundedButtonStartChatBgOnHoverColor"]
    }

    get chatBlockedForPrivateEventText() {
        return this.jsonData["chatBlockedForPrivateEventText"][this.lang]
    }

    get headerInfoBubbleBackgroundColor() {
        return this.jsonData["headerInfoBubbleBackgroundColor"]
    }

    get headerInfoBubbleColor() {
        return this.jsonData["headerInfoBubbleColor"]
    }

    get participantJoinedMessageText() {
        return this.jsonData["participantJoinedMessageText"][this.lang]
    }

    get participantRemovedMessageText() {
        return this.jsonData["participantRemovedMessageText"][this.lang]
    }

    get participantLeftMessageText() {
        return this.jsonData["participantLeftMessageText"][this.lang]
    }
}

class ContactEntryBrandingImpl extends BrandingData implements ContactEntryBranding {
    _disableBookmarkIcon!: boolean;
    _disableChatIcon!: boolean;
    _disableVideoChatIcon!: boolean;
    _disableMeetingIcon!: boolean;
    _disableConnectIcon!: boolean;
    _disableShareIcon!: boolean;
    _disableBlockIcon!: boolean;
    _disableDownloadIcon!: boolean;
    _disableReportIcon!: boolean;
    _personNameFont!: string;
    _personNameFontSize!: string;
    _personPositionFont!: string;
    _personPositionFontSize!: string;
    _personPositionFontWeight!: string;
    _contactEntryLetterSpacing!: string;
    _bookmarkTitle!: LocalizedString;
    _bookmarkHint!: LocalizedString;
    _unbookmarkTitle!: LocalizedString;
    _unbookmarkHint!: LocalizedString;
    _sendMessageTitle!: LocalizedString;
    _sendMessageHint!: LocalizedString;
    _startCallTitle!: LocalizedString;
    _startCallHint!: LocalizedString;
    _inviteToCallHint!: LocalizedString;
    _requestMeetingTitle!: LocalizedString;
    _requestMeetingHint!: LocalizedString;
    _connectTitle!: LocalizedString;
    _connectHint!: LocalizedString;
    _disconnectTitle!: LocalizedString;
    _disconnectHint!: LocalizedString;
    _shareTitle!: LocalizedString;
    _shareHint!: LocalizedString;
    _blockPersonTitle!: LocalizedString;
    _blockPersonHint!: LocalizedString;
    _unblockPersonTitle!: LocalizedString;
    _unblockPersonHint!: LocalizedString;
    _downloadVCardTitle!: LocalizedString;
    _downloadVCardHint!: LocalizedString;
    _cancelRequestTitle!: LocalizedString;
    _cancelRequestMessage!: LocalizedString;
    _cancelConnectionMessage!: LocalizedString;
    _ignoredMessage!: LocalizedString;
    _openRequestMessage!: LocalizedString;
    _openRequestMessageHint!: LocalizedString;
    _sendRequestMessage!: LocalizedString;
    _expoUserBadgeTitle!: LocalizedString;
    _speakerUserBadgeTitle!: LocalizedString;
    _visitorUserBadgeTitle!: LocalizedString;
    _addStaffText!: LocalizedString;
    _removeStaffText!: LocalizedString;

    get disableBookmarkIcon() {
        return this.jsonData["disableBookmarkIcon"]
    }

    get disableChatIcon() {
        return this.jsonData["disableChatIcon"]
    }

    get disableVideoChatIcon() {
        return this.jsonData["disableVideoChatIcon"]
    }

    get disableMeetingIcon() {
        return this.jsonData["disableMeetingIcon"]
    }

    get disableConnectIcon() {
        return this.jsonData["disableConnectIcon"]
    }

    get disableShareIcon() {
        return this.jsonData["disableShareIcon"]
    }

    get disableBlockIcon() {
        return this.jsonData["disableBlockIcon"]
    }

    get disableDownloadIcon() {
        return this.jsonData["disableDownloadIcon"]
    }

    get disableReportIcon() {
        return this.jsonData["disableReportIcon"]
    }

    get personNameFont() {
        return this.jsonData["personNameFont"]
    }

    get personNameFontSize() {
        return this.jsonData["personNameFontSize"]
    }

    get personPositionFont() {
        return this.jsonData["personPositionFont"]
    }

    get personPositionFontSize() {
        return this.jsonData["personPositionFontSize"]
    }

    get personPositionFontWeight() {
        return this.jsonData["personPositionFontWeight"]
    }

    get contactEntryLetterSpacing() {
        return this.jsonData["contactEntryLetterSpacing"]
    }

    get bookmarkTitle() {
        return this.jsonData["bookmarkTitle"][this.lang]
    }

    get bookmarkHint() {
        return this.jsonData["bookmarkHint"][this.lang]
    }

    get unbookmarkTitle() {
        return this.jsonData["unbookmarkTitle"][this.lang]
    }

    get unbookmarkHint() {
        return this.jsonData["unbookmarkHint"][this.lang]
    }

    get sendMessageTitle() {
        return this.jsonData["sendMessageTitle"][this.lang]
    }

    get sendMessageHint() {
        return this.jsonData["sendMessageHint"][this.lang]
    }

    get startCallTitle() {
        return this.jsonData["startCallTitle"][this.lang]
    }

    get startCallHint() {
        return this.jsonData["startCallHint"][this.lang]
    }

    get inviteToCallHint() {
        return this.jsonData["inviteToCallHint"][this.lang]
    }

    get requestMeetingTitle() {
        return this.jsonData["requestMeetingTitle"][this.lang]
    }

    get requestMeetingHint() {
        return this.jsonData["requestMeetingHint"][this.lang]
    }

    get connectTitle() {
        return this.jsonData["connectTitle"][this.lang]
    }

    get connectHint() {
        return this.jsonData["connectHint"][this.lang]
    }

    get disconnectTitle() {
        return this.jsonData["disconnectTitle"][this.lang]
    }

    get disconnectHint() {
        return this.jsonData["disconnectHint"][this.lang]
    }

    get shareTitle() {
        return this.jsonData["shareTitle"][this.lang]
    }

    get shareHint() {
        return this.jsonData["shareHint"][this.lang]
    }

    get blockPersonTitle() {
        return this.jsonData["blockPersonTitle"][this.lang]
    }

    get blockPersonHint() {
        return this.jsonData["blockPersonHint"][this.lang]
    }

    get unblockPersonTitle() {
        return this.jsonData["unblockPersonTitle"][this.lang]
    }

    get unblockPersonHint() {
        return this.jsonData["unblockPersonHint"][this.lang]
    }

    get downloadVCardTitle() {
        return this.jsonData["downloadVCardTitle"][this.lang]
    }

    get downloadVCardHint() {
        return this.jsonData["downloadVCardHint"][this.lang]
    }

    get cancelRequestTitle() {
        return this.jsonData["cancelRequestTitle"][this.lang]
    }

    get cancelRequestMessage() {
        return this.jsonData["cancelRequestMessage"][this.lang]
    }

    get cancelConnectionMessage() {
        return this.jsonData["cancelConnectionMessage"][this.lang]
    }

    get ignoredMessage() {
        return this.jsonData["ignoredMessage"][this.lang]
    }

    get openRequestMessage() {
        return this.jsonData["openRequestMessage"][this.lang]
    }

    get openRequestMessageHint() {
        return this.jsonData["openRequestMessageHint"][this.lang]
    }

    get sendRequestMessage() {
        return this.jsonData["sendRequestMessage"][this.lang]
    }

    get expoUserBadgeTitle() {
        return this.jsonData["expoUserBadgeTitle"][this.lang]
    }

    get speakerUserBadgeTitle() {
        return this.jsonData["speakerUserBadgeTitle"][this.lang]
    }

    get visitorUserBadgeTitle() {
        return this.jsonData["visitorUserBadgeTitle"][this.lang]
    }

    get addStaffText() {
        return this.jsonData["addStaffText"][this.lang]
    }

    get removeStaffText() {
        return this.jsonData["removeStaffText"][this.lang]
    }
}

class DeviceSwitcherBrandingImpl extends BrandingData implements DeviceSwitcherBranding {
    _videoInputLabel!: LocalizedString;
    _noVideoInputsLabel!: LocalizedString;
    _audioInputLabel!: LocalizedString;
    _noAudioInputsLabel!: LocalizedString;
    _audioOutputLabel!: LocalizedString;
    _noAudioOutputsLabel!: LocalizedString;
    _deviceSwitcherRowColor!: ColorString;

    get videoInputLabel() {
        return this.jsonData["videoInputLabel"][this.lang]
    }

    get noVideoInputsLabel() {
        return this.jsonData["noVideoInputsLabel"][this.lang]
    }

    get audioInputLabel() {
        return this.jsonData["audioInputLabel"][this.lang]
    }

    get noAudioInputsLabel() {
        return this.jsonData["noAudioInputsLabel"][this.lang]
    }

    get audioOutputLabel() {
        return this.jsonData["audioOutputLabel"][this.lang]
    }

    get noAudioOutputsLabel() {
        return this.jsonData["noAudioOutputsLabel"][this.lang]
    }

    get deviceSwitcherRowColor() {
        return this.jsonData["deviceSwitcherRowColor"]
    }
}

class SideIconBarBrandingImpl extends BrandingData implements SideIconBarBranding {
    _searchIconText!: LocalizedString;
    _sideIconBarTextColor!: ColorString;
    _sideIconBarMeetingTextColor!: ColorString;
    _homeIconText!: LocalizedString;
    _menuTooltipTextnavBarOpen!: LocalizedString;
    _menuTooltipTextnavBarClosed!: LocalizedString;
    _conferenceIconText!: LocalizedString;
    _seminarsIconText!: LocalizedString;
    _chatroomsIconText!: LocalizedString;
    _networkIconText!: LocalizedString;
    _expoIconText!: LocalizedString;
    _bookmarksIconText!: LocalizedString;
    _bookmarksTooltip!: LocalizedString;
    _historyIconText!: LocalizedString;
    _licensingIconText!: LocalizedString;
    _profileIconText!: LocalizedString;
    _searchBarPlaceholder!: LocalizedString;
    _searchBarColor!: ColorString;
    _lobbyMenuText!: LocalizedString;
    _liveMenuText!: LocalizedString;
    _masterclassesMenuText!: LocalizedString;
    _programMenuText!: LocalizedString;
    _virtualcafeMenuText!: LocalizedString;
    _collectionsMenuText!: LocalizedString;
    _collectionItemTitle!: LocalizedString;
    _hallplanMenuText!: LocalizedString;
    _networkingMenuText!: LocalizedString;
    _showfloorMenuText!: LocalizedString;
    _mediapartnersMenuText!: LocalizedString;
    _pressMediaMenuText!: LocalizedString;
    _iFrameTestMenuText!: LocalizedString;
    _businessAreaMenuText!: LocalizedString;
    _exhibitorStartupMenuText!: LocalizedString;
    _goodiebagMenuText!: LocalizedString;
    _companiesSearchDrawerText!: LocalizedString;
    _peopleSearchDrawerText!: LocalizedString;
    _networkingParticipantsSearchDrawerText!: LocalizedString;
    _programSearchDrawerText!: LocalizedString;
    _productsSearchDrawerText!: LocalizedString;
    _trademarksSearchDrawerText!: LocalizedString;
    _newsSearchDrawerText!: LocalizedString;
    _viewAllCompaniesSearchDrawerText!: LocalizedString;
    _viewAllPeopleSearchDrawerText!: LocalizedString;
    _viewAllProgramSearchDrawerText!: LocalizedString;
    _viewAllProductsSearchDrawerText!: LocalizedString;
    _viewAllTrademarksSearchDrawerText!: LocalizedString;
    _viewAllNewsSearchDrawerText!: LocalizedString;
    _viewAllCouponsSearchDrawerText!: LocalizedString;
    _emptyResultMessage!: LocalizedString;
    _topSponsorsVisible!: boolean;
    _topSponsorsText!: LocalizedString;
    _ideaSponsorsVisible!: boolean;
    _ideaSponsorsText!: LocalizedString;
    _specialSponsorLogoVisible!: boolean;
    _specialSponsorLogo!: string;
    _specialSponsorLogoLink!: string;
    _helpItem!: ExternalLinksBranding;
    _rulesItem!: ExternalLinksBranding;
    _termsOfUseItem!: ExternalLinksBranding;
    _dataPrivacyPolicyItem!: ExternalLinksBranding;
    _imprintItem!: ExternalLinksBranding;
    _sideIconSearchValueColor!: ColorString;
    _sideIconBarItemsOrder!: string[];
    _sideIconBarFooterItemsOrder!: string[];
    _drawerButtonSelectColor!: ColorString;
    _drawerButtonSelectBackgroundColor!: string;
    _drawerClosedButtonSelectBackgroundColor!: string;
    _descriptionTextUnderIconVisible!: boolean;
    _sideIconColorDark!: ColorString;
    _sideIconColorLight!: ColorString;
    _hasSecondLogoIcon!: boolean;
    _showCloseBannerIcon!: boolean;
    _listItemTextFontWeight!: string;
    _maxWidthForSecondLogoIcon!: string;
    _sideBarListItemFontSize!: string;
    _sideBarLogoWidth!: string;
    _sideBarLogoHeight!: string;
    _inactiveItemColor!: ColorString;
    _backgroundColorForTopSponsors!: ColorString;
    _cookieSettingsTitle!: LocalizedString;
    _moreItemsTitle!: LocalizedString;
    _languageSwitcherTitle!: LocalizedString;
    _serviceHelpTitle!: LocalizedString;
    _subMenuOpenBgColor!: string;
    _subMenuOpenTextColor!: string;
    _mobileNavigationItemBorderColor!: string;
    _mobileNavigationBorder!: string;
    _mobileNavigationBottomBorderShadow!: string;

    get searchIconText() {
        return this.jsonData["searchIconText"][this.lang]
    }

    get sideIconBarTextColor() {
        return this.jsonData["sideIconBarTextColor"]
    }

    get sideIconBarMeetingTextColor() {
        return this.jsonData["sideIconBarMeetingTextColor"]
    }

    get homeIconText() {
        return this.jsonData["homeIconText"][this.lang]
    }

    get menuTooltipTextnavBarOpen() {
        return this.jsonData["menuTooltipTextnavBarOpen"][this.lang]
    }

    get menuTooltipTextnavBarClosed() {
        return this.jsonData["menuTooltipTextnavBarClosed"][this.lang]
    }

    get conferenceIconText() {
        return this.jsonData["conferenceIconText"][this.lang]
    }

    get seminarsIconText() {
        return this.jsonData["seminarsIconText"][this.lang]
    }

    get chatroomsIconText() {
        return this.jsonData["chatroomsIconText"][this.lang]
    }

    get networkIconText() {
        return this.jsonData["networkIconText"][this.lang]
    }

    get expoIconText() {
        return this.jsonData["expoIconText"][this.lang]
    }

    get bookmarksIconText() {
        return this.jsonData["bookmarksIconText"][this.lang]
    }

    get bookmarksTooltip() {
        return this.jsonData["bookmarksTooltip"][this.lang]
    }

    get historyIconText() {
        return this.jsonData["historyIconText"][this.lang]
    }

    get licensingIconText() {
        return this.jsonData["licensingIconText"][this.lang]
    }

    get profileIconText() {
        return this.jsonData["profileIconText"][this.lang]
    }

    get searchBarPlaceholder() {
        return this.jsonData["searchBarPlaceholder"][this.lang]
    }

    get searchBarColor() {
        return this.jsonData["searchBarColor"]
    }

    get lobbyMenuText() {
        return this.jsonData["lobbyMenuText"][this.lang]
    }

    get liveMenuText() {
        return this.jsonData["liveMenuText"][this.lang]
    }

    get masterclassesMenuText() {
        return this.jsonData["masterclassesMenuText"][this.lang]
    }

    get programMenuText() {
        return this.jsonData["programMenuText"][this.lang]
    }

    get virtualcafeMenuText() {
        return this.jsonData["virtualcafeMenuText"][this.lang]
    }

    get collectionsMenuText() {
        return this.jsonData["collectionsMenuText"][this.lang]
    }

    get collectionItemTitle() {
        return this.jsonData["collectionItemTitle"][this.lang]
    }

    get hallplanMenuText() {
        return this.jsonData["hallplanMenuText"][this.lang]
    }

    get networkingMenuText() {
        return this.jsonData["networkingMenuText"][this.lang]
    }

    get showfloorMenuText() {
        return this.jsonData["showfloorMenuText"][this.lang]
    }

    get mediapartnersMenuText() {
        return this.jsonData["mediapartnersMenuText"][this.lang]
    }

    get pressMediaMenuText() {
        return this.jsonData["pressMediaMenuText"][this.lang]
    }

    get iFrameTestMenuText() {
        return this.jsonData["iFrameTestMenuText"][this.lang]
    }

    get businessAreaMenuText() {
        return this.jsonData["businessAreaMenuText"][this.lang]
    }

    get exhibitorStartupMenuText() {
        return this.jsonData["exhibitorStartupMenuText"][this.lang]
    }

    get goodiebagMenuText() {
        return this.jsonData["goodiebagMenuText"][this.lang]
    }

    get companiesSearchDrawerText() {
        return this.jsonData["companiesSearchDrawerText"][this.lang]
    }

    get peopleSearchDrawerText() {
        return this.jsonData["peopleSearchDrawerText"][this.lang]
    }

    get networkingParticipantsSearchDrawerText() {
        return this.jsonData["networkingParticipantsSearchDrawerText"][this.lang]
    }

    get programSearchDrawerText() {
        return this.jsonData["programSearchDrawerText"][this.lang]
    }

    get productsSearchDrawerText() {
        return this.jsonData["productsSearchDrawerText"][this.lang]
    }

    get trademarksSearchDrawerText() {
        return this.jsonData["trademarksSearchDrawerText"][this.lang]
    }

    get newsSearchDrawerText() {
        return this.jsonData["newsSearchDrawerText"][this.lang]
    }

    get viewAllCompaniesSearchDrawerText() {
        return this.jsonData["viewAllCompaniesSearchDrawerText"][this.lang]
    }

    get viewAllPeopleSearchDrawerText() {
        return this.jsonData["viewAllPeopleSearchDrawerText"][this.lang]
    }

    get viewAllProgramSearchDrawerText() {
        return this.jsonData["viewAllProgramSearchDrawerText"][this.lang]
    }

    get viewAllProductsSearchDrawerText() {
        return this.jsonData["viewAllProductsSearchDrawerText"][this.lang]
    }

    get viewAllTrademarksSearchDrawerText() {
        return this.jsonData["viewAllTrademarksSearchDrawerText"][this.lang]
    }

    get viewAllNewsSearchDrawerText() {
        return this.jsonData["viewAllNewsSearchDrawerText"][this.lang]
    }

    get viewAllCouponsSearchDrawerText() {
        return this.jsonData["viewAllCouponsSearchDrawerText"][this.lang]
    }

    get emptyResultMessage() {
        return this.jsonData["emptyResultMessage"][this.lang]
    }

    get topSponsorsVisible() {
        return this.jsonData["topSponsorsVisible"]
    }

    get topSponsorsText() {
        return this.jsonData["topSponsorsText"][this.lang]
    }

    get ideaSponsorsVisible() {
        return this.jsonData["ideaSponsorsVisible"]
    }

    get ideaSponsorsText() {
        return this.jsonData["ideaSponsorsText"][this.lang]
    }

    get specialSponsorLogoVisible() {
        return this.jsonData["specialSponsorLogoVisible"]
    }

    get specialSponsorLogo() {
        return this.jsonData["specialSponsorLogo"]
    }

    get specialSponsorLogoLink() {
        return this.jsonData["specialSponsorLogoLink"]
    }

    get helpItem() {
        if (!this._helpItem) {
            this._helpItem = new ExternalLinksBrandingImpl(this.jsonData["helpItem"], this.lang)
            this.subBrandings.push(this._helpItem as any as BrandingData)
        }
        return this._helpItem
    }

    get rulesItem() {
        if (!this._rulesItem) {
            this._rulesItem = new ExternalLinksBrandingImpl(this.jsonData["rulesItem"], this.lang)
            this.subBrandings.push(this._rulesItem as any as BrandingData)
        }
        return this._rulesItem
    }

    get termsOfUseItem() {
        if (!this._termsOfUseItem) {
            this._termsOfUseItem = new ExternalLinksBrandingImpl(this.jsonData["termsOfUseItem"], this.lang)
            this.subBrandings.push(this._termsOfUseItem as any as BrandingData)
        }
        return this._termsOfUseItem
    }

    get dataPrivacyPolicyItem() {
        if (!this._dataPrivacyPolicyItem) {
            this._dataPrivacyPolicyItem = new ExternalLinksBrandingImpl(this.jsonData["dataPrivacyPolicyItem"], this.lang)
            this.subBrandings.push(this._dataPrivacyPolicyItem as any as BrandingData)
        }
        return this._dataPrivacyPolicyItem
    }

    get imprintItem() {
        if (!this._imprintItem) {
            this._imprintItem = new ExternalLinksBrandingImpl(this.jsonData["imprintItem"], this.lang)
            this.subBrandings.push(this._imprintItem as any as BrandingData)
        }
        return this._imprintItem
    }

    get sideIconSearchValueColor() {
        return this.jsonData["sideIconSearchValueColor"]
    }

    get sideIconBarItemsOrder() {
        return this.jsonData["sideIconBarItemsOrder"]
    }

    get sideIconBarFooterItemsOrder() {
        return this.jsonData["sideIconBarFooterItemsOrder"]
    }

    get drawerButtonSelectColor() {
        return this.jsonData["drawerButtonSelectColor"]
    }

    get drawerButtonSelectBackgroundColor() {
        return this.jsonData["drawerButtonSelectBackgroundColor"]
    }

    get drawerClosedButtonSelectBackgroundColor() {
        return this.jsonData["drawerClosedButtonSelectBackgroundColor"]
    }

    get descriptionTextUnderIconVisible() {
        return this.jsonData["descriptionTextUnderIconVisible"]
    }

    get sideIconColorDark() {
        return this.jsonData["sideIconColorDark"]
    }

    get sideIconColorLight() {
        return this.jsonData["sideIconColorLight"]
    }

    get hasSecondLogoIcon() {
        return this.jsonData["hasSecondLogoIcon"]
    }

    get showCloseBannerIcon() {
        return this.jsonData["showCloseBannerIcon"]
    }

    get listItemTextFontWeight() {
        return this.jsonData["listItemTextFontWeight"]
    }

    get maxWidthForSecondLogoIcon() {
        return this.jsonData["maxWidthForSecondLogoIcon"]
    }

    get sideBarListItemFontSize() {
        return this.jsonData["sideBarListItemFontSize"]
    }

    get sideBarLogoWidth() {
        return this.jsonData["sideBarLogoWidth"]
    }

    get sideBarLogoHeight() {
        return this.jsonData["sideBarLogoHeight"]
    }

    get inactiveItemColor() {
        return this.jsonData["inactiveItemColor"]
    }

    get backgroundColorForTopSponsors() {
        return this.jsonData["backgroundColorForTopSponsors"]
    }

    get cookieSettingsTitle() {
        return this.jsonData["cookieSettingsTitle"][this.lang]
    }

    get moreItemsTitle() {
        return this.jsonData["moreItemsTitle"][this.lang]
    }

    get languageSwitcherTitle() {
        return this.jsonData["languageSwitcherTitle"][this.lang]
    }

    get serviceHelpTitle() {
        return this.jsonData["serviceHelpTitle"][this.lang]
    }

    get subMenuOpenBgColor() {
        return this.jsonData["subMenuOpenBgColor"]
    }

    get subMenuOpenTextColor() {
        return this.jsonData["subMenuOpenTextColor"]
    }

    get mobileNavigationItemBorderColor() {
        return this.jsonData["mobileNavigationItemBorderColor"]
    }

    get mobileNavigationBorder() {
        return this.jsonData["mobileNavigationBorder"]
    }

    get mobileNavigationBottomBorderShadow() {
        return this.jsonData["mobileNavigationBottomBorderShadow"]
    }
}

class ExternalLinksBrandingImpl extends BrandingData implements ExternalLinksBranding {
    _title!: LocalizedString;
    _isExternalLink!: boolean;
    _isInternalEmbedLink!: boolean;
    _link!: LocalizedString;
    _externalLink!: LocalizedString;
    _internalEmbedLink!: string;
    _visible!: boolean;

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get isExternalLink() {
        return this.jsonData["isExternalLink"]
    }

    get isInternalEmbedLink() {
        return this.jsonData["isInternalEmbedLink"]
    }

    get link() {
        return this.jsonData["link"][this.lang]
    }

    get externalLink() {
        return this.jsonData["externalLink"][this.lang]
    }

    get internalEmbedLink() {
        return this.jsonData["internalEmbedLink"]
    }

    get visible() {
        return this.jsonData["visible"]
    }
}

class NavigationAreaBrandingImpl extends BrandingData implements NavigationAreaBranding {
    _speakersItemTitle!: LocalizedString;
    _productsItemTitle!: LocalizedString;
    _trademarksItemTitle!: LocalizedString;
    _newsItemTitle!: LocalizedString;
    _couponItemTitle!: LocalizedString;
    _sponsorsItemTitle!: LocalizedString;
    _sessionsItemTitle!: LocalizedString;
    _liveSessionsItemTitle!: LocalizedString;
    _videoOnDemandItemTitle!: LocalizedString;
    _futureCafeItemTitle!: LocalizedString;
    _businessCafeItemTitle!: LocalizedString;
    _marketingCafeItemTitle!: LocalizedString;
    _technologyCafeItemTitle!: LocalizedString;
    _networkingItemTitle!: LocalizedString;
    _participantsItemTitle!: LocalizedString;
    _companiesItemTitle!: LocalizedString;
    _peopleItemTitle!: LocalizedString;
    _eventdateItemTitle!: LocalizedString;
    _scheduleItemTitle!: LocalizedString;
    _presenceItemTitle!: LocalizedString;
    _themesItemTitle!: LocalizedString;
    _themeItemTitle!: LocalizedString;
    _presenceTypes!: PresenceTypeNames;
    _signOutTitle!: LocalizedString;
    _showProduct!: boolean;
    _showTrademark!: boolean;
    _crsTabsItemOrder!: string[];
    _showCategories!: boolean;

    get speakersItemTitle() {
        return this.jsonData["speakersItemTitle"][this.lang]
    }

    get productsItemTitle() {
        return this.jsonData["productsItemTitle"][this.lang]
    }

    get trademarksItemTitle() {
        return this.jsonData["trademarksItemTitle"][this.lang]
    }

    get newsItemTitle() {
        return this.jsonData["newsItemTitle"][this.lang]
    }

    get couponItemTitle() {
        return this.jsonData["couponItemTitle"][this.lang]
    }

    get sponsorsItemTitle() {
        return this.jsonData["sponsorsItemTitle"][this.lang]
    }

    get sessionsItemTitle() {
        return this.jsonData["sessionsItemTitle"][this.lang]
    }

    get liveSessionsItemTitle() {
        return this.jsonData["liveSessionsItemTitle"][this.lang]
    }

    get videoOnDemandItemTitle() {
        return this.jsonData["videoOnDemandItemTitle"][this.lang]
    }

    get futureCafeItemTitle() {
        return this.jsonData["futureCafeItemTitle"][this.lang]
    }

    get businessCafeItemTitle() {
        return this.jsonData["businessCafeItemTitle"][this.lang]
    }

    get marketingCafeItemTitle() {
        return this.jsonData["marketingCafeItemTitle"][this.lang]
    }

    get technologyCafeItemTitle() {
        return this.jsonData["technologyCafeItemTitle"][this.lang]
    }

    get networkingItemTitle() {
        return this.jsonData["networkingItemTitle"][this.lang]
    }

    get participantsItemTitle() {
        return this.jsonData["participantsItemTitle"][this.lang]
    }

    get companiesItemTitle() {
        return this.jsonData["companiesItemTitle"][this.lang]
    }

    get peopleItemTitle() {
        return this.jsonData["peopleItemTitle"][this.lang]
    }

    get eventdateItemTitle() {
        return this.jsonData["eventdateItemTitle"][this.lang]
    }

    get scheduleItemTitle() {
        return this.jsonData["scheduleItemTitle"][this.lang]
    }

    get presenceItemTitle() {
        return this.jsonData["presenceItemTitle"][this.lang]
    }

    get themesItemTitle() {
        return this.jsonData["themesItemTitle"][this.lang]
    }

    get themeItemTitle() {
        return this.jsonData["themeItemTitle"][this.lang]
    }

    get presenceTypes() {
        if (!this._presenceTypes) {
            this._presenceTypes = new PresenceTypeNamesImpl(this.jsonData["presenceTypes"], this.lang)
            this.subBrandings.push(this._presenceTypes as any as BrandingData)
        }
        return this._presenceTypes
    }

    get signOutTitle() {
        return this.jsonData["signOutTitle"][this.lang]
    }

    get showProduct() {
        return this.jsonData["showProduct"]
    }

    get showTrademark() {
        return this.jsonData["showTrademark"]
    }

    get crsTabsItemOrder() {
        return this.jsonData["crsTabsItemOrder"]
    }

    get showCategories() {
        return this.jsonData["showCategories"]
    }
}

class PresenceTypeNamesImpl extends BrandingData implements PresenceTypeNames {
    _availableState!: LocalizedString;
    _busyState!: LocalizedString;
    _doNotDisturbState!: LocalizedString;
    _offWork!: LocalizedString;

    get availableState() {
        return this.jsonData["availableState"][this.lang]
    }

    get busyState() {
        return this.jsonData["busyState"][this.lang]
    }

    get doNotDisturbState() {
        return this.jsonData["doNotDisturbState"][this.lang]
    }

    get offWork() {
        return this.jsonData["offWork"][this.lang]
    }
}

class SponsorsPageContentBrandingImpl extends BrandingData implements SponsorsPageContentBranding {
    _sponsorsHeader!: LocalizedString;
    _moreSponsorsInfo!: LocalizedString;
    _listViewAsDefault!: boolean;
    _partnersSponsorsCategoryAliases!: string[];
    _ignoreBasisPremium!: boolean;

    get sponsorsHeader() {
        return this.jsonData["sponsorsHeader"][this.lang]
    }

    get moreSponsorsInfo() {
        return this.jsonData["moreSponsorsInfo"][this.lang]
    }

    get listViewAsDefault() {
        return this.jsonData["listViewAsDefault"]
    }

    get partnersSponsorsCategoryAliases() {
        return this.jsonData["partnersSponsorsCategoryAliases"]
    }

    get ignoreBasisPremium() {
        return this.jsonData["ignoreBasisPremium"]
    }
}

class MediaPartnerPageContentBrandingImpl extends BrandingData implements MediaPartnerPageContentBranding {
    _mediaPartnerName!: LocalizedString;
    _mediaPartnerCategoryAliases!: string[];

    get mediaPartnerName() {
        return this.jsonData["mediaPartnerName"][this.lang]
    }

    get mediaPartnerCategoryAliases() {
        return this.jsonData["mediaPartnerCategoryAliases"]
    }
}

class CalendarEntryModalBrandingImpl extends BrandingData implements CalendarEntryModalBranding {
    _createTitle!: LocalizedString;
    _descriptionTitle!: LocalizedString;
    _descriptionTitleAfter!: LocalizedString;
    _descriptionTitleOrganization!: LocalizedString;
    _editTitle!: LocalizedString;
    _name!: LocalizedString;
    _namePlaceholder!: LocalizedString;
    _searchPerson!: LocalizedString;
    _searchPersonPlaceholder!: LocalizedString;
    _dateSelectionPlaceholder!: LocalizedString;
    _searchPersonIsInvited!: LocalizedString;
    _notFound!: LocalizedString;
    _startDatePlaceholder!: LocalizedString;
    _endDatePlaceholder!: LocalizedString;
    _day!: LocalizedString;
    _start!: LocalizedString;
    _end!: LocalizedString;
    _dayPlaceholder!: LocalizedString;
    _notes!: LocalizedString;
    _notesPlaceholder!: LocalizedString;
    _sendInvite!: LocalizedString;
    _saveChanges!: LocalizedString;
    _deleteMeeting!: LocalizedString;
    _dayFormat!: LocalizedString;
    _calendarEntryParticipationStatusAccepted!: LocalizedString;
    _calendarEntryParticipationStatusDeclined!: LocalizedString;
    _calendarEntryParticipationStatusRequested!: LocalizedString;
    _cancel!: LocalizedString;
    _cancelText!: LocalizedString;
    _datePickerMenuHeaderColor!: ColorString;
    _timePickerDefaultMinutes!: number;
    _timeZoneModalTitle!: LocalizedString;
    _timeZoneDropdownCurrentTimezoneTitle!: LocalizedString;
    _timeZone!: LocalizedString;
    _available!: LocalizedString;
    _busy!: LocalizedString;
    _resultItemHover!: ColorString;
    _showEventDatesAvailability!: boolean;
    _calendarTheme!: ColorString;
    _calendarIconActiveColor!: ColorString;
    _currentTimeAreaTheme!: ColorString;
    _availabilityTitle!: LocalizedString;
    _busyLegendText!: LocalizedString;
    _availabilityButtonText!: LocalizedString;
    _fieldError!: LocalizedString;
    _calendarNotValid!: LocalizedString;
    _pastDateErrorMessage!: LocalizedString;
    _exportMeeting!: LocalizedString;
    _locationPickerActive!: boolean;
    _locationPickerTitle!: LocalizedString;
    _locationPickerPlaceholderStand!: LocalizedString;
    _locationPickerPlaceholderCustom!: LocalizedString;
    _locationPickerRadioButtonColor!: ColorString;
    _locationPickerVirtualMeetingLabel!: LocalizedString;
    _locationPickerCustomRadioButtonLabel!: LocalizedString;
    _locationPickerSearchConfig!: GroupConfig;

    get createTitle() {
        return this.jsonData["createTitle"][this.lang]
    }

    get descriptionTitle() {
        return this.jsonData["descriptionTitle"][this.lang]
    }

    get descriptionTitleAfter() {
        return this.jsonData["descriptionTitleAfter"][this.lang]
    }

    get descriptionTitleOrganization() {
        return this.jsonData["descriptionTitleOrganization"][this.lang]
    }

    get editTitle() {
        return this.jsonData["editTitle"][this.lang]
    }

    get name() {
        return this.jsonData["name"][this.lang]
    }

    get namePlaceholder() {
        return this.jsonData["namePlaceholder"][this.lang]
    }

    get searchPerson() {
        return this.jsonData["searchPerson"][this.lang]
    }

    get searchPersonPlaceholder() {
        return this.jsonData["searchPersonPlaceholder"][this.lang]
    }

    get dateSelectionPlaceholder() {
        return this.jsonData["dateSelectionPlaceholder"][this.lang]
    }

    get searchPersonIsInvited() {
        return this.jsonData["searchPersonIsInvited"][this.lang]
    }

    get notFound() {
        return this.jsonData["notFound"][this.lang]
    }

    get startDatePlaceholder() {
        return this.jsonData["startDatePlaceholder"][this.lang]
    }

    get endDatePlaceholder() {
        return this.jsonData["endDatePlaceholder"][this.lang]
    }

    get day() {
        return this.jsonData["day"][this.lang]
    }

    get start() {
        return this.jsonData["start"][this.lang]
    }

    get end() {
        return this.jsonData["end"][this.lang]
    }

    get dayPlaceholder() {
        return this.jsonData["dayPlaceholder"][this.lang]
    }

    get notes() {
        return this.jsonData["notes"][this.lang]
    }

    get notesPlaceholder() {
        return this.jsonData["notesPlaceholder"][this.lang]
    }

    get sendInvite() {
        return this.jsonData["sendInvite"][this.lang]
    }

    get saveChanges() {
        return this.jsonData["saveChanges"][this.lang]
    }

    get deleteMeeting() {
        return this.jsonData["deleteMeeting"][this.lang]
    }

    get dayFormat() {
        return this.jsonData["dayFormat"][this.lang]
    }

    get calendarEntryParticipationStatusAccepted() {
        return this.jsonData["calendarEntryParticipationStatusAccepted"][this.lang]
    }

    get calendarEntryParticipationStatusDeclined() {
        return this.jsonData["calendarEntryParticipationStatusDeclined"][this.lang]
    }

    get calendarEntryParticipationStatusRequested() {
        return this.jsonData["calendarEntryParticipationStatusRequested"][this.lang]
    }

    get cancel() {
        return this.jsonData["cancel"][this.lang]
    }

    get cancelText() {
        return this.jsonData["cancelText"][this.lang]
    }

    get datePickerMenuHeaderColor() {
        return this.jsonData["datePickerMenuHeaderColor"]
    }

    get timePickerDefaultMinutes() {
        return this.jsonData["timePickerDefaultMinutes"]
    }

    get timeZoneModalTitle() {
        return this.jsonData["timeZoneModalTitle"][this.lang]
    }

    get timeZoneDropdownCurrentTimezoneTitle() {
        return this.jsonData["timeZoneDropdownCurrentTimezoneTitle"][this.lang]
    }

    get timeZone() {
        return this.jsonData["timeZone"][this.lang]
    }

    get available() {
        return this.jsonData["available"][this.lang]
    }

    get busy() {
        return this.jsonData["busy"][this.lang]
    }

    get resultItemHover() {
        return this.jsonData["resultItemHover"]
    }

    get showEventDatesAvailability() {
        return this.jsonData["showEventDatesAvailability"]
    }

    get calendarTheme() {
        return this.jsonData["calendarTheme"]
    }

    get calendarIconActiveColor() {
        return this.jsonData["calendarIconActiveColor"]
    }

    get currentTimeAreaTheme() {
        return this.jsonData["currentTimeAreaTheme"]
    }

    get availabilityTitle() {
        return this.jsonData["availabilityTitle"][this.lang]
    }

    get busyLegendText() {
        return this.jsonData["busyLegendText"][this.lang]
    }

    get availabilityButtonText() {
        return this.jsonData["availabilityButtonText"][this.lang]
    }

    get fieldError() {
        return this.jsonData["fieldError"][this.lang]
    }

    get calendarNotValid() {
        return this.jsonData["calendarNotValid"][this.lang]
    }

    get pastDateErrorMessage() {
        return this.jsonData["pastDateErrorMessage"][this.lang]
    }

    get exportMeeting() {
        return this.jsonData["exportMeeting"][this.lang]
    }

    get locationPickerActive() {
        return this.jsonData["locationPickerActive"]
    }

    get locationPickerTitle() {
        return this.jsonData["locationPickerTitle"][this.lang]
    }

    get locationPickerPlaceholderStand() {
        return this.jsonData["locationPickerPlaceholderStand"][this.lang]
    }

    get locationPickerPlaceholderCustom() {
        return this.jsonData["locationPickerPlaceholderCustom"][this.lang]
    }

    get locationPickerRadioButtonColor() {
        return this.jsonData["locationPickerRadioButtonColor"]
    }

    get locationPickerVirtualMeetingLabel() {
        return this.jsonData["locationPickerVirtualMeetingLabel"][this.lang]
    }

    get locationPickerCustomRadioButtonLabel() {
        return this.jsonData["locationPickerCustomRadioButtonLabel"][this.lang]
    }

    get locationPickerSearchConfig() {
        if (!this._locationPickerSearchConfig) {
            this._locationPickerSearchConfig = new GroupConfigImpl(this.jsonData["locationPickerSearchConfig"], this.lang)
            this.subBrandings.push(this._locationPickerSearchConfig as any as BrandingData)
        }
        return this._locationPickerSearchConfig
    }
}

class GroupConfigImpl extends BrandingData implements GroupConfig {
    _id!: string;
    _type!: GroupConfigType;
    _entityType!: "eventdate" | "person" | "organization" | "product" | "trademark" | "news" | "category" | "customcategory" | "coupon" | "networking_user" | "sotuser" | "event" | "country" | "joboffer" | "stand_registration";
    _msc!: number;

    get id() {
        return this.jsonData["id"]
    }

    get type() {
        return this.jsonData["type"]
    }

    get entityType() {
        return this.jsonData["entityType"]
    }

    get msc() {
        return this.jsonData["msc"]
    }
}

class CalendarEntryListViewBrandingImpl extends BrandingData implements CalendarEntryListViewBranding {
    _editButtonTitle!: LocalizedString;
    _viewButtonTitle!: LocalizedString;
    _deleteButtonTitle!: LocalizedString;
    _callButtonTitle!: LocalizedString;
    _chatButtonTitle!: LocalizedString;
    _exportButtonTitle!: LocalizedString;
    _declineButtonTitle!: LocalizedString;
    _declineMeetingText!: LocalizedString;
    _acceptButtonTitle!: LocalizedString;
    _declineTitle!: LocalizedString;
    _acceptTitle!: LocalizedString;
    _dateTo!: LocalizedString;
    _dateHourFormat!: LocalizedString;
    _dateDayFormat!: LocalizedString;
    _dateContentPanelTextColor!: ColorString;
    _titleContentPanelTextColor!: ColorString;

    get editButtonTitle() {
        return this.jsonData["editButtonTitle"][this.lang]
    }

    get viewButtonTitle() {
        return this.jsonData["viewButtonTitle"][this.lang]
    }

    get deleteButtonTitle() {
        return this.jsonData["deleteButtonTitle"][this.lang]
    }

    get callButtonTitle() {
        return this.jsonData["callButtonTitle"][this.lang]
    }

    get chatButtonTitle() {
        return this.jsonData["chatButtonTitle"][this.lang]
    }

    get exportButtonTitle() {
        return this.jsonData["exportButtonTitle"][this.lang]
    }

    get declineButtonTitle() {
        return this.jsonData["declineButtonTitle"][this.lang]
    }

    get declineMeetingText() {
        return this.jsonData["declineMeetingText"][this.lang]
    }

    get acceptButtonTitle() {
        return this.jsonData["acceptButtonTitle"][this.lang]
    }

    get declineTitle() {
        return this.jsonData["declineTitle"][this.lang]
    }

    get acceptTitle() {
        return this.jsonData["acceptTitle"][this.lang]
    }

    get dateTo() {
        return this.jsonData["dateTo"][this.lang]
    }

    get dateHourFormat() {
        return this.jsonData["dateHourFormat"][this.lang]
    }

    get dateDayFormat() {
        return this.jsonData["dateDayFormat"][this.lang]
    }

    get dateContentPanelTextColor() {
        return this.jsonData["dateContentPanelTextColor"]
    }

    get titleContentPanelTextColor() {
        return this.jsonData["titleContentPanelTextColor"]
    }
}

class CalendarEntryExportBrandingImpl extends BrandingData implements CalendarEntryExportBranding {
    _organizer!: LocalizedString;
    _attendees!: LocalizedString;
    _meetingIcsTemplate!: LocalizedString;
    _meetingIcsTitle!: LocalizedString;

    get organizer() {
        return this.jsonData["organizer"][this.lang]
    }

    get attendees() {
        return this.jsonData["attendees"][this.lang]
    }

    get meetingIcsTemplate() {
        return this.jsonData["meetingIcsTemplate"][this.lang]
    }

    get meetingIcsTitle() {
        return this.jsonData["meetingIcsTitle"][this.lang]
    }
}

class EventDateIcsExportBrandingImpl extends BrandingData implements EventDateIcsExportBranding {
    _organizer!: LocalizedString;
    _attendees!: LocalizedString;
    _eventDateIcsTemplate!: LocalizedString;
    _eventDateIcsTitle!: LocalizedString;

    get organizer() {
        return this.jsonData["organizer"][this.lang]
    }

    get attendees() {
        return this.jsonData["attendees"][this.lang]
    }

    get eventDateIcsTemplate() {
        return this.jsonData["eventDateIcsTemplate"][this.lang]
    }

    get eventDateIcsTitle() {
        return this.jsonData["eventDateIcsTitle"][this.lang]
    }
}

class MyProfilePageContentBrandingImpl extends BrandingData implements MyProfilePageContentBranding {
    _salutationVisible!: boolean;
    _myProfileTitle!: LocalizedString;
    _editProfileTitle!: LocalizedString;
    _editProfileButtonText!: LocalizedString;
    _noInterestsSelectedMessage!: LocalizedString;
    _noOffersSelectedMessage!: LocalizedString;
    _noNeedsSelectedMessage!: LocalizedString;
    _goBackLabel!: LocalizedString;
    _profilePictureLabel!: LocalizedString;
    _uploadNewButtonText!: LocalizedString;
    _formatsText!: LocalizedString;
    _deleteCurrentPictureButtonText!: LocalizedString;
    _nameLabel!: LocalizedString;
    _namePlaceholder!: LocalizedString;
    _surnameLabel!: LocalizedString;
    _surnamePlaceholder!: LocalizedString;
    _companyLabel!: LocalizedString;
    _companyPlaceholder!: LocalizedString;
    _jobTitleLabel!: LocalizedString;
    _jobTitlePlaceholder!: LocalizedString;
    _jobTitleDeLabel!: LocalizedString;
    _jobTitleDePlaceholder!: LocalizedString;
    _phoneLabel!: LocalizedString;
    _phonePlaceholder!: LocalizedString;
    _emailLabel!: LocalizedString;
    _adress1Label!: LocalizedString;
    _adress1Placeholder!: LocalizedString;
    _adress2Label!: LocalizedString;
    _adress2Placeholder!: LocalizedString;
    _adress3Label!: LocalizedString;
    _adress3Placeholder!: LocalizedString;
    _cityLabel!: LocalizedString;
    _cityPlaceholder!: LocalizedString;
    _postalCodeLabel!: LocalizedString;
    _postalCodePlaceholder!: LocalizedString;
    _mobileLabel!: LocalizedString;
    _mobilePlaceholder!: LocalizedString;
    _timeZoneLabel!: LocalizedString;
    _industryLabel!: LocalizedString;
    _industryPlaceholder!: LocalizedString;
    _industryDeLabel!: LocalizedString;
    _industryDePlaceholder!: LocalizedString;
    _countryLabel!: LocalizedString;
    _countrySwitcherText!: LocalizedString;
    _languagesLabel!: LocalizedString;
    _languagesSwitcherText!: LocalizedString;
    _showEmailLangPreference!: boolean;
    _emailLangPreferenceLabel!: LocalizedString;
    _emailLangPreferenceText!: LocalizedString;
    _facebookLinkLabel!: LocalizedString;
    _twitterLinkLabel!: LocalizedString;
    _linkedInLinkLabel!: LocalizedString;
    _xingLinkLabel!: LocalizedString;
    _instagramLinkLabel!: LocalizedString;
    _youTubeLinkLabel!: LocalizedString;
    _pinterestLinkLabel!: LocalizedString;
    _biographyLabel!: LocalizedString;
    _biographyPlaceholder!: LocalizedString;
    _biographyDeLabel!: LocalizedString;
    _biographyDePlaceholder!: LocalizedString;
    _biographyMobileLabel!: LocalizedString;
    _numberOfCategories!: number;
    _interestsLabel!: LocalizedString;
    _imOfferingLabel!: LocalizedString;
    _numberOfOffers!: number;
    _imLookingToBuyLabel!: LocalizedString;
    _numberOfNeeds!: number;
    _showMoreButtonText!: LocalizedString;
    _showLessButtonText!: LocalizedString;
    _requiredFieldsLabel!: LocalizedString;
    _saveButtonText!: LocalizedString;
    _cancelButtonText!: LocalizedString;
    _dataSuccessfullyUpdatedText!: LocalizedString;
    _oneRequiredFieldEmptyWarning!: LocalizedString;
    _phoneValidationIncorrectWarning!: LocalizedString;
    _requiredFieldsEmptyWarning!: LocalizedString;
    _validationIncorrectWarning!: LocalizedString;
    _invalidNameWarning!: LocalizedString;
    _invalidSurnameWarning!: LocalizedString;
    _invalidCompanyNameWarning!: LocalizedString;
    _invalidJobTitleWarning!: LocalizedString;
    _invalidIndustryNameWarning!: LocalizedString;
    _invalidPhoneNumberWarning!: LocalizedString;
    _invalidAdressWarning!: LocalizedString;
    _invalidCityWarning!: LocalizedString;
    _invalidPostalCodeWarning!: LocalizedString;
    _invalidFacebookLinkWarning!: LocalizedString;
    _invalidTwitterLinkWarning!: LocalizedString;
    _invalidLinkedInLinkWarning!: LocalizedString;
    _invalidXingLinkWarning!: LocalizedString;
    _invalidInstagramLinkWarning!: LocalizedString;
    _invalidYouTubeLinkWarning!: LocalizedString;
    _invalidPinterestLinkWarning!: LocalizedString;
    _pictureSizeWarning!: LocalizedString;
    _backendErrorWarning!: LocalizedString;
    _searchPlaceholder!: LocalizedString;
    _minPictureHeight!: number;
    _minPictureWidth!: number;
    _editProfileTabs!: EditTab[];
    _basicInfoTabTitle!: LocalizedString;
    _buySellTabTitle!: LocalizedString;
    _yourInterestsSectionTitle!: LocalizedString;
    _backButtonTitle!: LocalizedString;
    _continueButtonTitle!: LocalizedString;
    _displayProfileLangLabel!: LocalizedString;
    _editRequiredFields!: Field[];
    _editDisabledFields!: Field[];
    _editDisabledSocialMedia!: SocialMediaType[];
    _availableSocialMedias!: SocialMediaType[];
    _offersNeedsCategories!: OffersNeedsCategoryType[];
    _categoriesOrder!: CategoryType[];
    _interestsRequired!: boolean;
    _hideIndustryAndCountry!: boolean;
    _hideJobTitleDe!: boolean;
    _hideIndustryDe!: boolean;
    _hideBiographyDe!: boolean;
    _contactButtonBgColor!: string;
    _socialMediaSectionTitle!: LocalizedString;
    _editButtonColor!: string;
    _mobilePageBackgroundColor!: string;
    _editProfileFieldsList!: Field[];
    _editPhotoMobileButtonTitle!: LocalizedString;
    _editPhotoMobileButtonTextColor!: string;
    _saveChangesMobileTitle!: LocalizedString;
    _saveChangesMobileTextColor!: string;
    _saveChangesMobileBgColor!: string;
    _successSavingMessageColor!: string;
    _textFieldsBorderColor!: string;
    _textFieldsBackgroundColor!: string;
    _notSelectedCategoryItemColor!: string;
    _showMyProfileButtonText!: LocalizedString;
    _basicInfoLanguages!: BasicInfoLanguage[];
    _saveChangesSectionTopShadow!: string;
    _showExhibitorBadge!: boolean;
    _deleteProfileLinkLabel!: LocalizedString;
    _deleteProfileModalTitle!: LocalizedString;
    _deleteProfileModalText!: LocalizedString;
    _deleteProfileModalCancelButton!: LocalizedString;
    _deleteProfileModalContinueButton!: LocalizedString;
    _deleteProfileConfirmModalTitle!: LocalizedString;
    _deleteProfileConfirmModalText!: LocalizedString;
    _deleteProfileConfirmModalSubmit!: LocalizedString;
    _languageSelectNoOptionMessage!: LocalizedString;

    get salutationVisible() {
        return this.jsonData["salutationVisible"]
    }

    get myProfileTitle() {
        return this.jsonData["myProfileTitle"][this.lang]
    }

    get editProfileTitle() {
        return this.jsonData["editProfileTitle"][this.lang]
    }

    get editProfileButtonText() {
        return this.jsonData["editProfileButtonText"][this.lang]
    }

    get noInterestsSelectedMessage() {
        return this.jsonData["noInterestsSelectedMessage"][this.lang]
    }

    get noOffersSelectedMessage() {
        return this.jsonData["noOffersSelectedMessage"][this.lang]
    }

    get noNeedsSelectedMessage() {
        return this.jsonData["noNeedsSelectedMessage"][this.lang]
    }

    get goBackLabel() {
        return this.jsonData["goBackLabel"][this.lang]
    }

    get profilePictureLabel() {
        return this.jsonData["profilePictureLabel"][this.lang]
    }

    get uploadNewButtonText() {
        return this.jsonData["uploadNewButtonText"][this.lang]
    }

    get formatsText() {
        return this.jsonData["formatsText"][this.lang]
    }

    get deleteCurrentPictureButtonText() {
        return this.jsonData["deleteCurrentPictureButtonText"][this.lang]
    }

    get nameLabel() {
        return this.jsonData["nameLabel"][this.lang]
    }

    get namePlaceholder() {
        return this.jsonData["namePlaceholder"][this.lang]
    }

    get surnameLabel() {
        return this.jsonData["surnameLabel"][this.lang]
    }

    get surnamePlaceholder() {
        return this.jsonData["surnamePlaceholder"][this.lang]
    }

    get companyLabel() {
        return this.jsonData["companyLabel"][this.lang]
    }

    get companyPlaceholder() {
        return this.jsonData["companyPlaceholder"][this.lang]
    }

    get jobTitleLabel() {
        return this.jsonData["jobTitleLabel"][this.lang]
    }

    get jobTitlePlaceholder() {
        return this.jsonData["jobTitlePlaceholder"][this.lang]
    }

    get jobTitleDeLabel() {
        return this.jsonData["jobTitleDeLabel"][this.lang]
    }

    get jobTitleDePlaceholder() {
        return this.jsonData["jobTitleDePlaceholder"][this.lang]
    }

    get phoneLabel() {
        return this.jsonData["phoneLabel"][this.lang]
    }

    get phonePlaceholder() {
        return this.jsonData["phonePlaceholder"][this.lang]
    }

    get emailLabel() {
        return this.jsonData["emailLabel"][this.lang]
    }

    get adress1Label() {
        return this.jsonData["adress1Label"][this.lang]
    }

    get adress1Placeholder() {
        return this.jsonData["adress1Placeholder"][this.lang]
    }

    get adress2Label() {
        return this.jsonData["adress2Label"][this.lang]
    }

    get adress2Placeholder() {
        return this.jsonData["adress2Placeholder"][this.lang]
    }

    get adress3Label() {
        return this.jsonData["adress3Label"][this.lang]
    }

    get adress3Placeholder() {
        return this.jsonData["adress3Placeholder"][this.lang]
    }

    get cityLabel() {
        return this.jsonData["cityLabel"][this.lang]
    }

    get cityPlaceholder() {
        return this.jsonData["cityPlaceholder"][this.lang]
    }

    get postalCodeLabel() {
        return this.jsonData["postalCodeLabel"][this.lang]
    }

    get postalCodePlaceholder() {
        return this.jsonData["postalCodePlaceholder"][this.lang]
    }

    get mobileLabel() {
        return this.jsonData["mobileLabel"][this.lang]
    }

    get mobilePlaceholder() {
        return this.jsonData["mobilePlaceholder"][this.lang]
    }

    get timeZoneLabel() {
        return this.jsonData["timeZoneLabel"][this.lang]
    }

    get industryLabel() {
        return this.jsonData["industryLabel"][this.lang]
    }

    get industryPlaceholder() {
        return this.jsonData["industryPlaceholder"][this.lang]
    }

    get industryDeLabel() {
        return this.jsonData["industryDeLabel"][this.lang]
    }

    get industryDePlaceholder() {
        return this.jsonData["industryDePlaceholder"][this.lang]
    }

    get countryLabel() {
        return this.jsonData["countryLabel"][this.lang]
    }

    get countrySwitcherText() {
        return this.jsonData["countrySwitcherText"][this.lang]
    }

    get languagesLabel() {
        return this.jsonData["languagesLabel"][this.lang]
    }

    get languagesSwitcherText() {
        return this.jsonData["languagesSwitcherText"][this.lang]
    }

    get showEmailLangPreference() {
        return this.jsonData["showEmailLangPreference"]
    }

    get emailLangPreferenceLabel() {
        return this.jsonData["emailLangPreferenceLabel"][this.lang]
    }

    get emailLangPreferenceText() {
        return this.jsonData["emailLangPreferenceText"][this.lang]
    }

    get facebookLinkLabel() {
        return this.jsonData["facebookLinkLabel"][this.lang]
    }

    get twitterLinkLabel() {
        return this.jsonData["twitterLinkLabel"][this.lang]
    }

    get linkedInLinkLabel() {
        return this.jsonData["linkedInLinkLabel"][this.lang]
    }

    get xingLinkLabel() {
        return this.jsonData["xingLinkLabel"][this.lang]
    }

    get instagramLinkLabel() {
        return this.jsonData["instagramLinkLabel"][this.lang]
    }

    get youTubeLinkLabel() {
        return this.jsonData["youTubeLinkLabel"][this.lang]
    }

    get pinterestLinkLabel() {
        return this.jsonData["pinterestLinkLabel"][this.lang]
    }

    get biographyLabel() {
        return this.jsonData["biographyLabel"][this.lang]
    }

    get biographyPlaceholder() {
        return this.jsonData["biographyPlaceholder"][this.lang]
    }

    get biographyDeLabel() {
        return this.jsonData["biographyDeLabel"][this.lang]
    }

    get biographyDePlaceholder() {
        return this.jsonData["biographyDePlaceholder"][this.lang]
    }

    get biographyMobileLabel() {
        return this.jsonData["biographyMobileLabel"][this.lang]
    }

    get numberOfCategories() {
        return this.jsonData["numberOfCategories"]
    }

    get interestsLabel() {
        return this.jsonData["interestsLabel"][this.lang]
    }

    get imOfferingLabel() {
        return this.jsonData["imOfferingLabel"][this.lang]
    }

    get numberOfOffers() {
        return this.jsonData["numberOfOffers"]
    }

    get imLookingToBuyLabel() {
        return this.jsonData["imLookingToBuyLabel"][this.lang]
    }

    get numberOfNeeds() {
        return this.jsonData["numberOfNeeds"]
    }

    get showMoreButtonText() {
        return this.jsonData["showMoreButtonText"][this.lang]
    }

    get showLessButtonText() {
        return this.jsonData["showLessButtonText"][this.lang]
    }

    get requiredFieldsLabel() {
        return this.jsonData["requiredFieldsLabel"][this.lang]
    }

    get saveButtonText() {
        return this.jsonData["saveButtonText"][this.lang]
    }

    get cancelButtonText() {
        return this.jsonData["cancelButtonText"][this.lang]
    }

    get dataSuccessfullyUpdatedText() {
        return this.jsonData["dataSuccessfullyUpdatedText"][this.lang]
    }

    get oneRequiredFieldEmptyWarning() {
        return this.jsonData["oneRequiredFieldEmptyWarning"][this.lang]
    }

    get phoneValidationIncorrectWarning() {
        return this.jsonData["phoneValidationIncorrectWarning"][this.lang]
    }

    get requiredFieldsEmptyWarning() {
        return this.jsonData["requiredFieldsEmptyWarning"][this.lang]
    }

    get validationIncorrectWarning() {
        return this.jsonData["validationIncorrectWarning"][this.lang]
    }

    get invalidNameWarning() {
        return this.jsonData["invalidNameWarning"][this.lang]
    }

    get invalidSurnameWarning() {
        return this.jsonData["invalidSurnameWarning"][this.lang]
    }

    get invalidCompanyNameWarning() {
        return this.jsonData["invalidCompanyNameWarning"][this.lang]
    }

    get invalidJobTitleWarning() {
        return this.jsonData["invalidJobTitleWarning"][this.lang]
    }

    get invalidIndustryNameWarning() {
        return this.jsonData["invalidIndustryNameWarning"][this.lang]
    }

    get invalidPhoneNumberWarning() {
        return this.jsonData["invalidPhoneNumberWarning"][this.lang]
    }

    get invalidAdressWarning() {
        return this.jsonData["invalidAdressWarning"][this.lang]
    }

    get invalidCityWarning() {
        return this.jsonData["invalidCityWarning"][this.lang]
    }

    get invalidPostalCodeWarning() {
        return this.jsonData["invalidPostalCodeWarning"][this.lang]
    }

    get invalidFacebookLinkWarning() {
        return this.jsonData["invalidFacebookLinkWarning"][this.lang]
    }

    get invalidTwitterLinkWarning() {
        return this.jsonData["invalidTwitterLinkWarning"][this.lang]
    }

    get invalidLinkedInLinkWarning() {
        return this.jsonData["invalidLinkedInLinkWarning"][this.lang]
    }

    get invalidXingLinkWarning() {
        return this.jsonData["invalidXingLinkWarning"][this.lang]
    }

    get invalidInstagramLinkWarning() {
        return this.jsonData["invalidInstagramLinkWarning"][this.lang]
    }

    get invalidYouTubeLinkWarning() {
        return this.jsonData["invalidYouTubeLinkWarning"][this.lang]
    }

    get invalidPinterestLinkWarning() {
        return this.jsonData["invalidPinterestLinkWarning"][this.lang]
    }

    get pictureSizeWarning() {
        return this.jsonData["pictureSizeWarning"][this.lang]
    }

    get backendErrorWarning() {
        return this.jsonData["backendErrorWarning"][this.lang]
    }

    get searchPlaceholder() {
        return this.jsonData["searchPlaceholder"][this.lang]
    }

    get minPictureHeight() {
        return this.jsonData["minPictureHeight"]
    }

    get minPictureWidth() {
        return this.jsonData["minPictureWidth"]
    }

    get editProfileTabs() {
        return this.jsonData["editProfileTabs"]
    }

    get basicInfoTabTitle() {
        return this.jsonData["basicInfoTabTitle"][this.lang]
    }

    get buySellTabTitle() {
        return this.jsonData["buySellTabTitle"][this.lang]
    }

    get yourInterestsSectionTitle() {
        return this.jsonData["yourInterestsSectionTitle"][this.lang]
    }

    get backButtonTitle() {
        return this.jsonData["backButtonTitle"][this.lang]
    }

    get continueButtonTitle() {
        return this.jsonData["continueButtonTitle"][this.lang]
    }

    get displayProfileLangLabel() {
        return this.jsonData["displayProfileLangLabel"][this.lang]
    }

    get editRequiredFields() {
        return this.jsonData["editRequiredFields"]
    }

    get editDisabledFields() {
        return this.jsonData["editDisabledFields"]
    }

    get editDisabledSocialMedia() {
        return this.jsonData["editDisabledSocialMedia"]
    }

    get availableSocialMedias() {
        return this.jsonData["availableSocialMedias"]
    }

    get offersNeedsCategories() {
        if (!this._offersNeedsCategories) {
            this._offersNeedsCategories = []
            for (const arrayElement of this.jsonData["offersNeedsCategories"]) {
                const arrayObject = new OffersNeedsCategoryTypeImpl(arrayElement, this.lang)
                this.offersNeedsCategories.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._offersNeedsCategories
    }

    get categoriesOrder() {
        return this.jsonData["categoriesOrder"]
    }

    get interestsRequired() {
        return this.jsonData["interestsRequired"]
    }

    get hideIndustryAndCountry() {
        return this.jsonData["hideIndustryAndCountry"]
    }

    get hideJobTitleDe() {
        return this.jsonData["hideJobTitleDe"]
    }

    get hideIndustryDe() {
        return this.jsonData["hideIndustryDe"]
    }

    get hideBiographyDe() {
        return this.jsonData["hideBiographyDe"]
    }

    get contactButtonBgColor() {
        return this.jsonData["contactButtonBgColor"]
    }

    get socialMediaSectionTitle() {
        return this.jsonData["socialMediaSectionTitle"][this.lang]
    }

    get editButtonColor() {
        return this.jsonData["editButtonColor"]
    }

    get mobilePageBackgroundColor() {
        return this.jsonData["mobilePageBackgroundColor"]
    }

    get editProfileFieldsList() {
        return this.jsonData["editProfileFieldsList"]
    }

    get editPhotoMobileButtonTitle() {
        return this.jsonData["editPhotoMobileButtonTitle"][this.lang]
    }

    get editPhotoMobileButtonTextColor() {
        return this.jsonData["editPhotoMobileButtonTextColor"]
    }

    get saveChangesMobileTitle() {
        return this.jsonData["saveChangesMobileTitle"][this.lang]
    }

    get saveChangesMobileTextColor() {
        return this.jsonData["saveChangesMobileTextColor"]
    }

    get saveChangesMobileBgColor() {
        return this.jsonData["saveChangesMobileBgColor"]
    }

    get successSavingMessageColor() {
        return this.jsonData["successSavingMessageColor"]
    }

    get textFieldsBorderColor() {
        return this.jsonData["textFieldsBorderColor"]
    }

    get textFieldsBackgroundColor() {
        return this.jsonData["textFieldsBackgroundColor"]
    }

    get notSelectedCategoryItemColor() {
        return this.jsonData["notSelectedCategoryItemColor"]
    }

    get showMyProfileButtonText() {
        return this.jsonData["showMyProfileButtonText"][this.lang]
    }

    get basicInfoLanguages() {
        if (!this._basicInfoLanguages) {
            this._basicInfoLanguages = []
            for (const arrayElement of this.jsonData["basicInfoLanguages"]) {
                const arrayObject = new BasicInfoLanguageImpl(arrayElement, this.lang)
                this.basicInfoLanguages.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._basicInfoLanguages
    }

    get saveChangesSectionTopShadow() {
        return this.jsonData["saveChangesSectionTopShadow"]
    }

    get showExhibitorBadge() {
        return this.jsonData["showExhibitorBadge"]
    }

    get deleteProfileLinkLabel() {
        return this.jsonData["deleteProfileLinkLabel"][this.lang]
    }

    get deleteProfileModalTitle() {
        return this.jsonData["deleteProfileModalTitle"][this.lang]
    }

    get deleteProfileModalText() {
        return this.jsonData["deleteProfileModalText"][this.lang]
    }

    get deleteProfileModalCancelButton() {
        return this.jsonData["deleteProfileModalCancelButton"][this.lang]
    }

    get deleteProfileModalContinueButton() {
        return this.jsonData["deleteProfileModalContinueButton"][this.lang]
    }

    get deleteProfileConfirmModalTitle() {
        return this.jsonData["deleteProfileConfirmModalTitle"][this.lang]
    }

    get deleteProfileConfirmModalText() {
        return this.jsonData["deleteProfileConfirmModalText"][this.lang]
    }

    get deleteProfileConfirmModalSubmit() {
        return this.jsonData["deleteProfileConfirmModalSubmit"][this.lang]
    }

    get languageSelectNoOptionMessage() {
        return this.jsonData["languageSelectNoOptionMessage"][this.lang]
    }
}

class OffersNeedsCategoryTypeImpl extends BrandingData implements OffersNeedsCategoryType {
    _id!: string;
    _name!: LocalizedString;
    _placeholderBuying!: LocalizedString;
    _placeholderSelling!: LocalizedString;

    get id() {
        return this.jsonData["id"]
    }

    get name() {
        return this.jsonData["name"][this.lang]
    }

    get placeholderBuying() {
        return this.jsonData["placeholderBuying"][this.lang]
    }

    get placeholderSelling() {
        return this.jsonData["placeholderSelling"][this.lang]
    }
}

class BasicInfoLanguageImpl extends BrandingData implements BasicInfoLanguage {
    _value!: string;
    _label!: string;

    get value() {
        return this.jsonData["value"]
    }

    get label() {
        return this.jsonData["label"]
    }
}

class PersonDetailPageContentBrandingImpl extends BrandingData implements PersonDetailPageContentBranding {
    _navbarText!: LocalizedString;
    _cancelRequestMessage!: LocalizedString;
    _cancelConnectionMessage!: LocalizedString;
    _ignoredMessage!: LocalizedString;
    _openRequestMessage!: LocalizedString;
    _sendRequestMessage!: LocalizedString;
    _chatText!: LocalizedString;
    _videoCallText!: LocalizedString;
    _voiceCallText!: LocalizedString;
    _voiceCallingText!: LocalizedString;
    _meetingText!: LocalizedString;
    _liveSessionsTitle!: LocalizedString;
    _upcomingSessionsTitle!: LocalizedString;
    _pastSessionsTitle!: LocalizedString;
    _interestsTitle!: LocalizedString;
    _offersTitle!: LocalizedString;
    _needsTitle!: LocalizedString;
    _viewMoreCategoriesButtonText!: LocalizedString;
    _categoriesLimitNumber!: number;
    _noInterestsSelectedMessage!: LocalizedString;
    _personDetailPageSpeakerTitleName!: ColorString;
    _personDetailPageSpeakerTitlePosition!: ColorString;
    _personContactDetailsContainerTextColor!: ColorString;
    _personContactDetailsContainerTextColorOnHover!: ColorString;
    _organizationLogoDiv!: string;
    _companyLogoDiv!: string;
    _upcomingSessionsTitleTextColor!: ColorString;
    _formattedDateDiv!: string;
    _interestBorderColor!: string;
    _interestTextColor!: ColorString;
    _commonInterestTextColor!: string;
    _upcomingSessionShareIconColor!: ColorString;
    _liveTileHeaderTextColor!: ColorString;
    _bookmarkEventsPopupVisible!: boolean;
    _bookmarkEventsPopupText!: LocalizedString;
    _bookmarkEventsPopupConfirmButtonText!: LocalizedString;
    _bookmarkEventsPopupCancelButtonText!: LocalizedString;
    _shareSpeakerButtonText!: LocalizedString;
    _bookmarkSpeakerButtonText!: LocalizedString;
    _unbookmarkSpeakerButtonText!: LocalizedString;
    _bookmarkAllEventsButtonText!: LocalizedString;
    _unbookmarkAllEventsButtonText!: LocalizedString;
    _meetingSlotsSection!: MeetingSlotsSectionBranding;
    _showPhoneNumber!: boolean;
    _showMobileNumber!: boolean;

    get navbarText() {
        return this.jsonData["navbarText"][this.lang]
    }

    get cancelRequestMessage() {
        return this.jsonData["cancelRequestMessage"][this.lang]
    }

    get cancelConnectionMessage() {
        return this.jsonData["cancelConnectionMessage"][this.lang]
    }

    get ignoredMessage() {
        return this.jsonData["ignoredMessage"][this.lang]
    }

    get openRequestMessage() {
        return this.jsonData["openRequestMessage"][this.lang]
    }

    get sendRequestMessage() {
        return this.jsonData["sendRequestMessage"][this.lang]
    }

    get chatText() {
        return this.jsonData["chatText"][this.lang]
    }

    get videoCallText() {
        return this.jsonData["videoCallText"][this.lang]
    }

    get voiceCallText() {
        return this.jsonData["voiceCallText"][this.lang]
    }

    get voiceCallingText() {
        return this.jsonData["voiceCallingText"][this.lang]
    }

    get meetingText() {
        return this.jsonData["meetingText"][this.lang]
    }

    get liveSessionsTitle() {
        return this.jsonData["liveSessionsTitle"][this.lang]
    }

    get upcomingSessionsTitle() {
        return this.jsonData["upcomingSessionsTitle"][this.lang]
    }

    get pastSessionsTitle() {
        return this.jsonData["pastSessionsTitle"][this.lang]
    }

    get interestsTitle() {
        return this.jsonData["interestsTitle"][this.lang]
    }

    get offersTitle() {
        return this.jsonData["offersTitle"][this.lang]
    }

    get needsTitle() {
        return this.jsonData["needsTitle"][this.lang]
    }

    get viewMoreCategoriesButtonText() {
        return this.jsonData["viewMoreCategoriesButtonText"][this.lang]
    }

    get categoriesLimitNumber() {
        return this.jsonData["categoriesLimitNumber"]
    }

    get noInterestsSelectedMessage() {
        return this.jsonData["noInterestsSelectedMessage"][this.lang]
    }

    get personDetailPageSpeakerTitleName() {
        return this.jsonData["personDetailPageSpeakerTitleName"]
    }

    get personDetailPageSpeakerTitlePosition() {
        return this.jsonData["personDetailPageSpeakerTitlePosition"]
    }

    get personContactDetailsContainerTextColor() {
        return this.jsonData["personContactDetailsContainerTextColor"]
    }

    get personContactDetailsContainerTextColorOnHover() {
        return this.jsonData["personContactDetailsContainerTextColorOnHover"]
    }

    get organizationLogoDiv() {
        return this.jsonData["organizationLogoDiv"]
    }

    get companyLogoDiv() {
        return this.jsonData["companyLogoDiv"]
    }

    get upcomingSessionsTitleTextColor() {
        return this.jsonData["upcomingSessionsTitleTextColor"]
    }

    get formattedDateDiv() {
        return this.jsonData["formattedDateDiv"]
    }

    get interestBorderColor() {
        return this.jsonData["interestBorderColor"]
    }

    get interestTextColor() {
        return this.jsonData["interestTextColor"]
    }

    get commonInterestTextColor() {
        return this.jsonData["commonInterestTextColor"]
    }

    get upcomingSessionShareIconColor() {
        return this.jsonData["upcomingSessionShareIconColor"]
    }

    get liveTileHeaderTextColor() {
        return this.jsonData["liveTileHeaderTextColor"]
    }

    get bookmarkEventsPopupVisible() {
        return this.jsonData["bookmarkEventsPopupVisible"]
    }

    get bookmarkEventsPopupText() {
        return this.jsonData["bookmarkEventsPopupText"][this.lang]
    }

    get bookmarkEventsPopupConfirmButtonText() {
        return this.jsonData["bookmarkEventsPopupConfirmButtonText"][this.lang]
    }

    get bookmarkEventsPopupCancelButtonText() {
        return this.jsonData["bookmarkEventsPopupCancelButtonText"][this.lang]
    }

    get shareSpeakerButtonText() {
        return this.jsonData["shareSpeakerButtonText"][this.lang]
    }

    get bookmarkSpeakerButtonText() {
        return this.jsonData["bookmarkSpeakerButtonText"][this.lang]
    }

    get unbookmarkSpeakerButtonText() {
        return this.jsonData["unbookmarkSpeakerButtonText"][this.lang]
    }

    get bookmarkAllEventsButtonText() {
        return this.jsonData["bookmarkAllEventsButtonText"][this.lang]
    }

    get unbookmarkAllEventsButtonText() {
        return this.jsonData["unbookmarkAllEventsButtonText"][this.lang]
    }

    get meetingSlotsSection() {
        if (!this._meetingSlotsSection) {
            this._meetingSlotsSection = new MeetingSlotsSectionBrandingImpl(this.jsonData["meetingSlotsSection"], this.lang)
            this.subBrandings.push(this._meetingSlotsSection as any as BrandingData)
        }
        return this._meetingSlotsSection
    }

    get showPhoneNumber() {
        return this.jsonData["showPhoneNumber"]
    }

    get showMobileNumber() {
        return this.jsonData["showMobileNumber"]
    }
}

class MeetingSlotsSectionBrandingImpl extends BrandingData implements MeetingSlotsSectionBranding {
    _visible!: boolean;
    _title!: LocalizedString;
    _showMoreButtonActiveLabel!: LocalizedString;
    _showMoreButtonInactiveLabel!: LocalizedString;
    _predefinedMeetingTitleTemplate!: LocalizedString;
    _timeSlotIntervalMinutes!: number;
    _startDate!: string;
    _endDate!: string;
    _startTime!: string;
    _endTime!: string;
    _titleColor!: ColorString;
    _datePickerInputBorderColor!: ColorString;
    _datePickerInputTextColor!: ColorString;
    _mobileArrowBoxBgColor!: ColorString;
    _mobileArrowBoxBorderColor!: ColorString;
    _mobileArrowIconColor!: ColorString;
    _mobileArrowBoxBgColorDisabled!: ColorString;
    _mobileArrowBoxBorderColorDisabled!: ColorString;
    _mobileArrowIconColorDisabled!: ColorString;
    _desktopArrowIconColor!: ColorString;
    _desktopArrowIconColorDisabled!: ColorString;
    _timeSlotBgColor!: ColorString;
    _timeSlotBgColorDisabled!: ColorString;
    _timeSlotHoverBgColor!: ColorString;
    _timeSlotColor!: ColorString;
    _timeSlotHoverColor!: ColorString;
    _timeSlotColorDisabled!: ColorString;
    _timeSlotBorderColor!: ColorString;
    _timeTableHeaderColor!: ColorString;
    _showMoreButtonBgColor!: ColorString;
    _showMoreButtonBgHoverColor!: ColorString;
    _showMoreButtonColor!: ColorString;
    _showMoreButtonHoverColor!: ColorString;
    _showMoreButtonBorderColor!: ColorString;
    _showMoreButtonIconColor!: ColorString;
    _showMoreButtonIconHoverColor!: ColorString;

    get visible() {
        return this.jsonData["visible"]
    }

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get showMoreButtonActiveLabel() {
        return this.jsonData["showMoreButtonActiveLabel"][this.lang]
    }

    get showMoreButtonInactiveLabel() {
        return this.jsonData["showMoreButtonInactiveLabel"][this.lang]
    }

    get predefinedMeetingTitleTemplate() {
        return this.jsonData["predefinedMeetingTitleTemplate"][this.lang]
    }

    get timeSlotIntervalMinutes() {
        return this.jsonData["timeSlotIntervalMinutes"]
    }

    get startDate() {
        return this.jsonData["startDate"]
    }

    get endDate() {
        return this.jsonData["endDate"]
    }

    get startTime() {
        return this.jsonData["startTime"]
    }

    get endTime() {
        return this.jsonData["endTime"]
    }

    get titleColor() {
        return this.jsonData["titleColor"]
    }

    get datePickerInputBorderColor() {
        return this.jsonData["datePickerInputBorderColor"]
    }

    get datePickerInputTextColor() {
        return this.jsonData["datePickerInputTextColor"]
    }

    get mobileArrowBoxBgColor() {
        return this.jsonData["mobileArrowBoxBgColor"]
    }

    get mobileArrowBoxBorderColor() {
        return this.jsonData["mobileArrowBoxBorderColor"]
    }

    get mobileArrowIconColor() {
        return this.jsonData["mobileArrowIconColor"]
    }

    get mobileArrowBoxBgColorDisabled() {
        return this.jsonData["mobileArrowBoxBgColorDisabled"]
    }

    get mobileArrowBoxBorderColorDisabled() {
        return this.jsonData["mobileArrowBoxBorderColorDisabled"]
    }

    get mobileArrowIconColorDisabled() {
        return this.jsonData["mobileArrowIconColorDisabled"]
    }

    get desktopArrowIconColor() {
        return this.jsonData["desktopArrowIconColor"]
    }

    get desktopArrowIconColorDisabled() {
        return this.jsonData["desktopArrowIconColorDisabled"]
    }

    get timeSlotBgColor() {
        return this.jsonData["timeSlotBgColor"]
    }

    get timeSlotBgColorDisabled() {
        return this.jsonData["timeSlotBgColorDisabled"]
    }

    get timeSlotHoverBgColor() {
        return this.jsonData["timeSlotHoverBgColor"]
    }

    get timeSlotColor() {
        return this.jsonData["timeSlotColor"]
    }

    get timeSlotHoverColor() {
        return this.jsonData["timeSlotHoverColor"]
    }

    get timeSlotColorDisabled() {
        return this.jsonData["timeSlotColorDisabled"]
    }

    get timeSlotBorderColor() {
        return this.jsonData["timeSlotBorderColor"]
    }

    get timeTableHeaderColor() {
        return this.jsonData["timeTableHeaderColor"]
    }

    get showMoreButtonBgColor() {
        return this.jsonData["showMoreButtonBgColor"]
    }

    get showMoreButtonBgHoverColor() {
        return this.jsonData["showMoreButtonBgHoverColor"]
    }

    get showMoreButtonColor() {
        return this.jsonData["showMoreButtonColor"]
    }

    get showMoreButtonHoverColor() {
        return this.jsonData["showMoreButtonHoverColor"]
    }

    get showMoreButtonBorderColor() {
        return this.jsonData["showMoreButtonBorderColor"]
    }

    get showMoreButtonIconColor() {
        return this.jsonData["showMoreButtonIconColor"]
    }

    get showMoreButtonIconHoverColor() {
        return this.jsonData["showMoreButtonIconHoverColor"]
    }
}

class SayHelloModalBrandingImpl extends BrandingData implements SayHelloModalBranding {
    _cancelConnectionMessage!: LocalizedString;
    _cancelConnectionTitle!: LocalizedString;
    _ignoredMessage!: LocalizedString;
    _openRequestMessage!: LocalizedString;
    _cancelRequestMessage!: LocalizedString;
    _sendMessagePlaceholder!: LocalizedString;
    _headerTextSayHello!: LocalizedString;
    _headerTextCancelRequest!: LocalizedString;
    _headerTextOrganizationRequest!: LocalizedString;
    _submitTextAccept!: LocalizedString;
    _submitTextIgnore!: LocalizedString;
    _submitTextYes!: LocalizedString;
    _submitTextOK!: LocalizedString;
    _submitTextSend!: LocalizedString;
    _submitTextClose!: LocalizedString;
    _subtitleRegisterInterestsPart1!: LocalizedString;
    _subtitleRegisterInterestsPart2!: LocalizedString;
    _subtitleRegisterInterestsPart3!: LocalizedString;
    _subtitleConnectRequestPart1!: LocalizedString;
    _subtitleConnectRequestPart2!: LocalizedString;
    _alertMessage!: LocalizedString;
    _meetingTimeZoneSubmitBtnText!: LocalizedString;
    _openRequestMessageModal!: LocalizedString;
    _submitTextForCancelButton!: LocalizedString;
    _submitBtnPrimaryBgColor!: ColorString;
    _submitBtnPrimaryTextColor!: ColorString;
    _submitBtnPrimaryBorderColor!: ColorString;
    _submitBtnPrimaryOnHoverBgColor!: ColorString;
    _submitBtnPrimaryOnHoverTextColor!: ColorString;
    _submitBtnSecondaryBgColor!: ColorString;
    _submitBtnSecondaryTextColor!: ColorString;
    _submitBtnSecondaryBorderColor!: ColorString;
    _cancelText!: LocalizedString;
    _cancelTextForCancelButton!: LocalizedString;
    _cancelTextYes!: LocalizedString;
    _cancelTextRequested!: LocalizedString;

    get cancelConnectionMessage() {
        return this.jsonData["cancelConnectionMessage"][this.lang]
    }

    get cancelConnectionTitle() {
        return this.jsonData["cancelConnectionTitle"][this.lang]
    }

    get ignoredMessage() {
        return this.jsonData["ignoredMessage"][this.lang]
    }

    get openRequestMessage() {
        return this.jsonData["openRequestMessage"][this.lang]
    }

    get cancelRequestMessage() {
        return this.jsonData["cancelRequestMessage"][this.lang]
    }

    get sendMessagePlaceholder() {
        return this.jsonData["sendMessagePlaceholder"][this.lang]
    }

    get headerTextSayHello() {
        return this.jsonData["headerTextSayHello"][this.lang]
    }

    get headerTextCancelRequest() {
        return this.jsonData["headerTextCancelRequest"][this.lang]
    }

    get headerTextOrganizationRequest() {
        return this.jsonData["headerTextOrganizationRequest"][this.lang]
    }

    get submitTextAccept() {
        return this.jsonData["submitTextAccept"][this.lang]
    }

    get submitTextIgnore() {
        return this.jsonData["submitTextIgnore"][this.lang]
    }

    get submitTextYes() {
        return this.jsonData["submitTextYes"][this.lang]
    }

    get submitTextOK() {
        return this.jsonData["submitTextOK"][this.lang]
    }

    get submitTextSend() {
        return this.jsonData["submitTextSend"][this.lang]
    }

    get submitTextClose() {
        return this.jsonData["submitTextClose"][this.lang]
    }

    get subtitleRegisterInterestsPart1() {
        return this.jsonData["subtitleRegisterInterestsPart1"][this.lang]
    }

    get subtitleRegisterInterestsPart2() {
        return this.jsonData["subtitleRegisterInterestsPart2"][this.lang]
    }

    get subtitleRegisterInterestsPart3() {
        return this.jsonData["subtitleRegisterInterestsPart3"][this.lang]
    }

    get subtitleConnectRequestPart1() {
        return this.jsonData["subtitleConnectRequestPart1"][this.lang]
    }

    get subtitleConnectRequestPart2() {
        return this.jsonData["subtitleConnectRequestPart2"][this.lang]
    }

    get alertMessage() {
        return this.jsonData["alertMessage"][this.lang]
    }

    get meetingTimeZoneSubmitBtnText() {
        return this.jsonData["meetingTimeZoneSubmitBtnText"][this.lang]
    }

    get openRequestMessageModal() {
        return this.jsonData["openRequestMessageModal"][this.lang]
    }

    get submitTextForCancelButton() {
        return this.jsonData["submitTextForCancelButton"][this.lang]
    }

    get submitBtnPrimaryBgColor() {
        return this.jsonData["submitBtnPrimaryBgColor"]
    }

    get submitBtnPrimaryTextColor() {
        return this.jsonData["submitBtnPrimaryTextColor"]
    }

    get submitBtnPrimaryBorderColor() {
        return this.jsonData["submitBtnPrimaryBorderColor"]
    }

    get submitBtnPrimaryOnHoverBgColor() {
        return this.jsonData["submitBtnPrimaryOnHoverBgColor"]
    }

    get submitBtnPrimaryOnHoverTextColor() {
        return this.jsonData["submitBtnPrimaryOnHoverTextColor"]
    }

    get submitBtnSecondaryBgColor() {
        return this.jsonData["submitBtnSecondaryBgColor"]
    }

    get submitBtnSecondaryTextColor() {
        return this.jsonData["submitBtnSecondaryTextColor"]
    }

    get submitBtnSecondaryBorderColor() {
        return this.jsonData["submitBtnSecondaryBorderColor"]
    }

    get cancelText() {
        return this.jsonData["cancelText"][this.lang]
    }

    get cancelTextForCancelButton() {
        return this.jsonData["cancelTextForCancelButton"][this.lang]
    }

    get cancelTextYes() {
        return this.jsonData["cancelTextYes"][this.lang]
    }

    get cancelTextRequested() {
        return this.jsonData["cancelTextRequested"][this.lang]
    }
}

class FilterBarBrandingImpl extends BrandingData implements FilterBarBranding {
    _allFilterTitle!: LocalizedString;

    get allFilterTitle() {
        return this.jsonData["allFilterTitle"][this.lang]
    }
}

class OrganizationDetailPageContentBrandingImpl extends BrandingData implements OrganizationDetailPageContentBranding {
    _showJoinShowRoomIcon!: boolean;
    _showScheduleMeetingIcon!: boolean;
    _showRegisterInterestIcon!: boolean;
    _showRecommendIcon!: boolean;
    _showBookmarkIcon!: boolean;
    _showDownloadIcon!: boolean;
    _showBackofficeIcon!: boolean;
    _showEditCompanyProfileIcon!: boolean;
    _navbarTitle!: LocalizedString;
    _sectionLineContactPerson!: LocalizedString;
    _sectionLineCategories!: LocalizedString;
    _sectionLineProductDescription!: LocalizedString;
    _buttonShowMore!: LocalizedString;
    _buttonCollapse!: LocalizedString;
    _joinShowroom!: LocalizedString;
    _requestMeeting!: LocalizedString;
    _registerInterest!: LocalizedString;
    _recommend!: LocalizedString;
    _bookmark!: LocalizedString;
    _backoffice!: LocalizedString;
    _editCompanyProfile!: LocalizedString;
    _connectWithUs!: LocalizedString;
    _teamSectionTitleVisible!: boolean;
    _teamSectionTitle!: LocalizedString;
    _talkWithTeamBefString!: LocalizedString;
    _talkWithTeamAftString!: LocalizedString;
    _joinButton!: LocalizedString;
    _liveSessionsTitle!: LocalizedString;
    _upcomingSessionsTitle!: LocalizedString;
    _pastSessionsTitle!: LocalizedString;
    _virtualCafesTitle!: LocalizedString;
    _mediaTitle!: LocalizedString;
    _videoDownloadEnabled!: boolean;
    _linksTitle!: LocalizedString;
    _joinNowButton!: LocalizedString;
    _showFullScreen!: LocalizedString;
    _bookmarkPerson!: LocalizedString;
    _unBookmarkPerson!: LocalizedString;
    _favoritePerson!: LocalizedString;
    _removeFavoritePerson!: LocalizedString;
    _foundingYearTitle!: LocalizedString;
    _companySizeTitle!: LocalizedString;
    _industryTitle!: LocalizedString;
    _explorationPhasePlaceholder!: LocalizedString;
    _postPhasePlaceholder!: LocalizedString;
    _mediaSliderPrimaryColor!: ColorString;
    _iconColor!: ColorString;
    _companyInformationTitle!: LocalizedString;
    _companyContactToTitle!: LocalizedString;
    _orgaDetailPageMainContentOrder!: DetailSection[];
    _orgaDetailPageInfoSectionOrder!: InfoContentOrderType[];
    _defaultHeaderBasisPremiumTypes!: string[];
    _companyLinksFontWeight!: string;
    _categoriesSectionTitle!: LocalizedString;
    _coExhibitorsSectionTitle!: LocalizedString;
    _parentSectionTitle!: LocalizedString;
    _removeExpoShowroomDuringPostEvent!: boolean;
    _blockedStaffIconColor!: string;
    _numberOfProductsNone!: number;
    _numberOfProductsBasic!: number;
    _numberOfProductsStandard!: number;
    _numberOfProductsPremium!: number;
    _numberOfProductsUltimate!: number;
    _numberOfTrademarksNone!: number;
    _numberOfTrademarksBasic!: number;
    _numberOfTrademarksStandard!: number;
    _numberOfTrademarksPremium!: number;
    _numberOfTrademarksUltimate!: number;
    _numberOfNewsNone!: number;
    _numberOfNewsBasic!: number;
    _numberOfNewsStandard!: number;
    _numberOfNewsPremium!: number;
    _numberOfNewsUltimate!: number;
    _vCardNoteTemplate!: LocalizedString;
    _vCardDownloadAvailable!: boolean;
    _availableSocialMedias!: SocialMediaType[];
    _companyTeaserFontSize!: string;
    _showcase!: LocalizedString;
    _fullscreenButton!: LocalizedString;
    _newTabButton!: LocalizedString;
    _rootCategories!: string[];
    _showCategoryHierarchy!: boolean;
    _showBoundedOrganizationCategories!: boolean;
    _categoryFillColor!: string;
    _categoryTextColor!: string;
    _categoryActiveFillColor!: string;
    _categoryActiveTextColor!: string;
    _listViewActionIconsColor!: ColorString;
    _listViewDividerColor!: ColorString;
    _iframeBorder!: string;
    _hideEmailAddress!: boolean;
    _hideTelephone!: boolean;
    _remoteLivestreamsTitle!: LocalizedString;
    _staffListViewTitleColor!: string;
    _staffListViewTitleFont!: string;
    _listViewEntitiesTitleColor!: string;
    _listViewEntitiesTitleFont!: string;
    _viewAllButtonText!: LocalizedString;
    _viewLessButtonText!: LocalizedString;
    _mobileBreadcrumbLabel!: LocalizedString;
    _descriptionLabel!: LocalizedString;
    _halleStandSectionTitle!: LocalizedString;
    _halleStandItemBgColor!: string;
    _halleStandItemColor!: string;
    _halleStandLinkEnabled!: boolean;
    _copyToClipboardText!: LocalizedString;
    _joinInfoDeskHint!: LocalizedString;
    _meetingRequestModalTitlePlaceholder!: LocalizedString;

    get showJoinShowRoomIcon() {
        return this.jsonData["showJoinShowRoomIcon"]
    }

    get showScheduleMeetingIcon() {
        return this.jsonData["showScheduleMeetingIcon"]
    }

    get showRegisterInterestIcon() {
        return this.jsonData["showRegisterInterestIcon"]
    }

    get showRecommendIcon() {
        return this.jsonData["showRecommendIcon"]
    }

    get showBookmarkIcon() {
        return this.jsonData["showBookmarkIcon"]
    }

    get showDownloadIcon() {
        return this.jsonData["showDownloadIcon"]
    }

    get showBackofficeIcon() {
        return this.jsonData["showBackofficeIcon"]
    }

    get showEditCompanyProfileIcon() {
        return this.jsonData["showEditCompanyProfileIcon"]
    }

    get navbarTitle() {
        return this.jsonData["navbarTitle"][this.lang]
    }

    get sectionLineContactPerson() {
        return this.jsonData["sectionLineContactPerson"][this.lang]
    }

    get sectionLineCategories() {
        return this.jsonData["sectionLineCategories"][this.lang]
    }

    get sectionLineProductDescription() {
        return this.jsonData["sectionLineProductDescription"][this.lang]
    }

    get buttonShowMore() {
        return this.jsonData["buttonShowMore"][this.lang]
    }

    get buttonCollapse() {
        return this.jsonData["buttonCollapse"][this.lang]
    }

    get joinShowroom() {
        return this.jsonData["joinShowroom"][this.lang]
    }

    get requestMeeting() {
        return this.jsonData["requestMeeting"][this.lang]
    }

    get registerInterest() {
        return this.jsonData["registerInterest"][this.lang]
    }

    get recommend() {
        return this.jsonData["recommend"][this.lang]
    }

    get bookmark() {
        return this.jsonData["bookmark"][this.lang]
    }

    get backoffice() {
        return this.jsonData["backoffice"][this.lang]
    }

    get editCompanyProfile() {
        return this.jsonData["editCompanyProfile"][this.lang]
    }

    get connectWithUs() {
        return this.jsonData["connectWithUs"][this.lang]
    }

    get teamSectionTitleVisible() {
        return this.jsonData["teamSectionTitleVisible"]
    }

    get teamSectionTitle() {
        return this.jsonData["teamSectionTitle"][this.lang]
    }

    get talkWithTeamBefString() {
        return this.jsonData["talkWithTeamBefString"][this.lang]
    }

    get talkWithTeamAftString() {
        return this.jsonData["talkWithTeamAftString"][this.lang]
    }

    get joinButton() {
        return this.jsonData["joinButton"][this.lang]
    }

    get liveSessionsTitle() {
        return this.jsonData["liveSessionsTitle"][this.lang]
    }

    get upcomingSessionsTitle() {
        return this.jsonData["upcomingSessionsTitle"][this.lang]
    }

    get pastSessionsTitle() {
        return this.jsonData["pastSessionsTitle"][this.lang]
    }

    get virtualCafesTitle() {
        return this.jsonData["virtualCafesTitle"][this.lang]
    }

    get mediaTitle() {
        return this.jsonData["mediaTitle"][this.lang]
    }

    get videoDownloadEnabled() {
        return this.jsonData["videoDownloadEnabled"]
    }

    get linksTitle() {
        return this.jsonData["linksTitle"][this.lang]
    }

    get joinNowButton() {
        return this.jsonData["joinNowButton"][this.lang]
    }

    get showFullScreen() {
        return this.jsonData["showFullScreen"][this.lang]
    }

    get bookmarkPerson() {
        return this.jsonData["bookmarkPerson"][this.lang]
    }

    get unBookmarkPerson() {
        return this.jsonData["unBookmarkPerson"][this.lang]
    }

    get favoritePerson() {
        return this.jsonData["favoritePerson"][this.lang]
    }

    get removeFavoritePerson() {
        return this.jsonData["removeFavoritePerson"][this.lang]
    }

    get foundingYearTitle() {
        return this.jsonData["foundingYearTitle"][this.lang]
    }

    get companySizeTitle() {
        return this.jsonData["companySizeTitle"][this.lang]
    }

    get industryTitle() {
        return this.jsonData["industryTitle"][this.lang]
    }

    get explorationPhasePlaceholder() {
        return this.jsonData["explorationPhasePlaceholder"][this.lang]
    }

    get postPhasePlaceholder() {
        return this.jsonData["postPhasePlaceholder"][this.lang]
    }

    get mediaSliderPrimaryColor() {
        return this.jsonData["mediaSliderPrimaryColor"]
    }

    get iconColor() {
        return this.jsonData["iconColor"]
    }

    get companyInformationTitle() {
        return this.jsonData["companyInformationTitle"][this.lang]
    }

    get companyContactToTitle() {
        return this.jsonData["companyContactToTitle"][this.lang]
    }

    get orgaDetailPageMainContentOrder() {
        return this.jsonData["orgaDetailPageMainContentOrder"]
    }

    get orgaDetailPageInfoSectionOrder() {
        return this.jsonData["orgaDetailPageInfoSectionOrder"]
    }

    get defaultHeaderBasisPremiumTypes() {
        return this.jsonData["defaultHeaderBasisPremiumTypes"]
    }

    get companyLinksFontWeight() {
        return this.jsonData["companyLinksFontWeight"]
    }

    get categoriesSectionTitle() {
        return this.jsonData["categoriesSectionTitle"][this.lang]
    }

    get coExhibitorsSectionTitle() {
        return this.jsonData["coExhibitorsSectionTitle"][this.lang]
    }

    get parentSectionTitle() {
        return this.jsonData["parentSectionTitle"][this.lang]
    }

    get removeExpoShowroomDuringPostEvent() {
        return this.jsonData["removeExpoShowroomDuringPostEvent"]
    }

    get blockedStaffIconColor() {
        return this.jsonData["blockedStaffIconColor"]
    }

    get numberOfProductsNone() {
        return this.jsonData["numberOfProductsNone"]
    }

    get numberOfProductsBasic() {
        return this.jsonData["numberOfProductsBasic"]
    }

    get numberOfProductsStandard() {
        return this.jsonData["numberOfProductsStandard"]
    }

    get numberOfProductsPremium() {
        return this.jsonData["numberOfProductsPremium"]
    }

    get numberOfProductsUltimate() {
        return this.jsonData["numberOfProductsUltimate"]
    }

    get numberOfTrademarksNone() {
        return this.jsonData["numberOfTrademarksNone"]
    }

    get numberOfTrademarksBasic() {
        return this.jsonData["numberOfTrademarksBasic"]
    }

    get numberOfTrademarksStandard() {
        return this.jsonData["numberOfTrademarksStandard"]
    }

    get numberOfTrademarksPremium() {
        return this.jsonData["numberOfTrademarksPremium"]
    }

    get numberOfTrademarksUltimate() {
        return this.jsonData["numberOfTrademarksUltimate"]
    }

    get numberOfNewsNone() {
        return this.jsonData["numberOfNewsNone"]
    }

    get numberOfNewsBasic() {
        return this.jsonData["numberOfNewsBasic"]
    }

    get numberOfNewsStandard() {
        return this.jsonData["numberOfNewsStandard"]
    }

    get numberOfNewsPremium() {
        return this.jsonData["numberOfNewsPremium"]
    }

    get numberOfNewsUltimate() {
        return this.jsonData["numberOfNewsUltimate"]
    }

    get vCardNoteTemplate() {
        return this.jsonData["vCardNoteTemplate"][this.lang]
    }

    get vCardDownloadAvailable() {
        return this.jsonData["vCardDownloadAvailable"]
    }

    get availableSocialMedias() {
        return this.jsonData["availableSocialMedias"]
    }

    get companyTeaserFontSize() {
        return this.jsonData["companyTeaserFontSize"]
    }

    get showcase() {
        return this.jsonData["showcase"][this.lang]
    }

    get fullscreenButton() {
        return this.jsonData["fullscreenButton"][this.lang]
    }

    get newTabButton() {
        return this.jsonData["newTabButton"][this.lang]
    }

    get rootCategories() {
        return this.jsonData["rootCategories"]
    }

    get showCategoryHierarchy() {
        return this.jsonData["showCategoryHierarchy"]
    }

    get showBoundedOrganizationCategories() {
        return this.jsonData["showBoundedOrganizationCategories"]
    }

    get categoryFillColor() {
        return this.jsonData["categoryFillColor"]
    }

    get categoryTextColor() {
        return this.jsonData["categoryTextColor"]
    }

    get categoryActiveFillColor() {
        return this.jsonData["categoryActiveFillColor"]
    }

    get categoryActiveTextColor() {
        return this.jsonData["categoryActiveTextColor"]
    }

    get listViewActionIconsColor() {
        return this.jsonData["listViewActionIconsColor"]
    }

    get listViewDividerColor() {
        return this.jsonData["listViewDividerColor"]
    }

    get iframeBorder() {
        return this.jsonData["iframeBorder"]
    }

    get hideEmailAddress() {
        return this.jsonData["hideEmailAddress"]
    }

    get hideTelephone() {
        return this.jsonData["hideTelephone"]
    }

    get remoteLivestreamsTitle() {
        return this.jsonData["remoteLivestreamsTitle"][this.lang]
    }

    get staffListViewTitleColor() {
        return this.jsonData["staffListViewTitleColor"]
    }

    get staffListViewTitleFont() {
        return this.jsonData["staffListViewTitleFont"]
    }

    get listViewEntitiesTitleColor() {
        return this.jsonData["listViewEntitiesTitleColor"]
    }

    get listViewEntitiesTitleFont() {
        return this.jsonData["listViewEntitiesTitleFont"]
    }

    get viewAllButtonText() {
        return this.jsonData["viewAllButtonText"][this.lang]
    }

    get viewLessButtonText() {
        return this.jsonData["viewLessButtonText"][this.lang]
    }

    get mobileBreadcrumbLabel() {
        return this.jsonData["mobileBreadcrumbLabel"][this.lang]
    }

    get descriptionLabel() {
        return this.jsonData["descriptionLabel"][this.lang]
    }

    get halleStandSectionTitle() {
        return this.jsonData["halleStandSectionTitle"][this.lang]
    }

    get halleStandItemBgColor() {
        return this.jsonData["halleStandItemBgColor"]
    }

    get halleStandItemColor() {
        return this.jsonData["halleStandItemColor"]
    }

    get halleStandLinkEnabled() {
        return this.jsonData["halleStandLinkEnabled"]
    }

    get copyToClipboardText() {
        return this.jsonData["copyToClipboardText"][this.lang]
    }

    get joinInfoDeskHint() {
        return this.jsonData["joinInfoDeskHint"][this.lang]
    }

    get meetingRequestModalTitlePlaceholder() {
        return this.jsonData["meetingRequestModalTitlePlaceholder"][this.lang]
    }
}

class BackofficeContentBrandingImpl extends BrandingData implements BackofficeContentBranding {
    _navbarTitle!: LocalizedString;
    _staffSectiontitle!: LocalizedString;
    _guestsSectionTitle!: LocalizedString;
    _contactRequestsSectionTitle!: LocalizedString;
    _meetingsSectionTitle!: LocalizedString;
    _loungeSectionTitle!: LocalizedString;
    _reportingSectionTitle!: LocalizedString;
    _requestMessageTitle!: LocalizedString;
    _noRequestMessageDefault!: LocalizedString;
    _noActiveContactRequests!: LocalizedString;
    _ignoreContactRequstTitle!: LocalizedString;
    _ignoreContactRequstText!: LocalizedString;
    _ignoreContactText!: LocalizedString;
    _emptySearch!: LocalizedString;
    _editCompanyProfileLinkLabel!: LocalizedString;
    _helpLink!: LocalizedString;
    _helpLinkText1!: LocalizedString;
    _helpLinkText2!: LocalizedString;
    _helpLinkTextColor!: ColorString;
    _alreadyMemberTitle!: LocalizedString;
    _noActiveContactRequestsTextColor!: ColorString;

    get navbarTitle() {
        return this.jsonData["navbarTitle"][this.lang]
    }

    get staffSectiontitle() {
        return this.jsonData["staffSectiontitle"][this.lang]
    }

    get guestsSectionTitle() {
        return this.jsonData["guestsSectionTitle"][this.lang]
    }

    get contactRequestsSectionTitle() {
        return this.jsonData["contactRequestsSectionTitle"][this.lang]
    }

    get meetingsSectionTitle() {
        return this.jsonData["meetingsSectionTitle"][this.lang]
    }

    get loungeSectionTitle() {
        return this.jsonData["loungeSectionTitle"][this.lang]
    }

    get reportingSectionTitle() {
        return this.jsonData["reportingSectionTitle"][this.lang]
    }

    get requestMessageTitle() {
        return this.jsonData["requestMessageTitle"][this.lang]
    }

    get noRequestMessageDefault() {
        return this.jsonData["noRequestMessageDefault"][this.lang]
    }

    get noActiveContactRequests() {
        return this.jsonData["noActiveContactRequests"][this.lang]
    }

    get ignoreContactRequstTitle() {
        return this.jsonData["ignoreContactRequstTitle"][this.lang]
    }

    get ignoreContactRequstText() {
        return this.jsonData["ignoreContactRequstText"][this.lang]
    }

    get ignoreContactText() {
        return this.jsonData["ignoreContactText"][this.lang]
    }

    get emptySearch() {
        return this.jsonData["emptySearch"][this.lang]
    }

    get editCompanyProfileLinkLabel() {
        return this.jsonData["editCompanyProfileLinkLabel"][this.lang]
    }

    get helpLink() {
        return this.jsonData["helpLink"][this.lang]
    }

    get helpLinkText1() {
        return this.jsonData["helpLinkText1"][this.lang]
    }

    get helpLinkText2() {
        return this.jsonData["helpLinkText2"][this.lang]
    }

    get helpLinkTextColor() {
        return this.jsonData["helpLinkTextColor"]
    }

    get alreadyMemberTitle() {
        return this.jsonData["alreadyMemberTitle"][this.lang]
    }

    get noActiveContactRequestsTextColor() {
        return this.jsonData["noActiveContactRequestsTextColor"]
    }
}

class BackofficeStaffBrandingImpl extends BrandingData implements BackofficeStaffBranding {
    _addStaffButton!: LocalizedString;
    _searchPersonPlaceholder!: LocalizedString;
    _removeStaffConfirmationTitle!: LocalizedString;
    _removeStaffConfirmationText!: LocalizedString;
    _cancelButton!: LocalizedString;
    _removeStaffButton!: LocalizedString;
    _alreadyAddedTitle!: LocalizedString;
    _alreadyAddedText!: LocalizedString;
    _showMeAsContactHoverText!: LocalizedString;
    _showShoowroomStandbyHoverText!: LocalizedString;
    _showMeAsContactDeactivateHoverText!: LocalizedString;
    _showShoowroomStandbyDeactivateHoverText!: LocalizedString;
    _startCall!: LocalizedString;
    _startChat!: LocalizedString;
    _requestMeeting!: LocalizedString;
    _share!: LocalizedString;
    _bookmark!: LocalizedString;
    _removeBookmark!: LocalizedString;
    _myBadgeText!: LocalizedString;
    _removeStaff!: LocalizedString;
    _searchBarColor!: ColorString;
    _addStaffButtonBgColor!: ColorString;
    _addStaffButtonTextColor!: ColorString;
    _addStaffButtonBorderColor!: ColorString;
    _organizationNamePanelTextColor!: ColorString;
    _removeConfirmationModalTextColor!: ColorString;
    _alreadyMemberTextColor!: ColorString;
    _alreadyMemberTitle!: LocalizedString;
    _alreadyMemberTitleIconColor!: ColorString;
    _addStaffButtonDescription!: LocalizedString;
    _addStaffButtonDescriptionColor!: ColorString;
    _staffEntryTextColor!: ColorString;
    _successfullyAddedStaffMessage!: LocalizedString;

    get addStaffButton() {
        return this.jsonData["addStaffButton"][this.lang]
    }

    get searchPersonPlaceholder() {
        return this.jsonData["searchPersonPlaceholder"][this.lang]
    }

    get removeStaffConfirmationTitle() {
        return this.jsonData["removeStaffConfirmationTitle"][this.lang]
    }

    get removeStaffConfirmationText() {
        return this.jsonData["removeStaffConfirmationText"][this.lang]
    }

    get cancelButton() {
        return this.jsonData["cancelButton"][this.lang]
    }

    get removeStaffButton() {
        return this.jsonData["removeStaffButton"][this.lang]
    }

    get alreadyAddedTitle() {
        return this.jsonData["alreadyAddedTitle"][this.lang]
    }

    get alreadyAddedText() {
        return this.jsonData["alreadyAddedText"][this.lang]
    }

    get showMeAsContactHoverText() {
        return this.jsonData["showMeAsContactHoverText"][this.lang]
    }

    get showShoowroomStandbyHoverText() {
        return this.jsonData["showShoowroomStandbyHoverText"][this.lang]
    }

    get showMeAsContactDeactivateHoverText() {
        return this.jsonData["showMeAsContactDeactivateHoverText"][this.lang]
    }

    get showShoowroomStandbyDeactivateHoverText() {
        return this.jsonData["showShoowroomStandbyDeactivateHoverText"][this.lang]
    }

    get startCall() {
        return this.jsonData["startCall"][this.lang]
    }

    get startChat() {
        return this.jsonData["startChat"][this.lang]
    }

    get requestMeeting() {
        return this.jsonData["requestMeeting"][this.lang]
    }

    get share() {
        return this.jsonData["share"][this.lang]
    }

    get bookmark() {
        return this.jsonData["bookmark"][this.lang]
    }

    get removeBookmark() {
        return this.jsonData["removeBookmark"][this.lang]
    }

    get myBadgeText() {
        return this.jsonData["myBadgeText"][this.lang]
    }

    get removeStaff() {
        return this.jsonData["removeStaff"][this.lang]
    }

    get searchBarColor() {
        return this.jsonData["searchBarColor"]
    }

    get addStaffButtonBgColor() {
        return this.jsonData["addStaffButtonBgColor"]
    }

    get addStaffButtonTextColor() {
        return this.jsonData["addStaffButtonTextColor"]
    }

    get addStaffButtonBorderColor() {
        return this.jsonData["addStaffButtonBorderColor"]
    }

    get organizationNamePanelTextColor() {
        return this.jsonData["organizationNamePanelTextColor"]
    }

    get removeConfirmationModalTextColor() {
        return this.jsonData["removeConfirmationModalTextColor"]
    }

    get alreadyMemberTextColor() {
        return this.jsonData["alreadyMemberTextColor"]
    }

    get alreadyMemberTitle() {
        return this.jsonData["alreadyMemberTitle"][this.lang]
    }

    get alreadyMemberTitleIconColor() {
        return this.jsonData["alreadyMemberTitleIconColor"]
    }

    get addStaffButtonDescription() {
        return this.jsonData["addStaffButtonDescription"][this.lang]
    }

    get addStaffButtonDescriptionColor() {
        return this.jsonData["addStaffButtonDescriptionColor"]
    }

    get staffEntryTextColor() {
        return this.jsonData["staffEntryTextColor"]
    }

    get successfullyAddedStaffMessage() {
        return this.jsonData["successfullyAddedStaffMessage"][this.lang]
    }
}

class BackofficeStaffReqBrandingImpl extends BrandingData implements BackofficeStaffReqBranding {
    _backofficeStaffReqRootBorder!: ColorString;
    _backofficeStaffReqRootTextColor!: ColorString;
    _listViewTitleColor!: string;
    _listViewTitleFont!: string;

    get backofficeStaffReqRootBorder() {
        return this.jsonData["backofficeStaffReqRootBorder"]
    }

    get backofficeStaffReqRootTextColor() {
        return this.jsonData["backofficeStaffReqRootTextColor"]
    }

    get listViewTitleColor() {
        return this.jsonData["listViewTitleColor"]
    }

    get listViewTitleFont() {
        return this.jsonData["listViewTitleFont"]
    }
}

class BackofficeMeetingsBrandingImpl extends BrandingData implements BackofficeMeetingsBranding {
    _backofficeMeetingsBorder!: string;
    _backofficeMeetingsTextColor!: string;
    _alreadyProcessedText!: LocalizedString;
    _listViewTitleColor!: string;
    _listViewTitleFont!: string;

    get backofficeMeetingsBorder() {
        return this.jsonData["backofficeMeetingsBorder"]
    }

    get backofficeMeetingsTextColor() {
        return this.jsonData["backofficeMeetingsTextColor"]
    }

    get alreadyProcessedText() {
        return this.jsonData["alreadyProcessedText"][this.lang]
    }

    get listViewTitleColor() {
        return this.jsonData["listViewTitleColor"]
    }

    get listViewTitleFont() {
        return this.jsonData["listViewTitleFont"]
    }
}

class BackofficeLVisitorsBrandingImpl extends BrandingData implements BackofficeLVisitorsBranding {
    _visitorTabTypesList!: VisitorTabType[];
    _guestsSelect!: LocalizedString;
    _visitorsSelect!: LocalizedString;
    _contactsSelct!: LocalizedString;
    _leadsSelect!: LocalizedString;
    _dbSelect!: LocalizedString;
    _noGuests!: LocalizedString;
    _visitorsFilter!: LocalizedString;
    _refreshList!: LocalizedString;
    _visitorsDivTextColor!: ColorString;
    _visitorsDropDownListTextColor!: ColorString;
    _visitorsDropDownListBgColor!: ColorString;
    _visitorsDropDownListBorder!: ColorString;
    _downloadTooltipTextColor!: ColorString;
    _downloadAsCSV!: LocalizedString;
    _optInLabel!: LocalizedString;
    _flagFlexBasis!: string;
    _exportDisabled!: boolean;
    _showProducts!: LocalizedString;
    _showTrademarks!: LocalizedString;
    _visitorsFilterOn!: boolean;
    _visitorsListLegendTooltipLeft!: string;
    _listViewTitleColor!: string;
    _listViewTitleFont!: string;

    get visitorTabTypesList() {
        return this.jsonData["visitorTabTypesList"]
    }

    get guestsSelect() {
        return this.jsonData["guestsSelect"][this.lang]
    }

    get visitorsSelect() {
        return this.jsonData["visitorsSelect"][this.lang]
    }

    get contactsSelct() {
        return this.jsonData["contactsSelct"][this.lang]
    }

    get leadsSelect() {
        return this.jsonData["leadsSelect"][this.lang]
    }

    get dbSelect() {
        return this.jsonData["dbSelect"][this.lang]
    }

    get noGuests() {
        return this.jsonData["noGuests"][this.lang]
    }

    get visitorsFilter() {
        return this.jsonData["visitorsFilter"][this.lang]
    }

    get refreshList() {
        return this.jsonData["refreshList"][this.lang]
    }

    get visitorsDivTextColor() {
        return this.jsonData["visitorsDivTextColor"]
    }

    get visitorsDropDownListTextColor() {
        return this.jsonData["visitorsDropDownListTextColor"]
    }

    get visitorsDropDownListBgColor() {
        return this.jsonData["visitorsDropDownListBgColor"]
    }

    get visitorsDropDownListBorder() {
        return this.jsonData["visitorsDropDownListBorder"]
    }

    get downloadTooltipTextColor() {
        return this.jsonData["downloadTooltipTextColor"]
    }

    get downloadAsCSV() {
        return this.jsonData["downloadAsCSV"][this.lang]
    }

    get optInLabel() {
        return this.jsonData["optInLabel"][this.lang]
    }

    get flagFlexBasis() {
        return this.jsonData["flagFlexBasis"]
    }

    get exportDisabled() {
        return this.jsonData["exportDisabled"]
    }

    get showProducts() {
        return this.jsonData["showProducts"][this.lang]
    }

    get showTrademarks() {
        return this.jsonData["showTrademarks"][this.lang]
    }

    get visitorsFilterOn() {
        return this.jsonData["visitorsFilterOn"]
    }

    get visitorsListLegendTooltipLeft() {
        return this.jsonData["visitorsListLegendTooltipLeft"]
    }

    get listViewTitleColor() {
        return this.jsonData["listViewTitleColor"]
    }

    get listViewTitleFont() {
        return this.jsonData["listViewTitleFont"]
    }
}

class BackofficeLoungeBrandingImpl extends BrandingData implements BackofficeLoungeBranding {
    _noMeetingGroups!: LocalizedString;
    _noRequest!: LocalizedString;
    _noGranted!: LocalizedString;
    _requestReasonTitle!: LocalizedString;
    _acceptAccessTitle!: LocalizedString;
    _declineAccessTitle!: LocalizedString;
    _meetingGroupRoomRowBorder!: string;
    _meetingGroupRoomBgColorOnHover!: ColorString;
    _meetingRoomGroupTileTextColor!: ColorString;
    _listViewTitleColor!: string;
    _listViewTitleFont!: string;

    get noMeetingGroups() {
        return this.jsonData["noMeetingGroups"][this.lang]
    }

    get noRequest() {
        return this.jsonData["noRequest"][this.lang]
    }

    get noGranted() {
        return this.jsonData["noGranted"][this.lang]
    }

    get requestReasonTitle() {
        return this.jsonData["requestReasonTitle"][this.lang]
    }

    get acceptAccessTitle() {
        return this.jsonData["acceptAccessTitle"][this.lang]
    }

    get declineAccessTitle() {
        return this.jsonData["declineAccessTitle"][this.lang]
    }

    get meetingGroupRoomRowBorder() {
        return this.jsonData["meetingGroupRoomRowBorder"]
    }

    get meetingGroupRoomBgColorOnHover() {
        return this.jsonData["meetingGroupRoomBgColorOnHover"]
    }

    get meetingRoomGroupTileTextColor() {
        return this.jsonData["meetingRoomGroupTileTextColor"]
    }

    get listViewTitleColor() {
        return this.jsonData["listViewTitleColor"]
    }

    get listViewTitleFont() {
        return this.jsonData["listViewTitleFont"]
    }
}

class BackofficeReportingBrandingImpl extends BrandingData implements BackofficeReportingBranding {
    _showroomSelect!: LocalizedString;
    _expoSelect!: LocalizedString;
    _meetingsSelect!: LocalizedString;
    _interestsSelect!: LocalizedString;
    _recommendationSelect!: LocalizedString;
    _vcSelect!: LocalizedString;
    _vcRoomSelect!: LocalizedString;
    _linksSelect!: LocalizedString;
    _mediaSelect!: LocalizedString;
    _liveSelect!: LocalizedString;
    _eventDateSelect!: LocalizedString;
    _sourceFloor!: LocalizedString;
    _sourceLobby!: LocalizedString;
    _sourceVC!: LocalizedString;
    _sourceSidebar!: LocalizedString;
    _sourceCoupon!: LocalizedString;
    _sourcePress!: LocalizedString;
    _sourceMagazine!: LocalizedString;
    _sourceBookmarks!: LocalizedString;
    _sourceSearch!: LocalizedString;
    _sourceBanner!: LocalizedString;
    _sourceSponsors!: LocalizedString;
    _sourceNotification!: LocalizedString;
    _sourceOrganization!: LocalizedString;
    _sourceEventDate!: LocalizedString;
    _sourceProgram!: LocalizedString;
    _sourcePerson!: LocalizedString;
    _sourceProduct!: LocalizedString;
    _sourceTrademark!: LocalizedString;
    _sourceNews!: LocalizedString;
    _sourceHallplan!: LocalizedString;
    _sourceJobOffer!: LocalizedString;
    _sourceCollection!: LocalizedString;
    _sourceStartup!: LocalizedString;
    _sourceProfile!: LocalizedString;
    _sourceGuest!: LocalizedString;
    _sourceEvent!: LocalizedString;
    _total!: LocalizedString;
    _visits!: LocalizedString;
    _clicks!: LocalizedString;
    _requests!: LocalizedString;
    _sent!: LocalizedString;
    _preview!: LocalizedString;
    _download!: LocalizedString;
    _hour!: LocalizedString;
    _hours!: LocalizedString;
    _noDataMessage!: string;
    _reportingTextColor!: ColorString;
    _misc!: LocalizedString;
    _exportDisabled!: boolean;
    _dateTitle!: LocalizedString;
    _statisticsColumnsOrder!: StatisticsColumnType[];

    get showroomSelect() {
        return this.jsonData["showroomSelect"][this.lang]
    }

    get expoSelect() {
        return this.jsonData["expoSelect"][this.lang]
    }

    get meetingsSelect() {
        return this.jsonData["meetingsSelect"][this.lang]
    }

    get interestsSelect() {
        return this.jsonData["interestsSelect"][this.lang]
    }

    get recommendationSelect() {
        return this.jsonData["recommendationSelect"][this.lang]
    }

    get vcSelect() {
        return this.jsonData["vcSelect"][this.lang]
    }

    get vcRoomSelect() {
        return this.jsonData["vcRoomSelect"][this.lang]
    }

    get linksSelect() {
        return this.jsonData["linksSelect"][this.lang]
    }

    get mediaSelect() {
        return this.jsonData["mediaSelect"][this.lang]
    }

    get liveSelect() {
        return this.jsonData["liveSelect"][this.lang]
    }

    get eventDateSelect() {
        return this.jsonData["eventDateSelect"][this.lang]
    }

    get sourceFloor() {
        return this.jsonData["sourceFloor"][this.lang]
    }

    get sourceLobby() {
        return this.jsonData["sourceLobby"][this.lang]
    }

    get sourceVC() {
        return this.jsonData["sourceVC"][this.lang]
    }

    get sourceSidebar() {
        return this.jsonData["sourceSidebar"][this.lang]
    }

    get sourceCoupon() {
        return this.jsonData["sourceCoupon"][this.lang]
    }

    get sourcePress() {
        return this.jsonData["sourcePress"][this.lang]
    }

    get sourceMagazine() {
        return this.jsonData["sourceMagazine"][this.lang]
    }

    get sourceBookmarks() {
        return this.jsonData["sourceBookmarks"][this.lang]
    }

    get sourceSearch() {
        return this.jsonData["sourceSearch"][this.lang]
    }

    get sourceBanner() {
        return this.jsonData["sourceBanner"][this.lang]
    }

    get sourceSponsors() {
        return this.jsonData["sourceSponsors"][this.lang]
    }

    get sourceNotification() {
        return this.jsonData["sourceNotification"][this.lang]
    }

    get sourceOrganization() {
        return this.jsonData["sourceOrganization"][this.lang]
    }

    get sourceEventDate() {
        return this.jsonData["sourceEventDate"][this.lang]
    }

    get sourceProgram() {
        return this.jsonData["sourceProgram"][this.lang]
    }

    get sourcePerson() {
        return this.jsonData["sourcePerson"][this.lang]
    }

    get sourceProduct() {
        return this.jsonData["sourceProduct"][this.lang]
    }

    get sourceTrademark() {
        return this.jsonData["sourceTrademark"][this.lang]
    }

    get sourceNews() {
        return this.jsonData["sourceNews"][this.lang]
    }

    get sourceHallplan() {
        return this.jsonData["sourceHallplan"][this.lang]
    }

    get sourceJobOffer() {
        return this.jsonData["sourceJobOffer"][this.lang]
    }

    get sourceCollection() {
        return this.jsonData["sourceCollection"][this.lang]
    }

    get sourceStartup() {
        return this.jsonData["sourceStartup"][this.lang]
    }

    get sourceProfile() {
        return this.jsonData["sourceProfile"][this.lang]
    }

    get sourceGuest() {
        return this.jsonData["sourceGuest"][this.lang]
    }

    get sourceEvent() {
        return this.jsonData["sourceEvent"][this.lang]
    }

    get total() {
        return this.jsonData["total"][this.lang]
    }

    get visits() {
        return this.jsonData["visits"][this.lang]
    }

    get clicks() {
        return this.jsonData["clicks"][this.lang]
    }

    get requests() {
        return this.jsonData["requests"][this.lang]
    }

    get sent() {
        return this.jsonData["sent"][this.lang]
    }

    get preview() {
        return this.jsonData["preview"][this.lang]
    }

    get download() {
        return this.jsonData["download"][this.lang]
    }

    get hour() {
        return this.jsonData["hour"][this.lang]
    }

    get hours() {
        return this.jsonData["hours"][this.lang]
    }

    get noDataMessage() {
        return this.jsonData["noDataMessage"]
    }

    get reportingTextColor() {
        return this.jsonData["reportingTextColor"]
    }

    get misc() {
        return this.jsonData["misc"][this.lang]
    }

    get exportDisabled() {
        return this.jsonData["exportDisabled"]
    }

    get dateTitle() {
        return this.jsonData["dateTitle"][this.lang]
    }

    get statisticsColumnsOrder() {
        return this.jsonData["statisticsColumnsOrder"]
    }
}

class ProductDetailPageContentBrandingImpl extends BrandingData implements ProductDetailPageContentBranding {
    _navbarText!: LocalizedString;
    _sectionOrder!: DetailSection[];
    _infoContentOrder!: InfoContentOrderType[];
    _rootCategories!: string[];
    _showCategoryHierarchy!: boolean;
    _mediaFullScreenText!: LocalizedString;
    _showMoreText!: LocalizedString;
    _showLessText!: LocalizedString;
    _halleStandSectionTitle!: LocalizedString;

    get navbarText() {
        return this.jsonData["navbarText"][this.lang]
    }

    get sectionOrder() {
        return this.jsonData["sectionOrder"]
    }

    get infoContentOrder() {
        return this.jsonData["infoContentOrder"]
    }

    get rootCategories() {
        return this.jsonData["rootCategories"]
    }

    get showCategoryHierarchy() {
        return this.jsonData["showCategoryHierarchy"]
    }

    get mediaFullScreenText() {
        return this.jsonData["mediaFullScreenText"][this.lang]
    }

    get showMoreText() {
        return this.jsonData["showMoreText"][this.lang]
    }

    get showLessText() {
        return this.jsonData["showLessText"][this.lang]
    }

    get halleStandSectionTitle() {
        return this.jsonData["halleStandSectionTitle"][this.lang]
    }
}

class TrademarkDetailPageContentBrandingImpl extends BrandingData implements TrademarkDetailPageContentBranding {
    _navbarText!: LocalizedString;
    _sectionOrder!: DetailSection[];
    _infoContentOrder!: InfoContentOrderType[];
    _rootCategories!: string[];
    _showCategoryHierarchy!: boolean;
    _downloadsTitle!: LocalizedString;
    _downloadTitle!: LocalizedString;

    get navbarText() {
        return this.jsonData["navbarText"][this.lang]
    }

    get sectionOrder() {
        return this.jsonData["sectionOrder"]
    }

    get infoContentOrder() {
        return this.jsonData["infoContentOrder"]
    }

    get rootCategories() {
        return this.jsonData["rootCategories"]
    }

    get showCategoryHierarchy() {
        return this.jsonData["showCategoryHierarchy"]
    }

    get downloadsTitle() {
        return this.jsonData["downloadsTitle"][this.lang]
    }

    get downloadTitle() {
        return this.jsonData["downloadTitle"][this.lang]
    }
}

class JobOfferDetailPageContentBrandingImpl extends BrandingData implements JobOfferDetailPageContentBranding {
    _navbarText!: LocalizedString;
    _sections!: DetailSection[];
    _infoContentOrder!: InfoContentOrderType[];
    _downloadsTitle!: LocalizedString;
    _downloadTitle!: LocalizedString;
    _personListTitle!: LocalizedString;
    _descriptionTitle!: LocalizedString;
    _requirementsTitle!: LocalizedString;
    _startDatePattern!: LocalizedString;
    _homeOfficeTitle!: LocalizedString;
    _rootCategories!: string[];
    _showCategoryHierarchy!: boolean;

    get navbarText() {
        return this.jsonData["navbarText"][this.lang]
    }

    get sections() {
        return this.jsonData["sections"]
    }

    get infoContentOrder() {
        return this.jsonData["infoContentOrder"]
    }

    get downloadsTitle() {
        return this.jsonData["downloadsTitle"][this.lang]
    }

    get downloadTitle() {
        return this.jsonData["downloadTitle"][this.lang]
    }

    get personListTitle() {
        return this.jsonData["personListTitle"][this.lang]
    }

    get descriptionTitle() {
        return this.jsonData["descriptionTitle"][this.lang]
    }

    get requirementsTitle() {
        return this.jsonData["requirementsTitle"][this.lang]
    }

    get startDatePattern() {
        return this.jsonData["startDatePattern"][this.lang]
    }

    get homeOfficeTitle() {
        return this.jsonData["homeOfficeTitle"][this.lang]
    }

    get rootCategories() {
        return this.jsonData["rootCategories"]
    }

    get showCategoryHierarchy() {
        return this.jsonData["showCategoryHierarchy"]
    }
}

class NewsDetailPageContentBrandingImpl extends BrandingData implements NewsDetailPageContentBranding {
    _sections!: DetailSection[];
    _infoContentOrder!: InfoContentOrderType[];
    _rootCategories!: string[];
    _showCategoryHierarchy!: boolean;
    _authorsSectionTitle!: LocalizedString;

    get sections() {
        return this.jsonData["sections"]
    }

    get infoContentOrder() {
        return this.jsonData["infoContentOrder"]
    }

    get rootCategories() {
        return this.jsonData["rootCategories"]
    }

    get showCategoryHierarchy() {
        return this.jsonData["showCategoryHierarchy"]
    }

    get authorsSectionTitle() {
        return this.jsonData["authorsSectionTitle"][this.lang]
    }
}

class EventDateDetailPageContentBrandingImpl extends BrandingData implements EventDateDetailPageContentBranding {
    _companyDetailsHeader!: LocalizedString;
    _eventLayoutTitle!: LocalizedString;
    _descriptionTextForEventDate!: LocalizedString;
    _descriptionForEventDatesBindedToEvent!: LocalizedString;
    _eventLayoutVisibleOnEventDateDetailPage!: boolean;
    _mediaVisibleOnEventDateDetailPage!: boolean;
    _downloadsVisibleOnEventDateDetailPage!: boolean;
    _personsTitle!: LocalizedString;
    _noPersonsMessage!: LocalizedString;
    _galleryTitle!: LocalizedString;
    _noSectionTitle!: LocalizedString;
    _eventDateRoundTableNoAccessText!: LocalizedString;
    _eventDateRoundTableGrantedAccessText!: LocalizedString;
    _privateEventDateNoAccessText!: LocalizedString;
    _privateEventDateTicketNoAccessText!: LocalizedString;
    _privateEventDateNoTicketsAvailable!: LocalizedString;
    _getTicketButtonText!: LocalizedString;
    _privateEventDateGrantedAccessText!: LocalizedString;
    _eventDateRoundTablePostEventText!: LocalizedString;
    _eventDateRoundTableButtonAccessGrantedLive!: LocalizedString;
    _eventDateRoundTableButtonPostEvent!: LocalizedString;
    _eventDateRoundTableButtonCountdown!: PluralString;
    _chatVisible!: boolean;
    _pollsVisible!: boolean;
    _qandaVisible!: boolean;
    _participantsVisible!: boolean;
    _multiSwitchItems!: string[];
    _multiSwitchItemVisible!: boolean;
    _linksVisible!: boolean;
    _showCategories!: boolean;
    _rootCategories!: string[];
    _showCategoryHierarchy!: boolean;
    _channelParticipantsTabTitle!: LocalizedString;
    _noParticipantsMessage!: LocalizedString;
    _typeformSectionEnabled!: boolean;
    _typeformSectionDescriptionText!: LocalizedString;
    _typeformSectionButtonText!: LocalizedString;
    _typeformSectionCountdownVisible!: boolean;
    _typeformSectionWaitingDescriptionText!: LocalizedString;
    _minutesBeforeEnablingTypeformSection!: number;
    _eventDetailSectionsOrder!: EventDateDetailSection[];
    _desktopSectionsOrder!: EventDateDetailSection[];
    _mobileSectionsOrder!: EventDateDetailSection[];

    get companyDetailsHeader() {
        return this.jsonData["companyDetailsHeader"][this.lang]
    }

    get eventLayoutTitle() {
        return this.jsonData["eventLayoutTitle"][this.lang]
    }

    get descriptionTextForEventDate() {
        return this.jsonData["descriptionTextForEventDate"][this.lang]
    }

    get descriptionForEventDatesBindedToEvent() {
        return this.jsonData["descriptionForEventDatesBindedToEvent"][this.lang]
    }

    get eventLayoutVisibleOnEventDateDetailPage() {
        return this.jsonData["eventLayoutVisibleOnEventDateDetailPage"]
    }

    get mediaVisibleOnEventDateDetailPage() {
        return this.jsonData["mediaVisibleOnEventDateDetailPage"]
    }

    get downloadsVisibleOnEventDateDetailPage() {
        return this.jsonData["downloadsVisibleOnEventDateDetailPage"]
    }

    get personsTitle() {
        return this.jsonData["personsTitle"][this.lang]
    }

    get noPersonsMessage() {
        return this.jsonData["noPersonsMessage"][this.lang]
    }

    get galleryTitle() {
        return this.jsonData["galleryTitle"][this.lang]
    }

    get noSectionTitle() {
        return this.jsonData["noSectionTitle"][this.lang]
    }

    get eventDateRoundTableNoAccessText() {
        return this.jsonData["eventDateRoundTableNoAccessText"][this.lang]
    }

    get eventDateRoundTableGrantedAccessText() {
        return this.jsonData["eventDateRoundTableGrantedAccessText"][this.lang]
    }

    get privateEventDateNoAccessText() {
        return this.jsonData["privateEventDateNoAccessText"][this.lang]
    }

    get privateEventDateTicketNoAccessText() {
        return this.jsonData["privateEventDateTicketNoAccessText"][this.lang]
    }

    get privateEventDateNoTicketsAvailable() {
        return this.jsonData["privateEventDateNoTicketsAvailable"][this.lang]
    }

    get getTicketButtonText() {
        return this.jsonData["getTicketButtonText"][this.lang]
    }

    get privateEventDateGrantedAccessText() {
        return this.jsonData["privateEventDateGrantedAccessText"][this.lang]
    }

    get eventDateRoundTablePostEventText() {
        return this.jsonData["eventDateRoundTablePostEventText"][this.lang]
    }

    get eventDateRoundTableButtonAccessGrantedLive() {
        return this.jsonData["eventDateRoundTableButtonAccessGrantedLive"][this.lang]
    }

    get eventDateRoundTableButtonPostEvent() {
        return this.jsonData["eventDateRoundTableButtonPostEvent"][this.lang]
    }

    get eventDateRoundTableButtonCountdown() {
        if (!this._eventDateRoundTableButtonCountdown) {
            this._eventDateRoundTableButtonCountdown = new PluralStringImpl(this.jsonData["eventDateRoundTableButtonCountdown"], this.lang)
            this.subBrandings.push(this._eventDateRoundTableButtonCountdown as any as BrandingData)
        }
        return this._eventDateRoundTableButtonCountdown
    }

    get chatVisible() {
        return this.jsonData["chatVisible"]
    }

    get pollsVisible() {
        return this.jsonData["pollsVisible"]
    }

    get qandaVisible() {
        return this.jsonData["qandaVisible"]
    }

    get participantsVisible() {
        return this.jsonData["participantsVisible"]
    }

    get multiSwitchItems() {
        return this.jsonData["multiSwitchItems"]
    }

    get multiSwitchItemVisible() {
        return this.jsonData["multiSwitchItemVisible"]
    }

    get linksVisible() {
        return this.jsonData["linksVisible"]
    }

    get showCategories() {
        return this.jsonData["showCategories"]
    }

    get rootCategories() {
        return this.jsonData["rootCategories"]
    }

    get showCategoryHierarchy() {
        return this.jsonData["showCategoryHierarchy"]
    }

    get channelParticipantsTabTitle() {
        return this.jsonData["channelParticipantsTabTitle"][this.lang]
    }

    get noParticipantsMessage() {
        return this.jsonData["noParticipantsMessage"][this.lang]
    }

    get typeformSectionEnabled() {
        return this.jsonData["typeformSectionEnabled"]
    }

    get typeformSectionDescriptionText() {
        return this.jsonData["typeformSectionDescriptionText"][this.lang]
    }

    get typeformSectionButtonText() {
        return this.jsonData["typeformSectionButtonText"][this.lang]
    }

    get typeformSectionCountdownVisible() {
        return this.jsonData["typeformSectionCountdownVisible"]
    }

    get typeformSectionWaitingDescriptionText() {
        return this.jsonData["typeformSectionWaitingDescriptionText"][this.lang]
    }

    get minutesBeforeEnablingTypeformSection() {
        return this.jsonData["minutesBeforeEnablingTypeformSection"]
    }

    get eventDetailSectionsOrder() {
        return this.jsonData["eventDetailSectionsOrder"]
    }

    get desktopSectionsOrder() {
        return this.jsonData["desktopSectionsOrder"]
    }

    get mobileSectionsOrder() {
        return this.jsonData["mobileSectionsOrder"]
    }
}

class PluralStringImpl extends BrandingData implements PluralString {
    _singular!: LocalizedString;
    _plural!: LocalizedString;

    get singular() {
        return this.jsonData["singular"][this.lang]
    }

    get plural() {
        return this.jsonData["plural"][this.lang]
    }
}

class ProductsPageContentBrandingImpl extends BrandingData implements ProductsPageContentBranding {
    _navigationSearchPlaceholderProducts!: LocalizedString;
    _loadingMessage!: LocalizedString;
    _noBookmarksMessage!: LocalizedString;
    _productsListLayoutTitleColor!: string;
    _productsListLayoutDescriptionColor!: string;
    _productsListLayoutTitleFont!: string;

    get navigationSearchPlaceholderProducts() {
        return this.jsonData["navigationSearchPlaceholderProducts"][this.lang]
    }

    get loadingMessage() {
        return this.jsonData["loadingMessage"][this.lang]
    }

    get noBookmarksMessage() {
        return this.jsonData["noBookmarksMessage"][this.lang]
    }

    get productsListLayoutTitleColor() {
        return this.jsonData["productsListLayoutTitleColor"]
    }

    get productsListLayoutDescriptionColor() {
        return this.jsonData["productsListLayoutDescriptionColor"]
    }

    get productsListLayoutTitleFont() {
        return this.jsonData["productsListLayoutTitleFont"]
    }
}

class NewsPageContentBrandingImpl extends BrandingData implements NewsPageContentBranding {
    _newsListTitle!: LocalizedString;
    _newsListSearchPlaceholder!: LocalizedString;
    _noSearchResultsLabel!: LocalizedString;
    _noBookmarksMessage!: LocalizedString;
    _showCategories!: boolean;
    _rootCategories!: string[];
    _showCategoryHierarchy!: boolean;
    _newsListLayoutTitleColor!: string;
    _newsListLayoutDescriptionColor!: string;
    _newsListLayoutDateTimeColor!: string;
    _newsListLayoutTitleFont!: string;
    _jobOfferListLayoutTitleColor!: string;
    _jobOfferListLayoutDescriptionColor!: string;
    _jobOfferListLayoutTitleFont!: string;

    get newsListTitle() {
        return this.jsonData["newsListTitle"][this.lang]
    }

    get newsListSearchPlaceholder() {
        return this.jsonData["newsListSearchPlaceholder"][this.lang]
    }

    get noSearchResultsLabel() {
        return this.jsonData["noSearchResultsLabel"][this.lang]
    }

    get noBookmarksMessage() {
        return this.jsonData["noBookmarksMessage"][this.lang]
    }

    get showCategories() {
        return this.jsonData["showCategories"]
    }

    get rootCategories() {
        return this.jsonData["rootCategories"]
    }

    get showCategoryHierarchy() {
        return this.jsonData["showCategoryHierarchy"]
    }

    get newsListLayoutTitleColor() {
        return this.jsonData["newsListLayoutTitleColor"]
    }

    get newsListLayoutDescriptionColor() {
        return this.jsonData["newsListLayoutDescriptionColor"]
    }

    get newsListLayoutDateTimeColor() {
        return this.jsonData["newsListLayoutDateTimeColor"]
    }

    get newsListLayoutTitleFont() {
        return this.jsonData["newsListLayoutTitleFont"]
    }

    get jobOfferListLayoutTitleColor() {
        return this.jsonData["jobOfferListLayoutTitleColor"]
    }

    get jobOfferListLayoutDescriptionColor() {
        return this.jsonData["jobOfferListLayoutDescriptionColor"]
    }

    get jobOfferListLayoutTitleFont() {
        return this.jsonData["jobOfferListLayoutTitleFont"]
    }
}

class TrademarksPageContentBrandingImpl extends BrandingData implements TrademarksPageContentBranding {
    _navigationSearchPlaceholderTrademarks!: LocalizedString;
    _loadingMessage!: LocalizedString;
    _noBookmarksMessage!: LocalizedString;
    _trademarksListLayoutTitleColor!: string;
    _trademarksListLayoutDescriptionColor!: string;
    _trademarksListLayoutTitleFont!: string;

    get navigationSearchPlaceholderTrademarks() {
        return this.jsonData["navigationSearchPlaceholderTrademarks"][this.lang]
    }

    get loadingMessage() {
        return this.jsonData["loadingMessage"][this.lang]
    }

    get noBookmarksMessage() {
        return this.jsonData["noBookmarksMessage"][this.lang]
    }

    get trademarksListLayoutTitleColor() {
        return this.jsonData["trademarksListLayoutTitleColor"]
    }

    get trademarksListLayoutDescriptionColor() {
        return this.jsonData["trademarksListLayoutDescriptionColor"]
    }

    get trademarksListLayoutTitleFont() {
        return this.jsonData["trademarksListLayoutTitleFont"]
    }
}

class ExhibitorsPageContentBrandingImpl extends BrandingData implements ExhibitorsPageContentBranding {
    _initialTab!: "eventdate" | "person" | "organization" | "product" | "trademark" | "news" | "category" | "customcategory" | "coupon" | "networking_user" | "sotuser" | "event" | "country" | "joboffer" | "stand_registration";
    _dropdownFilterConfig!: DropdownFilterConfigType[];
    _iconsVisibleOnTiles!: string[];
    _iconsVisibleOnSmallTiles!: boolean;
    _defaultLogoVisibleOnCompanyTiles!: boolean;
    _navigationSearchPlaceholderCompanies!: LocalizedString;
    _loadingMessage!: LocalizedString;
    _noBookmarksMessage!: LocalizedString;
    _onlyShowBookmarksTitle!: LocalizedString;
    _listView!: LocalizedString;
    _cardView!: LocalizedString;
    _sectionHeaderTop!: LocalizedString;
    _sectionHeaderAllExhibitors!: LocalizedString;
    _sectionHeaderAllProducts!: LocalizedString;
    _sectionHeaderAllTrademarks!: LocalizedString;
    _fillersData!: FillerTypeRoot;
    _companyTileBasicTextColor!: ColorString;
    _companyTileLogoBorderColor!: ColorString;
    _largeTileBgPosition!: string;
    _companyTileNoneClickable!: boolean;
    _tilePlaceholderBgColor!: ColorString;
    _bookmarkedExhibitorsTitle!: LocalizedString;
    _bookmarkedProductsTitle!: LocalizedString;
    _bookmarkedTrademarksTitle!: LocalizedString;
    _bookmarkedNewsTitle!: LocalizedString;
    _bookmarkedJoboffersTitle!: LocalizedString;
    _exhibitorsColInfoTextColor!: ColorString;
    _specialTileSizeForSponsors!: boolean;
    _companiesTilesLayoutOverlay!: string;
    _exhibitorsStartupPageName!: LocalizedString;
    _noSearchResultsText!: LocalizedString;
    _noSearchResultsTextColor!: string;
    _listViewAsDefaultStartup!: boolean;
    _listViewAsDefaultOrganization!: boolean;
    _exhibitorsPageContentBorderSize!: string;
    _resetFilterBorderColor!: ColorString;
    _resetFilterTextColor!: ColorString;
    _resetFilterButtonBgColor!: ColorString;
    _companiesTilesLayoutHoverOverlay!: string;
    _searchAllBorderColor!: ColorString;
    _searchAllColor!: ColorString;
    _searchAllBgColor!: ColorString;
    _exhibitorTilesBorderWidth!: string;
    _exhibitorTilesBorderColor!: ColorString;
    _exhibitorTilesMaxBadgeCount!: number;
    _currentSearchCloseIconFill!: ColorString;
    _currentSearchCloseIconStroke!: ColorString;
    _packagesWithMeetingsEnabled!: BasisPremiumType[];
    _sectionTitleFontFamily!: string;
    _sectionTitleFontSize!: string;
    _sectionTitleLetterSpacing!: string;
    _sectionTitleFontWeight!: string;
    _sectionTitleTextTransform!: string;
    _companiesListLayoutOrganizationLogoSquareShape!: boolean;
    _companiesListLayoutTrademarkLogoSquareShape!: boolean;
    _showHalleStandInfo!: boolean;
    _showAllHalleStandInfo!: boolean;
    _companiesListLayoutTitleColor!: string;
    _companiesListLayoutDescriptionColor!: string;
    _companiesListLayoutTitleFont!: string;
    _listLayoutActionsIconColor!: string;
    _startupPackageValue!: number;
    _startupCategories!: string[];
    _newsSortByFilterVisible!: boolean;
    _newsSortByNewestToOldestTitle!: LocalizedString;
    _newsSortByOldestToNewestTitle!: LocalizedString;
    _categoriesSliderSpacing!: string;
    _filterNoOptionsMessage!: LocalizedString;
    _jobofferTabTitle!: LocalizedString;
    _jobofferLocationTitleTemplate!: LocalizedString;
    _jobofferStartDateTitleTemplate!: LocalizedString;
    _jobofferCareerLevelTitleTemplate!: LocalizedString;
    _customCategoriesUrl!: string;
    _customCategoriesTitle!: LocalizedString;

    get initialTab() {
        return this.jsonData["initialTab"]
    }

    get dropdownFilterConfig() {
        if (!this._dropdownFilterConfig) {
            this._dropdownFilterConfig = []
            for (const arrayElement of this.jsonData["dropdownFilterConfig"]) {
                const arrayObject = new DropdownFilterConfigTypeImpl(arrayElement, this.lang)
                this.dropdownFilterConfig.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._dropdownFilterConfig
    }

    get iconsVisibleOnTiles() {
        return this.jsonData["iconsVisibleOnTiles"]
    }

    get iconsVisibleOnSmallTiles() {
        return this.jsonData["iconsVisibleOnSmallTiles"]
    }

    get defaultLogoVisibleOnCompanyTiles() {
        return this.jsonData["defaultLogoVisibleOnCompanyTiles"]
    }

    get navigationSearchPlaceholderCompanies() {
        return this.jsonData["navigationSearchPlaceholderCompanies"][this.lang]
    }

    get loadingMessage() {
        return this.jsonData["loadingMessage"][this.lang]
    }

    get noBookmarksMessage() {
        return this.jsonData["noBookmarksMessage"][this.lang]
    }

    get onlyShowBookmarksTitle() {
        return this.jsonData["onlyShowBookmarksTitle"][this.lang]
    }

    get listView() {
        return this.jsonData["listView"][this.lang]
    }

    get cardView() {
        return this.jsonData["cardView"][this.lang]
    }

    get sectionHeaderTop() {
        return this.jsonData["sectionHeaderTop"][this.lang]
    }

    get sectionHeaderAllExhibitors() {
        return this.jsonData["sectionHeaderAllExhibitors"][this.lang]
    }

    get sectionHeaderAllProducts() {
        return this.jsonData["sectionHeaderAllProducts"][this.lang]
    }

    get sectionHeaderAllTrademarks() {
        return this.jsonData["sectionHeaderAllTrademarks"][this.lang]
    }

    get fillersData() {
        if (!this._fillersData) {
            this._fillersData = new FillerTypeRootImpl(this.jsonData["fillersData"], this.lang)
            this.subBrandings.push(this._fillersData as any as BrandingData)
        }
        return this._fillersData
    }

    get companyTileBasicTextColor() {
        return this.jsonData["companyTileBasicTextColor"]
    }

    get companyTileLogoBorderColor() {
        return this.jsonData["companyTileLogoBorderColor"]
    }

    get largeTileBgPosition() {
        return this.jsonData["largeTileBgPosition"]
    }

    get companyTileNoneClickable() {
        return this.jsonData["companyTileNoneClickable"]
    }

    get tilePlaceholderBgColor() {
        return this.jsonData["tilePlaceholderBgColor"]
    }

    get bookmarkedExhibitorsTitle() {
        return this.jsonData["bookmarkedExhibitorsTitle"][this.lang]
    }

    get bookmarkedProductsTitle() {
        return this.jsonData["bookmarkedProductsTitle"][this.lang]
    }

    get bookmarkedTrademarksTitle() {
        return this.jsonData["bookmarkedTrademarksTitle"][this.lang]
    }

    get bookmarkedNewsTitle() {
        return this.jsonData["bookmarkedNewsTitle"][this.lang]
    }

    get bookmarkedJoboffersTitle() {
        return this.jsonData["bookmarkedJoboffersTitle"][this.lang]
    }

    get exhibitorsColInfoTextColor() {
        return this.jsonData["exhibitorsColInfoTextColor"]
    }

    get specialTileSizeForSponsors() {
        return this.jsonData["specialTileSizeForSponsors"]
    }

    get companiesTilesLayoutOverlay() {
        return this.jsonData["companiesTilesLayoutOverlay"]
    }

    get exhibitorsStartupPageName() {
        return this.jsonData["exhibitorsStartupPageName"][this.lang]
    }

    get noSearchResultsText() {
        return this.jsonData["noSearchResultsText"][this.lang]
    }

    get noSearchResultsTextColor() {
        return this.jsonData["noSearchResultsTextColor"]
    }

    get listViewAsDefaultStartup() {
        return this.jsonData["listViewAsDefaultStartup"]
    }

    get listViewAsDefaultOrganization() {
        return this.jsonData["listViewAsDefaultOrganization"]
    }

    get exhibitorsPageContentBorderSize() {
        return this.jsonData["exhibitorsPageContentBorderSize"]
    }

    get resetFilterBorderColor() {
        return this.jsonData["resetFilterBorderColor"]
    }

    get resetFilterTextColor() {
        return this.jsonData["resetFilterTextColor"]
    }

    get resetFilterButtonBgColor() {
        return this.jsonData["resetFilterButtonBgColor"]
    }

    get companiesTilesLayoutHoverOverlay() {
        return this.jsonData["companiesTilesLayoutHoverOverlay"]
    }

    get searchAllBorderColor() {
        return this.jsonData["searchAllBorderColor"]
    }

    get searchAllColor() {
        return this.jsonData["searchAllColor"]
    }

    get searchAllBgColor() {
        return this.jsonData["searchAllBgColor"]
    }

    get exhibitorTilesBorderWidth() {
        return this.jsonData["exhibitorTilesBorderWidth"]
    }

    get exhibitorTilesBorderColor() {
        return this.jsonData["exhibitorTilesBorderColor"]
    }

    get exhibitorTilesMaxBadgeCount() {
        return this.jsonData["exhibitorTilesMaxBadgeCount"]
    }

    get currentSearchCloseIconFill() {
        return this.jsonData["currentSearchCloseIconFill"]
    }

    get currentSearchCloseIconStroke() {
        return this.jsonData["currentSearchCloseIconStroke"]
    }

    get packagesWithMeetingsEnabled() {
        return this.jsonData["packagesWithMeetingsEnabled"]
    }

    get sectionTitleFontFamily() {
        return this.jsonData["sectionTitleFontFamily"]
    }

    get sectionTitleFontSize() {
        return this.jsonData["sectionTitleFontSize"]
    }

    get sectionTitleLetterSpacing() {
        return this.jsonData["sectionTitleLetterSpacing"]
    }

    get sectionTitleFontWeight() {
        return this.jsonData["sectionTitleFontWeight"]
    }

    get sectionTitleTextTransform() {
        return this.jsonData["sectionTitleTextTransform"]
    }

    get companiesListLayoutOrganizationLogoSquareShape() {
        return this.jsonData["companiesListLayoutOrganizationLogoSquareShape"]
    }

    get companiesListLayoutTrademarkLogoSquareShape() {
        return this.jsonData["companiesListLayoutTrademarkLogoSquareShape"]
    }

    get showHalleStandInfo() {
        return this.jsonData["showHalleStandInfo"]
    }

    get showAllHalleStandInfo() {
        return this.jsonData["showAllHalleStandInfo"]
    }

    get companiesListLayoutTitleColor() {
        return this.jsonData["companiesListLayoutTitleColor"]
    }

    get companiesListLayoutDescriptionColor() {
        return this.jsonData["companiesListLayoutDescriptionColor"]
    }

    get companiesListLayoutTitleFont() {
        return this.jsonData["companiesListLayoutTitleFont"]
    }

    get listLayoutActionsIconColor() {
        return this.jsonData["listLayoutActionsIconColor"]
    }

    get startupPackageValue() {
        return this.jsonData["startupPackageValue"]
    }

    get startupCategories() {
        return this.jsonData["startupCategories"]
    }

    get newsSortByFilterVisible() {
        return this.jsonData["newsSortByFilterVisible"]
    }

    get newsSortByNewestToOldestTitle() {
        return this.jsonData["newsSortByNewestToOldestTitle"][this.lang]
    }

    get newsSortByOldestToNewestTitle() {
        return this.jsonData["newsSortByOldestToNewestTitle"][this.lang]
    }

    get categoriesSliderSpacing() {
        return this.jsonData["categoriesSliderSpacing"]
    }

    get filterNoOptionsMessage() {
        return this.jsonData["filterNoOptionsMessage"][this.lang]
    }

    get jobofferTabTitle() {
        return this.jsonData["jobofferTabTitle"][this.lang]
    }

    get jobofferLocationTitleTemplate() {
        return this.jsonData["jobofferLocationTitleTemplate"][this.lang]
    }

    get jobofferStartDateTitleTemplate() {
        return this.jsonData["jobofferStartDateTitleTemplate"][this.lang]
    }

    get jobofferCareerLevelTitleTemplate() {
        return this.jsonData["jobofferCareerLevelTitleTemplate"][this.lang]
    }

    get customCategoriesUrl() {
        return this.jsonData["customCategoriesUrl"]
    }

    get customCategoriesTitle() {
        return this.jsonData["customCategoriesTitle"][this.lang]
    }
}

class DropdownFilterConfigTypeImpl extends BrandingData implements DropdownFilterConfigType {
    _id!: string;
    _type!: GroupConfigType;
    _defaultTitle!: LocalizedString;
    _visibleForEntities!: string[];
    _catIds!: string[];
    _catId!: string;
    _msc!: number;
    _fec!: boolean;
    _umlautSortType!: string;

    get id() {
        return this.jsonData["id"]
    }

    get type() {
        return this.jsonData["type"]
    }

    get defaultTitle() {
        return this.jsonData["defaultTitle"][this.lang]
    }

    get visibleForEntities() {
        return this.jsonData["visibleForEntities"]
    }

    get catIds() {
        return this.jsonData["catIds"]
    }

    get catId() {
        return this.jsonData["catId"]
    }

    get msc() {
        return this.jsonData["msc"]
    }

    get fec() {
        return this.jsonData["fec"]
    }

    get umlautSortType() {
        return this.jsonData["umlautSortType"]
    }
}

class FillerTypeRootImpl extends BrandingData implements FillerTypeRoot {
    _standard!: FillerType[];
    _basic!: FillerType[];
    _none!: FillerType[];

    get standard() {
        if (!this._standard) {
            this._standard = []
            for (const arrayElement of this.jsonData["standard"]) {
                const arrayObject = new FillerTypeImpl(arrayElement, this.lang)
                this.standard.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._standard
    }

    get basic() {
        if (!this._basic) {
            this._basic = []
            for (const arrayElement of this.jsonData["basic"]) {
                const arrayObject = new FillerTypeImpl(arrayElement, this.lang)
                this.basic.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._basic
    }

    get none() {
        if (!this._none) {
            this._none = []
            for (const arrayElement of this.jsonData["none"]) {
                const arrayObject = new FillerTypeImpl(arrayElement, this.lang)
                this.none.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._none
    }
}

class FillerTypeImpl extends BrandingData implements FillerType {
    _title!: LocalizedString;
    _subtitle!: LocalizedString;
    _link!: LocalizedString;
    _to!: LocalizedString;

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get subtitle() {
        return this.jsonData["subtitle"][this.lang]
    }

    get link() {
        return this.jsonData["link"][this.lang]
    }

    get to() {
        return this.jsonData["to"][this.lang]
    }
}

class SuggestBoxContentBrandingImpl extends BrandingData implements SuggestBoxContentBranding {
    _suggestGroups!: GroupConfig[];
    _showMoreTitleOrganizations!: LocalizedString;
    _showMoreTitleProducts!: LocalizedString;
    _showMoreTitleTrademarks!: LocalizedString;
    _showMoreTitleEventDates!: LocalizedString;
    _showMoreTitlePersons!: LocalizedString;
    _showMoreTitleUsers!: LocalizedString;
    _showMoreTitleNews!: LocalizedString;
    _showMoreTitleCollections!: LocalizedString;
    _showMoreTitleCountries!: LocalizedString;
    _showMoreTitleJobOffers!: LocalizedString;
    _showMoreTitleCoupons!: LocalizedString;
    _suggestBoxGreyedOverlayColor!: string;
    _clearAllBtn!: SuggestBoxButtonType;
    _searchAllBtn!: SuggestBoxButtonType;
    _showAllBtn!: SuggestBoxButtonType;
    _showAllIconColor!: ColorString;
    _countriesGroupTitle!: LocalizedString;
    _joboffersGroupTitle!: LocalizedString;

    get suggestGroups() {
        if (!this._suggestGroups) {
            this._suggestGroups = []
            for (const arrayElement of this.jsonData["suggestGroups"]) {
                const arrayObject = new GroupConfigImpl(arrayElement, this.lang)
                this.suggestGroups.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._suggestGroups
    }

    get showMoreTitleOrganizations() {
        return this.jsonData["showMoreTitleOrganizations"][this.lang]
    }

    get showMoreTitleProducts() {
        return this.jsonData["showMoreTitleProducts"][this.lang]
    }

    get showMoreTitleTrademarks() {
        return this.jsonData["showMoreTitleTrademarks"][this.lang]
    }

    get showMoreTitleEventDates() {
        return this.jsonData["showMoreTitleEventDates"][this.lang]
    }

    get showMoreTitlePersons() {
        return this.jsonData["showMoreTitlePersons"][this.lang]
    }

    get showMoreTitleUsers() {
        return this.jsonData["showMoreTitleUsers"][this.lang]
    }

    get showMoreTitleNews() {
        return this.jsonData["showMoreTitleNews"][this.lang]
    }

    get showMoreTitleCollections() {
        return this.jsonData["showMoreTitleCollections"][this.lang]
    }

    get showMoreTitleCountries() {
        return this.jsonData["showMoreTitleCountries"][this.lang]
    }

    get showMoreTitleJobOffers() {
        return this.jsonData["showMoreTitleJobOffers"][this.lang]
    }

    get showMoreTitleCoupons() {
        return this.jsonData["showMoreTitleCoupons"][this.lang]
    }

    get suggestBoxGreyedOverlayColor() {
        return this.jsonData["suggestBoxGreyedOverlayColor"]
    }

    get clearAllBtn() {
        if (!this._clearAllBtn) {
            this._clearAllBtn = new SuggestBoxButtonTypeImpl(this.jsonData["clearAllBtn"], this.lang)
            this.subBrandings.push(this._clearAllBtn as any as BrandingData)
        }
        return this._clearAllBtn
    }

    get searchAllBtn() {
        if (!this._searchAllBtn) {
            this._searchAllBtn = new SuggestBoxButtonTypeImpl(this.jsonData["searchAllBtn"], this.lang)
            this.subBrandings.push(this._searchAllBtn as any as BrandingData)
        }
        return this._searchAllBtn
    }

    get showAllBtn() {
        if (!this._showAllBtn) {
            this._showAllBtn = new SuggestBoxButtonTypeImpl(this.jsonData["showAllBtn"], this.lang)
            this.subBrandings.push(this._showAllBtn as any as BrandingData)
        }
        return this._showAllBtn
    }

    get showAllIconColor() {
        return this.jsonData["showAllIconColor"]
    }

    get countriesGroupTitle() {
        return this.jsonData["countriesGroupTitle"][this.lang]
    }

    get joboffersGroupTitle() {
        return this.jsonData["joboffersGroupTitle"][this.lang]
    }
}

class SuggestBoxButtonTypeImpl extends BrandingData implements SuggestBoxButtonType {
    _text!: LocalizedString;
    _color!: ColorString;
    _bgColor!: ColorString;

    get text() {
        return this.jsonData["text"][this.lang]
    }

    get color() {
        return this.jsonData["color"]
    }

    get bgColor() {
        return this.jsonData["bgColor"]
    }
}

class CategoriesPageContentBrandingImpl extends BrandingData implements CategoriesPageContentBranding {
    _categoriesPageTitle!: LocalizedString;
    _categoriesTileTitlePosition!: string;
    _categoriesIconColorOnEditProfilePage!: ColorString;
    _categoriesBackgroundColorOnEditProfilePage!: ColorString;
    _categoriesTextColorOnEditProfilePage!: ColorString;
    _categoriesTileActiveColor!: string;
    _categoriesTileInctiveColor!: string;
    _categoriesTileDisabledColor!: string;
    _selectedCategoryTextColor!: string;
    _categoriesListInactiveColor!: string;
    _categoriesListFont!: string;
    _listViewAsDefault!: boolean;
    _categoriesConfiguration!: CategoryConfigType[];
    _customCategoriesConfiguration!: CategoryConfigType[];
    _emptyListMessage!: LocalizedString;
    _categorySliderItemDefaultColor!: ColorString;
    _categorySliderItemActiveColor!: ColorString;
    _categorySliderArrowsColor!: ColorString;
    _categoriesListShowCode!: boolean;
    _categoriesTileShowCode!: boolean;
    _categoriesTileListHeaderVisible!: boolean;
    _categoriesTileColorMappings!: CategoryTileConfig[];
    _categoriesBadgeShowCode!: boolean;
    _filterEmptyCategories!: boolean;

    get categoriesPageTitle() {
        return this.jsonData["categoriesPageTitle"][this.lang]
    }

    get categoriesTileTitlePosition() {
        return this.jsonData["categoriesTileTitlePosition"]
    }

    get categoriesIconColorOnEditProfilePage() {
        return this.jsonData["categoriesIconColorOnEditProfilePage"]
    }

    get categoriesBackgroundColorOnEditProfilePage() {
        return this.jsonData["categoriesBackgroundColorOnEditProfilePage"]
    }

    get categoriesTextColorOnEditProfilePage() {
        return this.jsonData["categoriesTextColorOnEditProfilePage"]
    }

    get categoriesTileActiveColor() {
        return this.jsonData["categoriesTileActiveColor"]
    }

    get categoriesTileInctiveColor() {
        return this.jsonData["categoriesTileInctiveColor"]
    }

    get categoriesTileDisabledColor() {
        return this.jsonData["categoriesTileDisabledColor"]
    }

    get selectedCategoryTextColor() {
        return this.jsonData["selectedCategoryTextColor"]
    }

    get categoriesListInactiveColor() {
        return this.jsonData["categoriesListInactiveColor"]
    }

    get categoriesListFont() {
        return this.jsonData["categoriesListFont"]
    }

    get listViewAsDefault() {
        return this.jsonData["listViewAsDefault"]
    }

    get categoriesConfiguration() {
        if (!this._categoriesConfiguration) {
            this._categoriesConfiguration = []
            for (const arrayElement of this.jsonData["categoriesConfiguration"]) {
                const arrayObject = new CategoryConfigTypeImpl(arrayElement, this.lang)
                this.categoriesConfiguration.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._categoriesConfiguration
    }

    get customCategoriesConfiguration() {
        if (!this._customCategoriesConfiguration) {
            this._customCategoriesConfiguration = []
            for (const arrayElement of this.jsonData["customCategoriesConfiguration"]) {
                const arrayObject = new CategoryConfigTypeImpl(arrayElement, this.lang)
                this.customCategoriesConfiguration.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._customCategoriesConfiguration
    }

    get emptyListMessage() {
        return this.jsonData["emptyListMessage"][this.lang]
    }

    get categorySliderItemDefaultColor() {
        return this.jsonData["categorySliderItemDefaultColor"]
    }

    get categorySliderItemActiveColor() {
        return this.jsonData["categorySliderItemActiveColor"]
    }

    get categorySliderArrowsColor() {
        return this.jsonData["categorySliderArrowsColor"]
    }

    get categoriesListShowCode() {
        return this.jsonData["categoriesListShowCode"]
    }

    get categoriesTileShowCode() {
        return this.jsonData["categoriesTileShowCode"]
    }

    get categoriesTileListHeaderVisible() {
        return this.jsonData["categoriesTileListHeaderVisible"]
    }

    get categoriesTileColorMappings() {
        if (!this._categoriesTileColorMappings) {
            this._categoriesTileColorMappings = []
            for (const arrayElement of this.jsonData["categoriesTileColorMappings"]) {
                const arrayObject = new CategoryTileConfigImpl(arrayElement, this.lang)
                this.categoriesTileColorMappings.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._categoriesTileColorMappings
    }

    get categoriesBadgeShowCode() {
        return this.jsonData["categoriesBadgeShowCode"]
    }

    get filterEmptyCategories() {
        return this.jsonData["filterEmptyCategories"]
    }
}

class CategoryConfigTypeImpl extends BrandingData implements CategoryConfigType {
    _rootCategoryAlias!: string;
    _rootCategoryLabel!: LocalizedString;
    _listViewAsDefault!: boolean;
    _subCategories!: string[];

    get rootCategoryAlias() {
        return this.jsonData["rootCategoryAlias"]
    }

    get rootCategoryLabel() {
        return this.jsonData["rootCategoryLabel"][this.lang]
    }

    get listViewAsDefault() {
        return this.jsonData["listViewAsDefault"]
    }

    get subCategories() {
        return this.jsonData["subCategories"]
    }
}

class CategoryTileConfigImpl extends BrandingData implements CategoryTileConfig {
    _alias!: string;
    _defaultColor!: string;
    _activeColor!: string;
    _disabledColor!: string;

    get alias() {
        return this.jsonData["alias"]
    }

    get defaultColor() {
        return this.jsonData["defaultColor"]
    }

    get activeColor() {
        return this.jsonData["activeColor"]
    }

    get disabledColor() {
        return this.jsonData["disabledColor"]
    }
}

class ProgramPageContentBrandingImpl extends BrandingData implements ProgramPageContentBranding {
    _programPageTitle!: LocalizedString;
    _scheduleTabTitle!: LocalizedString;
    _speakersTabTitle!: LocalizedString;
    _pixelsPerSlot!: string;
    _crsTabsItemOrder!: string[];
    _liveSessionsMaxBadgeCount!: number;

    get programPageTitle() {
        return this.jsonData["programPageTitle"][this.lang]
    }

    get scheduleTabTitle() {
        return this.jsonData["scheduleTabTitle"][this.lang]
    }

    get speakersTabTitle() {
        return this.jsonData["speakersTabTitle"][this.lang]
    }

    get pixelsPerSlot() {
        return this.jsonData["pixelsPerSlot"]
    }

    get crsTabsItemOrder() {
        return this.jsonData["crsTabsItemOrder"]
    }

    get liveSessionsMaxBadgeCount() {
        return this.jsonData["liveSessionsMaxBadgeCount"]
    }
}

class ProgramScheduleBrandingImpl extends BrandingData implements ProgramScheduleBranding {
    _roundtableAccessGranted!: LocalizedString;
    _roundtableAccessRequested!: LocalizedString;
    _searchBarPlaceholder!: LocalizedString;
    _sunday!: LocalizedString;
    _monday!: LocalizedString;
    _tuesday!: LocalizedString;
    _wednesday!: LocalizedString;
    _thursday!: LocalizedString;
    _friday!: LocalizedString;
    _saturday!: LocalizedString;
    _noBookmarksMessage!: LocalizedString;
    _noDataMessage!: LocalizedString;
    _onlyShowBookmarksTitle!: LocalizedString;
    _allStagesText!: LocalizedString;
    _meetingText!: LocalizedString;
    _badgeAllText!: LocalizedString;
    _withBadgeText!: LocalizedString;
    _withouthBadgeText!: LocalizedString;
    _pointsBadgeText!: LocalizedString;
    _pointsBadgeCategoryAlias!: string;
    _showSpeakersTitles!: boolean;
    _useCategoriesInsteadOfStages!: boolean;
    _stagesOrder!: Stage[];
    _allCategoriesFilterText!: LocalizedString;
    _categoriesFilterList!: Filter[];
    _secondCategoriesFilterText!: LocalizedString;
    _showSecondCategoriesFilter!: boolean;
    _secondCategoriesFilterList!: Filter[];
    _showBadgeFilter!: boolean;
    _badgeFilterList!: Filter[];
    _iconsColor!: ColorString;
    _eventdateDescriptionVisible!: boolean;
    _dayStartHour!: string;
    _timeIncrements!: string;
    _borderWidthForProgramTiles!: string;
    _customColorsForOrganizations!: CustomColorByOrganization[];
    _listViewAsDefault!: boolean;
    _noSearchResultsText!: LocalizedString;
    _noBookmarkedEventDatesText!: LocalizedString;
    _activateChannelBeforeMinutes!: number;
    _dateLabelFontFamily!: string;
    _dateLabelFontSize!: string;
    _dateLabelLetterSpacing!: string;
    _dateLabelFontWeight!: string;
    _dateLabelTextTransform!: string;
    _showCurrentTimeLabelAndLine!: boolean;
    _currentTimeLabelAndLineColor!: ColorString;
    _currentTimeLabelAndLineBackgroundColor!: ColorString;
    _privateEventMarkerText!: LocalizedString;
    _listViewTitleColor!: string;
    _listViewTitleFont!: string;
    _nowButtonText!: LocalizedString;
    _nowButtonResetModalTitle!: LocalizedString;
    _nowButtonResetModalText1!: LocalizedString;
    _nowButtonResetModalText2!: LocalizedString;
    _nowButtonResetModalText3!: LocalizedString;
    _nowButtonResetModalSubtitleText!: LocalizedString;
    _nowButtonResetModalCheckboxText!: LocalizedString;
    _nowButtonResetModalCancelButton!: LocalizedString;
    _nowButtonResetModalAgreeButton!: LocalizedString;
    _nowButtonBgColor!: ColorString;
    _nowButtonColor!: ColorString;
    _showTileTags!: boolean;
    _tagBorderColor!: ColorString;
    _videoOnDemandNeededToDisplayTypeformTag!: boolean;
    _filterNoOptionsMessage!: LocalizedString;
    _liveStageTagText!: LocalizedString;
    _prerecordedTagText!: LocalizedString;
    _remoteProviderTagText!: LocalizedString;
    _roundtableTagText!: LocalizedString;
    _masterclassTagText!: LocalizedString;
    _videoOnDemandTagText!: LocalizedString;
    _typeformTagText!: LocalizedString;
    _defaultStartDate!: string;

    get roundtableAccessGranted() {
        return this.jsonData["roundtableAccessGranted"][this.lang]
    }

    get roundtableAccessRequested() {
        return this.jsonData["roundtableAccessRequested"][this.lang]
    }

    get searchBarPlaceholder() {
        return this.jsonData["searchBarPlaceholder"][this.lang]
    }

    get sunday() {
        return this.jsonData["sunday"][this.lang]
    }

    get monday() {
        return this.jsonData["monday"][this.lang]
    }

    get tuesday() {
        return this.jsonData["tuesday"][this.lang]
    }

    get wednesday() {
        return this.jsonData["wednesday"][this.lang]
    }

    get thursday() {
        return this.jsonData["thursday"][this.lang]
    }

    get friday() {
        return this.jsonData["friday"][this.lang]
    }

    get saturday() {
        return this.jsonData["saturday"][this.lang]
    }

    get noBookmarksMessage() {
        return this.jsonData["noBookmarksMessage"][this.lang]
    }

    get noDataMessage() {
        return this.jsonData["noDataMessage"][this.lang]
    }

    get onlyShowBookmarksTitle() {
        return this.jsonData["onlyShowBookmarksTitle"][this.lang]
    }

    get allStagesText() {
        return this.jsonData["allStagesText"][this.lang]
    }

    get meetingText() {
        return this.jsonData["meetingText"][this.lang]
    }

    get badgeAllText() {
        return this.jsonData["badgeAllText"][this.lang]
    }

    get withBadgeText() {
        return this.jsonData["withBadgeText"][this.lang]
    }

    get withouthBadgeText() {
        return this.jsonData["withouthBadgeText"][this.lang]
    }

    get pointsBadgeText() {
        return this.jsonData["pointsBadgeText"][this.lang]
    }

    get pointsBadgeCategoryAlias() {
        return this.jsonData["pointsBadgeCategoryAlias"]
    }

    get showSpeakersTitles() {
        return this.jsonData["showSpeakersTitles"]
    }

    get useCategoriesInsteadOfStages() {
        return this.jsonData["useCategoriesInsteadOfStages"]
    }

    get stagesOrder() {
        if (!this._stagesOrder) {
            this._stagesOrder = []
            for (const arrayElement of this.jsonData["stagesOrder"]) {
                const arrayObject = new StageImpl(arrayElement, this.lang)
                this.stagesOrder.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._stagesOrder
    }

    get allCategoriesFilterText() {
        return this.jsonData["allCategoriesFilterText"][this.lang]
    }

    get categoriesFilterList() {
        if (!this._categoriesFilterList) {
            this._categoriesFilterList = []
            for (const arrayElement of this.jsonData["categoriesFilterList"]) {
                const arrayObject = new FilterImpl(arrayElement, this.lang)
                this.categoriesFilterList.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._categoriesFilterList
    }

    get secondCategoriesFilterText() {
        return this.jsonData["secondCategoriesFilterText"][this.lang]
    }

    get showSecondCategoriesFilter() {
        return this.jsonData["showSecondCategoriesFilter"]
    }

    get secondCategoriesFilterList() {
        if (!this._secondCategoriesFilterList) {
            this._secondCategoriesFilterList = []
            for (const arrayElement of this.jsonData["secondCategoriesFilterList"]) {
                const arrayObject = new FilterImpl(arrayElement, this.lang)
                this.secondCategoriesFilterList.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._secondCategoriesFilterList
    }

    get showBadgeFilter() {
        return this.jsonData["showBadgeFilter"]
    }

    get badgeFilterList() {
        if (!this._badgeFilterList) {
            this._badgeFilterList = []
            for (const arrayElement of this.jsonData["badgeFilterList"]) {
                const arrayObject = new FilterImpl(arrayElement, this.lang)
                this.badgeFilterList.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._badgeFilterList
    }

    get iconsColor() {
        return this.jsonData["iconsColor"]
    }

    get eventdateDescriptionVisible() {
        return this.jsonData["eventdateDescriptionVisible"]
    }

    get dayStartHour() {
        return this.jsonData["dayStartHour"]
    }

    get timeIncrements() {
        return this.jsonData["timeIncrements"]
    }

    get borderWidthForProgramTiles() {
        return this.jsonData["borderWidthForProgramTiles"]
    }

    get customColorsForOrganizations() {
        if (!this._customColorsForOrganizations) {
            this._customColorsForOrganizations = []
            for (const arrayElement of this.jsonData["customColorsForOrganizations"]) {
                const arrayObject = new CustomColorByOrganizationImpl(arrayElement, this.lang)
                this.customColorsForOrganizations.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._customColorsForOrganizations
    }

    get listViewAsDefault() {
        return this.jsonData["listViewAsDefault"]
    }

    get noSearchResultsText() {
        return this.jsonData["noSearchResultsText"][this.lang]
    }

    get noBookmarkedEventDatesText() {
        return this.jsonData["noBookmarkedEventDatesText"][this.lang]
    }

    get activateChannelBeforeMinutes() {
        return this.jsonData["activateChannelBeforeMinutes"]
    }

    get dateLabelFontFamily() {
        return this.jsonData["dateLabelFontFamily"]
    }

    get dateLabelFontSize() {
        return this.jsonData["dateLabelFontSize"]
    }

    get dateLabelLetterSpacing() {
        return this.jsonData["dateLabelLetterSpacing"]
    }

    get dateLabelFontWeight() {
        return this.jsonData["dateLabelFontWeight"]
    }

    get dateLabelTextTransform() {
        return this.jsonData["dateLabelTextTransform"]
    }

    get showCurrentTimeLabelAndLine() {
        return this.jsonData["showCurrentTimeLabelAndLine"]
    }

    get currentTimeLabelAndLineColor() {
        return this.jsonData["currentTimeLabelAndLineColor"]
    }

    get currentTimeLabelAndLineBackgroundColor() {
        return this.jsonData["currentTimeLabelAndLineBackgroundColor"]
    }

    get privateEventMarkerText() {
        return this.jsonData["privateEventMarkerText"][this.lang]
    }

    get listViewTitleColor() {
        return this.jsonData["listViewTitleColor"]
    }

    get listViewTitleFont() {
        return this.jsonData["listViewTitleFont"]
    }

    get nowButtonText() {
        return this.jsonData["nowButtonText"][this.lang]
    }

    get nowButtonResetModalTitle() {
        return this.jsonData["nowButtonResetModalTitle"][this.lang]
    }

    get nowButtonResetModalText1() {
        return this.jsonData["nowButtonResetModalText1"][this.lang]
    }

    get nowButtonResetModalText2() {
        return this.jsonData["nowButtonResetModalText2"][this.lang]
    }

    get nowButtonResetModalText3() {
        return this.jsonData["nowButtonResetModalText3"][this.lang]
    }

    get nowButtonResetModalSubtitleText() {
        return this.jsonData["nowButtonResetModalSubtitleText"][this.lang]
    }

    get nowButtonResetModalCheckboxText() {
        return this.jsonData["nowButtonResetModalCheckboxText"][this.lang]
    }

    get nowButtonResetModalCancelButton() {
        return this.jsonData["nowButtonResetModalCancelButton"][this.lang]
    }

    get nowButtonResetModalAgreeButton() {
        return this.jsonData["nowButtonResetModalAgreeButton"][this.lang]
    }

    get nowButtonBgColor() {
        return this.jsonData["nowButtonBgColor"]
    }

    get nowButtonColor() {
        return this.jsonData["nowButtonColor"]
    }

    get showTileTags() {
        return this.jsonData["showTileTags"]
    }

    get tagBorderColor() {
        return this.jsonData["tagBorderColor"]
    }

    get videoOnDemandNeededToDisplayTypeformTag() {
        return this.jsonData["videoOnDemandNeededToDisplayTypeformTag"]
    }

    get filterNoOptionsMessage() {
        return this.jsonData["filterNoOptionsMessage"][this.lang]
    }

    get liveStageTagText() {
        return this.jsonData["liveStageTagText"][this.lang]
    }

    get prerecordedTagText() {
        return this.jsonData["prerecordedTagText"][this.lang]
    }

    get remoteProviderTagText() {
        return this.jsonData["remoteProviderTagText"][this.lang]
    }

    get roundtableTagText() {
        return this.jsonData["roundtableTagText"][this.lang]
    }

    get masterclassTagText() {
        return this.jsonData["masterclassTagText"][this.lang]
    }

    get videoOnDemandTagText() {
        return this.jsonData["videoOnDemandTagText"][this.lang]
    }

    get typeformTagText() {
        return this.jsonData["typeformTagText"][this.lang]
    }

    get defaultStartDate() {
        return this.jsonData["defaultStartDate"]
    }
}

class StageImpl extends BrandingData implements Stage {
    _stage!: LocalizedString;
    _order!: number;
    _backgroundColor!: string;
    _borderColor!: string;

    get stage() {
        return this.jsonData["stage"][this.lang]
    }

    get order() {
        return this.jsonData["order"]
    }

    get backgroundColor() {
        return this.jsonData["backgroundColor"]
    }

    get borderColor() {
        return this.jsonData["borderColor"]
    }
}

class FilterImpl extends BrandingData implements Filter {
    _label!: LocalizedString;
    _value!: string;

    get label() {
        return this.jsonData["label"][this.lang]
    }

    get value() {
        return this.jsonData["value"]
    }
}

class CustomColorByOrganizationImpl extends BrandingData implements CustomColorByOrganization {
    _organizationId!: string;
    _borderColor!: string;

    get organizationId() {
        return this.jsonData["organizationId"]
    }

    get borderColor() {
        return this.jsonData["borderColor"]
    }
}

class ProgramSpeakersBrandingImpl extends BrandingData implements ProgramSpeakersBranding {
    _navigationSearchPlaceholderSpeakers!: LocalizedString;
    _loadingMessage!: LocalizedString;
    _noBookmarksMessage!: LocalizedString;
    _onlyShowBookmarksTitle!: LocalizedString;
    _noSearchResultsLabel!: LocalizedString;
    _bookmarkedSpeakersTitle!: LocalizedString;
    _speakerColInfoTextColor!: ColorString;
    _listViewTitleFont!: string;
    _listViewTitleColor!: string;
    _speakersListDividerColor!: ColorString;
    _speakersActionIconsColor!: ColorString;
    _noBookmarkedSpeakers!: LocalizedString;
    _orderType!: string;

    get navigationSearchPlaceholderSpeakers() {
        return this.jsonData["navigationSearchPlaceholderSpeakers"][this.lang]
    }

    get loadingMessage() {
        return this.jsonData["loadingMessage"][this.lang]
    }

    get noBookmarksMessage() {
        return this.jsonData["noBookmarksMessage"][this.lang]
    }

    get onlyShowBookmarksTitle() {
        return this.jsonData["onlyShowBookmarksTitle"][this.lang]
    }

    get noSearchResultsLabel() {
        return this.jsonData["noSearchResultsLabel"][this.lang]
    }

    get bookmarkedSpeakersTitle() {
        return this.jsonData["bookmarkedSpeakersTitle"][this.lang]
    }

    get speakerColInfoTextColor() {
        return this.jsonData["speakerColInfoTextColor"]
    }

    get listViewTitleFont() {
        return this.jsonData["listViewTitleFont"]
    }

    get listViewTitleColor() {
        return this.jsonData["listViewTitleColor"]
    }

    get speakersListDividerColor() {
        return this.jsonData["speakersListDividerColor"]
    }

    get speakersActionIconsColor() {
        return this.jsonData["speakersActionIconsColor"]
    }

    get noBookmarkedSpeakers() {
        return this.jsonData["noBookmarkedSpeakers"][this.lang]
    }

    get orderType() {
        return this.jsonData["orderType"]
    }
}

class PressMediaPageContentBrandingImpl extends BrandingData implements PressMediaPageContentBranding {
    _pressTab!: SwitchTabProps;
    _magazineTab!: SwitchTabProps;
    _pageIntro!: LocalizedString;
    _pressContent!: PressItemBranding[];
    _magazineContent!: MagazineItemBranding[];
    _largeTile!: PressItemBranding;
    _largeTileOpenMagazine!: boolean;
    _magazineTileTextColor!: string;
    _pressEventStage!: PressEventStage;
    _rssFeedUrl!: string;
    _rssFeedUrlDe!: string;
    _rssFeedReadMore!: LocalizedString;
    _feedBorderColor!: string;
    _feedTextColor!: string;
    _feedBgColor!: string;
    _feedEmptyMessage!: LocalizedString;
    _darkenOverlayBigTile!: string;
    _pressMediaPageHeaderPool!: string[];
    _pressMediaPageHeaderPoolNumber!: number[];
    _autoPlayValuePressPool!: number;
    _darkenOverlaySmallerTiles!: string;
    _darkenOverlayHeaderImage!: string;
    _twitterInsteadOfRss!: boolean;
    _twitterHandle!: string;
    _twitterErrorMessage!: LocalizedString;
    _crsTabsItemOrder!: string[];

    get pressTab() {
        if (!this._pressTab) {
            this._pressTab = new SwitchTabPropsImpl(this.jsonData["pressTab"], this.lang)
            this.subBrandings.push(this._pressTab as any as BrandingData)
        }
        return this._pressTab
    }

    get magazineTab() {
        if (!this._magazineTab) {
            this._magazineTab = new SwitchTabPropsImpl(this.jsonData["magazineTab"], this.lang)
            this.subBrandings.push(this._magazineTab as any as BrandingData)
        }
        return this._magazineTab
    }

    get pageIntro() {
        return this.jsonData["pageIntro"][this.lang]
    }

    get pressContent() {
        if (!this._pressContent) {
            this._pressContent = []
            for (const arrayElement of this.jsonData["pressContent"]) {
                const arrayObject = new PressItemBrandingImpl(arrayElement, this.lang)
                this.pressContent.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._pressContent
    }

    get magazineContent() {
        if (!this._magazineContent) {
            this._magazineContent = []
            for (const arrayElement of this.jsonData["magazineContent"]) {
                const arrayObject = new MagazineItemBrandingImpl(arrayElement, this.lang)
                this.magazineContent.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._magazineContent
    }

    get largeTile() {
        if (!this._largeTile) {
            this._largeTile = new PressItemBrandingImpl(this.jsonData["largeTile"], this.lang)
            this.subBrandings.push(this._largeTile as any as BrandingData)
        }
        return this._largeTile
    }

    get largeTileOpenMagazine() {
        return this.jsonData["largeTileOpenMagazine"]
    }

    get magazineTileTextColor() {
        return this.jsonData["magazineTileTextColor"]
    }

    get pressEventStage() {
        if (!this._pressEventStage) {
            this._pressEventStage = new PressEventStageImpl(this.jsonData["pressEventStage"], this.lang)
            this.subBrandings.push(this._pressEventStage as any as BrandingData)
        }
        return this._pressEventStage
    }

    get rssFeedUrl() {
        return this.jsonData["rssFeedUrl"]
    }

    get rssFeedUrlDe() {
        return this.jsonData["rssFeedUrlDe"]
    }

    get rssFeedReadMore() {
        return this.jsonData["rssFeedReadMore"][this.lang]
    }

    get feedBorderColor() {
        return this.jsonData["feedBorderColor"]
    }

    get feedTextColor() {
        return this.jsonData["feedTextColor"]
    }

    get feedBgColor() {
        return this.jsonData["feedBgColor"]
    }

    get feedEmptyMessage() {
        return this.jsonData["feedEmptyMessage"][this.lang]
    }

    get darkenOverlayBigTile() {
        return this.jsonData["darkenOverlayBigTile"]
    }

    get pressMediaPageHeaderPool() {
        return this.jsonData["pressMediaPageHeaderPool"]
    }

    get pressMediaPageHeaderPoolNumber() {
        return this.jsonData["pressMediaPageHeaderPoolNumber"]
    }

    get autoPlayValuePressPool() {
        return this.jsonData["autoPlayValuePressPool"]
    }

    get darkenOverlaySmallerTiles() {
        return this.jsonData["darkenOverlaySmallerTiles"]
    }

    get darkenOverlayHeaderImage() {
        return this.jsonData["darkenOverlayHeaderImage"]
    }

    get twitterInsteadOfRss() {
        return this.jsonData["twitterInsteadOfRss"]
    }

    get twitterHandle() {
        return this.jsonData["twitterHandle"]
    }

    get twitterErrorMessage() {
        return this.jsonData["twitterErrorMessage"][this.lang]
    }

    get crsTabsItemOrder() {
        return this.jsonData["crsTabsItemOrder"]
    }
}

class SwitchTabPropsImpl extends BrandingData implements SwitchTabProps {
    _key!: string;
    _label!: LocalizedString;

    get key() {
        return this.jsonData["key"]
    }

    get label() {
        return this.jsonData["label"][this.lang]
    }
}

class PressItemBrandingImpl extends BrandingData implements PressItemBranding {
    _title!: LocalizedString;
    _backgroundImageUrl!: string;
    _organization!: string;
    _linkInfo!: LinkInfo;

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get backgroundImageUrl() {
        return this.jsonData["backgroundImageUrl"]
    }

    get organization() {
        return this.jsonData["organization"]
    }

    get linkInfo() {
        if (!this._linkInfo) {
            this._linkInfo = new LinkInfoImpl(this.jsonData["linkInfo"], this.lang)
            this.subBrandings.push(this._linkInfo as any as BrandingData)
        }
        return this._linkInfo
    }
}

class LinkInfoImpl extends BrandingData implements LinkInfo {
    _isExternalLink!: boolean;
    _link!: LocalizedString;

    get isExternalLink() {
        return this.jsonData["isExternalLink"]
    }

    get link() {
        return this.jsonData["link"][this.lang]
    }
}

class MagazineItemBrandingImpl extends BrandingData implements MagazineItemBranding {
    _title!: LocalizedString;
    _link!: LocalizedString;
    _releaseDate!: string;
    _backgroundImageUrl!: string;
    _organization!: string;

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get link() {
        return this.jsonData["link"][this.lang]
    }

    get releaseDate() {
        return this.jsonData["releaseDate"]
    }

    get backgroundImageUrl() {
        return this.jsonData["backgroundImageUrl"]
    }

    get organization() {
        return this.jsonData["organization"]
    }
}

class PressEventStageImpl extends BrandingData implements PressEventStage {
    _filter!: string;
    _stage!: string;

    get filter() {
        return this.jsonData["filter"]
    }

    get stage() {
        return this.jsonData["stage"]
    }
}

class BusinessAreaPageContentBrandingImpl extends BrandingData implements BusinessAreaPageContentBranding {
    _searchTermTextColor!: ColorString;
    _searchBarPlaceholder!: LocalizedString;
    _firstMultiSwitcherItemText!: LocalizedString;
    _secondMultiSwitcherItemText!: LocalizedString;
    _relevantsMultiSwitcherItemText!: LocalizedString;
    _emptyResultText!: LocalizedString;
    _countryDropdownPlaceholer!: LocalizedString;
    _editMyProfil!: LocalizedString;
    _showEditProfileNotice!: boolean;
    _editProfileNoticeTitle!: LocalizedString;
    _editProfileNoticeText!: LocalizedString;
    _editProfileNoticeContinueToProfile!: LocalizedString;
    _editProfileNoticeContinueWithoutProfil!: LocalizedString;
    _filterButtonText!: LocalizedString;
    _resetFilterButtonText!: LocalizedString;
    _filterButtonBgColor!: ColorString;
    _filterButtonColor!: ColorString;
    _filterButtonBorderColor!: ColorString;
    _showMoreText!: LocalizedString;
    _onlyShowBookmarksText!: LocalizedString;
    _showCountriesDropdown!: boolean;
    _crsTabsItemOrder!: string[];
    _listViewTitleColor!: string;
    _listViewTitleFont!: string;
    _searchBarIconMargin!: string;
    _searchBarTextMargin!: string;
    _relevantTopicFilterVisible!: boolean;
    _relevantCategoryFilterList!: Filter[];
    _relevantCategoryFilterVisible!: boolean;

    get searchTermTextColor() {
        return this.jsonData["searchTermTextColor"]
    }

    get searchBarPlaceholder() {
        return this.jsonData["searchBarPlaceholder"][this.lang]
    }

    get firstMultiSwitcherItemText() {
        return this.jsonData["firstMultiSwitcherItemText"][this.lang]
    }

    get secondMultiSwitcherItemText() {
        return this.jsonData["secondMultiSwitcherItemText"][this.lang]
    }

    get relevantsMultiSwitcherItemText() {
        return this.jsonData["relevantsMultiSwitcherItemText"][this.lang]
    }

    get emptyResultText() {
        return this.jsonData["emptyResultText"][this.lang]
    }

    get countryDropdownPlaceholer() {
        return this.jsonData["countryDropdownPlaceholer"][this.lang]
    }

    get editMyProfil() {
        return this.jsonData["editMyProfil"][this.lang]
    }

    get showEditProfileNotice() {
        return this.jsonData["showEditProfileNotice"]
    }

    get editProfileNoticeTitle() {
        return this.jsonData["editProfileNoticeTitle"][this.lang]
    }

    get editProfileNoticeText() {
        return this.jsonData["editProfileNoticeText"][this.lang]
    }

    get editProfileNoticeContinueToProfile() {
        return this.jsonData["editProfileNoticeContinueToProfile"][this.lang]
    }

    get editProfileNoticeContinueWithoutProfil() {
        return this.jsonData["editProfileNoticeContinueWithoutProfil"][this.lang]
    }

    get filterButtonText() {
        return this.jsonData["filterButtonText"][this.lang]
    }

    get resetFilterButtonText() {
        return this.jsonData["resetFilterButtonText"][this.lang]
    }

    get filterButtonBgColor() {
        return this.jsonData["filterButtonBgColor"]
    }

    get filterButtonColor() {
        return this.jsonData["filterButtonColor"]
    }

    get filterButtonBorderColor() {
        return this.jsonData["filterButtonBorderColor"]
    }

    get showMoreText() {
        return this.jsonData["showMoreText"][this.lang]
    }

    get onlyShowBookmarksText() {
        return this.jsonData["onlyShowBookmarksText"][this.lang]
    }

    get showCountriesDropdown() {
        return this.jsonData["showCountriesDropdown"]
    }

    get crsTabsItemOrder() {
        return this.jsonData["crsTabsItemOrder"]
    }

    get listViewTitleColor() {
        return this.jsonData["listViewTitleColor"]
    }

    get listViewTitleFont() {
        return this.jsonData["listViewTitleFont"]
    }

    get searchBarIconMargin() {
        return this.jsonData["searchBarIconMargin"]
    }

    get searchBarTextMargin() {
        return this.jsonData["searchBarTextMargin"]
    }

    get relevantTopicFilterVisible() {
        return this.jsonData["relevantTopicFilterVisible"]
    }

    get relevantCategoryFilterList() {
        if (!this._relevantCategoryFilterList) {
            this._relevantCategoryFilterList = []
            for (const arrayElement of this.jsonData["relevantCategoryFilterList"]) {
                const arrayObject = new FilterImpl(arrayElement, this.lang)
                this.relevantCategoryFilterList.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._relevantCategoryFilterList
    }

    get relevantCategoryFilterVisible() {
        return this.jsonData["relevantCategoryFilterVisible"]
    }
}

class SystemCheckBrandingImpl extends BrandingData implements SystemCheckBranding {
    _checkHeadline!: LocalizedString;
    _stepJavascriptName!: LocalizedString;
    _stepJavascriptPending!: LocalizedString;
    _stepJavascriptSuccess!: LocalizedString;
    _stepJavascriptError!: LocalizedString;
    _stepBrowserName!: LocalizedString;
    _stepBrowserPending!: LocalizedString;
    _stepBrowserSuccess!: LocalizedString;
    _stepBrowserError!: LocalizedString;
    _stepLocalStorageName!: LocalizedString;
    _stepLocalStoragePending!: LocalizedString;
    _stepLocalStorageSuccess!: LocalizedString;
    _stepLocalStorageError!: LocalizedString;
    _stepCameraName!: LocalizedString;
    _stepCameraPending!: LocalizedString;
    _stepCameraSuccess!: LocalizedString;
    _stepCameraError!: LocalizedString;
    _continue!: LocalizedString;
    _continueToMicrophone!: LocalizedString;
    _continueToSpeakers!: LocalizedString;
    _stepMicrophoneName!: LocalizedString;
    _stepMicrophoneInfo!: LocalizedString;
    _stepMicrophonePending!: LocalizedString;
    _stepMicrophoneSuccess!: LocalizedString;
    _stepMicrophoneError!: LocalizedString;
    _stepSpeakerName!: LocalizedString;
    _stepSpeakerInfo!: LocalizedString;
    _stepSpeakerPending!: LocalizedString;
    _stepSpeakerSuccess!: LocalizedString;
    _stepSpeakerError!: LocalizedString;
    _stepDownloadName!: LocalizedString;
    _stepDownloadPending!: LocalizedString;
    _stepDownloadSuccess!: LocalizedString;
    _stepDownloadError!: LocalizedString;
    _stepSystemName!: LocalizedString;
    _stepSystemBackToHome!: LocalizedString;
    _ticketUrl!: LocalizedString;
    _buyTicket!: LocalizedString;
    _yes!: LocalizedString;
    _no!: LocalizedString;
    _headline!: LocalizedString;
    _description!: LocalizedString;
    _done!: LocalizedString;
    _stepLocalStorageHelpTopic!: LocalizedString;
    _stepLocalStorageHelpDescription!: LocalizedString;
    _genericMissingHardwareHelpTopic!: LocalizedString;
    _genericMissingHardwareHelpDescription!: LocalizedString;
    _genericOsLevelPermissionHelpTopic!: LocalizedString;
    _genericOsLevelPermissionHelpDescription!: LocalizedString;
    _genericMacOsLevelPermissionHelpTopic!: LocalizedString;
    _genericMacOsLevelPermissionHelpDescription!: LocalizedString;
    _genericWindowsOsLevelPermissionHelpTopic!: LocalizedString;
    _genericWindowsOsLevelPermissionHelpDescription!: LocalizedString;
    _genericBrowserPermissionHelpTopic!: LocalizedString;
    _genericBrowserPermissionHelpDescription!: LocalizedString;
    _stepSpeakerHelpTopic!: LocalizedString;
    _stepSpeakerHelpDescription!: LocalizedString;
    _reloadHelp!: LocalizedString;
    _camera!: LocalizedString;
    _microphone!: LocalizedString;
    _speaker!: LocalizedString;
    _devicePermissionAsk!: LocalizedString;
    _devicePermissionGranted!: LocalizedString;
    _devicePermissionDenied!: LocalizedString;
    _retry!: LocalizedString;
    _testSound!: LocalizedString;
    _showResult!: LocalizedString;
    _skippedCheck!: LocalizedString;
    _skipAndContinue!: LocalizedString;
    _hearSound!: LocalizedString;
    _skippedTestResult!: LocalizedString;
    _startCameraTest!: LocalizedString;
    _grantMicAccess!: LocalizedString;

    get checkHeadline() {
        return this.jsonData["checkHeadline"][this.lang]
    }

    get stepJavascriptName() {
        return this.jsonData["stepJavascriptName"][this.lang]
    }

    get stepJavascriptPending() {
        return this.jsonData["stepJavascriptPending"][this.lang]
    }

    get stepJavascriptSuccess() {
        return this.jsonData["stepJavascriptSuccess"][this.lang]
    }

    get stepJavascriptError() {
        return this.jsonData["stepJavascriptError"][this.lang]
    }

    get stepBrowserName() {
        return this.jsonData["stepBrowserName"][this.lang]
    }

    get stepBrowserPending() {
        return this.jsonData["stepBrowserPending"][this.lang]
    }

    get stepBrowserSuccess() {
        return this.jsonData["stepBrowserSuccess"][this.lang]
    }

    get stepBrowserError() {
        return this.jsonData["stepBrowserError"][this.lang]
    }

    get stepLocalStorageName() {
        return this.jsonData["stepLocalStorageName"][this.lang]
    }

    get stepLocalStoragePending() {
        return this.jsonData["stepLocalStoragePending"][this.lang]
    }

    get stepLocalStorageSuccess() {
        return this.jsonData["stepLocalStorageSuccess"][this.lang]
    }

    get stepLocalStorageError() {
        return this.jsonData["stepLocalStorageError"][this.lang]
    }

    get stepCameraName() {
        return this.jsonData["stepCameraName"][this.lang]
    }

    get stepCameraPending() {
        return this.jsonData["stepCameraPending"][this.lang]
    }

    get stepCameraSuccess() {
        return this.jsonData["stepCameraSuccess"][this.lang]
    }

    get stepCameraError() {
        return this.jsonData["stepCameraError"][this.lang]
    }

    get continue() {
        return this.jsonData["continue"][this.lang]
    }

    get continueToMicrophone() {
        return this.jsonData["continueToMicrophone"][this.lang]
    }

    get continueToSpeakers() {
        return this.jsonData["continueToSpeakers"][this.lang]
    }

    get stepMicrophoneName() {
        return this.jsonData["stepMicrophoneName"][this.lang]
    }

    get stepMicrophoneInfo() {
        return this.jsonData["stepMicrophoneInfo"][this.lang]
    }

    get stepMicrophonePending() {
        return this.jsonData["stepMicrophonePending"][this.lang]
    }

    get stepMicrophoneSuccess() {
        return this.jsonData["stepMicrophoneSuccess"][this.lang]
    }

    get stepMicrophoneError() {
        return this.jsonData["stepMicrophoneError"][this.lang]
    }

    get stepSpeakerName() {
        return this.jsonData["stepSpeakerName"][this.lang]
    }

    get stepSpeakerInfo() {
        return this.jsonData["stepSpeakerInfo"][this.lang]
    }

    get stepSpeakerPending() {
        return this.jsonData["stepSpeakerPending"][this.lang]
    }

    get stepSpeakerSuccess() {
        return this.jsonData["stepSpeakerSuccess"][this.lang]
    }

    get stepSpeakerError() {
        return this.jsonData["stepSpeakerError"][this.lang]
    }

    get stepDownloadName() {
        return this.jsonData["stepDownloadName"][this.lang]
    }

    get stepDownloadPending() {
        return this.jsonData["stepDownloadPending"][this.lang]
    }

    get stepDownloadSuccess() {
        return this.jsonData["stepDownloadSuccess"][this.lang]
    }

    get stepDownloadError() {
        return this.jsonData["stepDownloadError"][this.lang]
    }

    get stepSystemName() {
        return this.jsonData["stepSystemName"][this.lang]
    }

    get stepSystemBackToHome() {
        return this.jsonData["stepSystemBackToHome"][this.lang]
    }

    get ticketUrl() {
        return this.jsonData["ticketUrl"][this.lang]
    }

    get buyTicket() {
        return this.jsonData["buyTicket"][this.lang]
    }

    get yes() {
        return this.jsonData["yes"][this.lang]
    }

    get no() {
        return this.jsonData["no"][this.lang]
    }

    get headline() {
        return this.jsonData["headline"][this.lang]
    }

    get description() {
        return this.jsonData["description"][this.lang]
    }

    get done() {
        return this.jsonData["done"][this.lang]
    }

    get stepLocalStorageHelpTopic() {
        return this.jsonData["stepLocalStorageHelpTopic"][this.lang]
    }

    get stepLocalStorageHelpDescription() {
        return this.jsonData["stepLocalStorageHelpDescription"][this.lang]
    }

    get genericMissingHardwareHelpTopic() {
        return this.jsonData["genericMissingHardwareHelpTopic"][this.lang]
    }

    get genericMissingHardwareHelpDescription() {
        return this.jsonData["genericMissingHardwareHelpDescription"][this.lang]
    }

    get genericOsLevelPermissionHelpTopic() {
        return this.jsonData["genericOsLevelPermissionHelpTopic"][this.lang]
    }

    get genericOsLevelPermissionHelpDescription() {
        return this.jsonData["genericOsLevelPermissionHelpDescription"][this.lang]
    }

    get genericMacOsLevelPermissionHelpTopic() {
        return this.jsonData["genericMacOsLevelPermissionHelpTopic"][this.lang]
    }

    get genericMacOsLevelPermissionHelpDescription() {
        return this.jsonData["genericMacOsLevelPermissionHelpDescription"][this.lang]
    }

    get genericWindowsOsLevelPermissionHelpTopic() {
        return this.jsonData["genericWindowsOsLevelPermissionHelpTopic"][this.lang]
    }

    get genericWindowsOsLevelPermissionHelpDescription() {
        return this.jsonData["genericWindowsOsLevelPermissionHelpDescription"][this.lang]
    }

    get genericBrowserPermissionHelpTopic() {
        return this.jsonData["genericBrowserPermissionHelpTopic"][this.lang]
    }

    get genericBrowserPermissionHelpDescription() {
        return this.jsonData["genericBrowserPermissionHelpDescription"][this.lang]
    }

    get stepSpeakerHelpTopic() {
        return this.jsonData["stepSpeakerHelpTopic"][this.lang]
    }

    get stepSpeakerHelpDescription() {
        return this.jsonData["stepSpeakerHelpDescription"][this.lang]
    }

    get reloadHelp() {
        return this.jsonData["reloadHelp"][this.lang]
    }

    get camera() {
        return this.jsonData["camera"][this.lang]
    }

    get microphone() {
        return this.jsonData["microphone"][this.lang]
    }

    get speaker() {
        return this.jsonData["speaker"][this.lang]
    }

    get devicePermissionAsk() {
        return this.jsonData["devicePermissionAsk"][this.lang]
    }

    get devicePermissionGranted() {
        return this.jsonData["devicePermissionGranted"][this.lang]
    }

    get devicePermissionDenied() {
        return this.jsonData["devicePermissionDenied"][this.lang]
    }

    get retry() {
        return this.jsonData["retry"][this.lang]
    }

    get testSound() {
        return this.jsonData["testSound"][this.lang]
    }

    get showResult() {
        return this.jsonData["showResult"][this.lang]
    }

    get skippedCheck() {
        return this.jsonData["skippedCheck"][this.lang]
    }

    get skipAndContinue() {
        return this.jsonData["skipAndContinue"][this.lang]
    }

    get hearSound() {
        return this.jsonData["hearSound"][this.lang]
    }

    get skippedTestResult() {
        return this.jsonData["skippedTestResult"][this.lang]
    }

    get startCameraTest() {
        return this.jsonData["startCameraTest"][this.lang]
    }

    get grantMicAccess() {
        return this.jsonData["grantMicAccess"][this.lang]
    }
}

class ReceptionPageSiteBrandingImpl extends BrandingData implements ReceptionPageSiteBranding {
    _eventKeyLobby!: LocalizedString;
    _eventKeyMyPage!: LocalizedString;
    _lobbyComponentOrder!: string[];
    _myPageComponentOrder!: string[];
    _receptionPageLobbyHeaderTitle!: LocalizedString;
    _receptionPageLobbyHeaderDescription!: LocalizedString;
    _lobbyHeaderPool!: string[];
    _lobbyHeaderPoolNumber!: number[];
    _autoPlayValueLobbyPool!: number;
    _myFairPageHeaderPool!: string[];
    _myFairPageHeaderPoolNumber!: number[];
    _autoPlayValueMyFairPool!: number;
    _receptionPageMyScheduleHeaderTitle!: LocalizedString;
    _receptionPageMyScheduleHeaderDescription!: LocalizedString;
    _mySchedulePageHeaderPool!: string[];
    _mySchedulePageHeaderPoolNumber!: number[];
    _autoPlayValueMySchedulePool!: number;
    _lobbyHeaderTitleMarginTop!: string;
    _lobbyHeaderTitleMarginTopForSmallerScreen!: string;
    _navbarMyPageShortName!: LocalizedString;
    _navbarHomePageShortName!: LocalizedString;
    _navbarShowfloorPageShortName!: LocalizedString;
    _navbarProgramPageShortName!: LocalizedString;
    _navbarVirtualCafePageShortName!: LocalizedString;
    _navbarCollectionsPageShortName!: LocalizedString;
    _navbarHallplanPageShortName!: LocalizedString;
    _navbarNetworkingPageShortName!: LocalizedString;
    _navbarPartnersSponsorsPageShortName!: LocalizedString;
    _navbarGoodieBagPageShortName!: LocalizedString;
    _navbarPressMediaPageShortName!: LocalizedString;
    _navbarLeadFinderPageShortName!: LocalizedString;
    _navbarIFramePageShortName!: LocalizedString;
    _navbarStartupPageShortName!: LocalizedString;
    _navbarHelpPageShortName!: LocalizedString;
    _receptionPageMyHeaderTitle!: LocalizedString;
    _receptionPageMyHeaderDescriptionFirstPart!: LocalizedString;
    _receptionPageMyHeaderDescriptionSecondPart!: LocalizedString;
    _receptionPageMyHeaderDescriptionThirdPart!: LocalizedString;
    _visitMyProfile!: LocalizedString;
    _viewMySchedule!: LocalizedString;
    _sponsorsTitle!: LocalizedString;
    _upNextTitle!: LocalizedString;
    _liveSessions!: LocalizedString;
    _speakersNavLinkText!: LocalizedString;
    _speakersBookmarkedNavLinkText!: LocalizedString;
    _featuredCompanies!: LocalizedString;
    _liveSessionsNavLinkText!: LocalizedString;
    _virtualCaffeNavLinkText!: LocalizedString;
    _lobbyExhibitorsBasisPremiumTypesList!: LobbyExhibitorsBasisPremiumType[];
    _lobbyExhibitorsShowTopSponsors!: boolean;
    _showFloorNavLinkText!: LocalizedString;
    _productsNavLinkText!: LocalizedString;
    _productsBookmarkedNavLinkText!: LocalizedString;
    _trademarksNavLinkText!: LocalizedString;
    _trademarksBookmarkedNavLinkText!: LocalizedString;
    _newsNavLinkText!: LocalizedString;
    _newsBookmarkedNavLinkText!: LocalizedString;
    _joboffersNavLinkText!: LocalizedString;
    _joboffersBookmarkedNavLinkText!: LocalizedString;
    _showFloorBookmarkedNavLinkText!: LocalizedString;
    _leadfinderNavLinkText!: LocalizedString;
    _networkingNavLinkText!: LocalizedString;
    _programNavLinkText!: LocalizedString;
    _participantsNavLinkText!: LocalizedString;
    _mySchedule!: LocalizedString;
    _speakers!: LocalizedString;
    _exhibitors!: LocalizedString;
    _noEventsScheduled!: LocalizedString;
    _noBookmarkedSpeakers!: LocalizedString;
    _noBookmarkedExhibitors!: LocalizedString;
    _noBookmarkedProducts!: LocalizedString;
    _noBookmarkedTrademarks!: LocalizedString;
    _noBookmarkedNews!: LocalizedString;
    _noBookmarkedJoboffers!: LocalizedString;
    _receptionPageLobbyHeaderDesc!: string;
    _receptionPageMyFairHeaderDesc!: string;
    _networkingButton!: LocalizedString;
    _tileRowIconVisible!: boolean;
    _tileRowTitleVisible!: boolean;
    _tileRowNavLinkVisible!: boolean;
    _tileRowProgramIconVisible!: boolean;
    _tileRowProgramTitleVisible!: boolean;
    _tileRowProgramNavLinkVisible!: boolean;
    _tileRowLeadFinderIconVisible!: boolean;
    _tileRowLeadFinderTitleVisible!: boolean;
    _tileRowLeadFinderNavLinkVisible!: boolean;
    _networkingText1!: LocalizedString;
    _networkingText2!: LocalizedString;
    _leadFinderButton!: LocalizedString;
    _leadFinderText1!: LocalizedString;
    _leadFinderText2!: LocalizedString;
    _programButton!: LocalizedString;
    _programText1!: LocalizedString;
    _programText2!: LocalizedString;
    _liveTomorrow!: LocalizedString;
    _nowLive!: LocalizedString;
    _liveOn!: LocalizedString;
    _myFairBookmarkTileTextColor!: string;
    _myFairBookmarkTileIconColor!: string;
    _visitExhibitorText!: LocalizedString;
    _visitProductText!: LocalizedString;
    _visitTrademarkText!: LocalizedString;
    _visitNewsText!: LocalizedString;
    _visitJobofferText!: LocalizedString;
    _topBarLinkRoot!: TopBarLinkRoot;
    _topBarTitleFontSize!: string;
    _myFairHeaderSubtitleMarginTop!: string;
    _topBarTitleFontWeight!: string;
    _receptionPageHeaderFontSize!: string;
    _receptionPageHeaderFontWeight!: string;
    _emptyTileTextColor!: string;
    _lobbyHeaderLinearGradient1!: string;
    _lobbyHeaderLinearGradient2!: string;
    _myScheduleTitleColor!: string;
    _virtualCafeAccessButtonFontSize!: string;
    _headerTitleFontSizeIndex!: number;
    _headerFooterFontSizeIndex!: number;
    _headerTitleInitialFontSize!: string;
    _networkingBannerTextColor!: string;
    _lobbyTileTextWidth!: string;
    _personComponentActionsMarginTop!: string;
    _personComponentActionsMarginRight!: string;
    _receptionPageHeaderTitleTextDecoration!: string;
    _sponsoredExhibitorsMyPageList!: TopSponsor[];
    _showPremiumExhibitorsWithSponsors!: boolean;
    _lobbyTopSponsors!: TopSponsor[];
    _networkingBannerTitle!: LocalizedString;
    _leadFinderBannerTitle!: LocalizedString;
    _programBannerTitle!: LocalizedString;
    _darkenOverlayForParticipants!: string;
    _darkenOverlayOnHoverForParticipants!: string;
    _speakersTileGridGap!: string;
    _lobbypageShowfloorTilesDarkenOverlay!: string;
    _topBarLinkHeaderTextColor!: ColorString;
    _topBarLinkHeaderTextColorSubtitle!: ColorString;
    _topBarLinkHeaderTextColorOnHover!: ColorString;
    _topBarLinkHeaderTextColorSubtitleOnHover!: ColorString;
    _showfloorTileMargins!: string;
    _myFairPageExhibitorTilesMargin!: string;
    _tileRowShowNavLinkNextToTitle!: boolean;
    _tileRowTitleFont!: string;
    _tileRowSubtitleFont!: string;
    _tileRowSubtitleTextTransform!: string;
    _tileRowBottomBorder!: string;
    _tileRowArrowColor!: string;
    _topBarLinkRightArrowColor!: string;
    _mobileTopBarLinkRightArrowColor!: string;
    _headerTitleFontFamily!: string;
    _headerTitleTextTransform!: string;
    _headerTitleLetterSpacing!: string;
    _headerTitleFontWeight!: string;
    _crsTabsItemsOrder!: string[];
    _tileRowCollectionsIconVisible!: boolean;
    _tileRowCollectionsTitleVisible!: boolean;
    _tileRowCollectionsNavLinkVisible!: boolean;
    _collectionsBannerTitle!: LocalizedString;
    _collectionsNavLinkText!: LocalizedString;
    _topNewsList!: TopNews[];

    get eventKeyLobby() {
        return this.jsonData["eventKeyLobby"][this.lang]
    }

    get eventKeyMyPage() {
        return this.jsonData["eventKeyMyPage"][this.lang]
    }

    get lobbyComponentOrder() {
        return this.jsonData["lobbyComponentOrder"]
    }

    get myPageComponentOrder() {
        return this.jsonData["myPageComponentOrder"]
    }

    get receptionPageLobbyHeaderTitle() {
        return this.jsonData["receptionPageLobbyHeaderTitle"][this.lang]
    }

    get receptionPageLobbyHeaderDescription() {
        return this.jsonData["receptionPageLobbyHeaderDescription"][this.lang]
    }

    get lobbyHeaderPool() {
        return this.jsonData["lobbyHeaderPool"]
    }

    get lobbyHeaderPoolNumber() {
        return this.jsonData["lobbyHeaderPoolNumber"]
    }

    get autoPlayValueLobbyPool() {
        return this.jsonData["autoPlayValueLobbyPool"]
    }

    get myFairPageHeaderPool() {
        return this.jsonData["myFairPageHeaderPool"]
    }

    get myFairPageHeaderPoolNumber() {
        return this.jsonData["myFairPageHeaderPoolNumber"]
    }

    get autoPlayValueMyFairPool() {
        return this.jsonData["autoPlayValueMyFairPool"]
    }

    get receptionPageMyScheduleHeaderTitle() {
        return this.jsonData["receptionPageMyScheduleHeaderTitle"][this.lang]
    }

    get receptionPageMyScheduleHeaderDescription() {
        return this.jsonData["receptionPageMyScheduleHeaderDescription"][this.lang]
    }

    get mySchedulePageHeaderPool() {
        return this.jsonData["mySchedulePageHeaderPool"]
    }

    get mySchedulePageHeaderPoolNumber() {
        return this.jsonData["mySchedulePageHeaderPoolNumber"]
    }

    get autoPlayValueMySchedulePool() {
        return this.jsonData["autoPlayValueMySchedulePool"]
    }

    get lobbyHeaderTitleMarginTop() {
        return this.jsonData["lobbyHeaderTitleMarginTop"]
    }

    get lobbyHeaderTitleMarginTopForSmallerScreen() {
        return this.jsonData["lobbyHeaderTitleMarginTopForSmallerScreen"]
    }

    get navbarMyPageShortName() {
        return this.jsonData["navbarMyPageShortName"][this.lang]
    }

    get navbarHomePageShortName() {
        return this.jsonData["navbarHomePageShortName"][this.lang]
    }

    get navbarShowfloorPageShortName() {
        return this.jsonData["navbarShowfloorPageShortName"][this.lang]
    }

    get navbarProgramPageShortName() {
        return this.jsonData["navbarProgramPageShortName"][this.lang]
    }

    get navbarVirtualCafePageShortName() {
        return this.jsonData["navbarVirtualCafePageShortName"][this.lang]
    }

    get navbarCollectionsPageShortName() {
        return this.jsonData["navbarCollectionsPageShortName"][this.lang]
    }

    get navbarHallplanPageShortName() {
        return this.jsonData["navbarHallplanPageShortName"][this.lang]
    }

    get navbarNetworkingPageShortName() {
        return this.jsonData["navbarNetworkingPageShortName"][this.lang]
    }

    get navbarPartnersSponsorsPageShortName() {
        return this.jsonData["navbarPartnersSponsorsPageShortName"][this.lang]
    }

    get navbarGoodieBagPageShortName() {
        return this.jsonData["navbarGoodieBagPageShortName"][this.lang]
    }

    get navbarPressMediaPageShortName() {
        return this.jsonData["navbarPressMediaPageShortName"][this.lang]
    }

    get navbarLeadFinderPageShortName() {
        return this.jsonData["navbarLeadFinderPageShortName"][this.lang]
    }

    get navbarIFramePageShortName() {
        return this.jsonData["navbarIFramePageShortName"][this.lang]
    }

    get navbarStartupPageShortName() {
        return this.jsonData["navbarStartupPageShortName"][this.lang]
    }

    get navbarHelpPageShortName() {
        return this.jsonData["navbarHelpPageShortName"][this.lang]
    }

    get receptionPageMyHeaderTitle() {
        return this.jsonData["receptionPageMyHeaderTitle"][this.lang]
    }

    get receptionPageMyHeaderDescriptionFirstPart() {
        return this.jsonData["receptionPageMyHeaderDescriptionFirstPart"][this.lang]
    }

    get receptionPageMyHeaderDescriptionSecondPart() {
        return this.jsonData["receptionPageMyHeaderDescriptionSecondPart"][this.lang]
    }

    get receptionPageMyHeaderDescriptionThirdPart() {
        return this.jsonData["receptionPageMyHeaderDescriptionThirdPart"][this.lang]
    }

    get visitMyProfile() {
        return this.jsonData["visitMyProfile"][this.lang]
    }

    get viewMySchedule() {
        return this.jsonData["viewMySchedule"][this.lang]
    }

    get sponsorsTitle() {
        return this.jsonData["sponsorsTitle"][this.lang]
    }

    get upNextTitle() {
        return this.jsonData["upNextTitle"][this.lang]
    }

    get liveSessions() {
        return this.jsonData["liveSessions"][this.lang]
    }

    get speakersNavLinkText() {
        return this.jsonData["speakersNavLinkText"][this.lang]
    }

    get speakersBookmarkedNavLinkText() {
        return this.jsonData["speakersBookmarkedNavLinkText"][this.lang]
    }

    get featuredCompanies() {
        return this.jsonData["featuredCompanies"][this.lang]
    }

    get liveSessionsNavLinkText() {
        return this.jsonData["liveSessionsNavLinkText"][this.lang]
    }

    get virtualCaffeNavLinkText() {
        return this.jsonData["virtualCaffeNavLinkText"][this.lang]
    }

    get lobbyExhibitorsBasisPremiumTypesList() {
        if (!this._lobbyExhibitorsBasisPremiumTypesList) {
            this._lobbyExhibitorsBasisPremiumTypesList = []
            for (const arrayElement of this.jsonData["lobbyExhibitorsBasisPremiumTypesList"]) {
                const arrayObject = new LobbyExhibitorsBasisPremiumTypeImpl(arrayElement, this.lang)
                this.lobbyExhibitorsBasisPremiumTypesList.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._lobbyExhibitorsBasisPremiumTypesList
    }

    get lobbyExhibitorsShowTopSponsors() {
        return this.jsonData["lobbyExhibitorsShowTopSponsors"]
    }

    get showFloorNavLinkText() {
        return this.jsonData["showFloorNavLinkText"][this.lang]
    }

    get productsNavLinkText() {
        return this.jsonData["productsNavLinkText"][this.lang]
    }

    get productsBookmarkedNavLinkText() {
        return this.jsonData["productsBookmarkedNavLinkText"][this.lang]
    }

    get trademarksNavLinkText() {
        return this.jsonData["trademarksNavLinkText"][this.lang]
    }

    get trademarksBookmarkedNavLinkText() {
        return this.jsonData["trademarksBookmarkedNavLinkText"][this.lang]
    }

    get newsNavLinkText() {
        return this.jsonData["newsNavLinkText"][this.lang]
    }

    get newsBookmarkedNavLinkText() {
        return this.jsonData["newsBookmarkedNavLinkText"][this.lang]
    }

    get joboffersNavLinkText() {
        return this.jsonData["joboffersNavLinkText"][this.lang]
    }

    get joboffersBookmarkedNavLinkText() {
        return this.jsonData["joboffersBookmarkedNavLinkText"][this.lang]
    }

    get showFloorBookmarkedNavLinkText() {
        return this.jsonData["showFloorBookmarkedNavLinkText"][this.lang]
    }

    get leadfinderNavLinkText() {
        return this.jsonData["leadfinderNavLinkText"][this.lang]
    }

    get networkingNavLinkText() {
        return this.jsonData["networkingNavLinkText"][this.lang]
    }

    get programNavLinkText() {
        return this.jsonData["programNavLinkText"][this.lang]
    }

    get participantsNavLinkText() {
        return this.jsonData["participantsNavLinkText"][this.lang]
    }

    get mySchedule() {
        return this.jsonData["mySchedule"][this.lang]
    }

    get speakers() {
        return this.jsonData["speakers"][this.lang]
    }

    get exhibitors() {
        return this.jsonData["exhibitors"][this.lang]
    }

    get noEventsScheduled() {
        return this.jsonData["noEventsScheduled"][this.lang]
    }

    get noBookmarkedSpeakers() {
        return this.jsonData["noBookmarkedSpeakers"][this.lang]
    }

    get noBookmarkedExhibitors() {
        return this.jsonData["noBookmarkedExhibitors"][this.lang]
    }

    get noBookmarkedProducts() {
        return this.jsonData["noBookmarkedProducts"][this.lang]
    }

    get noBookmarkedTrademarks() {
        return this.jsonData["noBookmarkedTrademarks"][this.lang]
    }

    get noBookmarkedNews() {
        return this.jsonData["noBookmarkedNews"][this.lang]
    }

    get noBookmarkedJoboffers() {
        return this.jsonData["noBookmarkedJoboffers"][this.lang]
    }

    get receptionPageLobbyHeaderDesc() {
        return this.jsonData["receptionPageLobbyHeaderDesc"]
    }

    get receptionPageMyFairHeaderDesc() {
        return this.jsonData["receptionPageMyFairHeaderDesc"]
    }

    get networkingButton() {
        return this.jsonData["networkingButton"][this.lang]
    }

    get tileRowIconVisible() {
        return this.jsonData["tileRowIconVisible"]
    }

    get tileRowTitleVisible() {
        return this.jsonData["tileRowTitleVisible"]
    }

    get tileRowNavLinkVisible() {
        return this.jsonData["tileRowNavLinkVisible"]
    }

    get tileRowProgramIconVisible() {
        return this.jsonData["tileRowProgramIconVisible"]
    }

    get tileRowProgramTitleVisible() {
        return this.jsonData["tileRowProgramTitleVisible"]
    }

    get tileRowProgramNavLinkVisible() {
        return this.jsonData["tileRowProgramNavLinkVisible"]
    }

    get tileRowLeadFinderIconVisible() {
        return this.jsonData["tileRowLeadFinderIconVisible"]
    }

    get tileRowLeadFinderTitleVisible() {
        return this.jsonData["tileRowLeadFinderTitleVisible"]
    }

    get tileRowLeadFinderNavLinkVisible() {
        return this.jsonData["tileRowLeadFinderNavLinkVisible"]
    }

    get networkingText1() {
        return this.jsonData["networkingText1"][this.lang]
    }

    get networkingText2() {
        return this.jsonData["networkingText2"][this.lang]
    }

    get leadFinderButton() {
        return this.jsonData["leadFinderButton"][this.lang]
    }

    get leadFinderText1() {
        return this.jsonData["leadFinderText1"][this.lang]
    }

    get leadFinderText2() {
        return this.jsonData["leadFinderText2"][this.lang]
    }

    get programButton() {
        return this.jsonData["programButton"][this.lang]
    }

    get programText1() {
        return this.jsonData["programText1"][this.lang]
    }

    get programText2() {
        return this.jsonData["programText2"][this.lang]
    }

    get liveTomorrow() {
        return this.jsonData["liveTomorrow"][this.lang]
    }

    get nowLive() {
        return this.jsonData["nowLive"][this.lang]
    }

    get liveOn() {
        return this.jsonData["liveOn"][this.lang]
    }

    get myFairBookmarkTileTextColor() {
        return this.jsonData["myFairBookmarkTileTextColor"]
    }

    get myFairBookmarkTileIconColor() {
        return this.jsonData["myFairBookmarkTileIconColor"]
    }

    get visitExhibitorText() {
        return this.jsonData["visitExhibitorText"][this.lang]
    }

    get visitProductText() {
        return this.jsonData["visitProductText"][this.lang]
    }

    get visitTrademarkText() {
        return this.jsonData["visitTrademarkText"][this.lang]
    }

    get visitNewsText() {
        return this.jsonData["visitNewsText"][this.lang]
    }

    get visitJobofferText() {
        return this.jsonData["visitJobofferText"][this.lang]
    }

    get topBarLinkRoot() {
        if (!this._topBarLinkRoot) {
            this._topBarLinkRoot = new TopBarLinkRootImpl(this.jsonData["topBarLinkRoot"], this.lang)
            this.subBrandings.push(this._topBarLinkRoot as any as BrandingData)
        }
        return this._topBarLinkRoot
    }

    get topBarTitleFontSize() {
        return this.jsonData["topBarTitleFontSize"]
    }

    get myFairHeaderSubtitleMarginTop() {
        return this.jsonData["myFairHeaderSubtitleMarginTop"]
    }

    get topBarTitleFontWeight() {
        return this.jsonData["topBarTitleFontWeight"]
    }

    get receptionPageHeaderFontSize() {
        return this.jsonData["receptionPageHeaderFontSize"]
    }

    get receptionPageHeaderFontWeight() {
        return this.jsonData["receptionPageHeaderFontWeight"]
    }

    get emptyTileTextColor() {
        return this.jsonData["emptyTileTextColor"]
    }

    get lobbyHeaderLinearGradient1() {
        return this.jsonData["lobbyHeaderLinearGradient1"]
    }

    get lobbyHeaderLinearGradient2() {
        return this.jsonData["lobbyHeaderLinearGradient2"]
    }

    get myScheduleTitleColor() {
        return this.jsonData["myScheduleTitleColor"]
    }

    get virtualCafeAccessButtonFontSize() {
        return this.jsonData["virtualCafeAccessButtonFontSize"]
    }

    get headerTitleFontSizeIndex() {
        return this.jsonData["headerTitleFontSizeIndex"]
    }

    get headerFooterFontSizeIndex() {
        return this.jsonData["headerFooterFontSizeIndex"]
    }

    get headerTitleInitialFontSize() {
        return this.jsonData["headerTitleInitialFontSize"]
    }

    get networkingBannerTextColor() {
        return this.jsonData["networkingBannerTextColor"]
    }

    get lobbyTileTextWidth() {
        return this.jsonData["lobbyTileTextWidth"]
    }

    get personComponentActionsMarginTop() {
        return this.jsonData["personComponentActionsMarginTop"]
    }

    get personComponentActionsMarginRight() {
        return this.jsonData["personComponentActionsMarginRight"]
    }

    get receptionPageHeaderTitleTextDecoration() {
        return this.jsonData["receptionPageHeaderTitleTextDecoration"]
    }

    get sponsoredExhibitorsMyPageList() {
        if (!this._sponsoredExhibitorsMyPageList) {
            this._sponsoredExhibitorsMyPageList = []
            for (const arrayElement of this.jsonData["sponsoredExhibitorsMyPageList"]) {
                const arrayObject = new TopSponsorImpl(arrayElement, this.lang)
                this.sponsoredExhibitorsMyPageList.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._sponsoredExhibitorsMyPageList
    }

    get showPremiumExhibitorsWithSponsors() {
        return this.jsonData["showPremiumExhibitorsWithSponsors"]
    }

    get lobbyTopSponsors() {
        if (!this._lobbyTopSponsors) {
            this._lobbyTopSponsors = []
            for (const arrayElement of this.jsonData["lobbyTopSponsors"]) {
                const arrayObject = new TopSponsorImpl(arrayElement, this.lang)
                this.lobbyTopSponsors.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._lobbyTopSponsors
    }

    get networkingBannerTitle() {
        return this.jsonData["networkingBannerTitle"][this.lang]
    }

    get leadFinderBannerTitle() {
        return this.jsonData["leadFinderBannerTitle"][this.lang]
    }

    get programBannerTitle() {
        return this.jsonData["programBannerTitle"][this.lang]
    }

    get darkenOverlayForParticipants() {
        return this.jsonData["darkenOverlayForParticipants"]
    }

    get darkenOverlayOnHoverForParticipants() {
        return this.jsonData["darkenOverlayOnHoverForParticipants"]
    }

    get speakersTileGridGap() {
        return this.jsonData["speakersTileGridGap"]
    }

    get lobbypageShowfloorTilesDarkenOverlay() {
        return this.jsonData["lobbypageShowfloorTilesDarkenOverlay"]
    }

    get topBarLinkHeaderTextColor() {
        return this.jsonData["topBarLinkHeaderTextColor"]
    }

    get topBarLinkHeaderTextColorSubtitle() {
        return this.jsonData["topBarLinkHeaderTextColorSubtitle"]
    }

    get topBarLinkHeaderTextColorOnHover() {
        return this.jsonData["topBarLinkHeaderTextColorOnHover"]
    }

    get topBarLinkHeaderTextColorSubtitleOnHover() {
        return this.jsonData["topBarLinkHeaderTextColorSubtitleOnHover"]
    }

    get showfloorTileMargins() {
        return this.jsonData["showfloorTileMargins"]
    }

    get myFairPageExhibitorTilesMargin() {
        return this.jsonData["myFairPageExhibitorTilesMargin"]
    }

    get tileRowShowNavLinkNextToTitle() {
        return this.jsonData["tileRowShowNavLinkNextToTitle"]
    }

    get tileRowTitleFont() {
        return this.jsonData["tileRowTitleFont"]
    }

    get tileRowSubtitleFont() {
        return this.jsonData["tileRowSubtitleFont"]
    }

    get tileRowSubtitleTextTransform() {
        return this.jsonData["tileRowSubtitleTextTransform"]
    }

    get tileRowBottomBorder() {
        return this.jsonData["tileRowBottomBorder"]
    }

    get tileRowArrowColor() {
        return this.jsonData["tileRowArrowColor"]
    }

    get topBarLinkRightArrowColor() {
        return this.jsonData["topBarLinkRightArrowColor"]
    }

    get mobileTopBarLinkRightArrowColor() {
        return this.jsonData["mobileTopBarLinkRightArrowColor"]
    }

    get headerTitleFontFamily() {
        return this.jsonData["headerTitleFontFamily"]
    }

    get headerTitleTextTransform() {
        return this.jsonData["headerTitleTextTransform"]
    }

    get headerTitleLetterSpacing() {
        return this.jsonData["headerTitleLetterSpacing"]
    }

    get headerTitleFontWeight() {
        return this.jsonData["headerTitleFontWeight"]
    }

    get crsTabsItemsOrder() {
        return this.jsonData["crsTabsItemsOrder"]
    }

    get tileRowCollectionsIconVisible() {
        return this.jsonData["tileRowCollectionsIconVisible"]
    }

    get tileRowCollectionsTitleVisible() {
        return this.jsonData["tileRowCollectionsTitleVisible"]
    }

    get tileRowCollectionsNavLinkVisible() {
        return this.jsonData["tileRowCollectionsNavLinkVisible"]
    }

    get collectionsBannerTitle() {
        return this.jsonData["collectionsBannerTitle"][this.lang]
    }

    get collectionsNavLinkText() {
        return this.jsonData["collectionsNavLinkText"][this.lang]
    }

    get topNewsList() {
        if (!this._topNewsList) {
            this._topNewsList = []
            for (const arrayElement of this.jsonData["topNewsList"]) {
                const arrayObject = new TopNewsImpl(arrayElement, this.lang)
                this.topNewsList.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._topNewsList
    }
}

class LobbyExhibitorsBasisPremiumTypeImpl extends BrandingData implements LobbyExhibitorsBasisPremiumType {
    _packageName!: BasisPremiumType;
    _numberOfExhibitors!: number;

    get packageName() {
        return this.jsonData["packageName"]
    }

    get numberOfExhibitors() {
        return this.jsonData["numberOfExhibitors"]
    }
}

class TopBarLinkRootImpl extends BrandingData implements TopBarLinkRoot {
    _borderBottom!: string;
    _marginBottom!: string;
    _marginBottomMobile!: string;
    _fontSize!: string;

    get borderBottom() {
        return this.jsonData["borderBottom"]
    }

    get marginBottom() {
        return this.jsonData["marginBottom"]
    }

    get marginBottomMobile() {
        return this.jsonData["marginBottomMobile"]
    }

    get fontSize() {
        return this.jsonData["fontSize"]
    }
}

class TopSponsorImpl extends BrandingData implements TopSponsor {
    _id!: string;
    _name!: string;
    _logo!: string;
    _useCustomName!: boolean;
    _order!: number;

    get id() {
        return this.jsonData["id"]
    }

    get name() {
        return this.jsonData["name"]
    }

    get logo() {
        return this.jsonData["logo"]
    }

    get useCustomName() {
        return this.jsonData["useCustomName"]
    }

    get order() {
        return this.jsonData["order"]
    }
}

class TopNewsImpl extends BrandingData implements TopNews {
    _id!: string;
    _background!: string;
    _order!: number;

    get id() {
        return this.jsonData["id"]
    }

    get background() {
        return this.jsonData["background"]
    }

    get order() {
        return this.jsonData["order"]
    }
}

class CollectionPageBrandingImpl extends BrandingData implements CollectionPageBranding {
    _collections!: CollectionBranding[];
    _collectionTilesDarkenOverlay!: string;

    get collections() {
        if (!this._collections) {
            this._collections = []
            for (const arrayElement of this.jsonData["collections"]) {
                const arrayObject = new CollectionBrandingImpl(arrayElement, this.lang)
                this.collections.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._collections
    }

    get collectionTilesDarkenOverlay() {
        return this.jsonData["collectionTilesDarkenOverlay"]
    }
}

class CollectionBrandingImpl extends BrandingData implements CollectionBranding {
    _id!: string;
    _title!: LocalizedString;
    _description!: LocalizedString;
    _bannerPoolTitle!: string;
    _backgroundImage!: string;
    _widgets!: (EntityListWidgetBranding | TextGalleryWidgetBranding | GalleryImageTwoColumnWidgetBranding)[];

    get id() {
        return this.jsonData["id"]
    }

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get description() {
        return this.jsonData["description"][this.lang]
    }

    get bannerPoolTitle() {
        return this.jsonData["bannerPoolTitle"]
    }

    get backgroundImage() {
        return this.jsonData["backgroundImage"]
    }

    get widgets() {
        if (!this._widgets) {
            this._widgets = []
            for (const arrayElement of this.jsonData["widgets"]) {
                let arrayObject
                switch (arrayElement._cType) {
                    case "EntityListWidget":
                        arrayObject = new EntityListWidgetBrandingImpl(arrayElement, this.lang)
                        break
                    case "TextGalleryWidget":
                        arrayObject = new TextGalleryWidgetBrandingImpl(arrayElement, this.lang)
                        break
                    case "GalleryImageTwoColumnWidget":
                        arrayObject = new GalleryImageTwoColumnWidgetBrandingImpl(arrayElement, this.lang)
                        break
                }
                this._widgets.push(arrayObject as any)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._widgets
    }
}

class EntityListWidgetBrandingImpl extends BrandingData implements EntityListWidgetBranding {
    _title!: LocalizedString;
    _type!: "eventdate" | "person" | "organization" | "product" | "trademark" | "joboffer";
    _filter!: string;
    _moreUrl!: LocalizedString;
    _moreText!: LocalizedString;
    _globalSuggestText!: LocalizedString;
    __cType!: "EntityListWidget";

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get type() {
        return this.jsonData["type"]
    }

    get filter() {
        return this.jsonData["filter"]
    }

    get moreUrl() {
        return this.jsonData["moreUrl"][this.lang]
    }

    get moreText() {
        return this.jsonData["moreText"][this.lang]
    }

    get globalSuggestText() {
        return this.jsonData["globalSuggestText"][this.lang]
    }

    get _cType() {
        return this.jsonData["_cType"]
    }
}

class TextGalleryWidgetBrandingImpl extends BrandingData implements TextGalleryWidgetBranding {
    _title!: LocalizedString;
    _subtitle!: LocalizedString;
    _text!: LocalizedString;
    _items!: GalleryItem[];
    __cType!: "TextGalleryWidget";

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get subtitle() {
        return this.jsonData["subtitle"][this.lang]
    }

    get text() {
        return this.jsonData["text"][this.lang]
    }

    get items() {
        if (!this._items) {
            this._items = []
            for (const arrayElement of this.jsonData["items"]) {
                const arrayObject = new GalleryItemImpl(arrayElement, this.lang)
                this.items.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._items
    }

    get _cType() {
        return this.jsonData["_cType"]
    }
}

class GalleryItemImpl extends BrandingData implements GalleryItem {
    _title!: LocalizedString;
    _mediaUrl!: LocalizedString;
    _mediaType!: GalleryItemMediaType;
    _targetUrl!: LocalizedString;
    _targetType!: GalleryItemLinkType;

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get mediaUrl() {
        return this.jsonData["mediaUrl"][this.lang]
    }

    get mediaType() {
        return this.jsonData["mediaType"]
    }

    get targetUrl() {
        return this.jsonData["targetUrl"][this.lang]
    }

    get targetType() {
        return this.jsonData["targetType"]
    }
}

class GalleryImageTwoColumnWidgetBrandingImpl extends BrandingData implements GalleryImageTwoColumnWidgetBranding {
    _order!: "GalleryImage" | "ImageGallery";
    _titleGallery!: LocalizedString;
    _textGallery!: LocalizedString;
    _items!: GalleryItem[];
    _titleImage!: LocalizedString;
    _textImage!: LocalizedString;
    _image!: GalleryItem;
    __cType!: "GalleryImageTwoColumnWidget";

    get order() {
        return this.jsonData["order"]
    }

    get titleGallery() {
        return this.jsonData["titleGallery"][this.lang]
    }

    get textGallery() {
        return this.jsonData["textGallery"][this.lang]
    }

    get items() {
        if (!this._items) {
            this._items = []
            for (const arrayElement of this.jsonData["items"]) {
                const arrayObject = new GalleryItemImpl(arrayElement, this.lang)
                this.items.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._items
    }

    get titleImage() {
        return this.jsonData["titleImage"][this.lang]
    }

    get textImage() {
        return this.jsonData["textImage"][this.lang]
    }

    get image() {
        if (!this._image) {
            this._image = new GalleryItemImpl(this.jsonData["image"], this.lang)
            this.subBrandings.push(this._image as any as BrandingData)
        }
        return this._image
    }

    get _cType() {
        return this.jsonData["_cType"]
    }
}

class MeetingRoomGroupTypeImpl extends BrandingData implements MeetingRoomGroupType {
    _id!: string;
    _isPrivate!: boolean;
    _title!: LocalizedString;
    _titleVisible!: boolean;
    _organizationName!: LocalizedString;
    _organizationId!: string;
    _secondOrganizationId!: string;
    _description!: LocalizedString;
    _pictureUrl!: string;
    _bottomExtraOverlay!: boolean;
    _infoBox!: InfoBox;
    _meetingRooms!: MeetingRoomType[];
    _restrictedAreaType!: string;

    get id() {
        return this.jsonData["id"]
    }

    get isPrivate() {
        return this.jsonData["isPrivate"]
    }

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get titleVisible() {
        return this.jsonData["titleVisible"]
    }

    get organizationName() {
        return this.jsonData["organizationName"][this.lang]
    }

    get organizationId() {
        return this.jsonData["organizationId"]
    }

    get secondOrganizationId() {
        return this.jsonData["secondOrganizationId"]
    }

    get description() {
        return this.jsonData["description"][this.lang]
    }

    get pictureUrl() {
        return this.jsonData["pictureUrl"]
    }

    get bottomExtraOverlay() {
        return this.jsonData["bottomExtraOverlay"]
    }

    get infoBox() {
        if (!this._infoBox) {
            this._infoBox = new InfoBoxImpl(this.jsonData["infoBox"], this.lang)
            this.subBrandings.push(this._infoBox as any as BrandingData)
        }
        return this._infoBox
    }

    get meetingRooms() {
        if (!this._meetingRooms) {
            this._meetingRooms = []
            for (const arrayElement of this.jsonData["meetingRooms"]) {
                const arrayObject = new MeetingRoomTypeImpl(arrayElement, this.lang)
                this.meetingRooms.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._meetingRooms
    }

    get restrictedAreaType() {
        return this.jsonData["restrictedAreaType"]
    }
}

class InfoBoxImpl extends BrandingData implements InfoBox {
    _title!: LocalizedString;
    _text!: LocalizedString;

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get text() {
        return this.jsonData["text"][this.lang]
    }
}

class MeetingRoomTypeImpl extends BrandingData implements MeetingRoomType {
    _id!: string;
    _title!: LocalizedString;
    _titleVisible!: boolean;
    _description!: LocalizedString;
    _pictureUrl!: string;

    get id() {
        return this.jsonData["id"]
    }

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get titleVisible() {
        return this.jsonData["titleVisible"]
    }

    get description() {
        return this.jsonData["description"][this.lang]
    }

    get pictureUrl() {
        return this.jsonData["pictureUrl"]
    }
}

class SettingsBrandingImpl extends BrandingData implements SettingsBranding {
    _tabTitleAudio!: LocalizedString;
    _tabTitleVideo!: LocalizedString;
    _tabTitleBackground!: LocalizedString;
    _tabTitleLogo!: LocalizedString;
    _audioInputLabel!: LocalizedString;
    _audioOutputLabel!: LocalizedString;
    _videoInputLabel!: LocalizedString;
    _radioTitleLogoType!: LocalizedString;
    _radioNoLogo!: LocalizedString;
    _radioCompanyLogo!: LocalizedString;
    _radioChooseLogo!: LocalizedString;
    _radioTitlePosition!: LocalizedString;
    _radioLogoPositionTopLeft!: LocalizedString;
    _radioLogoPositionTopRight!: LocalizedString;
    _radioLogoPositionBottomLeft!: LocalizedString;
    _radioLogoPositionBottomRight!: LocalizedString;
    _radioNoBackground!: LocalizedString;
    _radioChooseBackground!: LocalizedString;
    _radioBlurBackground!: LocalizedString;
    _radioLogoButtonBackgroundColor!: string;
    _radioLogoButtonTextColor!: string;
    _radioLogoButtonBackgroundColorActive!: string;
    _radioLogoButtonTextColorActive!: string;
    _preview!: LocalizedString;
    _galleryLabel!: LocalizedString;
    _galleryItemList!: string[];

    get tabTitleAudio() {
        return this.jsonData["tabTitleAudio"][this.lang]
    }

    get tabTitleVideo() {
        return this.jsonData["tabTitleVideo"][this.lang]
    }

    get tabTitleBackground() {
        return this.jsonData["tabTitleBackground"][this.lang]
    }

    get tabTitleLogo() {
        return this.jsonData["tabTitleLogo"][this.lang]
    }

    get audioInputLabel() {
        return this.jsonData["audioInputLabel"][this.lang]
    }

    get audioOutputLabel() {
        return this.jsonData["audioOutputLabel"][this.lang]
    }

    get videoInputLabel() {
        return this.jsonData["videoInputLabel"][this.lang]
    }

    get radioTitleLogoType() {
        return this.jsonData["radioTitleLogoType"][this.lang]
    }

    get radioNoLogo() {
        return this.jsonData["radioNoLogo"][this.lang]
    }

    get radioCompanyLogo() {
        return this.jsonData["radioCompanyLogo"][this.lang]
    }

    get radioChooseLogo() {
        return this.jsonData["radioChooseLogo"][this.lang]
    }

    get radioTitlePosition() {
        return this.jsonData["radioTitlePosition"][this.lang]
    }

    get radioLogoPositionTopLeft() {
        return this.jsonData["radioLogoPositionTopLeft"][this.lang]
    }

    get radioLogoPositionTopRight() {
        return this.jsonData["radioLogoPositionTopRight"][this.lang]
    }

    get radioLogoPositionBottomLeft() {
        return this.jsonData["radioLogoPositionBottomLeft"][this.lang]
    }

    get radioLogoPositionBottomRight() {
        return this.jsonData["radioLogoPositionBottomRight"][this.lang]
    }

    get radioNoBackground() {
        return this.jsonData["radioNoBackground"][this.lang]
    }

    get radioChooseBackground() {
        return this.jsonData["radioChooseBackground"][this.lang]
    }

    get radioBlurBackground() {
        return this.jsonData["radioBlurBackground"][this.lang]
    }

    get radioLogoButtonBackgroundColor() {
        return this.jsonData["radioLogoButtonBackgroundColor"]
    }

    get radioLogoButtonTextColor() {
        return this.jsonData["radioLogoButtonTextColor"]
    }

    get radioLogoButtonBackgroundColorActive() {
        return this.jsonData["radioLogoButtonBackgroundColorActive"]
    }

    get radioLogoButtonTextColorActive() {
        return this.jsonData["radioLogoButtonTextColorActive"]
    }

    get preview() {
        return this.jsonData["preview"][this.lang]
    }

    get galleryLabel() {
        return this.jsonData["galleryLabel"][this.lang]
    }

    get galleryItemList() {
        return this.jsonData["galleryItemList"]
    }
}

class ConferenceTextsImpl extends BrandingData implements ConferenceTexts {
    _noAttendeesLabelTextSize!: string;
    _noAttendees!: LocalizedString;
    _mute!: LocalizedString;
    _unmute!: LocalizedString;
    _mutedByMod!: LocalizedString;
    _closeMutedBy!: LocalizedString;
    _soundOn!: LocalizedString;
    _soundOff!: LocalizedString;
    _videoOn!: LocalizedString;
    _videoOff!: LocalizedString;
    _handRaised!: LocalizedString;
    _raiseHand!: LocalizedString;
    _lowerHand!: LocalizedString;
    _shareScreenStart!: LocalizedString;
    _shareScreenStop!: LocalizedString;
    _currentlySharingScreen!: LocalizedString;
    _openChat!: LocalizedString;
    _leave!: LocalizedString;
    _incomingCall!: LocalizedString;
    _outgoingCall!: LocalizedString;
    _acceptCall!: LocalizedString;
    _declineCall!: LocalizedString;
    _cancelCall!: LocalizedString;
    _callWith!: LocalizedString;
    _settings!: LocalizedString;
    _changingRoomConfirmationTitle!: LocalizedString;
    _changingRoomConfirmationAccept!: LocalizedString;
    _changingRoomConfirmationText!: LocalizedString;
    _maxAttendees!: LocalizedString;
    _disconnected!: LocalizedString;
    _timeUp!: LocalizedString;
    _genericError!: LocalizedString;
    _kickConfirmationHeader!: LocalizedString;
    _banConfirmationHeader!: LocalizedString;
    _kickBanConfirmationBody!: LocalizedString;
    _statusBanned!: LocalizedString;
    _statusBannedDefault!: LocalizedString;
    _statusKicked!: LocalizedString;
    _statusKickedDefault!: LocalizedString;
    _kick!: LocalizedString;
    _kickHint!: LocalizedString;
    _ban!: LocalizedString;
    _banHint!: LocalizedString;
    _statusLive!: LocalizedString;
    _moderator!: LocalizedString;
    _participantsSwitch!: LocalizedString;
    _chatSwitch!: LocalizedString;
    _searchForParticipantsPlaceholder!: LocalizedString;
    _addParticipantsText!: LocalizedString;
    _connectWithAllParticipantsText!: LocalizedString;
    _timerRemaining!: LocalizedString;
    _timerUpAlert!: LocalizedString;
    _postEventPhaseMessage!: LocalizedString;
    _showroomTitle!: LocalizedString;
    _showroomUnknownCompanyText!: LocalizedString;
    _masterclassTitle!: LocalizedString;
    _greenRoomTitle!: LocalizedString;
    _roundtableTitle!: LocalizedString;
    _breakoutTitle!: LocalizedString;
    _conferenceRoomTitle!: LocalizedString;
    _onAirMessage!: LocalizedString;
    _liveInMessage!: LocalizedString;
    _roomLockedTitle!: LocalizedString;
    _currentlyNotLiveMessage!: LocalizedString;
    _gettingLiveInMessage!: LocalizedString;
    _goLiveMessage!: LocalizedString;
    _goingLiveMessage!: LocalizedString;
    _stopLiveMessage!: LocalizedString;
    _stoppingLiveMessage!: LocalizedString;
    _initializingMessage!: LocalizedString;
    _lockRoomMessage!: LocalizedString;
    _lockingMessage!: LocalizedString;
    _unlockingMessage!: LocalizedString;
    _unlockRoomMessage!: LocalizedString;
    _microphoneFailureAlertTitle!: LocalizedString;
    _microphoneFailureAlertMessage!: LocalizedString;
    _speakerFailureAlertTitle!: LocalizedString;
    _speakerFailureAlertMessage!: LocalizedString;
    _cameraFailureAlertTitle!: LocalizedString;
    _cameraFailureAlertMessage!: LocalizedString;

    get noAttendeesLabelTextSize() {
        return this.jsonData["noAttendeesLabelTextSize"]
    }

    get noAttendees() {
        return this.jsonData["noAttendees"][this.lang]
    }

    get mute() {
        return this.jsonData["mute"][this.lang]
    }

    get unmute() {
        return this.jsonData["unmute"][this.lang]
    }

    get mutedByMod() {
        return this.jsonData["mutedByMod"][this.lang]
    }

    get closeMutedBy() {
        return this.jsonData["closeMutedBy"][this.lang]
    }

    get soundOn() {
        return this.jsonData["soundOn"][this.lang]
    }

    get soundOff() {
        return this.jsonData["soundOff"][this.lang]
    }

    get videoOn() {
        return this.jsonData["videoOn"][this.lang]
    }

    get videoOff() {
        return this.jsonData["videoOff"][this.lang]
    }

    get handRaised() {
        return this.jsonData["handRaised"][this.lang]
    }

    get raiseHand() {
        return this.jsonData["raiseHand"][this.lang]
    }

    get lowerHand() {
        return this.jsonData["lowerHand"][this.lang]
    }

    get shareScreenStart() {
        return this.jsonData["shareScreenStart"][this.lang]
    }

    get shareScreenStop() {
        return this.jsonData["shareScreenStop"][this.lang]
    }

    get currentlySharingScreen() {
        return this.jsonData["currentlySharingScreen"][this.lang]
    }

    get openChat() {
        return this.jsonData["openChat"][this.lang]
    }

    get leave() {
        return this.jsonData["leave"][this.lang]
    }

    get incomingCall() {
        return this.jsonData["incomingCall"][this.lang]
    }

    get outgoingCall() {
        return this.jsonData["outgoingCall"][this.lang]
    }

    get acceptCall() {
        return this.jsonData["acceptCall"][this.lang]
    }

    get declineCall() {
        return this.jsonData["declineCall"][this.lang]
    }

    get cancelCall() {
        return this.jsonData["cancelCall"][this.lang]
    }

    get callWith() {
        return this.jsonData["callWith"][this.lang]
    }

    get settings() {
        return this.jsonData["settings"][this.lang]
    }

    get changingRoomConfirmationTitle() {
        return this.jsonData["changingRoomConfirmationTitle"][this.lang]
    }

    get changingRoomConfirmationAccept() {
        return this.jsonData["changingRoomConfirmationAccept"][this.lang]
    }

    get changingRoomConfirmationText() {
        return this.jsonData["changingRoomConfirmationText"][this.lang]
    }

    get maxAttendees() {
        return this.jsonData["maxAttendees"][this.lang]
    }

    get disconnected() {
        return this.jsonData["disconnected"][this.lang]
    }

    get timeUp() {
        return this.jsonData["timeUp"][this.lang]
    }

    get genericError() {
        return this.jsonData["genericError"][this.lang]
    }

    get kickConfirmationHeader() {
        return this.jsonData["kickConfirmationHeader"][this.lang]
    }

    get banConfirmationHeader() {
        return this.jsonData["banConfirmationHeader"][this.lang]
    }

    get kickBanConfirmationBody() {
        return this.jsonData["kickBanConfirmationBody"][this.lang]
    }

    get statusBanned() {
        return this.jsonData["statusBanned"][this.lang]
    }

    get statusBannedDefault() {
        return this.jsonData["statusBannedDefault"][this.lang]
    }

    get statusKicked() {
        return this.jsonData["statusKicked"][this.lang]
    }

    get statusKickedDefault() {
        return this.jsonData["statusKickedDefault"][this.lang]
    }

    get kick() {
        return this.jsonData["kick"][this.lang]
    }

    get kickHint() {
        return this.jsonData["kickHint"][this.lang]
    }

    get ban() {
        return this.jsonData["ban"][this.lang]
    }

    get banHint() {
        return this.jsonData["banHint"][this.lang]
    }

    get statusLive() {
        return this.jsonData["statusLive"][this.lang]
    }

    get moderator() {
        return this.jsonData["moderator"][this.lang]
    }

    get participantsSwitch() {
        return this.jsonData["participantsSwitch"][this.lang]
    }

    get chatSwitch() {
        return this.jsonData["chatSwitch"][this.lang]
    }

    get searchForParticipantsPlaceholder() {
        return this.jsonData["searchForParticipantsPlaceholder"][this.lang]
    }

    get addParticipantsText() {
        return this.jsonData["addParticipantsText"][this.lang]
    }

    get connectWithAllParticipantsText() {
        return this.jsonData["connectWithAllParticipantsText"][this.lang]
    }

    get timerRemaining() {
        return this.jsonData["timerRemaining"][this.lang]
    }

    get timerUpAlert() {
        return this.jsonData["timerUpAlert"][this.lang]
    }

    get postEventPhaseMessage() {
        return this.jsonData["postEventPhaseMessage"][this.lang]
    }

    get showroomTitle() {
        return this.jsonData["showroomTitle"][this.lang]
    }

    get showroomUnknownCompanyText() {
        return this.jsonData["showroomUnknownCompanyText"][this.lang]
    }

    get masterclassTitle() {
        return this.jsonData["masterclassTitle"][this.lang]
    }

    get greenRoomTitle() {
        return this.jsonData["greenRoomTitle"][this.lang]
    }

    get roundtableTitle() {
        return this.jsonData["roundtableTitle"][this.lang]
    }

    get breakoutTitle() {
        return this.jsonData["breakoutTitle"][this.lang]
    }

    get conferenceRoomTitle() {
        return this.jsonData["conferenceRoomTitle"][this.lang]
    }

    get onAirMessage() {
        return this.jsonData["onAirMessage"][this.lang]
    }

    get liveInMessage() {
        return this.jsonData["liveInMessage"][this.lang]
    }

    get roomLockedTitle() {
        return this.jsonData["roomLockedTitle"][this.lang]
    }

    get currentlyNotLiveMessage() {
        return this.jsonData["currentlyNotLiveMessage"][this.lang]
    }

    get gettingLiveInMessage() {
        return this.jsonData["gettingLiveInMessage"][this.lang]
    }

    get goLiveMessage() {
        return this.jsonData["goLiveMessage"][this.lang]
    }

    get goingLiveMessage() {
        return this.jsonData["goingLiveMessage"][this.lang]
    }

    get stopLiveMessage() {
        return this.jsonData["stopLiveMessage"][this.lang]
    }

    get stoppingLiveMessage() {
        return this.jsonData["stoppingLiveMessage"][this.lang]
    }

    get initializingMessage() {
        return this.jsonData["initializingMessage"][this.lang]
    }

    get lockRoomMessage() {
        return this.jsonData["lockRoomMessage"][this.lang]
    }

    get lockingMessage() {
        return this.jsonData["lockingMessage"][this.lang]
    }

    get unlockingMessage() {
        return this.jsonData["unlockingMessage"][this.lang]
    }

    get unlockRoomMessage() {
        return this.jsonData["unlockRoomMessage"][this.lang]
    }

    get microphoneFailureAlertTitle() {
        return this.jsonData["microphoneFailureAlertTitle"][this.lang]
    }

    get microphoneFailureAlertMessage() {
        return this.jsonData["microphoneFailureAlertMessage"][this.lang]
    }

    get speakerFailureAlertTitle() {
        return this.jsonData["speakerFailureAlertTitle"][this.lang]
    }

    get speakerFailureAlertMessage() {
        return this.jsonData["speakerFailureAlertMessage"][this.lang]
    }

    get cameraFailureAlertTitle() {
        return this.jsonData["cameraFailureAlertTitle"][this.lang]
    }

    get cameraFailureAlertMessage() {
        return this.jsonData["cameraFailureAlertMessage"][this.lang]
    }
}

class ConferenceListTilesImpl extends BrandingData implements ConferenceListTiles {
    _gradientDivBackgroundFirst!: string;
    _gradientDivBackgroundSecond!: string;
    _headerStickyBackground!: string;
    _cardBackground!: string;
    _corouselBackground!: string;
    _sponsorsBackground1!: string;
    _sponsorsBackground2!: string;
    _sponsorsBackground3!: string;
    _sponsorsBackground4!: string;
    _sponsorsBackground5!: string;

    get gradientDivBackgroundFirst() {
        return this.jsonData["gradientDivBackgroundFirst"]
    }

    get gradientDivBackgroundSecond() {
        return this.jsonData["gradientDivBackgroundSecond"]
    }

    get headerStickyBackground() {
        return this.jsonData["headerStickyBackground"]
    }

    get cardBackground() {
        return this.jsonData["cardBackground"]
    }

    get corouselBackground() {
        return this.jsonData["corouselBackground"]
    }

    get sponsorsBackground1() {
        return this.jsonData["sponsorsBackground1"]
    }

    get sponsorsBackground2() {
        return this.jsonData["sponsorsBackground2"]
    }

    get sponsorsBackground3() {
        return this.jsonData["sponsorsBackground3"]
    }

    get sponsorsBackground4() {
        return this.jsonData["sponsorsBackground4"]
    }

    get sponsorsBackground5() {
        return this.jsonData["sponsorsBackground5"]
    }
}

class ThirdPartyResourcesImpl extends BrandingData implements ThirdPartyResources {
    _id!: string;
    _config!: SlidoConfigBranding;

    get id() {
        return this.jsonData["id"]
    }

    get config() {
        if (!this._config) {
            this._config = new SlidoConfigBrandingImpl(this.jsonData["config"], this.lang)
            this.subBrandings.push(this._config as any as BrandingData)
        }
        return this._config
    }
}

class SlidoConfigBrandingImpl extends BrandingData implements SlidoConfigBranding {
    _topic!: string;
    _eventCode!: string;
    _slidoRooms!: SlidoRooms[];

    get topic() {
        return this.jsonData["topic"]
    }

    get eventCode() {
        return this.jsonData["eventCode"]
    }

    get slidoRooms() {
        if (!this._slidoRooms) {
            this._slidoRooms = []
            for (const arrayElement of this.jsonData["slidoRooms"]) {
                const arrayObject = new SlidoRoomsImpl(arrayElement, this.lang)
                this.slidoRooms.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._slidoRooms
    }
}

class SlidoRoomsImpl extends BrandingData implements SlidoRooms {
    _id!: string;
    _name!: string;
    _uuid!: string;

    get id() {
        return this.jsonData["id"]
    }

    get name() {
        return this.jsonData["name"]
    }

    get uuid() {
        return this.jsonData["uuid"]
    }
}

class CrsMultiSwitcherBrandingImpl extends BrandingData implements CrsMultiSwitcherBranding {
    _crsMultiSwitcherDefaultBgColor!: ColorString;
    _crsMultiSwitcherDefaultColor!: string;
    _crsMultiSwitcherActiveBgColor!: ColorString;
    _crsMultiSwitcherActiveColor!: string;
    _crsMultiSwitcherNotActiveColor!: ColorString;
    _crsMultiSwitcherBorder!: string;
    _crsMultiSwitcherOnDarkThemeBorderColor!: string;
    _crsMultiSwitcherOnDarkThemeBgColor!: string;
    _crsMultiSwitcherOnDarkThemeColor!: ColorString;
    _crsMultiSwitcherActiveColorInMeeting!: ColorString;
    _crsMultiSwitcherMainWidth!: string;
    _crsMultiSwitcherMultiSwitchItemFontSize!: string;

    get crsMultiSwitcherDefaultBgColor() {
        return this.jsonData["crsMultiSwitcherDefaultBgColor"]
    }

    get crsMultiSwitcherDefaultColor() {
        return this.jsonData["crsMultiSwitcherDefaultColor"]
    }

    get crsMultiSwitcherActiveBgColor() {
        return this.jsonData["crsMultiSwitcherActiveBgColor"]
    }

    get crsMultiSwitcherActiveColor() {
        return this.jsonData["crsMultiSwitcherActiveColor"]
    }

    get crsMultiSwitcherNotActiveColor() {
        return this.jsonData["crsMultiSwitcherNotActiveColor"]
    }

    get crsMultiSwitcherBorder() {
        return this.jsonData["crsMultiSwitcherBorder"]
    }

    get crsMultiSwitcherOnDarkThemeBorderColor() {
        return this.jsonData["crsMultiSwitcherOnDarkThemeBorderColor"]
    }

    get crsMultiSwitcherOnDarkThemeBgColor() {
        return this.jsonData["crsMultiSwitcherOnDarkThemeBgColor"]
    }

    get crsMultiSwitcherOnDarkThemeColor() {
        return this.jsonData["crsMultiSwitcherOnDarkThemeColor"]
    }

    get crsMultiSwitcherActiveColorInMeeting() {
        return this.jsonData["crsMultiSwitcherActiveColorInMeeting"]
    }

    get crsMultiSwitcherMainWidth() {
        return this.jsonData["crsMultiSwitcherMainWidth"]
    }

    get crsMultiSwitcherMultiSwitchItemFontSize() {
        return this.jsonData["crsMultiSwitcherMultiSwitchItemFontSize"]
    }
}

class CrsTabsBrandingImpl extends BrandingData implements CrsTabsBranding {
    _defaultBackgroundColor!: string;
    _defaultBorderColor!: string;
    _tabItemDefaultTextColor!: string;
    _tabItemDefaultFontSize!: string;
    _tabItemDefaultActiveStateColor!: string;
    _defaultActionItemColor!: string;
    _defaultActionItemActiveStateColor!: string;
    _listViewTooltip!: LocalizedString;
    _tileViewTooltip!: LocalizedString;
    _bookmarkViewTooltip!: LocalizedString;

    get defaultBackgroundColor() {
        return this.jsonData["defaultBackgroundColor"]
    }

    get defaultBorderColor() {
        return this.jsonData["defaultBorderColor"]
    }

    get tabItemDefaultTextColor() {
        return this.jsonData["tabItemDefaultTextColor"]
    }

    get tabItemDefaultFontSize() {
        return this.jsonData["tabItemDefaultFontSize"]
    }

    get tabItemDefaultActiveStateColor() {
        return this.jsonData["tabItemDefaultActiveStateColor"]
    }

    get defaultActionItemColor() {
        return this.jsonData["defaultActionItemColor"]
    }

    get defaultActionItemActiveStateColor() {
        return this.jsonData["defaultActionItemActiveStateColor"]
    }

    get listViewTooltip() {
        return this.jsonData["listViewTooltip"][this.lang]
    }

    get tileViewTooltip() {
        return this.jsonData["tileViewTooltip"][this.lang]
    }

    get bookmarkViewTooltip() {
        return this.jsonData["bookmarkViewTooltip"][this.lang]
    }
}

class HelpPageContentBrandingImpl extends BrandingData implements HelpPageContentBranding {
    _helpPageItems!: HelpTileType[];
    _helpPageBorderColor!: ColorString;
    _helpPageTextColor!: ColorString;

    get helpPageItems() {
        if (!this._helpPageItems) {
            this._helpPageItems = []
            for (const arrayElement of this.jsonData["helpPageItems"]) {
                const arrayObject = new HelpTileTypeImpl(arrayElement, this.lang)
                this.helpPageItems.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._helpPageItems
    }

    get helpPageBorderColor() {
        return this.jsonData["helpPageBorderColor"]
    }

    get helpPageTextColor() {
        return this.jsonData["helpPageTextColor"]
    }
}

class HelpTileTypeImpl extends BrandingData implements HelpTileType {
    _title!: LocalizedString;
    _items!: HelpLinkType[];

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get items() {
        if (!this._items) {
            this._items = []
            for (const arrayElement of this.jsonData["items"]) {
                const arrayObject = new HelpLinkTypeImpl(arrayElement, this.lang)
                this.items.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._items
    }
}

class HelpLinkTypeImpl extends BrandingData implements HelpLinkType {
    _title!: LocalizedString;
    _link!: LocalizedString;

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get link() {
        return this.jsonData["link"][this.lang]
    }
}

class NetworkingAreaBrandingImpl extends BrandingData implements NetworkingAreaBranding {
    _filterByTypeText!: LocalizedString;
    _filterByPositionText!: LocalizedString;
    _showMyContactsText!: LocalizedString;
    _sayHelloText!: LocalizedString;
    _showMoreText!: LocalizedString;
    _startVideoAudioChatText!: LocalizedString;
    _startTextChat!: LocalizedString;
    _connectedText!: LocalizedString;
    _requestedText!: LocalizedString;
    _ignoredText!: LocalizedString;
    _loadMoreConnectionsText!: LocalizedString;
    _showTacticalViewText!: LocalizedString;
    _showSecondGenText!: LocalizedString;
    _showRelevantConnectionsText!: LocalizedString;
    _myConnectionsText!: LocalizedString;
    _findNewPeopleText!: LocalizedString;
    _relevantTilesText!: LocalizedString;
    _filteredByInterestText!: LocalizedString;
    _exhibitorsTitle!: LocalizedString;
    _bothTitle!: LocalizedString;
    _attendeesTitle!: LocalizedString;
    _resetGraphText!: LocalizedString;
    _selectedPersonTypeFilterPlaceholder!: LocalizedString;
    _suggestSearchAll!: LocalizedString;
    _suggestNameContains!: LocalizedString;
    _loadMoreSuggestionsText!: LocalizedString;
    _collapseConnections!: LocalizedString;
    _hideFromSuggestionsText!: LocalizedString;
    _networkingRangeActionButtonBgColor!: ColorString;
    _networkingRangeActionButtonColor!: ColorString;
    _networkingRangeButtonColor!: ColorString;
    _resetGraphBorderColor!: string;
    _resetGraphTextColor!: ColorString;
    _resetGraphBackgroundColor!: ColorString;
    _loadMoreConnectionsBgColor!: ColorString;
    _loadMoreConnectionsColor!: ColorString;
    _loadMoreSuggestionsBorderColor!: string;
    _loadMoreSuggestionsTextColor!: ColorString;
    _loadMoreSuggestionsOnHoverBgColor!: ColorString;
    _loadMoreSuggestionsOnHoverTextColor!: string;
    _filterBorderBottomColor!: string;
    _filterPlaceholderColor!: ColorString;
    _filterInputColor!: ColorString;
    _filterIconColor!: ColorString;
    _nodeDetailRootBgColor!: ColorString;
    _nodeDetailRootBorderColor!: ColorString;
    _nodeDetailRootBoxShadowColor!: string;
    _nodeDetailContentUsernameTextColor!: ColorString;
    _nodeDetailContentTitleTextColor!: ColorString;
    _nodeDetailContentInterestsTitleParagraph!: ColorString;
    _nodeDetailContentInterestsBorder!: ColorString;
    _nodeDetailContentInterestsBackground!: ColorString;
    _nodeDetailContentInterestsTextColor!: ColorString;
    _nodeDetailContentRemoveSuggestionTextColor!: ColorString;
    _autocompleteItemHoverColor!: ColorString;
    _searchPlaceholderText!: LocalizedString;
    _zoomChartLicences!: ZoomChartLicenceObject[];
    _autosuggestFilterOptions!: autosuggestFilterOptions[];
    _disableLoadContactsOfMyContacts!: boolean;
    _nodeDetailActionsItemsPerRow!: number;
    _goToProfilePageText!: LocalizedString;
    _hideNodeText!: LocalizedString;
    _focusNodeText!: LocalizedString;
    _unfocusNodeText!: LocalizedString;
    _pinNodeText!: LocalizedString;
    _unpinNodeText!: LocalizedString;
    _collapseNodeText!: LocalizedString;
    _expandNodeText!: LocalizedString;
    _initialViewType!: viewType;
    _initialViewTypeForLobbyNetworkingBanner!: viewType;
    _availableViews!: viewType[];
    _topicFilter!: Topic[];
    _topicFilterActive!: boolean;
    _topicFilterPlaceholder!: LocalizedString;
    _userCategories!: string[];

    get filterByTypeText() {
        return this.jsonData["filterByTypeText"][this.lang]
    }

    get filterByPositionText() {
        return this.jsonData["filterByPositionText"][this.lang]
    }

    get showMyContactsText() {
        return this.jsonData["showMyContactsText"][this.lang]
    }

    get sayHelloText() {
        return this.jsonData["sayHelloText"][this.lang]
    }

    get showMoreText() {
        return this.jsonData["showMoreText"][this.lang]
    }

    get startVideoAudioChatText() {
        return this.jsonData["startVideoAudioChatText"][this.lang]
    }

    get startTextChat() {
        return this.jsonData["startTextChat"][this.lang]
    }

    get connectedText() {
        return this.jsonData["connectedText"][this.lang]
    }

    get requestedText() {
        return this.jsonData["requestedText"][this.lang]
    }

    get ignoredText() {
        return this.jsonData["ignoredText"][this.lang]
    }

    get loadMoreConnectionsText() {
        return this.jsonData["loadMoreConnectionsText"][this.lang]
    }

    get showTacticalViewText() {
        return this.jsonData["showTacticalViewText"][this.lang]
    }

    get showSecondGenText() {
        return this.jsonData["showSecondGenText"][this.lang]
    }

    get showRelevantConnectionsText() {
        return this.jsonData["showRelevantConnectionsText"][this.lang]
    }

    get myConnectionsText() {
        return this.jsonData["myConnectionsText"][this.lang]
    }

    get findNewPeopleText() {
        return this.jsonData["findNewPeopleText"][this.lang]
    }

    get relevantTilesText() {
        return this.jsonData["relevantTilesText"][this.lang]
    }

    get filteredByInterestText() {
        return this.jsonData["filteredByInterestText"][this.lang]
    }

    get exhibitorsTitle() {
        return this.jsonData["exhibitorsTitle"][this.lang]
    }

    get bothTitle() {
        return this.jsonData["bothTitle"][this.lang]
    }

    get attendeesTitle() {
        return this.jsonData["attendeesTitle"][this.lang]
    }

    get resetGraphText() {
        return this.jsonData["resetGraphText"][this.lang]
    }

    get selectedPersonTypeFilterPlaceholder() {
        return this.jsonData["selectedPersonTypeFilterPlaceholder"][this.lang]
    }

    get suggestSearchAll() {
        return this.jsonData["suggestSearchAll"][this.lang]
    }

    get suggestNameContains() {
        return this.jsonData["suggestNameContains"][this.lang]
    }

    get loadMoreSuggestionsText() {
        return this.jsonData["loadMoreSuggestionsText"][this.lang]
    }

    get collapseConnections() {
        return this.jsonData["collapseConnections"][this.lang]
    }

    get hideFromSuggestionsText() {
        return this.jsonData["hideFromSuggestionsText"][this.lang]
    }

    get networkingRangeActionButtonBgColor() {
        return this.jsonData["networkingRangeActionButtonBgColor"]
    }

    get networkingRangeActionButtonColor() {
        return this.jsonData["networkingRangeActionButtonColor"]
    }

    get networkingRangeButtonColor() {
        return this.jsonData["networkingRangeButtonColor"]
    }

    get resetGraphBorderColor() {
        return this.jsonData["resetGraphBorderColor"]
    }

    get resetGraphTextColor() {
        return this.jsonData["resetGraphTextColor"]
    }

    get resetGraphBackgroundColor() {
        return this.jsonData["resetGraphBackgroundColor"]
    }

    get loadMoreConnectionsBgColor() {
        return this.jsonData["loadMoreConnectionsBgColor"]
    }

    get loadMoreConnectionsColor() {
        return this.jsonData["loadMoreConnectionsColor"]
    }

    get loadMoreSuggestionsBorderColor() {
        return this.jsonData["loadMoreSuggestionsBorderColor"]
    }

    get loadMoreSuggestionsTextColor() {
        return this.jsonData["loadMoreSuggestionsTextColor"]
    }

    get loadMoreSuggestionsOnHoverBgColor() {
        return this.jsonData["loadMoreSuggestionsOnHoverBgColor"]
    }

    get loadMoreSuggestionsOnHoverTextColor() {
        return this.jsonData["loadMoreSuggestionsOnHoverTextColor"]
    }

    get filterBorderBottomColor() {
        return this.jsonData["filterBorderBottomColor"]
    }

    get filterPlaceholderColor() {
        return this.jsonData["filterPlaceholderColor"]
    }

    get filterInputColor() {
        return this.jsonData["filterInputColor"]
    }

    get filterIconColor() {
        return this.jsonData["filterIconColor"]
    }

    get nodeDetailRootBgColor() {
        return this.jsonData["nodeDetailRootBgColor"]
    }

    get nodeDetailRootBorderColor() {
        return this.jsonData["nodeDetailRootBorderColor"]
    }

    get nodeDetailRootBoxShadowColor() {
        return this.jsonData["nodeDetailRootBoxShadowColor"]
    }

    get nodeDetailContentUsernameTextColor() {
        return this.jsonData["nodeDetailContentUsernameTextColor"]
    }

    get nodeDetailContentTitleTextColor() {
        return this.jsonData["nodeDetailContentTitleTextColor"]
    }

    get nodeDetailContentInterestsTitleParagraph() {
        return this.jsonData["nodeDetailContentInterestsTitleParagraph"]
    }

    get nodeDetailContentInterestsBorder() {
        return this.jsonData["nodeDetailContentInterestsBorder"]
    }

    get nodeDetailContentInterestsBackground() {
        return this.jsonData["nodeDetailContentInterestsBackground"]
    }

    get nodeDetailContentInterestsTextColor() {
        return this.jsonData["nodeDetailContentInterestsTextColor"]
    }

    get nodeDetailContentRemoveSuggestionTextColor() {
        return this.jsonData["nodeDetailContentRemoveSuggestionTextColor"]
    }

    get autocompleteItemHoverColor() {
        return this.jsonData["autocompleteItemHoverColor"]
    }

    get searchPlaceholderText() {
        return this.jsonData["searchPlaceholderText"][this.lang]
    }

    get zoomChartLicences() {
        if (!this._zoomChartLicences) {
            this._zoomChartLicences = []
            for (const arrayElement of this.jsonData["zoomChartLicences"]) {
                const arrayObject = new ZoomChartLicenceObjectImpl(arrayElement, this.lang)
                this.zoomChartLicences.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._zoomChartLicences
    }

    get autosuggestFilterOptions() {
        return this.jsonData["autosuggestFilterOptions"]
    }

    get disableLoadContactsOfMyContacts() {
        return this.jsonData["disableLoadContactsOfMyContacts"]
    }

    get nodeDetailActionsItemsPerRow() {
        return this.jsonData["nodeDetailActionsItemsPerRow"]
    }

    get goToProfilePageText() {
        return this.jsonData["goToProfilePageText"][this.lang]
    }

    get hideNodeText() {
        return this.jsonData["hideNodeText"][this.lang]
    }

    get focusNodeText() {
        return this.jsonData["focusNodeText"][this.lang]
    }

    get unfocusNodeText() {
        return this.jsonData["unfocusNodeText"][this.lang]
    }

    get pinNodeText() {
        return this.jsonData["pinNodeText"][this.lang]
    }

    get unpinNodeText() {
        return this.jsonData["unpinNodeText"][this.lang]
    }

    get collapseNodeText() {
        return this.jsonData["collapseNodeText"][this.lang]
    }

    get expandNodeText() {
        return this.jsonData["expandNodeText"][this.lang]
    }

    get initialViewType() {
        return this.jsonData["initialViewType"]
    }

    get initialViewTypeForLobbyNetworkingBanner() {
        return this.jsonData["initialViewTypeForLobbyNetworkingBanner"]
    }

    get availableViews() {
        return this.jsonData["availableViews"]
    }

    get topicFilter() {
        if (!this._topicFilter) {
            this._topicFilter = []
            for (const arrayElement of this.jsonData["topicFilter"]) {
                const arrayObject = new TopicImpl(arrayElement, this.lang)
                this.topicFilter.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._topicFilter
    }

    get topicFilterActive() {
        return this.jsonData["topicFilterActive"]
    }

    get topicFilterPlaceholder() {
        return this.jsonData["topicFilterPlaceholder"][this.lang]
    }

    get userCategories() {
        return this.jsonData["userCategories"]
    }
}

class ZoomChartLicenceObjectImpl extends BrandingData implements ZoomChartLicenceObject {
    _domain!: string;
    _value!: string;
    _key!: string;

    get domain() {
        return this.jsonData["domain"]
    }

    get value() {
        return this.jsonData["value"]
    }

    get key() {
        return this.jsonData["key"]
    }
}

class TopicImpl extends BrandingData implements Topic {
    _label!: string;
    _value!: number;

    get label() {
        return this.jsonData["label"]
    }

    get value() {
        return this.jsonData["value"]
    }
}

class GlobalTextsBrandingImpl extends BrandingData implements GlobalTextsBranding {
    _usersOnline!: LocalizedString;
    _usersOnPage!: LocalizedString;
    _privacyPolicyTitle!: LocalizedString;
    _privacyPolicyText!: LocalizedString;
    _privacyPolicyBoldText!: LocalizedString;
    _privacyPolicyAgreeText!: LocalizedString;
    _privacyPolicyDisagreeText!: LocalizedString;
    _privacyPolicyCheckboxLabel!: LocalizedString;
    _privacyPolicyFontSize!: string;
    _agreeBtnBackgroundColor!: ColorString;
    _agreeBtnTextColor!: string;
    _cancel!: LocalizedString;
    _confirm!: LocalizedString;
    _requestAccess!: LocalizedString;
    _loadingMessage!: LocalizedString;
    _requestSent!: LocalizedString;
    _statusRequested!: LocalizedString;
    _statusGranted!: LocalizedString;
    _accessRequestTitle!: LocalizedString;
    _requestReasonText!: LocalizedString;
    _descriptionText!: LocalizedString;
    _noAccessMessage!: LocalizedString;
    _guestRestrictionMessage!: LocalizedString;
    _hostedByText!: LocalizedString;
    _privacyPolicyEndLink!: LocalizedString;
    _privacyPolicyEndLinkText!: LocalizedString;
    _hasLinkAtTheEnd!: boolean;
    _currentSearchLabel!: LocalizedString;
    _globalSearchTitle!: LocalizedString;

    get usersOnline() {
        return this.jsonData["usersOnline"][this.lang]
    }

    get usersOnPage() {
        return this.jsonData["usersOnPage"][this.lang]
    }

    get privacyPolicyTitle() {
        return this.jsonData["privacyPolicyTitle"][this.lang]
    }

    get privacyPolicyText() {
        return this.jsonData["privacyPolicyText"][this.lang]
    }

    get privacyPolicyBoldText() {
        return this.jsonData["privacyPolicyBoldText"][this.lang]
    }

    get privacyPolicyAgreeText() {
        return this.jsonData["privacyPolicyAgreeText"][this.lang]
    }

    get privacyPolicyDisagreeText() {
        return this.jsonData["privacyPolicyDisagreeText"][this.lang]
    }

    get privacyPolicyCheckboxLabel() {
        return this.jsonData["privacyPolicyCheckboxLabel"][this.lang]
    }

    get privacyPolicyFontSize() {
        return this.jsonData["privacyPolicyFontSize"]
    }

    get agreeBtnBackgroundColor() {
        return this.jsonData["agreeBtnBackgroundColor"]
    }

    get agreeBtnTextColor() {
        return this.jsonData["agreeBtnTextColor"]
    }

    get cancel() {
        return this.jsonData["cancel"][this.lang]
    }

    get confirm() {
        return this.jsonData["confirm"][this.lang]
    }

    get requestAccess() {
        return this.jsonData["requestAccess"][this.lang]
    }

    get loadingMessage() {
        return this.jsonData["loadingMessage"][this.lang]
    }

    get requestSent() {
        return this.jsonData["requestSent"][this.lang]
    }

    get statusRequested() {
        return this.jsonData["statusRequested"][this.lang]
    }

    get statusGranted() {
        return this.jsonData["statusGranted"][this.lang]
    }

    get accessRequestTitle() {
        return this.jsonData["accessRequestTitle"][this.lang]
    }

    get requestReasonText() {
        return this.jsonData["requestReasonText"][this.lang]
    }

    get descriptionText() {
        return this.jsonData["descriptionText"][this.lang]
    }

    get noAccessMessage() {
        return this.jsonData["noAccessMessage"][this.lang]
    }

    get guestRestrictionMessage() {
        return this.jsonData["guestRestrictionMessage"][this.lang]
    }

    get hostedByText() {
        return this.jsonData["hostedByText"][this.lang]
    }

    get privacyPolicyEndLink() {
        return this.jsonData["privacyPolicyEndLink"][this.lang]
    }

    get privacyPolicyEndLinkText() {
        return this.jsonData["privacyPolicyEndLinkText"][this.lang]
    }

    get hasLinkAtTheEnd() {
        return this.jsonData["hasLinkAtTheEnd"]
    }

    get currentSearchLabel() {
        return this.jsonData["currentSearchLabel"][this.lang]
    }

    get globalSearchTitle() {
        return this.jsonData["globalSearchTitle"][this.lang]
    }
}

class TicketSaleBrandingImpl extends BrandingData implements TicketSaleBranding {
    _getYourTicketButton!: LocalizedString;
    _getYourTicketNowButton!: LocalizedString;
    _getToServiceButton!: LocalizedString;
    _leadDescriptionText!: LocalizedString;
    _commingText!: LocalizedString;
    _checkYourSystemDescriptionText!: LocalizedString;
    _descriptionTextWhenCheckSystemIsDisabled!: LocalizedString;
    _descriptionTextWhenCheckSystemIsDisabledFontSize!: string;
    _checkYourSystemButton!: LocalizedString;
    _checkYourSystemButtonLoginPageVisible!: boolean;
    _checkYourSystemButtonLoginPageMobileViewVisible!: boolean;
    _imprintLink!: LocalizedString;
    _imprintLinkUrl!: LocalizedString;
    _ticketsUrl!: LocalizedString;
    _serviceUrl!: LocalizedString;
    _officialWebsiteButtonLabel!: LocalizedString;
    _officialWebsiteButtonVisible!: boolean;
    _officialWebsiteUrl!: string;
    _systemCheckVisible!: boolean;
    _getYourTicketVisible!: boolean;
    _getYourServiceButtonVisible!: boolean;
    _introTextAndImagesVisible!: boolean;
    _getYourTicketTopRightVisible!: boolean;
    _rootBackground!: string;
    _headerTitleClass!: string;
    _headerTitleFont1!: string;
    _headerTitleFont2!: string;
    _ticketAndServiceButtonBgColor!: string;
    _marginLeftForTicketParagraph!: string;
    _lineHeightForTicketAndServiceParagraphs!: string;
    _fontSizeSubtitleHeaderParagraph!: string;
    _ticketAndServiceButtonTextColor!: string;
    _descriptionTitleForGetTicketLabel!: LocalizedString;
    _descriptionTitleForGetServiceLabel!: LocalizedString;

    get getYourTicketButton() {
        return this.jsonData["getYourTicketButton"][this.lang]
    }

    get getYourTicketNowButton() {
        return this.jsonData["getYourTicketNowButton"][this.lang]
    }

    get getToServiceButton() {
        return this.jsonData["getToServiceButton"][this.lang]
    }

    get leadDescriptionText() {
        return this.jsonData["leadDescriptionText"][this.lang]
    }

    get commingText() {
        return this.jsonData["commingText"][this.lang]
    }

    get checkYourSystemDescriptionText() {
        return this.jsonData["checkYourSystemDescriptionText"][this.lang]
    }

    get descriptionTextWhenCheckSystemIsDisabled() {
        return this.jsonData["descriptionTextWhenCheckSystemIsDisabled"][this.lang]
    }

    get descriptionTextWhenCheckSystemIsDisabledFontSize() {
        return this.jsonData["descriptionTextWhenCheckSystemIsDisabledFontSize"]
    }

    get checkYourSystemButton() {
        return this.jsonData["checkYourSystemButton"][this.lang]
    }

    get checkYourSystemButtonLoginPageVisible() {
        return this.jsonData["checkYourSystemButtonLoginPageVisible"]
    }

    get checkYourSystemButtonLoginPageMobileViewVisible() {
        return this.jsonData["checkYourSystemButtonLoginPageMobileViewVisible"]
    }

    get imprintLink() {
        return this.jsonData["imprintLink"][this.lang]
    }

    get imprintLinkUrl() {
        return this.jsonData["imprintLinkUrl"][this.lang]
    }

    get ticketsUrl() {
        return this.jsonData["ticketsUrl"][this.lang]
    }

    get serviceUrl() {
        return this.jsonData["serviceUrl"][this.lang]
    }

    get officialWebsiteButtonLabel() {
        return this.jsonData["officialWebsiteButtonLabel"][this.lang]
    }

    get officialWebsiteButtonVisible() {
        return this.jsonData["officialWebsiteButtonVisible"]
    }

    get officialWebsiteUrl() {
        return this.jsonData["officialWebsiteUrl"]
    }

    get systemCheckVisible() {
        return this.jsonData["systemCheckVisible"]
    }

    get getYourTicketVisible() {
        return this.jsonData["getYourTicketVisible"]
    }

    get getYourServiceButtonVisible() {
        return this.jsonData["getYourServiceButtonVisible"]
    }

    get introTextAndImagesVisible() {
        return this.jsonData["introTextAndImagesVisible"]
    }

    get getYourTicketTopRightVisible() {
        return this.jsonData["getYourTicketTopRightVisible"]
    }

    get rootBackground() {
        return this.jsonData["rootBackground"]
    }

    get headerTitleClass() {
        return this.jsonData["headerTitleClass"]
    }

    get headerTitleFont1() {
        return this.jsonData["headerTitleFont1"]
    }

    get headerTitleFont2() {
        return this.jsonData["headerTitleFont2"]
    }

    get ticketAndServiceButtonBgColor() {
        return this.jsonData["ticketAndServiceButtonBgColor"]
    }

    get marginLeftForTicketParagraph() {
        return this.jsonData["marginLeftForTicketParagraph"]
    }

    get lineHeightForTicketAndServiceParagraphs() {
        return this.jsonData["lineHeightForTicketAndServiceParagraphs"]
    }

    get fontSizeSubtitleHeaderParagraph() {
        return this.jsonData["fontSizeSubtitleHeaderParagraph"]
    }

    get ticketAndServiceButtonTextColor() {
        return this.jsonData["ticketAndServiceButtonTextColor"]
    }

    get descriptionTitleForGetTicketLabel() {
        return this.jsonData["descriptionTitleForGetTicketLabel"][this.lang]
    }

    get descriptionTitleForGetServiceLabel() {
        return this.jsonData["descriptionTitleForGetServiceLabel"][this.lang]
    }
}

class NotificationBrandingImpl extends BrandingData implements NotificationBranding {
    _newMessageTitle!: LocalizedString;
    _meetingRequestTitle!: LocalizedString;
    _meetingRequestTextTemplate!: LocalizedString;
    _meetingRequestDeletedTextTemplate!: LocalizedString;
    _meetingRequestParticipationDeletedTextTemplate!: LocalizedString;
    _meetingRequestParticipationAcceptedTextTemplate!: LocalizedString;
    _meetingRequestParticipationDeclinedTextTemplate!: LocalizedString;
    _meetingRequestUpdatedTextTemplate!: LocalizedString;
    _connectRequestTitle!: LocalizedString;
    _connectRequestAcceptedTextTemplate!: LocalizedString;
    _connectRequestIncomingTextTemplate!: LocalizedString;
    _meetingReminderTitle!: LocalizedString;
    _meetingReminderTextTemplate!: LocalizedString;
    _meetingReminderNameLink!: LocalizedString;
    _missedCallTitle!: LocalizedString;
    _missedCallTextTemplate!: LocalizedString;
    _successCallTitle!: LocalizedString;
    _successCallTextTemplate!: LocalizedString;
    _announcementTitle!: LocalizedString;
    _announcementText!: LocalizedString;
    _accessRequestTitle!: LocalizedString;
    _accessRequestText!: LocalizedString;
    _accessAcceptedTitle!: LocalizedString;
    _accessAcceptedText!: LocalizedString;
    _accessDeclinedTitle!: LocalizedString;
    _accessDeclinedText!: LocalizedString;
    _accessAddedTitle!: LocalizedString;
    _accessAddedText!: LocalizedString;
    _accessDeletedTitle!: LocalizedString;
    _accessDeletedText!: LocalizedString;
    _notificationTitleTextColor!: string;
    _notificationContentTextColor!: string;

    get newMessageTitle() {
        return this.jsonData["newMessageTitle"][this.lang]
    }

    get meetingRequestTitle() {
        return this.jsonData["meetingRequestTitle"][this.lang]
    }

    get meetingRequestTextTemplate() {
        return this.jsonData["meetingRequestTextTemplate"][this.lang]
    }

    get meetingRequestDeletedTextTemplate() {
        return this.jsonData["meetingRequestDeletedTextTemplate"][this.lang]
    }

    get meetingRequestParticipationDeletedTextTemplate() {
        return this.jsonData["meetingRequestParticipationDeletedTextTemplate"][this.lang]
    }

    get meetingRequestParticipationAcceptedTextTemplate() {
        return this.jsonData["meetingRequestParticipationAcceptedTextTemplate"][this.lang]
    }

    get meetingRequestParticipationDeclinedTextTemplate() {
        return this.jsonData["meetingRequestParticipationDeclinedTextTemplate"][this.lang]
    }

    get meetingRequestUpdatedTextTemplate() {
        return this.jsonData["meetingRequestUpdatedTextTemplate"][this.lang]
    }

    get connectRequestTitle() {
        return this.jsonData["connectRequestTitle"][this.lang]
    }

    get connectRequestAcceptedTextTemplate() {
        return this.jsonData["connectRequestAcceptedTextTemplate"][this.lang]
    }

    get connectRequestIncomingTextTemplate() {
        return this.jsonData["connectRequestIncomingTextTemplate"][this.lang]
    }

    get meetingReminderTitle() {
        return this.jsonData["meetingReminderTitle"][this.lang]
    }

    get meetingReminderTextTemplate() {
        return this.jsonData["meetingReminderTextTemplate"][this.lang]
    }

    get meetingReminderNameLink() {
        return this.jsonData["meetingReminderNameLink"][this.lang]
    }

    get missedCallTitle() {
        return this.jsonData["missedCallTitle"][this.lang]
    }

    get missedCallTextTemplate() {
        return this.jsonData["missedCallTextTemplate"][this.lang]
    }

    get successCallTitle() {
        return this.jsonData["successCallTitle"][this.lang]
    }

    get successCallTextTemplate() {
        return this.jsonData["successCallTextTemplate"][this.lang]
    }

    get announcementTitle() {
        return this.jsonData["announcementTitle"][this.lang]
    }

    get announcementText() {
        return this.jsonData["announcementText"][this.lang]
    }

    get accessRequestTitle() {
        return this.jsonData["accessRequestTitle"][this.lang]
    }

    get accessRequestText() {
        return this.jsonData["accessRequestText"][this.lang]
    }

    get accessAcceptedTitle() {
        return this.jsonData["accessAcceptedTitle"][this.lang]
    }

    get accessAcceptedText() {
        return this.jsonData["accessAcceptedText"][this.lang]
    }

    get accessDeclinedTitle() {
        return this.jsonData["accessDeclinedTitle"][this.lang]
    }

    get accessDeclinedText() {
        return this.jsonData["accessDeclinedText"][this.lang]
    }

    get accessAddedTitle() {
        return this.jsonData["accessAddedTitle"][this.lang]
    }

    get accessAddedText() {
        return this.jsonData["accessAddedText"][this.lang]
    }

    get accessDeletedTitle() {
        return this.jsonData["accessDeletedTitle"][this.lang]
    }

    get accessDeletedText() {
        return this.jsonData["accessDeletedText"][this.lang]
    }

    get notificationTitleTextColor() {
        return this.jsonData["notificationTitleTextColor"]
    }

    get notificationContentTextColor() {
        return this.jsonData["notificationContentTextColor"]
    }
}

class BackendErrorBrandingImpl extends BrandingData implements BackendErrorBranding {
    _errorMessage!: LocalizedString;
    _errorButtonTitle!: LocalizedString;
    _backendErrorColor!: ColorString;
    _backendErrorDarkColor!: ColorString;

    get errorMessage() {
        return this.jsonData["errorMessage"][this.lang]
    }

    get errorButtonTitle() {
        return this.jsonData["errorButtonTitle"][this.lang]
    }

    get backendErrorColor() {
        return this.jsonData["backendErrorColor"]
    }

    get backendErrorDarkColor() {
        return this.jsonData["backendErrorDarkColor"]
    }
}

class RecommendModalBrandingImpl extends BrandingData implements RecommendModalBranding {
    _closeIconColor!: ColorString;
    _title!: LocalizedString;
    _titleContact!: LocalizedString;
    _titleEventDate!: LocalizedString;
    _titleEvent!: LocalizedString;
    _titleJoboffer!: LocalizedString;
    _subtitlePart1!: LocalizedString;
    _subtitlePart2!: LocalizedString;
    _subtitleWithoutName!: LocalizedString;
    _subtitleEventDate!: LocalizedString;
    _subtitleJoboffer!: LocalizedString;
    _subtitleEvent!: LocalizedString;
    _shareButton!: LocalizedString;
    _sendMessageOrganization!: LocalizedString;
    _sendMessagePerson!: LocalizedString;
    _sendMessageSotUser!: LocalizedString;
    _sendMessageEventdate!: LocalizedString;
    _sendMessageEvent!: LocalizedString;
    _sendMessageJoboffer!: LocalizedString;
    _notes!: LocalizedString;
    _notesPlaceholder!: LocalizedString;
    _submitBtnPrimaryOnHoverBgColor!: ColorString;
    _submitBtnPrimaryOnHoverTextColor!: ColorString;
    _submitBtnPrimaryBgColor!: ColorString;
    _submitBtnPrimaryTextColor!: ColorString;
    _submitBtnPrimaryBorderColor!: ColorString;
    _cancelBtnPrimaryBorderColor!: ColorString;
    _cancelBtnPrimaryTextColor!: ColorString;
    _textareaCounterTextColor!: string;
    _privacyPolicyDisagreeButtonPadding!: string;

    get closeIconColor() {
        return this.jsonData["closeIconColor"]
    }

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get titleContact() {
        return this.jsonData["titleContact"][this.lang]
    }

    get titleEventDate() {
        return this.jsonData["titleEventDate"][this.lang]
    }

    get titleEvent() {
        return this.jsonData["titleEvent"][this.lang]
    }

    get titleJoboffer() {
        return this.jsonData["titleJoboffer"][this.lang]
    }

    get subtitlePart1() {
        return this.jsonData["subtitlePart1"][this.lang]
    }

    get subtitlePart2() {
        return this.jsonData["subtitlePart2"][this.lang]
    }

    get subtitleWithoutName() {
        return this.jsonData["subtitleWithoutName"][this.lang]
    }

    get subtitleEventDate() {
        return this.jsonData["subtitleEventDate"][this.lang]
    }

    get subtitleJoboffer() {
        return this.jsonData["subtitleJoboffer"][this.lang]
    }

    get subtitleEvent() {
        return this.jsonData["subtitleEvent"][this.lang]
    }

    get shareButton() {
        return this.jsonData["shareButton"][this.lang]
    }

    get sendMessageOrganization() {
        return this.jsonData["sendMessageOrganization"][this.lang]
    }

    get sendMessagePerson() {
        return this.jsonData["sendMessagePerson"][this.lang]
    }

    get sendMessageSotUser() {
        return this.jsonData["sendMessageSotUser"][this.lang]
    }

    get sendMessageEventdate() {
        return this.jsonData["sendMessageEventdate"][this.lang]
    }

    get sendMessageEvent() {
        return this.jsonData["sendMessageEvent"][this.lang]
    }

    get sendMessageJoboffer() {
        return this.jsonData["sendMessageJoboffer"][this.lang]
    }

    get notes() {
        return this.jsonData["notes"][this.lang]
    }

    get notesPlaceholder() {
        return this.jsonData["notesPlaceholder"][this.lang]
    }

    get submitBtnPrimaryOnHoverBgColor() {
        return this.jsonData["submitBtnPrimaryOnHoverBgColor"]
    }

    get submitBtnPrimaryOnHoverTextColor() {
        return this.jsonData["submitBtnPrimaryOnHoverTextColor"]
    }

    get submitBtnPrimaryBgColor() {
        return this.jsonData["submitBtnPrimaryBgColor"]
    }

    get submitBtnPrimaryTextColor() {
        return this.jsonData["submitBtnPrimaryTextColor"]
    }

    get submitBtnPrimaryBorderColor() {
        return this.jsonData["submitBtnPrimaryBorderColor"]
    }

    get cancelBtnPrimaryBorderColor() {
        return this.jsonData["cancelBtnPrimaryBorderColor"]
    }

    get cancelBtnPrimaryTextColor() {
        return this.jsonData["cancelBtnPrimaryTextColor"]
    }

    get textareaCounterTextColor() {
        return this.jsonData["textareaCounterTextColor"]
    }

    get privacyPolicyDisagreeButtonPadding() {
        return this.jsonData["privacyPolicyDisagreeButtonPadding"]
    }
}

class TopicBadgeImpl extends BrandingData implements TopicBadge {
    _topicName!: string;
    _badgeText!: string;
    _textColor!: string;
    _backgroundColor!: string;
    _borderColor!: string;

    get topicName() {
        return this.jsonData["topicName"]
    }

    get badgeText() {
        return this.jsonData["badgeText"]
    }

    get textColor() {
        return this.jsonData["textColor"]
    }

    get backgroundColor() {
        return this.jsonData["backgroundColor"]
    }

    get borderColor() {
        return this.jsonData["borderColor"]
    }
}

class CouponsBrandingImpl extends BrandingData implements CouponsBranding {
    _visitExhibitor!: LocalizedString;
    _goToDeal!: LocalizedString;
    _explorationPhaseMessage!: LocalizedString;
    _postEventPhaseMessage!: LocalizedString;
    _defaultButtonBgColor!: ColorString;
    _defaultButtonTextColor!: ColorString;
    _defaultButtonBorderColor!: ColorString;
    _defaultButtonBgColorOnHover!: ColorString;
    _defaultButtonTextColorOnHover!: ColorString;
    _linkToExhibitor!: boolean;
    _dayFormat!: LocalizedString;
    _validityTemplateStartEnd!: LocalizedString;
    _validityTemplateEnd!: PluralString;
    _couponOverlay!: string;
    _couponVisibilityByPhases!: string[];
    _couponsListLayoutTitleColor!: string;

    get visitExhibitor() {
        return this.jsonData["visitExhibitor"][this.lang]
    }

    get goToDeal() {
        return this.jsonData["goToDeal"][this.lang]
    }

    get explorationPhaseMessage() {
        return this.jsonData["explorationPhaseMessage"][this.lang]
    }

    get postEventPhaseMessage() {
        return this.jsonData["postEventPhaseMessage"][this.lang]
    }

    get defaultButtonBgColor() {
        return this.jsonData["defaultButtonBgColor"]
    }

    get defaultButtonTextColor() {
        return this.jsonData["defaultButtonTextColor"]
    }

    get defaultButtonBorderColor() {
        return this.jsonData["defaultButtonBorderColor"]
    }

    get defaultButtonBgColorOnHover() {
        return this.jsonData["defaultButtonBgColorOnHover"]
    }

    get defaultButtonTextColorOnHover() {
        return this.jsonData["defaultButtonTextColorOnHover"]
    }

    get linkToExhibitor() {
        return this.jsonData["linkToExhibitor"]
    }

    get dayFormat() {
        return this.jsonData["dayFormat"][this.lang]
    }

    get validityTemplateStartEnd() {
        return this.jsonData["validityTemplateStartEnd"][this.lang]
    }

    get validityTemplateEnd() {
        if (!this._validityTemplateEnd) {
            this._validityTemplateEnd = new PluralStringImpl(this.jsonData["validityTemplateEnd"], this.lang)
            this.subBrandings.push(this._validityTemplateEnd as any as BrandingData)
        }
        return this._validityTemplateEnd
    }

    get couponOverlay() {
        return this.jsonData["couponOverlay"]
    }

    get couponVisibilityByPhases() {
        return this.jsonData["couponVisibilityByPhases"]
    }

    get couponsListLayoutTitleColor() {
        return this.jsonData["couponsListLayoutTitleColor"]
    }
}

class TopBannerBrandingImpl extends BrandingData implements TopBannerBranding {
    _bannerPools!: BannerPool[];
    _autoPlayValue!: number;

    get bannerPools() {
        if (!this._bannerPools) {
            this._bannerPools = []
            for (const arrayElement of this.jsonData["bannerPools"]) {
                const arrayObject = new BannerPoolImpl(arrayElement, this.lang)
                this.bannerPools.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._bannerPools
    }

    get autoPlayValue() {
        return this.jsonData["autoPlayValue"]
    }
}

class BannerPoolImpl extends BrandingData implements BannerPool {
    _title!: string;
    _items!: BannerBrandingItem[];

    get title() {
        return this.jsonData["title"]
    }

    get items() {
        if (!this._items) {
            this._items = []
            for (const arrayElement of this.jsonData["items"]) {
                const arrayObject = new BannerBrandingItemImpl(arrayElement, this.lang)
                this.items.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._items
    }
}

class BannerBrandingItemImpl extends BrandingData implements BannerBrandingItem {
    _id!: string;
    _name!: string;
    _image!: string;
    _externalLink!: string;
    _internalLink!: string;

    get id() {
        return this.jsonData["id"]
    }

    get name() {
        return this.jsonData["name"]
    }

    get image() {
        return this.jsonData["image"]
    }

    get externalLink() {
        return this.jsonData["externalLink"]
    }

    get internalLink() {
        return this.jsonData["internalLink"]
    }
}

class GuestUserBannerBrandingImpl extends BrandingData implements GuestUserBannerBranding {
    _bannerTextNoOrganization!: LocalizedString;
    _bannerTextOrga!: LocalizedString;
    _bannerBgColor!: ColorString;
    _bannerFgColor!: ColorString;

    get bannerTextNoOrganization() {
        return this.jsonData["bannerTextNoOrganization"][this.lang]
    }

    get bannerTextOrga() {
        return this.jsonData["bannerTextOrga"][this.lang]
    }

    get bannerBgColor() {
        return this.jsonData["bannerBgColor"]
    }

    get bannerFgColor() {
        return this.jsonData["bannerFgColor"]
    }
}

class TopBannerConfigImpl extends BrandingData implements TopBannerConfig {
    _guest!: TopBannerUserTypeConfig;
    _attendee!: TopBannerUserTypeConfig;
    _staff!: TopBannerUserTypeConfig;
    _speaker!: TopBannerUserTypeConfig;
    _none!: TopBannerUserTypeConfig;

    get guest() {
        if (!this._guest) {
            this._guest = new TopBannerUserTypeConfigImpl(this.jsonData["guest"], this.lang)
            this.subBrandings.push(this._guest as any as BrandingData)
        }
        return this._guest
    }

    get attendee() {
        if (!this._attendee) {
            this._attendee = new TopBannerUserTypeConfigImpl(this.jsonData["attendee"], this.lang)
            this.subBrandings.push(this._attendee as any as BrandingData)
        }
        return this._attendee
    }

    get staff() {
        if (!this._staff) {
            this._staff = new TopBannerUserTypeConfigImpl(this.jsonData["staff"], this.lang)
            this.subBrandings.push(this._staff as any as BrandingData)
        }
        return this._staff
    }

    get speaker() {
        if (!this._speaker) {
            this._speaker = new TopBannerUserTypeConfigImpl(this.jsonData["speaker"], this.lang)
            this.subBrandings.push(this._speaker as any as BrandingData)
        }
        return this._speaker
    }

    get none() {
        if (!this._none) {
            this._none = new TopBannerUserTypeConfigImpl(this.jsonData["none"], this.lang)
            this.subBrandings.push(this._none as any as BrandingData)
        }
        return this._none
    }
}

class TopBannerUserTypeConfigImpl extends BrandingData implements TopBannerUserTypeConfig {
    _bannerTextNoOrganization!: LocalizedString;
    _bannerTextOrga!: LocalizedString;
    _bannerBgColor!: ColorString;
    _bannerFgColor!: ColorString;

    get bannerTextNoOrganization() {
        return this.jsonData["bannerTextNoOrganization"][this.lang]
    }

    get bannerTextOrga() {
        return this.jsonData["bannerTextOrga"][this.lang]
    }

    get bannerBgColor() {
        return this.jsonData["bannerBgColor"]
    }

    get bannerFgColor() {
        return this.jsonData["bannerFgColor"]
    }
}

class RulesPageContentBrandingImpl extends BrandingData implements RulesPageContentBranding {
    _rulesTitle!: LocalizedString;
    _rulesSubtitle!: LocalizedString;
    _rules!: RuleType[];
    _rulesFooterItem1!: LocalizedString;
    _rulesFooterItem2!: LocalizedString;
    _rulesFooterItem3!: LocalizedString;

    get rulesTitle() {
        return this.jsonData["rulesTitle"][this.lang]
    }

    get rulesSubtitle() {
        return this.jsonData["rulesSubtitle"][this.lang]
    }

    get rules() {
        if (!this._rules) {
            this._rules = []
            for (const arrayElement of this.jsonData["rules"]) {
                const arrayObject = new RuleTypeImpl(arrayElement, this.lang)
                this.rules.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._rules
    }

    get rulesFooterItem1() {
        return this.jsonData["rulesFooterItem1"][this.lang]
    }

    get rulesFooterItem2() {
        return this.jsonData["rulesFooterItem2"][this.lang]
    }

    get rulesFooterItem3() {
        return this.jsonData["rulesFooterItem3"][this.lang]
    }
}

class RuleTypeImpl extends BrandingData implements RuleType {
    _name!: LocalizedString;
    _description!: LocalizedString;

    get name() {
        return this.jsonData["name"][this.lang]
    }

    get description() {
        return this.jsonData["description"][this.lang]
    }
}

class TopBarBrandingImpl extends BrandingData implements TopBarBranding {
    _fontWeight!: string;
    _textColor!: string;
    _background!: string;
    _searchTitleColor!: string;
    _searchTitleFontFamily!: string;
    _searchTitleFontSize!: string;
    _searchTitleFontWeight!: string;
    _searchTitleTextTranform!: string;
    _searchTitleLetterSpacing!: string;
    _breadcrumbTextFontFamily!: string;
    _breadcrumbTextFontSize!: string;
    _breadcrumbTextFontWeight!: string;
    _breadcrumbTextTransform!: string;
    _breadcrumbLetterSpacing!: string;

    get fontWeight() {
        return this.jsonData["fontWeight"]
    }

    get textColor() {
        return this.jsonData["textColor"]
    }

    get background() {
        return this.jsonData["background"]
    }

    get searchTitleColor() {
        return this.jsonData["searchTitleColor"]
    }

    get searchTitleFontFamily() {
        return this.jsonData["searchTitleFontFamily"]
    }

    get searchTitleFontSize() {
        return this.jsonData["searchTitleFontSize"]
    }

    get searchTitleFontWeight() {
        return this.jsonData["searchTitleFontWeight"]
    }

    get searchTitleTextTranform() {
        return this.jsonData["searchTitleTextTranform"]
    }

    get searchTitleLetterSpacing() {
        return this.jsonData["searchTitleLetterSpacing"]
    }

    get breadcrumbTextFontFamily() {
        return this.jsonData["breadcrumbTextFontFamily"]
    }

    get breadcrumbTextFontSize() {
        return this.jsonData["breadcrumbTextFontSize"]
    }

    get breadcrumbTextFontWeight() {
        return this.jsonData["breadcrumbTextFontWeight"]
    }

    get breadcrumbTextTransform() {
        return this.jsonData["breadcrumbTextTransform"]
    }

    get breadcrumbLetterSpacing() {
        return this.jsonData["breadcrumbLetterSpacing"]
    }
}

class RosterBrandingImpl extends BrandingData implements RosterBranding {
    _buttonConnectColor!: string;
    _buttonConnectBgColor!: string;
    _buttonConnectBorderColor!: string;
    _searchInputColor!: string;
    _searchInputBgColor!: string;
    _greenRoomPresenterViewColor!: string;
    _supportVirtualCafes!: string[];
    _moderatorsSectionTitle!: LocalizedString;
    _participantsSectionTitle!: LocalizedString;
    _muteAllButtonText!: LocalizedString;
    _muteAllModalTitle!: LocalizedString;
    _muteAllModalText!: LocalizedString;
    _muteAllModalCloseButtonText!: LocalizedString;
    _muteAllModalContinueButtonText!: LocalizedString;

    get buttonConnectColor() {
        return this.jsonData["buttonConnectColor"]
    }

    get buttonConnectBgColor() {
        return this.jsonData["buttonConnectBgColor"]
    }

    get buttonConnectBorderColor() {
        return this.jsonData["buttonConnectBorderColor"]
    }

    get searchInputColor() {
        return this.jsonData["searchInputColor"]
    }

    get searchInputBgColor() {
        return this.jsonData["searchInputBgColor"]
    }

    get greenRoomPresenterViewColor() {
        return this.jsonData["greenRoomPresenterViewColor"]
    }

    get supportVirtualCafes() {
        return this.jsonData["supportVirtualCafes"]
    }

    get moderatorsSectionTitle() {
        return this.jsonData["moderatorsSectionTitle"][this.lang]
    }

    get participantsSectionTitle() {
        return this.jsonData["participantsSectionTitle"][this.lang]
    }

    get muteAllButtonText() {
        return this.jsonData["muteAllButtonText"][this.lang]
    }

    get muteAllModalTitle() {
        return this.jsonData["muteAllModalTitle"][this.lang]
    }

    get muteAllModalText() {
        return this.jsonData["muteAllModalText"][this.lang]
    }

    get muteAllModalCloseButtonText() {
        return this.jsonData["muteAllModalCloseButtonText"][this.lang]
    }

    get muteAllModalContinueButtonText() {
        return this.jsonData["muteAllModalContinueButtonText"][this.lang]
    }
}

class SettingsTabBrandingImpl extends BrandingData implements SettingsTabBranding {
    _settingsAreaRowColor!: string;
    _timezonePickerRowMarginTop!: string;
    _thirdPartySettingsType!: string;
    _thirdPartySettingsKoelnmesse!: ThirdPartySettingsKoelnmesseBranding;

    get settingsAreaRowColor() {
        return this.jsonData["settingsAreaRowColor"]
    }

    get timezonePickerRowMarginTop() {
        return this.jsonData["timezonePickerRowMarginTop"]
    }

    get thirdPartySettingsType() {
        return this.jsonData["thirdPartySettingsType"]
    }

    get thirdPartySettingsKoelnmesse() {
        if (!this._thirdPartySettingsKoelnmesse) {
            this._thirdPartySettingsKoelnmesse = new ThirdPartySettingsKoelnmesseBrandingImpl(this.jsonData["thirdPartySettingsKoelnmesse"], this.lang)
            this.subBrandings.push(this._thirdPartySettingsKoelnmesse as any as BrandingData)
        }
        return this._thirdPartySettingsKoelnmesse
    }
}

class ThirdPartySettingsKoelnmesseBrandingImpl extends BrandingData implements ThirdPartySettingsKoelnmesseBranding {
    _settingsUrl!: string;
    _apiKey!: string;
    _title!: LocalizedString;
    _changePassword!: LocalizedString;
    _changePasswordIconUrl!: string;
    _wrongPassword!: LocalizedString;
    _passwordSame!: LocalizedString;
    _newPasswordNotSame!: LocalizedString;
    _passwordChangeSuccess!: LocalizedString;
    _genericError!: LocalizedString;
    _currentPassword!: LocalizedString;
    _newPassword!: LocalizedString;
    _newPasswordRepeat!: LocalizedString;
    _pleaseFillAllFields!: LocalizedString;
    _errorCodeMap!: LocalizedString;
    _termsOfUseButton!: LocalizedString;
    _termsOfUseButtonIconUrl!: string;
    _termsOfUseTitle!: LocalizedString;
    _termsOfUseShowButton!: LocalizedString;
    _termsOfUseInEffectSince!: LocalizedString;
    _termsOfUseAccpetAt!: LocalizedString;

    get settingsUrl() {
        return this.jsonData["settingsUrl"]
    }

    get apiKey() {
        return this.jsonData["apiKey"]
    }

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get changePassword() {
        return this.jsonData["changePassword"][this.lang]
    }

    get changePasswordIconUrl() {
        return this.jsonData["changePasswordIconUrl"]
    }

    get wrongPassword() {
        return this.jsonData["wrongPassword"][this.lang]
    }

    get passwordSame() {
        return this.jsonData["passwordSame"][this.lang]
    }

    get newPasswordNotSame() {
        return this.jsonData["newPasswordNotSame"][this.lang]
    }

    get passwordChangeSuccess() {
        return this.jsonData["passwordChangeSuccess"][this.lang]
    }

    get genericError() {
        return this.jsonData["genericError"][this.lang]
    }

    get currentPassword() {
        return this.jsonData["currentPassword"][this.lang]
    }

    get newPassword() {
        return this.jsonData["newPassword"][this.lang]
    }

    get newPasswordRepeat() {
        return this.jsonData["newPasswordRepeat"][this.lang]
    }

    get pleaseFillAllFields() {
        return this.jsonData["pleaseFillAllFields"][this.lang]
    }

    get errorCodeMap() {
        return this.jsonData["errorCodeMap"][this.lang]
    }

    get termsOfUseButton() {
        return this.jsonData["termsOfUseButton"][this.lang]
    }

    get termsOfUseButtonIconUrl() {
        return this.jsonData["termsOfUseButtonIconUrl"]
    }

    get termsOfUseTitle() {
        return this.jsonData["termsOfUseTitle"][this.lang]
    }

    get termsOfUseShowButton() {
        return this.jsonData["termsOfUseShowButton"][this.lang]
    }

    get termsOfUseInEffectSince() {
        return this.jsonData["termsOfUseInEffectSince"][this.lang]
    }

    get termsOfUseAccpetAt() {
        return this.jsonData["termsOfUseAccpetAt"][this.lang]
    }
}

class MySchedulePageBrandingImpl extends BrandingData implements MySchedulePageBranding {
    _myScheduleTitleColor!: ColorString;
    _privateEventColors!: ColorBranding;
    _openEventColors!: ColorBranding;
    _myFairPageEventColors!: ColorBranding;
    _todayButtonTitle!: LocalizedString;
    _dropdownFilterTitle!: LocalizedString;
    _dropdownFilterLiveSession!: LocalizedString;
    _dropdownFilterMeeting!: LocalizedString;
    _defaultStartDate!: string;
    _noItemsText!: LocalizedString;

    get myScheduleTitleColor() {
        return this.jsonData["myScheduleTitleColor"]
    }

    get privateEventColors() {
        if (!this._privateEventColors) {
            this._privateEventColors = new ColorBrandingImpl(this.jsonData["privateEventColors"], this.lang)
            this.subBrandings.push(this._privateEventColors as any as BrandingData)
        }
        return this._privateEventColors
    }

    get openEventColors() {
        if (!this._openEventColors) {
            this._openEventColors = new ColorBrandingImpl(this.jsonData["openEventColors"], this.lang)
            this.subBrandings.push(this._openEventColors as any as BrandingData)
        }
        return this._openEventColors
    }

    get myFairPageEventColors() {
        if (!this._myFairPageEventColors) {
            this._myFairPageEventColors = new ColorBrandingImpl(this.jsonData["myFairPageEventColors"], this.lang)
            this.subBrandings.push(this._myFairPageEventColors as any as BrandingData)
        }
        return this._myFairPageEventColors
    }

    get todayButtonTitle() {
        return this.jsonData["todayButtonTitle"][this.lang]
    }

    get dropdownFilterTitle() {
        return this.jsonData["dropdownFilterTitle"][this.lang]
    }

    get dropdownFilterLiveSession() {
        return this.jsonData["dropdownFilterLiveSession"][this.lang]
    }

    get dropdownFilterMeeting() {
        return this.jsonData["dropdownFilterMeeting"][this.lang]
    }

    get defaultStartDate() {
        return this.jsonData["defaultStartDate"]
    }

    get noItemsText() {
        return this.jsonData["noItemsText"][this.lang]
    }
}

class ColorBrandingImpl extends BrandingData implements ColorBranding {
    _backgroundColor!: ColorString;
    _hoverColor!: ColorString;
    _textColor!: ColorString;
    _border!: string;
    _borderLeft!: string;

    get backgroundColor() {
        return this.jsonData["backgroundColor"]
    }

    get hoverColor() {
        return this.jsonData["hoverColor"]
    }

    get textColor() {
        return this.jsonData["textColor"]
    }

    get border() {
        return this.jsonData["border"]
    }

    get borderLeft() {
        return this.jsonData["borderLeft"]
    }
}

class JiraSupportDeskDataImpl extends BrandingData implements JiraSupportDeskData {
    _visible!: boolean;
    _key!: string;

    get visible() {
        return this.jsonData["visible"]
    }

    get key() {
        return this.jsonData["key"]
    }
}

class ConversationEntryBrandingImpl extends BrandingData implements ConversationEntryBranding {
    _conversationEntryTextColor!: string;
    _unreadMarkerBgColor!: string;

    get conversationEntryTextColor() {
        return this.jsonData["conversationEntryTextColor"]
    }

    get unreadMarkerBgColor() {
        return this.jsonData["unreadMarkerBgColor"]
    }
}

class TrackingBrandingImpl extends BrandingData implements TrackingBranding {
    _detailpage!: boolean;
    _showroom!: boolean;
    _calendarEntry!: boolean;
    _interest!: boolean;
    _recommend!: boolean;
    _lounge!: boolean;
    _links!: boolean;
    _media!: boolean;
    _stream!: boolean;
    _schedule!: boolean;
    _coupon!: boolean;
    _press!: boolean;
    _magazine!: boolean;
    _adr1!: boolean;
    _adr2!: boolean;
    _adr3!: boolean;
    _zipCode!: boolean;
    _city!: boolean;
    _offering!: boolean;
    _lookingfor!: boolean;
    _showVisitorsTab!: boolean;
    _showLoungeTab!: boolean;
    _showGuestsSelect!: boolean;
    _showVisitorsSelect!: boolean;
    _showLeadsSelect!: boolean;
    _showContactsSelect!: boolean;
    _showDbSelect!: boolean;
    _showReportTab!: boolean;
    _enableDownloadVisitors!: boolean;
    _enableDownloadStatistics!: boolean;

    get detailpage() {
        return this.jsonData["detailpage"]
    }

    get showroom() {
        return this.jsonData["showroom"]
    }

    get calendarEntry() {
        return this.jsonData["calendarEntry"]
    }

    get interest() {
        return this.jsonData["interest"]
    }

    get recommend() {
        return this.jsonData["recommend"]
    }

    get lounge() {
        return this.jsonData["lounge"]
    }

    get links() {
        return this.jsonData["links"]
    }

    get media() {
        return this.jsonData["media"]
    }

    get stream() {
        return this.jsonData["stream"]
    }

    get schedule() {
        return this.jsonData["schedule"]
    }

    get coupon() {
        return this.jsonData["coupon"]
    }

    get press() {
        return this.jsonData["press"]
    }

    get magazine() {
        return this.jsonData["magazine"]
    }

    get adr1() {
        return this.jsonData["adr1"]
    }

    get adr2() {
        return this.jsonData["adr2"]
    }

    get adr3() {
        return this.jsonData["adr3"]
    }

    get zipCode() {
        return this.jsonData["zipCode"]
    }

    get city() {
        return this.jsonData["city"]
    }

    get offering() {
        return this.jsonData["offering"]
    }

    get lookingfor() {
        return this.jsonData["lookingfor"]
    }

    get showVisitorsTab() {
        return this.jsonData["showVisitorsTab"]
    }

    get showLoungeTab() {
        return this.jsonData["showLoungeTab"]
    }

    get showGuestsSelect() {
        return this.jsonData["showGuestsSelect"]
    }

    get showVisitorsSelect() {
        return this.jsonData["showVisitorsSelect"]
    }

    get showLeadsSelect() {
        return this.jsonData["showLeadsSelect"]
    }

    get showContactsSelect() {
        return this.jsonData["showContactsSelect"]
    }

    get showDbSelect() {
        return this.jsonData["showDbSelect"]
    }

    get showReportTab() {
        return this.jsonData["showReportTab"]
    }

    get enableDownloadVisitors() {
        return this.jsonData["enableDownloadVisitors"]
    }

    get enableDownloadStatistics() {
        return this.jsonData["enableDownloadStatistics"]
    }
}

class CSVExportBrandingImpl extends BrandingData implements CSVExportBranding {
    _firstName!: LocalizedString;
    _lastName!: LocalizedString;
    _company!: LocalizedString;
    _jobTitle!: LocalizedString;
    _adr1!: LocalizedString;
    _adr2!: LocalizedString;
    _adr3!: LocalizedString;
    _zipCode!: LocalizedString;
    _city!: LocalizedString;
    _country!: LocalizedString;
    _email!: LocalizedString;
    _phone!: LocalizedString;
    _interests!: LocalizedString;
    _offering!: LocalizedString;
    _lookingfor!: LocalizedString;
    _optIn!: LocalizedString;
    _detailPage!: LocalizedString;
    _showroom!: LocalizedString;
    _requestedMeeting!: LocalizedString;
    _registeredInterest!: LocalizedString;
    _recommendation!: LocalizedString;
    _virtualCafe!: LocalizedString;
    _virtualSpace!: LocalizedString;
    _link!: LocalizedString;
    _media!: LocalizedString;
    _livestream!: LocalizedString;
    _conferenceSlot!: LocalizedString;
    _goodieBag!: LocalizedString;
    _lastInteraction!: LocalizedString;
    _lastLogin!: LocalizedString;
    _markedBy!: LocalizedString;
    _markedAt!: LocalizedString;
    _press!: LocalizedString;
    _magazine!: LocalizedString;

    get firstName() {
        return this.jsonData["firstName"][this.lang]
    }

    get lastName() {
        return this.jsonData["lastName"][this.lang]
    }

    get company() {
        return this.jsonData["company"][this.lang]
    }

    get jobTitle() {
        return this.jsonData["jobTitle"][this.lang]
    }

    get adr1() {
        return this.jsonData["adr1"][this.lang]
    }

    get adr2() {
        return this.jsonData["adr2"][this.lang]
    }

    get adr3() {
        return this.jsonData["adr3"][this.lang]
    }

    get zipCode() {
        return this.jsonData["zipCode"][this.lang]
    }

    get city() {
        return this.jsonData["city"][this.lang]
    }

    get country() {
        return this.jsonData["country"][this.lang]
    }

    get email() {
        return this.jsonData["email"][this.lang]
    }

    get phone() {
        return this.jsonData["phone"][this.lang]
    }

    get interests() {
        return this.jsonData["interests"][this.lang]
    }

    get offering() {
        return this.jsonData["offering"][this.lang]
    }

    get lookingfor() {
        return this.jsonData["lookingfor"][this.lang]
    }

    get optIn() {
        return this.jsonData["optIn"][this.lang]
    }

    get detailPage() {
        return this.jsonData["detailPage"][this.lang]
    }

    get showroom() {
        return this.jsonData["showroom"][this.lang]
    }

    get requestedMeeting() {
        return this.jsonData["requestedMeeting"][this.lang]
    }

    get registeredInterest() {
        return this.jsonData["registeredInterest"][this.lang]
    }

    get recommendation() {
        return this.jsonData["recommendation"][this.lang]
    }

    get virtualCafe() {
        return this.jsonData["virtualCafe"][this.lang]
    }

    get virtualSpace() {
        return this.jsonData["virtualSpace"][this.lang]
    }

    get link() {
        return this.jsonData["link"][this.lang]
    }

    get media() {
        return this.jsonData["media"][this.lang]
    }

    get livestream() {
        return this.jsonData["livestream"][this.lang]
    }

    get conferenceSlot() {
        return this.jsonData["conferenceSlot"][this.lang]
    }

    get goodieBag() {
        return this.jsonData["goodieBag"][this.lang]
    }

    get lastInteraction() {
        return this.jsonData["lastInteraction"][this.lang]
    }

    get lastLogin() {
        return this.jsonData["lastLogin"][this.lang]
    }

    get markedBy() {
        return this.jsonData["markedBy"][this.lang]
    }

    get markedAt() {
        return this.jsonData["markedAt"][this.lang]
    }

    get press() {
        return this.jsonData["press"][this.lang]
    }

    get magazine() {
        return this.jsonData["magazine"][this.lang]
    }
}

class CrsTimePickerBrandingImpl extends BrandingData implements CrsTimePickerBranding {
    _hoursLabel!: LocalizedString;
    _minutesLabel!: LocalizedString;

    get hoursLabel() {
        return this.jsonData["hoursLabel"][this.lang]
    }

    get minutesLabel() {
        return this.jsonData["minutesLabel"][this.lang]
    }
}

class ContextMenuBrandingImpl extends BrandingData implements ContextMenuBranding {
    _contextMenuItemsVisibility!: ContextMenuItem[];
    _appDownloadEnabled!: boolean;
    _contextBackgroundColor!: ColorString;
    _contextBackgroundColorOnHover!: ColorString;
    _appDownloadButtonText!: LocalizedString;

    get contextMenuItemsVisibility() {
        if (!this._contextMenuItemsVisibility) {
            this._contextMenuItemsVisibility = []
            for (const arrayElement of this.jsonData["contextMenuItemsVisibility"]) {
                const arrayObject = new ContextMenuItemImpl(arrayElement, this.lang)
                this.contextMenuItemsVisibility.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._contextMenuItemsVisibility
    }

    get appDownloadEnabled() {
        return this.jsonData["appDownloadEnabled"]
    }

    get contextBackgroundColor() {
        return this.jsonData["contextBackgroundColor"]
    }

    get contextBackgroundColorOnHover() {
        return this.jsonData["contextBackgroundColorOnHover"]
    }

    get appDownloadButtonText() {
        return this.jsonData["appDownloadButtonText"][this.lang]
    }
}

class ContextMenuItemImpl extends BrandingData implements ContextMenuItem {
    _title!: string;
    _visible!: boolean;

    get title() {
        return this.jsonData["title"]
    }

    get visible() {
        return this.jsonData["visible"]
    }
}

class DropdownStyleImpl extends BrandingData implements DropdownStyle {
    _primaryColor!: string;
    _primary25Color!: string;
    _primary50Color!: string;
    _primary75Color!: string;

    get primaryColor() {
        return this.jsonData["primaryColor"]
    }

    get primary25Color() {
        return this.jsonData["primary25Color"]
    }

    get primary50Color() {
        return this.jsonData["primary50Color"]
    }

    get primary75Color() {
        return this.jsonData["primary75Color"]
    }
}

class IFrameTestPageContentBrandingImpl extends BrandingData implements IFrameTestPageContentBranding {
    _iframeInfo1!: LocalizedString;
    _iframeInfo2!: LocalizedString;

    get iframeInfo1() {
        return this.jsonData["iframeInfo1"][this.lang]
    }

    get iframeInfo2() {
        return this.jsonData["iframeInfo2"][this.lang]
    }
}

class GlobalSearchResultPageBrandingImpl extends BrandingData implements GlobalSearchResultPageBranding {
    _pageTitle!: LocalizedString;
    _dropdownPlaceholder!: LocalizedString;
    _resetBtnTitle!: LocalizedString;
    _dropdownOptionColor!: ColorString;
    _dropdownPlaceholderDefaultColor!: ColorString;
    _dropdownPlaceholderCustomColor!: ColorString;
    _dropdownBorderColor!: ColorString;
    _resetBtnColor!: ColorString;
    _resetBtnBgColor!: ColorString;
    _resetBtnBorder!: ColorString;
    _searchEntities!: SearchEntityConfig[];
    _exhibitorEntityTitle!: LocalizedString;
    _productEntityTitle!: LocalizedString;
    _trademarkEntityTitle!: LocalizedString;
    _networkingUserEntityTitle!: LocalizedString;
    _personEntityTitle!: LocalizedString;
    _newsEntityTitle!: LocalizedString;
    _jobofferEntityTitle!: LocalizedString;
    _eventdateEntityTitle!: LocalizedString;
    _couponEntityTitle!: LocalizedString;
    _categoryEntityTitle!: LocalizedString;

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get dropdownPlaceholder() {
        return this.jsonData["dropdownPlaceholder"][this.lang]
    }

    get resetBtnTitle() {
        return this.jsonData["resetBtnTitle"][this.lang]
    }

    get dropdownOptionColor() {
        return this.jsonData["dropdownOptionColor"]
    }

    get dropdownPlaceholderDefaultColor() {
        return this.jsonData["dropdownPlaceholderDefaultColor"]
    }

    get dropdownPlaceholderCustomColor() {
        return this.jsonData["dropdownPlaceholderCustomColor"]
    }

    get dropdownBorderColor() {
        return this.jsonData["dropdownBorderColor"]
    }

    get resetBtnColor() {
        return this.jsonData["resetBtnColor"]
    }

    get resetBtnBgColor() {
        return this.jsonData["resetBtnBgColor"]
    }

    get resetBtnBorder() {
        return this.jsonData["resetBtnBorder"]
    }

    get searchEntities() {
        if (!this._searchEntities) {
            this._searchEntities = []
            for (const arrayElement of this.jsonData["searchEntities"]) {
                const arrayObject = new SearchEntityConfigImpl(arrayElement, this.lang)
                this.searchEntities.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._searchEntities
    }

    get exhibitorEntityTitle() {
        return this.jsonData["exhibitorEntityTitle"][this.lang]
    }

    get productEntityTitle() {
        return this.jsonData["productEntityTitle"][this.lang]
    }

    get trademarkEntityTitle() {
        return this.jsonData["trademarkEntityTitle"][this.lang]
    }

    get networkingUserEntityTitle() {
        return this.jsonData["networkingUserEntityTitle"][this.lang]
    }

    get personEntityTitle() {
        return this.jsonData["personEntityTitle"][this.lang]
    }

    get newsEntityTitle() {
        return this.jsonData["newsEntityTitle"][this.lang]
    }

    get jobofferEntityTitle() {
        return this.jsonData["jobofferEntityTitle"][this.lang]
    }

    get eventdateEntityTitle() {
        return this.jsonData["eventdateEntityTitle"][this.lang]
    }

    get couponEntityTitle() {
        return this.jsonData["couponEntityTitle"][this.lang]
    }

    get categoryEntityTitle() {
        return this.jsonData["categoryEntityTitle"][this.lang]
    }
}

class SearchEntityConfigImpl extends BrandingData implements SearchEntityConfig {
    _id!: string;
    _title!: LocalizedString;
    _entityType!: "eventdate" | "person" | "organization" | "product" | "trademark" | "news" | "category" | "customcategory" | "coupon" | "networking_user" | "sotuser" | "event" | "country" | "joboffer" | "stand_registration";

    get id() {
        return this.jsonData["id"]
    }

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get entityType() {
        return this.jsonData["entityType"]
    }
}

class VideoPlayerBrandingImpl extends BrandingData implements VideoPlayerBranding {
    _pipDoubleClickHint!: LocalizedString;
    _pipStreamErrorMessage!: LocalizedString;
    _videoPlayerStreamErrorMessage!: LocalizedString;
    _videoPlayerPlaylistErrorMessage!: LocalizedString;
    _dummyVideoPlayerNoAccessMessage!: LocalizedString;
    _closeText!: LocalizedString;
    _resizeButtonHint!: LocalizedString;
    _pipHintOnError!: LocalizedString;
    _reloadButtonText!: LocalizedString;

    get pipDoubleClickHint() {
        return this.jsonData["pipDoubleClickHint"][this.lang]
    }

    get pipStreamErrorMessage() {
        return this.jsonData["pipStreamErrorMessage"][this.lang]
    }

    get videoPlayerStreamErrorMessage() {
        return this.jsonData["videoPlayerStreamErrorMessage"][this.lang]
    }

    get videoPlayerPlaylistErrorMessage() {
        return this.jsonData["videoPlayerPlaylistErrorMessage"][this.lang]
    }

    get dummyVideoPlayerNoAccessMessage() {
        return this.jsonData["dummyVideoPlayerNoAccessMessage"][this.lang]
    }

    get closeText() {
        return this.jsonData["closeText"][this.lang]
    }

    get resizeButtonHint() {
        return this.jsonData["resizeButtonHint"][this.lang]
    }

    get pipHintOnError() {
        return this.jsonData["pipHintOnError"][this.lang]
    }

    get reloadButtonText() {
        return this.jsonData["reloadButtonText"][this.lang]
    }
}

class VideoPageContentBrandingImpl extends BrandingData implements VideoPageContentBranding {
    _joinBreakoutMessage!: LocalizedString;
    _joinBreakoutButtonText!: LocalizedString;
    _joinBreakoutButtonColor!: ColorString;
    _joinBreakoutButtonTextColor!: ColorString;
    _participantsCounterText!: LocalizedString;
    _comingUpText!: LocalizedString;
    _minutesBeforeSwitchingToTheNextEvent!: number;
    _pollingTime!: number;

    get joinBreakoutMessage() {
        return this.jsonData["joinBreakoutMessage"][this.lang]
    }

    get joinBreakoutButtonText() {
        return this.jsonData["joinBreakoutButtonText"][this.lang]
    }

    get joinBreakoutButtonColor() {
        return this.jsonData["joinBreakoutButtonColor"]
    }

    get joinBreakoutButtonTextColor() {
        return this.jsonData["joinBreakoutButtonTextColor"]
    }

    get participantsCounterText() {
        return this.jsonData["participantsCounterText"][this.lang]
    }

    get comingUpText() {
        return this.jsonData["comingUpText"][this.lang]
    }

    get minutesBeforeSwitchingToTheNextEvent() {
        return this.jsonData["minutesBeforeSwitchingToTheNextEvent"]
    }

    get pollingTime() {
        return this.jsonData["pollingTime"]
    }
}

class SocialMediaIconsBrandingImpl extends BrandingData implements SocialMediaIconsBranding {
    _useOriginalSocialMediaIcons!: boolean;

    get useOriginalSocialMediaIcons() {
        return this.jsonData["useOriginalSocialMediaIcons"]
    }
}

class AvatarBrandingImpl extends BrandingData implements AvatarBranding {
    _showAvatarRoleBorder!: boolean;
    _borderColorSpeaker!: string;
    _borderColorExhibitor!: string;

    get showAvatarRoleBorder() {
        return this.jsonData["showAvatarRoleBorder"]
    }

    get borderColorSpeaker() {
        return this.jsonData["borderColorSpeaker"]
    }

    get borderColorExhibitor() {
        return this.jsonData["borderColorExhibitor"]
    }
}

class TileViewConfigImpl extends BrandingData implements TileViewConfig {
    _page!: PagesUsingTileView;
    _minRequiredWidth!: number;

    get page() {
        return this.jsonData["page"]
    }

    get minRequiredWidth() {
        return this.jsonData["minRequiredWidth"]
    }
}

class HallPlanPageBrandingImpl extends BrandingData implements HallPlanPageBranding {
    _hallPlanPageDrawerExhibitorsTitle!: LocalizedString;
    _hallPlanPageDrawerLiveSessionsTitle!: LocalizedString;
    _hallPlanPageDrawerProductsTitle!: LocalizedString;
    _hallPlanPageDrawerExhibitorsTitleColor!: ColorString;
    _hallSelectorPlaceholder!: LocalizedString;
    _hallPlanPageSelectedStandTitle!: LocalizedString;
    _hallPlanPageSelectBadgePlaceholder!: LocalizedString;
    _hallPlanPageNoExhibitorsData!: LocalizedString;
    _hallPlanPageNoDataFoundForStandDetails!: LocalizedString;
    _hallPlanPageMainExhibitorLabel!: LocalizedString;
    _hallPlanPageCoExhibitorLabel!: LocalizedString;
    _bookmarkIconColor!: ColorString;
    _showMoreButtonColor!: ColorString;
    _badgeDropDownVisible!: boolean;
    _selectMarkerColor!: ColorString;
    _selectMarkerOpacity!: string;
    _selectMarkerWidth!: string;

    get hallPlanPageDrawerExhibitorsTitle() {
        return this.jsonData["hallPlanPageDrawerExhibitorsTitle"][this.lang]
    }

    get hallPlanPageDrawerLiveSessionsTitle() {
        return this.jsonData["hallPlanPageDrawerLiveSessionsTitle"][this.lang]
    }

    get hallPlanPageDrawerProductsTitle() {
        return this.jsonData["hallPlanPageDrawerProductsTitle"][this.lang]
    }

    get hallPlanPageDrawerExhibitorsTitleColor() {
        return this.jsonData["hallPlanPageDrawerExhibitorsTitleColor"]
    }

    get hallSelectorPlaceholder() {
        return this.jsonData["hallSelectorPlaceholder"][this.lang]
    }

    get hallPlanPageSelectedStandTitle() {
        return this.jsonData["hallPlanPageSelectedStandTitle"][this.lang]
    }

    get hallPlanPageSelectBadgePlaceholder() {
        return this.jsonData["hallPlanPageSelectBadgePlaceholder"][this.lang]
    }

    get hallPlanPageNoExhibitorsData() {
        return this.jsonData["hallPlanPageNoExhibitorsData"][this.lang]
    }

    get hallPlanPageNoDataFoundForStandDetails() {
        return this.jsonData["hallPlanPageNoDataFoundForStandDetails"][this.lang]
    }

    get hallPlanPageMainExhibitorLabel() {
        return this.jsonData["hallPlanPageMainExhibitorLabel"][this.lang]
    }

    get hallPlanPageCoExhibitorLabel() {
        return this.jsonData["hallPlanPageCoExhibitorLabel"][this.lang]
    }

    get bookmarkIconColor() {
        return this.jsonData["bookmarkIconColor"]
    }

    get showMoreButtonColor() {
        return this.jsonData["showMoreButtonColor"]
    }

    get badgeDropDownVisible() {
        return this.jsonData["badgeDropDownVisible"]
    }

    get selectMarkerColor() {
        return this.jsonData["selectMarkerColor"]
    }

    get selectMarkerOpacity() {
        return this.jsonData["selectMarkerOpacity"]
    }

    get selectMarkerWidth() {
        return this.jsonData["selectMarkerWidth"]
    }
}
