import { useCallback, useEffect, useMemo, useState } from "react"
import * as React from "react"
import { Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import Select, { ActionMeta, OptionTypeBase, ValueType } from "react-select"
import styled from "styled-components"
import { ModalType, SotUser } from "../backendServices/Types"
import branding from "../branding/branding"
import { NextPageLoader } from "../communicationArea/CommunicationArea"
import { CommunicationModals } from "../communicationArea/CommunicationOptions"
import { useAppState } from "../globalStates/AppState"
import { useFavoriteState } from "../globalStates/Favorites"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import Breadcrumb from "../navigationArea/Breadcrumb"
import {
    businessAreaAllPageRoute,
    businessAreaBuyersPageRoute,
    businessAreaPageRoute,
    businessAreaSellersPageRoute,
    editMyProfilePageRoute
} from "../navigationArea/RoutePaths"
import TopBar from "../navigationArea/TopBar"
import { calcBreadcrumbLocations } from "../tracking/RouteTracker"
import BackendError from "../ui/BackendError"
import CenteredLoader from "../ui/CenteredLoader"
import CrsTabs, { ActionTypes, ViewMode } from "../ui/CrsTabs"
import { IconBookmark, IconBookmarkFilled, IconReset } from "../ui/Icons"
import { ContentScrollContainer } from "../ui/ScrollContainer"
import SearchBar from "../ui/SearchBar"
import SpeakersTilesLayout from "../ui/SpeakersTilesLayout"
import { Staff, StaffEntry, StaffType } from "../ui/StaffEntry"
import useWindowDimensions from "../ui/WindowDimensionsHook"
import { device, MobileVersionContainer, DesktopVersionContainer } from "../utils/Device"
import TopBannerSharedState from "./advertisingBanner/TopBannerSharedState"
import ConfirmationModal from "../ui/modals/ConfirmationModal"
import GuestUserBanner from "./guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "./guestUserBanner/GuestUserBannerSharedState"
import { getCountries } from "./myprofile/CountrySwitcherHelper"
import { SelectThemeCustom } from "./myprofile/EditMyProfileLayout"
import EmptyTile from "./reception/EmptyTile"
import RelevantTilesAndListView from "./networkingGraph/RelevantTilesAndListView"
import { Topic, viewType as NetworkingViewType } from "./networkingGraph/NetworkingPageContentBranding"
import { getTileViewMinReqWidth, PagesUsingTileView } from "../globalStates/TileViewConfig"
import { getUserDefaultViewMode } from "../ui/CrsTabs"
import { ContactItem, getInterest, loadUsersData } from "../backendServices/SeriesOfTopicsUserServices"
import InView from "react-intersection-observer"
import { debounce } from "lodash"

enum viewType {
    SELLERS = 1,
    BUYERS = 2,
    ALL = 3
}

enum viewModeType {
    LIST = 1,
    TILES = 2
}

const FilterConfig = {
    1: "OFFERING",
    2: "LOOKINGFOR",
    3: "ALL"
}

interface GroupCategoriesType {
    id: string
    categories: Array<{ label: string; value: string }>
}

const PageRootElement = styled.div<{ viewMode: viewModeType }>`
    position: relative;
    width: 100%;
    height: 100%;
    font-family: ${branding.font1};

    & .ScrollbarsCustom-Content {
        ${(props) => (props.viewMode === viewModeType.LIST ? "padding: 0 !important" : "")};
    }
`

const ListLayoutContainer = styled.div`
    padding: 0 25px;

    @media ${device.mobile} {
        padding-left: 20px;
        padding-right: 15px;
    }
`

const HeaderSectionRoot = styled.div<{ showShadow?: boolean; visible: boolean }>`
    position: relative;
    font-family: ${branding.font1};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "initial")};
    z-index: 6;

    display: ${(props) => (props.visible ? "block" : "none !important")};

    @media ${device.mobile} {
        box-shadow: ${(props) => (props.showShadow && props.visible ? branding.primaryScrollDarkShadowTTB : "initial")};
    }
`

const SearchDropdownRoot = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    font-size: 12px;
`

const FilterBarRoot = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 25px;
    flex-wrap: wrap;

    @media ${device.mobile} {
        padding-left: 20px;
        padding-right: 15px;
        gap: 17px;
    }
`

const FilterButton = styled.div`
    height: 38px;
    width: 150px;
    border: 1px solid ${branding.businessAreaPageContent.filterButtonBgColor ?? "#000"};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: ${branding.businessAreaPageContent.filterButtonBgColor ?? "#FFF"};
    color: ${branding.businessAreaPageContent.filterButtonColor ?? "#000"};
    font-family: ${branding.font1};
    font-size: 12px;
    font-weight: normal;
    border-radius: 5px;

    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }

    @media ${device.mobile} {
        width: 43vw;
    }
`

const ResetFilterButton = styled.div`
    display: flex;
    font-family: ${branding.font1};
    height: 38px;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border: 1px;
    border-style: solid;
    border-color: ${branding.businessAreaPageContent.filterButtonBgColor ?? "#000"};
    color: ${branding.businessAreaPageContent.filterButtonBgColor ?? "#000"};
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;

    @media ${device.mobile} {
        width: 43vw;
        margin-top: -15px;
    }
`

const FilterResetIconSpan = styled.span`
    margin-left: 10px;
`

const FilterResetTextSpan = styled.span`
    position: relative;
    width: auto;
    text-align: center;
    padding: 0.3rem 1rem;
    display: inline-block;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const styledSelectStyles = {
    control: (provided: any, state: any) => ({
        border: state.isFocused ? "1px solid #000" : "1px solid #c9c9c9",
        borderRadius: "5px",
        display: "flex"
    })
}

const StyledSelect = styled(Select)`
    width: 200px;
    font-size: 12px;
    z-index: 100;

    @media ${device.mobile} {
        width: 43vw;
    }
`

const BusinessAreaPageContent: React.FunctionComponent = (props) => {
    const appState = useAppState()
    const userLink = useLoggedInState()
    const loggedInUserId = userLink.user()?.profileId
    const history = useHistory()
    const langState = useLanguageState()
    const favoriteState = useFavoriteState()
    const strings = langState.getStrings()
    const lang = langState.getLanguage()
    const locations = calcBreadcrumbLocations(strings)

    // add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const { topBarRef, setTopBarRef, hideOnScroll, setHideOnScroll } = TopBannerSharedState()

    // switcher
    const itemsOrder = branding.businessAreaPageContent.crsTabsItemOrder

    const { isMobile } = useWindowDimensions()

    let viewTypeData: any = []

    itemsOrder.forEach((item: any) => {
        let newItem
        switch (item) {
            case "BUYERS":
                newItem = {
                    value: "buyers",
                    label: strings.businessAreaPageContent.firstMultiSwitcherItemText,
                    actions: [ActionTypes.BOOKMARK, ActionTypes.TILELIST, ActionTypes.TOGGLEFILTERS]
                }
                break
            case "SELLERS":
                newItem = {
                    value: "sellers",
                    label: strings.businessAreaPageContent.secondMultiSwitcherItemText,
                    actions: [ActionTypes.BOOKMARK, ActionTypes.TILELIST, ActionTypes.TOGGLEFILTERS]
                }
                break
            case "ALL":
                newItem = {
                    value: "all",
                    label: strings.businessAreaPageContent.relevantsMultiSwitcherItemText,
                    actions: [ActionTypes.BOOKMARK, ActionTypes.TILELIST, ActionTypes.TOGGLEFILTERS]
                }
                break
        }
        viewTypeData.push(newItem)
    })

    // data
    const numResultRows = 15
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState("")
    const [allUsers, setAllUsers] = useState<SotUser[]>([])
    const [allStaff, setAllStaff] = useState<Staff[] | undefined>()
    const [startResultRow, setStartResultRow] = useState(0)
    const [personCount, setPersonCount] = useState(0)
    const countries = useMemo(() => {
        return getCountries(lang).map((country) => {
            return { value: country.code, label: lang === "de" ? country.de : country.en }
        })
    }, [lang])

    // relevant tiles view
    const relevantsTabEnabled = branding.businessAreaPageContent.crsTabsItemOrder.includes("ALL")
    const [page, setPage] = useState(0)
    const [relevantUsers, setRelevantUsers] = useState<ContactItem[]>([])
    const [isLoader, setIsLoader] = useState(false)

    // filter data
    const groupCategories = branding.myProfilePageContent.offersNeedsCategories.filter((x) => x.id !== "default").map((x) => x.id)
    const [lookingforAndOfferingGroups, setLookingforAndOfferingGroups] = useState<Array<GroupCategoriesType>>()

    // filter values
    const [currentSearch, setCurrentSearch] = useState<LeadFinderSearch>(getSearchFromStorage(relevantsTabEnabled))

    const [filterDisabled, setFilterDisabled] = useState(true)
    const [clearIconClicked, setClearIconClicked] = useState(false)
    // eslint-disable-next-line
    const [lastSearchString, setLastSearchString] = useState(currentSearch[currentSearch.viewType]?.searchString || "")
    const [networkingSearch, setNetworkingSearch] = useState(currentSearch[currentSearch.viewType]?.searchString || "")

    function getInitialActiveItem(): string {
        let firstItem = itemsOrder[0].toLowerCase()
        let item = ""

        if (window.location.href.includes("#buyers")) {
            item = "buyers"
        } else if (window.location.href.includes("#sellers")) {
            item = "sellers"
        } else if (window.location.href.includes("#all") && relevantsTabEnabled) {
            item = "all"
        } else if (!appState.lastVisitedTab) {
            item = firstItem
        } else if (
            appState.lastVisitedTab &&
            (appState.lastVisitedTab === "buyers" ||
                appState.lastVisitedTab === "sellers" ||
                (relevantsTabEnabled && appState.lastVisitedTab === "all"))
        ) {
            item = appState.lastVisitedTab
        }
        if (item === "buyers") currentSearch.viewType = viewType.BUYERS
        else if (item === "sellers") currentSearch.viewType = viewType.SELLERS
        else currentSearch.viewType = viewType.ALL

        //setCurrentSearchViewType(currentSearch.viewType)

        return item.toString()
    }

    const [activeItem, setActiveItem] = useState<string>(getInitialActiveItem())

    const [currentSearchViewType, setCurrentSearchViewType] = useState<viewType>(currentSearch.viewType ?? viewType.ALL)

    const [filterReset, setFilterReset] = useState<boolean>(true)
    const windowSize = useWindowDimensions()

    // Topic filter
    let topicFilter = branding.networkingArea.topicFilterActive ? branding.networkingArea.topicFilter : null
    const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null)
    const [selectedTopicValue, setSelectedTopicValue] = useState<number | null>(null)

    const customWindowSizeForViewSwitch: number = getTileViewMinReqWidth(PagesUsingTileView.BUSSINES_AREA)

    const [filtersVisible, setFiltersVisible] = useState<boolean>(true)

    const breadcrumb = [{ to: locations[0], name: strings.sideIconBar.businessAreaMenuText }, getCurrentBreadcrumb()]

    function getCurrentBreadcrumb(): any {
        if (currentSearchViewType === viewType.BUYERS) {
            return { to: businessAreaBuyersPageRoute, name: strings.businessAreaPageContent.firstMultiSwitcherItemText }
        } else if (currentSearchViewType === viewType.SELLERS) {
            return { to: businessAreaSellersPageRoute, name: strings.businessAreaPageContent.secondMultiSwitcherItemText }
        } else {
            return { to: businessAreaAllPageRoute, name: strings.businessAreaPageContent.relevantsMultiSwitcherItemText }
        }
    }

    // Context menu (modals)
    const [modalType, setModalType] = useState<ModalType>("none")
    const [selectedUser, setSelectedUser] = useState()
    const menuCallback: (param: { modalType?: ModalType }) => void = ({ modalType }) => {
        if (modalType) {
            setModalType(modalType)
        }
    }

    /* #region  Helper methods */
    const onNextPageLoaderVisibilityChange = (isVisible: boolean) => {
        if (isVisible) setStartResultRow(startResultRow + numResultRows)
    }

    const isMoreDataToLoad = useCallback(() => {
        return personCount - numResultRows > startResultRow
    }, [personCount, startResultRow])

    const getFilter = (categoryGroups: { [key: string]: OptionTypeBase[] }, prefix: string) => {
        return Object.values(categoryGroups)
            .flat()
            .map((cat) => prefix + cat.value)
            .join(", ")
    }

    const onToggleViewMode = () => {
        const currentView = currentSearch.viewMode
        setCurrentSearch({
            ...currentSearch,
            viewMode: currentView === viewModeType.LIST ? viewModeType.TILES : viewModeType.LIST
        })
    }

    useEffect(() => {
        let defaultView: any = getUserDefaultViewMode(appState)

        setCurrentSearch({
            ...currentSearch,
            viewMode: defaultView === viewModeType.LIST ? viewModeType.TILES : viewModeType.LIST
        })

        // eslint-disable-next-line
    }, [appState.lastVisitedTab, appState.currentItem])

    /* #endregion */

    function isSearchEmpty(): boolean {
        return (
            JSON.stringify(currentSearch[currentSearchViewType]) ===
            JSON.stringify({
                searchString: "",
                selectedOffers: {},
                selectedNeeds: {},
                selectedCountries: [],
                relevantCategoryFilter: null
            })
        )
    }

    function resetSearch() {
        setCurrentSearch({
            ...currentSearch,
            [currentSearchViewType]: {
                searchString: "",
                selectedOffers: {},
                selectedNeeds: {},
                selectedCountries: [],
                relevantCategoryFilter: null
            }
        })

        setFilterReset(true)
    }

    function updateBookmarkFilter() {
        setCurrentSearch({
            ...currentSearch,
            bookmarkFilter: !currentSearch.bookmarkFilter
        })
    }

    /* #region  UseEffects */

    useEffect(() => {
        setActiveItem(getInitialActiveItem())
        // eslint-disable-next-line
    }, [window.location])

    useEffect(() => {
        setCurrentSearch({
            ...currentSearch,
            viewType: currentSearchViewType
        })
        // eslint-disable-next-line
    }, [currentSearchViewType])

    const debouncedSearch = React.useMemo(
        () =>
            debounce((searchStr: string) => {
                setNetworkingSearch(searchStr)
            }, 800),
        // eslint-disable-next-line
        []
    )

    const handleSearchDebounced = useCallback(
        (value: string) => {
            debouncedSearch(value)
        },
        [debouncedSearch]
    )

    useEffect(() => {
        if (currentSearchViewType === viewType.ALL) {
            handleSearchDebounced(currentSearch[currentSearchViewType]?.searchString || "")
        }
        // eslint-disable-next-line
    }, [currentSearch])

    useEffect(() => {
        const selectedTopicStorageBusiness = JSON.parse(localStorage.getItem("selectedTopicStorageBusiness") || "{}")

        if (Object.keys(selectedTopicStorageBusiness).length > 0) {
            setSelectedTopic(selectedTopicStorageBusiness?.value || null)
        }
        // eslint-disable-next-line
    }, [])

    // Fetching interests to fill dropdowns
    useEffect(() => {
        appState.setCurrentMobileBreadcrumb(strings.sideIconBar.businessAreaMenuText)

        getInterest().then((res) => {
            if (res.content && res.content.lookingforandoffering) {
                let groupsTemp: Array<GroupCategoriesType> = []
                let isDefault = false
                if (groupCategories.length === 0) {
                    groupCategories.push("default")
                    isDefault = true
                }
                groupCategories.forEach((parent) => {
                    const children = isDefault
                        ? res.content.lookingforandoffering
                        : res.content.lookingforandoffering.filter((x: any) => x.parent === parent)
                    if (children && children.length > 0) {
                        const childrenTemp = children.map((cat: any, index: number) => {
                            return { label: cat.name, value: cat.id, key: parent + "__" + index }
                        })
                        groupsTemp.push({ id: parent, categories: childrenTemp })
                    }
                })
                setLookingforAndOfferingGroups(groupsTemp)
            }
        })
    }, [lang]) //eslint-disable-line

    useEffect(() => {
        setStartResultRow(0)
    }, [currentSearchViewType, currentSearch.bookmarkFilter]) //eslint-disable-line

    const buildFilterList = () => {
        let buySellCategoryFilter = ""
        let countryFilter = ""
        let relevantCategoryFilter = ""

        if (currentSearchViewType === viewType.BUYERS) {
            buySellCategoryFilter = getFilter(currentSearch[currentSearchViewType].selectedNeeds, "cat_")
            countryFilter = getFilter({ tmp: currentSearch[currentSearchViewType].selectedCountries }, "country_")
        }
        if (currentSearchViewType === viewType.SELLERS)
            buySellCategoryFilter = getFilter(currentSearch[currentSearchViewType].selectedOffers, "cat_")

        if (currentSearch[currentSearchViewType]?.relevantCategoryFilter) {
            relevantCategoryFilter = "cat_" + currentSearch[currentSearchViewType]?.relevantCategoryFilter?.value
        }

        let buyerSellerFilter = "sotucbt_" + FilterConfig[currentSearchViewType]
        const favorites = currentSearch.bookmarkFilter ? favoriteState.get("sotuser") : ""

        let entityFilter = "entity_sotu"
        if (currentSearch.bookmarkFilter && favorites) entityFilter = favorites

        return [
            entityFilter,
            buySellCategoryFilter,
            relevantCategoryFilter,
            buyerSellerFilter,
            countryFilter,
            currentSearch[currentSearchViewType]?.searchString
        ]
    }

    const getCurrentPath = (viewType: string): string => {
        switch (viewType) {
            case "buyers":
                return businessAreaBuyersPageRoute
            case "sellers":
                return businessAreaSellersPageRoute
            case "all":
                return businessAreaAllPageRoute
            default:
                return businessAreaPageRoute
        }
    }

    const startSearch = (startRow: number) => {
        putSearchToStorage(currentSearch)
        if (currentSearch[currentSearchViewType]) setLastSearchString(currentSearch[currentSearchViewType]!.searchString)

        const filterList = buildFilterList()

        let personParameter: any = {
            filterlist: filterList.join(", "),
            startresultrow: startRow,
            numresultrows: numResultRows,
            order: "lexic",
            lang: lang
        }

        setIsLoaded(false)
        if (currentSearch.bookmarkFilter && !favoriteState.get("sotuser")) {
            setAllUsers([])
            setPersonCount(0)
            setIsLoaded(true)
            return
        }

        loadUsersData(personParameter)
            .then((response) => {
                const resp = startRow === 0 ? response.users : allUsers.concat(response.users)
                setAllUsers(resp)
                setAllStaff(
                    resp.map((user) => {
                        return {
                            firstName: user.firstName,
                            lastName: user.lastName,
                            id: user.id,
                            type: StaffType.USER,
                            logoUrl: user.logoUrl,
                            position: user.position,
                            company: user.organization,
                            sotUserId: user.userId,
                            visible: true,
                            showroomStandby: false,
                            title: "",
                            categories: []
                        }
                    })
                )
                setPersonCount(response.count)
                setIsLoaded(true)
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setIsLoaded(true)
                setError(e.message)
            })
    }

    const onScroll = (scrollValues: any) => {
        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
            setHideOnScroll(scrollValues.scrollTop > 0)
        } else {
            setHideOnScroll(true)
        }
    }

    const startSearchDependency = startResultRow + currentSearchViewType + Number(currentSearch.bookmarkFilter) + lang
    useEffect(() => {
        startSearch(startResultRow)

        if (filterReset) {
            setFilterReset(false)
        }
    }, [startSearchDependency, filterReset]) //eslint-disable-line

    useEffect(() => {
        if (currentSearchViewType === viewType.ALL && !currentSearch[currentSearchViewType]?.relevantCategoryFilter) {
            return
        }

        if (clearIconClicked) {
            setClearIconClicked(false)
            return
        }
        setFilterDisabled(false)

        let allLFGroupsEmpty: boolean = true

        lookingforAndOfferingGroups?.forEach((group) => {
            if (
                (currentSearch[currentSearchViewType]?.selectedOffers[group.id] &&
                    currentSearch[currentSearchViewType]?.selectedOffers[group.id]?.length! > 0) ||
                (currentSearch[currentSearchViewType]?.selectedNeeds[group.id] &&
                    currentSearch[currentSearchViewType]?.selectedNeeds[group.id]?.length! > 0)
            ) {
                allLFGroupsEmpty = false
                return
            }
        })

        if (allLFGroupsEmpty) {
            const newSearch = { ...currentSearch }

            if (newSearch[currentSearchViewType] && newSearch[currentSearchViewType]!.selectedOffers) {
                newSearch[currentSearchViewType]!.selectedOffers = {}
            }

            if (newSearch[currentSearchViewType] && newSearch[currentSearchViewType]!.selectedNeeds) {
                newSearch[currentSearchViewType]!.selectedNeeds = {}
            }
        }
        startSearch(startResultRow)
    }, [JSON.stringify(currentSearch[1]), JSON.stringify(currentSearch[2]), JSON.stringify(currentSearch[3])]) //eslint-disable-line

    useEffect(() => {
        if (currentSearchViewType === viewType.ALL && !currentSearch[currentSearchViewType]?.relevantCategoryFilter) {
            setNetworkingSearch("")
        }
        // eslint-disable-next-line
    }, [currentSearchViewType])
    /* #endregion */

    /* #region  Main content */

    const adjustHeight =
        (filtersVisible ? (isMobile ? (currentSearchViewType === viewType.BUYERS ? 345 : 304) : 190) : 105) +
        (guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0) +
        (topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0)

    let content: JSX.Element | null
    if (error !== "") {
        content = (
            <div style={{ marginTop: "20%" }}>
                <BackendError />
            </div>
        )
    } else if (!isLoaded) {
        content = (
            <div style={{ marginTop: "15%" }}>
                <CenteredLoader />
            </div>
        )
    }
    if (
        isLoaded &&
        ((currentSearchViewType === viewType.ALL &&
            currentSearch[currentSearchViewType]?.relevantCategoryFilter &&
            personCount === 0) ||
            (currentSearchViewType !== viewType.ALL && personCount === 0))
    ) {
        content = (
            <div style={{ marginTop: "15%" }}>
                <EmptyTile header={strings.businessAreaPageContent.emptyResultText} bgColor="transparent" hideButton={true} />
            </div>
        )
    } else {
        content =
            currentSearchViewType === viewType.ALL && !currentSearch[currentSearchViewType]?.relevantCategoryFilter ? (
                <RelevantTilesAndListView
                    relevantUsers={relevantUsers}
                    setRelevantUsers={setRelevantUsers}
                    sotUsers={[]}
                    loggedInUserId={loggedInUserId}
                    isLoader={isLoader}
                    page={page}
                    searchString={networkingSearch}
                    topicFilter={selectedTopicValue}
                    setIsLoader={setIsLoader}
                    setPage={setPage}
                    subContentHeight={250}
                    isMatchActive={userLink.isMatchActive()}
                    currentViewType={NetworkingViewType.RELEVANT_TILES}
                    businessArea
                    setSelectedUser={setSelectedUser}
                    callback={menuCallback}
                    viewMode={currentSearch.viewMode === viewModeType.LIST ? ViewMode.LIST : ViewMode.TILES}
                    showOnlyBookmarks={currentSearch.bookmarkFilter}
                />
            ) : (
                <ContentScrollContainer
                    handleScroll={(e: any) => {
                        window.scrollTo(0, 0)
                        onScroll(e)
                    }}
                    adjustForHeaderWith={adjustHeight + "px"}
                >
                    {currentSearch.viewMode === viewModeType.LIST || windowSize.width < customWindowSizeForViewSwitch ? (
                        <ListLayoutContainer>
                            {allStaff &&
                                allStaff.map((user, index) => {
                                    return (
                                        <>
                                            <StaffEntry
                                                key={index}
                                                staff={user}
                                                organizationId={""}
                                                backOffice={false}
                                                leadFinder={true}
                                                setModalType={setModalType}
                                                setSelectedUser={setSelectedUser}
                                            />
                                        </>
                                    )
                                })}
                        </ListLayoutContainer>
                    ) : (
                        <div style={{ padding: "0px 25px" }}>
                            <SpeakersTilesLayout
                                speakersList={allUsers}
                                componentType="user"
                                setSelectedUser={setSelectedUser}
                                callback={menuCallback}
                                showContextMenuOnHover={true}
                            />
                        </div>
                    )}
                    {isMoreDataToLoad() && (
                        <InView threshold={0.1} onChange={onNextPageLoaderVisibilityChange}>
                            <NextPageLoader />
                        </InView>
                    )}
                </ContentScrollContainer>
            )
    }

    return (
        <PageRootElement viewMode={currentSearch.viewMode}>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar setRef={setTopBarRef} />
            <Breadcrumb breadcrumb={breadcrumb} fillBackground={true} />
            <CrsTabs
                tabItems={viewTypeData}
                activeItem={activeItem}
                onTabItemClicked={(e: string) => {
                    if (e === "buyers") currentSearch.viewType = viewType.BUYERS
                    else if (e === "sellers") currentSearch.viewType = viewType.SELLERS
                    else currentSearch.viewType = viewType.ALL

                    setActiveItem(e)
                    setCurrentSearch({
                        ...currentSearch,
                        viewType: e === "sellers" ? viewType.SELLERS : e === "buyers" ? viewType.BUYERS : viewType.ALL
                    })

                    setCurrentSearchViewType(currentSearch.viewType)

                    history.replace(getCurrentPath(e))
                    appState.setLastVisitedTab(e)
                }}
                responsive={true}
                viewMode={currentSearch.viewMode === viewModeType.LIST ? ViewMode.LIST : ViewMode.TILES}
                onViewModeToggle={() => onToggleViewMode()}
                bookmarkFilter={currentSearch.bookmarkFilter}
                setBookmarkFilter={() => updateBookmarkFilter()}
                filtersVisible={filtersVisible}
                setFiltersVisible={setFiltersVisible}
                pageUsingTileView={PagesUsingTileView.BUSSINES_AREA}
            />
            <HeaderSectionRoot showShadow={hideOnScroll} visible={filtersVisible}>
                {/* Searchbar */}
                <SearchDropdownRoot>
                    <FilterBarRoot>
                        <MobileVersionContainer style={{ width: "100%" }}>
                            <SearchBarComponent
                                currentSearch={currentSearch}
                                setCurrentSearch={setCurrentSearch}
                                strings={strings}
                                updateBookmarkFilter={updateBookmarkFilter}
                                currentSearchViewType={currentSearchViewType}
                            />
                        </MobileVersionContainer>

                        {/* Dropdown for topics */}
                        {branding.businessAreaPageContent.relevantTopicFilterVisible &&
                            currentSearchViewType === viewType.ALL &&
                            topicFilter?.length && (
                                <StyledSelect
                                    styles={styledSelectStyles}
                                    placeholder={strings.networkingArea.topicFilterPlaceholder}
                                    isMulti={false}
                                    isSearchable={true}
                                    isClearable={true}
                                    options={topicFilter}
                                    value={selectedTopic}
                                    onChange={(selectedTopic: Topic) => {
                                        if (selectedTopic) {
                                            setSelectedTopic(selectedTopic)
                                            setSelectedTopicValue(selectedTopic?.value || null)
                                            localStorage.setItem(
                                                "selectedTopicStorageBusiness",
                                                JSON.stringify({ value: selectedTopic?.value, label: selectedTopic?.label })
                                            )
                                        } else {
                                            localStorage.removeItem("selectedTopicStorageBusiness")
                                            setSelectedTopic(null)
                                            setSelectedTopicValue(null)
                                        }
                                    }}
                                    theme={SelectThemeCustom}
                                />
                            )}

                        {/* Dropdown for category badges */}
                        {branding.businessAreaPageContent.relevantCategoryFilterVisible && (
                            <StyledSelect
                                styles={styledSelectStyles}
                                placeholder={strings.programSchedule.badgeAllText}
                                isMulti={false}
                                isSearchable={true}
                                isClearable={true}
                                options={branding.businessAreaPageContent.relevantCategoryFilterList}
                                value={
                                    currentSearch && currentSearch[currentSearchViewType]?.relevantCategoryFilter
                                        ? currentSearch[currentSearchViewType]?.relevantCategoryFilter
                                        : null
                                }
                                noOptionsMessage={() => strings.programSchedule.filterNoOptionsMessage}
                                onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                                    if (value === null) {
                                        const newSearch = { ...currentSearch }
                                        if (newSearch[currentSearchViewType]) {
                                            itemsOrder.forEach((item: any) => {
                                                switch (item) {
                                                    case "BUYERS":
                                                        newSearch[2]!.relevantCategoryFilter = null
                                                        break
                                                    case "SELLERS":
                                                        newSearch[1]!.relevantCategoryFilter = null
                                                        break
                                                    case "ALL":
                                                        newSearch[3]!.relevantCategoryFilter = null
                                                        break
                                                }
                                            })

                                            setCurrentSearch(newSearch)
                                            putSearchToStorage(newSearch)
                                        }
                                    } else {
                                        if (value != null && action.action === "select-option") {
                                            const option = value as OptionTypeBase
                                            const newFilter = { label: option.label, value: option.value }

                                            const newSearch = { ...currentSearch }
                                            if (newSearch[currentSearchViewType]) {
                                                itemsOrder.forEach((item: any) => {
                                                    switch (item) {
                                                        case "BUYERS":
                                                            newSearch[2]!.relevantCategoryFilter = newFilter
                                                            break
                                                        case "SELLERS":
                                                            newSearch[1]!.relevantCategoryFilter = newFilter
                                                            break
                                                        case "ALL":
                                                            newSearch[3]!.relevantCategoryFilter = newFilter
                                                            break
                                                    }
                                                })

                                                setCurrentSearch(newSearch)
                                            }
                                        }
                                    }
                                }}
                                theme={SelectThemeCustom}
                            />
                        )}

                        {/* MultiSelect Dropdown Offers */}
                        {currentSearchViewType === viewType.SELLERS &&
                            lookingforAndOfferingGroups?.map((group, index) => {
                                // placeholder for the dropdown
                                const placeholder =
                                    strings.myProfilePageContent.offersNeedsCategories.find((x) => x.id === group.id)
                                        ?.placeholderSelling ?? ""
                                // list of selected offers from the current search
                                const selectedOffersGrouped = currentSearch[currentSearchViewType]?.selectedOffers[group.id]
                                // first selected offer. We have single use for now
                                let value =
                                    selectedOffersGrouped && selectedOffersGrouped.length > 0
                                        ? (selectedOffersGrouped[0] as { value: string; label: string })
                                        : null
                                // if the selected value is not part of the available options, we deselect it
                                if (!value || group.categories.findIndex((element) => element.value === value?.value) < 0)
                                    value = null
                                return (
                                    <StyledSelect
                                        key={index}
                                        styles={styledSelectStyles}
                                        placeholder={placeholder}
                                        isMulti={false}
                                        isSearchable={true}
                                        isClearable={true}
                                        options={group.categories}
                                        value={value}
                                        onChange={(
                                            value: ValueType<OptionTypeBase, boolean>,
                                            action: ActionMeta<OptionTypeBase>
                                        ) => {
                                            const newOffers = { ...currentSearch[currentSearchViewType]?.selectedOffers }
                                            if (value != null && action.action === "select-option") newOffers[group.id] = [value]
                                            else newOffers[group.id] = []

                                            const newSearch = { ...currentSearch }
                                            if (
                                                newSearch[currentSearchViewType] &&
                                                newSearch[currentSearchViewType]!.selectedOffers
                                            ) {
                                                newSearch[currentSearchViewType]!.selectedOffers = newOffers
                                                setCurrentSearch(newSearch)
                                            }
                                        }}
                                        theme={SelectThemeCustom}
                                    />
                                )
                            })}

                        {/* MultiSelect Dropdown Needs */}
                        {currentSearchViewType === viewType.BUYERS &&
                            lookingforAndOfferingGroups?.map((group, index) => {
                                // placeholder for the dropdown
                                const placeholder =
                                    strings.myProfilePageContent.offersNeedsCategories.find((x) => x.id === group.id)
                                        ?.placeholderBuying ?? ""
                                // list of selected needs from the current search
                                const selectedNeedsGrouped = currentSearch[currentSearchViewType]?.selectedNeeds[group.id]
                                // first selected need. We have single use for now
                                let value =
                                    selectedNeedsGrouped && selectedNeedsGrouped.length > 0
                                        ? (selectedNeedsGrouped[0] as { value: string; label: string })
                                        : null
                                // if the selected value is not part of the available options, we deselect it
                                if (!value || group.categories.findIndex((element) => element.value === value?.value) < 0)
                                    value = null
                                return (
                                    <StyledSelect
                                        key={index}
                                        styles={styledSelectStyles}
                                        placeholder={placeholder}
                                        isMulti={false}
                                        isSearchable={true}
                                        isClearable={true}
                                        options={group.categories}
                                        value={value}
                                        onChange={(
                                            value: ValueType<OptionTypeBase, boolean>,
                                            action: ActionMeta<OptionTypeBase>
                                        ) => {
                                            const newNeeds = { ...currentSearch[currentSearchViewType]?.selectedNeeds }
                                            if (value != null && action.action === "select-option") newNeeds[group.id] = [value]
                                            else newNeeds[group.id] = []

                                            const newSearch = { ...currentSearch }
                                            if (
                                                newSearch[currentSearchViewType] &&
                                                newSearch[currentSearchViewType]!.selectedNeeds
                                            )
                                                newSearch[currentSearchViewType]!.selectedNeeds = newNeeds
                                            setCurrentSearch(newSearch)
                                        }}
                                        theme={SelectThemeCustom}
                                    />
                                )
                            })}
                        {/* MultiSelect Dropdown Resident Country */}
                        {currentSearchViewType === viewType.BUYERS && branding.businessAreaPageContent.showCountriesDropdown && (
                            <StyledSelect
                                styles={styledSelectStyles}
                                placeholder={strings.businessAreaPageContent.countryDropdownPlaceholer}
                                isMulti={false}
                                isSearchable={true}
                                isClearable={true}
                                options={countries}
                                value={
                                    currentSearch && currentSearch[currentSearchViewType]?.selectedCountries?.length! > 0
                                        ? currentSearch[currentSearchViewType]?.selectedCountries[0]
                                        : null
                                }
                                onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                                    let newValue: OptionTypeBase[] = []
                                    if (value != null && action.action === "select-option") newValue = [value]

                                    const newSearch = { ...currentSearch }
                                    if (newSearch[currentSearchViewType] && newSearch[currentSearchViewType]!.selectedCountries) {
                                        newSearch[currentSearchViewType]!.selectedCountries = newValue
                                        setCurrentSearch(newSearch)
                                    }
                                }}
                                theme={SelectThemeCustom}
                            />
                        )}

                        <DesktopVersionContainer>
                            <SearchBarComponent
                                currentSearch={currentSearch}
                                setCurrentSearch={setCurrentSearch}
                                strings={strings}
                                updateBookmarkFilter={updateBookmarkFilter}
                                currentSearchViewType={currentSearchViewType}
                            />
                        </DesktopVersionContainer>

                        {(currentSearchViewType !== viewType.ALL ||
                            (currentSearchViewType === viewType.ALL &&
                                branding.businessAreaPageContent.relevantCategoryFilterVisible) ||
                            branding.businessAreaPageContent.relevantTopicFilterVisible) && (
                            <FilterButtonsComponent
                                onFilterButtonClick={() => {
                                    setFilterDisabled(true)
                                    startSearch(0)
                                }}
                                onResetFilterButtonClick={() => resetSearch()}
                                filterDisabled={filterDisabled}
                                strings={strings}
                                filterReset={filterReset}
                                isSearchEmpty={isSearchEmpty()}
                            />
                        )}
                    </FilterBarRoot>
                </SearchDropdownRoot>
            </HeaderSectionRoot>
            {content}
            <CommunicationModals show={modalType} contact={selectedUser} onHide={() => setModalType("none")} />
            {!userLink.wasLeadFinderNoticeShown() && branding.businessAreaPageContent.showEditProfileNotice && (
                <ConfirmationModal
                    title={strings.businessAreaPageContent.editProfileNoticeTitle}
                    text={strings.businessAreaPageContent.editProfileNoticeText}
                    acceptButtonText={strings.businessAreaPageContent.editProfileNoticeContinueToProfile}
                    accept={() => {
                        userLink.setLeadFinterNoticeShown()
                        history.push(editMyProfilePageRoute)
                    }}
                    dismissButtonText={strings.businessAreaPageContent.editProfileNoticeContinueWithoutProfil}
                    dismiss={() => userLink.setLeadFinterNoticeShown()}
                />
            )}
        </PageRootElement>
    )
}
/* #endregion */

export default BusinessAreaPageContent
/* #region  search in Local Storage */

const SearchBarComponentRoot = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SearchBarContainer = styled.div`
    svg {
        color: ${branding.primaryColor};
    }
    width: auto;

    @media (max-width: 1300px) {
        width: 100%;
    }

    @media ${device.mobile} {
        width: auto;
    }
`
interface SearchBarComponentProps {
    currentSearch: LeadFinderSearch
    setCurrentSearch: (value: LeadFinderSearch) => void
    strings: any
    updateBookmarkFilter: () => void
    currentSearchViewType: viewType
}

const SearchBarComponent: React.FunctionComponent<SearchBarComponentProps> = React.memo((props: SearchBarComponentProps) => {
    const { isMobile } = useWindowDimensions()
    const searchBarWidth = isMobile
        ? "72vw"
        : props.currentSearchViewType !== viewType.ALL ||
          (props.currentSearchViewType === viewType.ALL &&
              (branding.businessAreaPageContent.relevantCategoryFilterVisible ||
                  branding.businessAreaPageContent.relevantTopicFilterVisible))
        ? "auto"
        : "300px"

    function handleSearch(value: string) {
        const newSearch = { ...props.currentSearch }
        if (newSearch[props.currentSearchViewType] && newSearch[props.currentSearchViewType]!.searchString !== undefined) {
            newSearch[props.currentSearchViewType]!.searchString = value
            props.setCurrentSearch(newSearch)
        }
    }

    return (
        <SearchBarComponentRoot>
            <SearchBarContainer className={"ml-0"}>
                <SearchBar
                    setSearchParam={(searchString) => {
                        handleSearch(searchString)
                    }}
                    searchValue={props.currentSearch[props.currentSearchViewType]?.searchString ?? ""}
                    width={searchBarWidth}
                    placeholder={props.strings.sideIconBar.searchBarPlaceholder}
                    height={"38px"}
                    isSearchBar={true}
                    bottom={"12px"}
                    bottomEnd={"10px"}
                />
            </SearchBarContainer>
            <MobileVersionContainer>
                <OnlyBookmarksButton
                    bookmarkFilter={props.currentSearch.bookmarkFilter}
                    updateBookmarkFilter={props.updateBookmarkFilter}
                />
            </MobileVersionContainer>
        </SearchBarComponentRoot>
    )
})

const OnlyBookmarksButtonRoot = styled.div`
    display: flex;
    font-family: ${branding.font1};
    height: 38px;
    width: 54px;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border: 1px;
    border-style: solid;
    border-color: ${branding.programSpeakers.speakersListDividerColor ?? "#000"};
    color: ${branding.programSpeakers.speakersListDividerColor ?? "#000"};
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
`

interface OnlyBookmarksButtonProps {
    updateBookmarkFilter: () => void
    bookmarkFilter: boolean
}

export const OnlyBookmarksButton: React.FunctionComponent<OnlyBookmarksButtonProps> = (props: OnlyBookmarksButtonProps) => {
    return (
        <OnlyBookmarksButtonRoot onClick={() => props.updateBookmarkFilter()}>
            {props.bookmarkFilter
                ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark, width: "15", height: "15" })
                : IconBookmark({ fill: branding.sideIconBar.sideIconColorDark, width: "15", height: "15" })}
        </OnlyBookmarksButtonRoot>
    )
}

interface FilterButtonsComponentProps {
    onFilterButtonClick: () => void
    onResetFilterButtonClick: () => void
    filterDisabled: boolean
    strings: any
    filterReset: boolean
    isSearchEmpty: boolean
}

const FilterButtonsComponent: React.FunctionComponent<FilterButtonsComponentProps> = (props: FilterButtonsComponentProps) => {
    const content: JSX.Element = (
        <>
            {false && (
                <FilterButton onClick={() => props.onFilterButtonClick()} className={props.filterDisabled ? "disabled" : ""}>
                    {props.strings.businessAreaPageContent.filterButtonText}
                </FilterButton>
            )}

            {!props.isSearchEmpty && (
                <ResetFilterButton
                    onClick={() => props.onResetFilterButtonClick()}
                    className={props.filterDisabled ? "disabled" : ""}
                >
                    <FilterResetIconSpan>
                        {IconReset({ fill: branding.businessAreaPageContent.filterButtonBgColor })}{" "}
                    </FilterResetIconSpan>
                    <FilterResetTextSpan>{props.strings.businessAreaPageContent.resetFilterButtonText}</FilterResetTextSpan>
                </ResetFilterButton>
            )}
        </>
    )
    return (
        <>
            <DesktopVersionContainer>
                <FilterBarRoot style={{ padding: 0 }}>{content}</FilterBarRoot>
            </DesktopVersionContainer>
            <MobileVersionContainer>
                <Row style={{ marginLeft: "0px", marginRight: "0px", marginTop: "15px" }}>
                    <FilterBarRoot style={{ padding: 0 }}>{content}</FilterBarRoot>
                </Row>
            </MobileVersionContainer>
        </>
    )
}

interface SearchValues {
    searchString: string
    selectedOffers: { [key: string]: OptionTypeBase[] }
    selectedNeeds: { [key: string]: OptionTypeBase[] }
    selectedCountries: OptionTypeBase[]
    relevantCategoryFilter: { label: string; value: string } | null
}
interface LeadFinderSearch {
    viewType: viewType
    viewMode: viewModeType
    bookmarkFilter: boolean
    1: SearchValues // viewType.SELLERS
    2: SearchValues // viewType.BUYERS
    3?: SearchValues // viewType.ALL
}
function getEmptySearch(): LeadFinderSearch {
    const relevantsTabEnabled = branding.businessAreaPageContent.crsTabsItemOrder.includes("ALL")

    return {
        viewType: relevantsTabEnabled ? viewType.ALL : viewType.BUYERS,
        viewMode: viewModeType.TILES,
        bookmarkFilter: false,
        1: {
            searchString: "",
            selectedOffers: {},
            selectedNeeds: {},
            selectedCountries: [],
            relevantCategoryFilter: null
        },
        2: {
            searchString: "",
            selectedOffers: {},
            selectedNeeds: {},
            selectedCountries: [],
            relevantCategoryFilter: null
        },
        3: relevantsTabEnabled
            ? {
                  searchString: "",
                  selectedOffers: {},
                  selectedNeeds: {},
                  selectedCountries: [],
                  relevantCategoryFilter: null
              }
            : undefined
    }
}

const currentSearchKey = "virtualGuide-leadFinderSearch"
function getSearchFromStorage(relevantsTabEnabled: boolean): LeadFinderSearch {
    const storedSearchString = localStorage.getItem(currentSearchKey)
    if (storedSearchString === null) return getEmptySearch()
    const storedSearch = JSON.parse(storedSearchString)

    if (!relevantsTabEnabled && storedSearch[viewType.ALL]) {
        localStorage.removeItem(currentSearchKey)
        return getEmptySearch()
    } else if (relevantsTabEnabled && !storedSearch[viewType.ALL]) {
        localStorage.setItem(currentSearchKey, JSON.stringify(getEmptySearch()))
    }

    if ((storedSearch[viewType.BUYERS] && storedSearch[viewType.SELLERS]) || (relevantsTabEnabled && storedSearch[viewType.ALL]))
        return storedSearch as LeadFinderSearch
    return getEmptySearch()
}

function putSearchToStorage(search: LeadFinderSearch) {
    localStorage.setItem(currentSearchKey, JSON.stringify(search))
}
/* #endregion */
