import { defaultLogger as logger } from "../../globalStates/AppState"
import { Company, Product, EventDate, Category } from "../../backendServices/Types"
import { fetchDataWebService } from "../../backendServices/BackendServicesUtils"

export interface StandInformation {
    standDetails: StandDetails
    organizations?: Company[]
    products?: Product[]
    eventDates?: EventDate[]
}

interface StandDetails {
    id: string
    name: string
    displaytype: string
    type?: string
    sqm?: string
    width?: string
    depth?: string
    pricesqm?: string
    price?: string
    description?: string
}

export async function getStandInformation(
    hall: string,
    stand: string,
    signal?: AbortSignal
): Promise<StandInformation | undefined> {
    const params = {
        hall: hall,
        stand: stand
    }
    try {
        const data = await fetchDataWebService("/standdetailsinformation", params, signal)
        return data
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/standdetailsinformation",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return undefined
    }
}

interface CategoryStandListResponse {
    categoryStandList: [{ cat: Category }]
}

export async function getCategoryStandlist(hall: string, signal?: AbortSignal): Promise<CategoryStandListResponse | undefined> {
    const params = {
        hallId: hall
    }
    try {
        const data = await fetchDataWebService("/categorystandlist", params, signal)
        return data
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/categorystandlist",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return undefined
    }
}
